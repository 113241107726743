import { RangeFilterType } from '../enums/range-filter-type.enum';

export const getRangeFilterNameByType = (filterType: RangeFilterType) => {
    switch (filterType) {
        case RangeFilterType.ACO_TOTAL_PATIENT_POPULATION_BENEFICIARIES:
            return 'Total Patient Population Beneficiaries';
        case RangeFilterType.FOLLOWED_PATIENTS:
            return 'Followed Patients';
        case RangeFilterType.PHYSICIAN_MEDICARE_PATIENTS:
            return 'Physician Medicare Patients (Professional)';
        case RangeFilterType.PHYSICIAN_MEDICARE_PATIENTS_PLUS_OP:
            return 'Physician Medicare Patients (Professional + OP)';
        case RangeFilterType.PHYSICIAN_HHA_PATIENTS:
            return 'Physician HHA Patients';
        case RangeFilterType.HOME_HEALTH_UTILIZATION:
            return 'Home Health Utilization %';
        case RangeFilterType.LEVEL_OF_AFFILIATION:
            return 'Hospital Affiliation %';
    }
};
