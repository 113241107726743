<div class="alert-wrapper">
    <div
            *ngIf="showAlert"
            [class.alert-danger]="error"
            [class.alert-success]="success"
            [class.alert-warning]="warning"
            [ngClass]="{ slideOutUp: slideOutUp }"
            class="alert alert-dismissible animated slideInDown"
            role="alert">
        <strong>{{ message }}</strong>
        <button
                (click)="reset()"
                aria-label="Close"
                class="btn-close"
                data-bs-dismiss="alert"
                type="button">
        </button>
    </div>
</div>
