<div class="row p-3">
	<div class="col-auto mx-auto">
		<div class="row text-center">
			<div class="col cover-page-width">
				<div class="row py-md-5">
					<div class="col-12 logo">
						<img class="img-fluid" height="350px" src="../../assets/img/logo.svg" width="350px"/>
					</div>
				</div>
				<div class="row text-center">
					<div class="col">
						<p class="text-primary fa-15x">{{ landingMessage }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
