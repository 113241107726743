import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CHART_TYPE } from '../../enums/chart-type.enum';
import { Observable, Subject } from 'rxjs';
import { ChartConfig } from '../../models/chart-config.model';
import { Target } from '../../models/target.model';


@Component({
    selector: 'trella-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class TrellaChartComponent implements OnInit {
    @ViewChild('chartimage', {static: false}) public chartImage: any;
    @Input() chartInfo: ChartConfig;
    @Input() dataChange: Observable<ChartConfig>;
    @Input() activeLegendItems = [];
    @Input() target: Target;

    @Output() clickEvent: EventEmitter<any> = new EventEmitter();
    @Output() activeLegendItemsChange = new EventEmitter();

    dataChangeSub = new Subject<ChartConfig>();
    CHART_TYPE = CHART_TYPE;

    ngOnInit() {
        this.dataChange.subscribe(chartInfo => this.dataChangeSub.next(chartInfo));
    }

    onClickEvent(options: any) {
        this.clickEvent.emit(options);
    }
}
