<div [class.disabled]="isDisabled" class="row g-0">
    <div class="col d-flex flex-column">
        <input [(ngModel)]="userSearchString" [disabled]="disabled" [placeholder]="searchBoxPlaceholder"
               class="form-control w-100 search-input" type="text"/>
        <div class="border rounded">
            <cdk-virtual-scroll-viewport [style.height]="height" itemSize="26">
                <div *ngIf="!singleSelect">
                    <mat-checkbox
                            (change)="toggleSelectAll(opt)"
                            *ngIf="showSelectAll"
                            [checked]="selectAllChecked"
                            [disabled]="disabled"
                            class="text-input ps-2"
                            color="primary">
                        {{ selectAllText }}
                    </mat-checkbox>
                    <div *cdkVirtualFor="let opt of options | search: 'display':userSearchString; templateCacheSize: 0">
                        <mat-checkbox
                                (change)="toggleVal(opt)"
                                [checked]="isChecked(opt)"
                                [disabled]="disabled"
                                [ngClass]="{ 'border': hasAllOption(opt), 'rounded': hasAllOption(opt) }"
                                [style.width]="'100%'"
                                class="text-input ps-2"
                                color="primary">
                            {{ opt.display }}
                        </mat-checkbox>
                    </div>
                </div>
                <div *ngIf="singleSelect" class="ps-2">
                    <mat-radio-group [(ngModel)]="selectedOption" aria-label="Select an option" class="radio-group">
                        <div *cdkVirtualFor="let opt of options | search: 'display':userSearchString; templateCacheSize: 0">
                            <mat-radio-button (change)="toggleVal(opt)" [value]="opt"
                                              class="radio-button">
                                {{ opt.display }}
                            </mat-radio-button>
                        </div>
                    </mat-radio-group>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>
