import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {
	ActionGroup,
	ActionGroupContract,
	UpdateActionGroupContract
} from '@appcore/models/directory/action-group.model';

@Injectable({
	providedIn: 'root'
})
export class ActionGroupApi {
	private _controllerName = 'ActionGroup';
	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getAll(): Observable<ActionGroupContract[]> {
		return this.api.get(this._controllerName);
	}

	get(actionGroupId: number): Observable<ActionGroupContract> {
		return this.api.get(this._controllerName, `${actionGroupId}`);
	}

	create(actionGroup: ActionGroup): Observable<number> {
		return this.api.post(this._controllerName, null, actionGroup);
	}

	update(actionGroup: UpdateActionGroupContract): Observable<void> {
		return this.api.put(this._controllerName, null, actionGroup);
	}

	delete(actionGroupId: any): Observable<void> {
		if (!actionGroupId)
			throw new Error('Action Group Id Required');


		return this.api.delete(this._controllerName, `${actionGroupId}`);
	}

}
