<div *ngIf="ratingNotApplicable && !noRating" class="text-data">No Star Rating Available</div>
<div *ngIf="!ratingNotApplicable && !noRating" class="d-inline star-rating">
    <ul class="d-inline list-inline">
        <li *ngFor="let item of wholeStars" class="list-inline-item">
            <i class="fas fa-star star_color"></i>
        </li>
        <li *ngFor="let item of halfStars" class="list-inline-item">
            <i class="fas fa-star-half-alt star_color"></i>
        </li>
        <li *ngFor="let item of emptyStars" class="list-inline-item">
            <i class="far fa-star star_color"></i>
        </li>
    </ul>
</div>
