<div class="float-start px-0 trella-filter-group-holder">
	<div class="row pt-2 pb-1">
		<div class="col fw-bold">
			Filter By
		</div>
	</div>

	<div class="d-flex flex-row align-items-center">
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_COMPANY_ID) || isIncluded(FILTER_TYPE.CODESET_COMPANY)"
			[caption]="'Company'"
			[filterType]="FILTER_TYPE.ADMIN_COMPANY_ID"
			[options]="companyOptions"
			class="pe-2 pt-1 admin-company-id-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_COMPANY_ENABLED)"
			[caption]="'Status'"
			[filterType]="FILTER_TYPE.ADMIN_COMPANY_ENABLED"
			[options]="statusOptions"
			class="pe-2 pt-1 admin-company-enabled-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_ASSIGN_ANYWHERE_PRODUCT)"
			[caption]="'Assign Anywhere Product'"
			[filterType]="FILTER_TYPE.ADMIN_ASSIGN_ANYWHERE_PRODUCT"
			[options]="assignAnywhereProductOptions"
			class="pe-2 pt-1 admin-assign-anywhere-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_NPI_GROUP_TYPE)"
			[caption]="'NPI Group Type'"
			[filterType]="FILTER_TYPE.ADMIN_NPI_GROUP_TYPE"
			[options]="npiGroupTypeOptions"
			class="pe-2 pt-1 admin-npi-group-type-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_USER_ENABLED)"
			[caption]="'Status'"
			[filterType]="FILTER_TYPE.ADMIN_USER_ENABLED"
			[options]="statusOptions"
			class="pe-2 pt-1 admin-user-enabled-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_COMPANY_MODULE)"
			[caption]="'Product'"
			[filterType]="FILTER_TYPE.ADMIN_COMPANY_MODULE"
			[options]="productOptions"
			class="pe-2 pt-1 admin-company-product-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_USER_ROLE)"
			[caption]="'Role'"
			[filterType]="FILTER_TYPE.ADMIN_USER_ROLE"
			[options]="roleOptions"
			class="pe-2 pt-1 admin-user-role-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_USER_MODULE)"
			[caption]="'Product'"
			[filterType]="FILTER_TYPE.ADMIN_USER_MODULE"
			[options]="productOptions"
			class="pe-2 pt-1 admin-user-product-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_COMPANY_MARKET)"
			[caption]="'Market'"
			[filterType]="FILTER_TYPE.ADMIN_COMPANY_MARKET"
			[options]="marketOptions"
			class="pe-2 pt-1 admin-company-market-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_USER_MARKET)"
			[caption]="'Market'"
			[filterType]="FILTER_TYPE.ADMIN_USER_MARKET"
			[options]="marketOptions"
			class="pe-2 pt-1 admin-user-market-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_USER_MANAGER)"
			[caption]="'Manager'"
			[filterType]="FILTER_TYPE.ADMIN_USER_MANAGER"
			[options]="managerOptions"
			class="pe-2 pt-1 admin-user-manager-filter"
		></trella-simple-filter>
		<trella-date-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_REPORT_START_DATE)"
			[caption]="'Start Date'"
			[filterType]="FILTER_TYPE.ADMIN_REPORT_START_DATE"
			[selectedDate]="selectedStartDate"
			class="pe-2 pt-1 admin-report-start-filter"
		></trella-date-filter>
		<trella-date-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_REPORT_END_DATE)"
			[caption]="'End Date'"
			[filterType]="FILTER_TYPE.ADMIN_REPORT_END_DATE"
			[selectedDate]="selectedEndDate"
			class="pe-2 pt-1 admin-report-end-filter"
		></trella-date-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.ADMIN_USER_ID)"
			[caption]="'User'"
			[filterType]="FILTER_TYPE.ADMIN_USER_ID"
			[options]="userOptions"
			class="pe-2 pt-1 admin-user-id-filter"
		></trella-simple-filter>
		<trella-simple-filter
			(selectionsChanged)="handleSelectionsChanged($event)"
			*ngIf="isIncluded(FILTER_TYPE.USAGE)"
			[caption]="'Usage Options'"
			[filterType]="(FILTER_TYPE.USAGE)"
			[options]="usageOptions"
			class="pr-2 pt-1 admin-usage-filter"
		></trella-simple-filter>

		<button (click)="reset()" class="btn btn-secondary btn-sm ms-2" type="button">
			Clear Filters
		</button>
	</div>
</div>
