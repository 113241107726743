import { Component, Input, OnInit } from '@angular/core';
import { ComponentWithSubscription } from '../../../components/component-with-subscription';
import { GridInfo } from '../../../models/grid-info.model';
import { FormattedData } from '../../../interfaces/formatted-data.interface';
import { Utils } from '../../../helpers/Utils';
import { GridService } from '../../../services/grid.service';

@Component({
    selector: 'app-row-detail-grid',
    templateUrl: './row-detail-grid.component.html',
    styleUrls: ['./row-detail-grid.component.scss']
})
export class RowDetailComponent extends ComponentWithSubscription implements OnInit {
    @Input() dataItem: unknown;
    @Input() parentKey: string;
    @Input() parentGridInfo: GridInfo;
    @Input() rowLinkFactory: (key: Record<string, FormattedData>, info: GridInfo) => string;

    gridInfo: GridInfo;
    key = Utils.generateGuid();
    loadingData = true;

    constructor(private gridService: GridService) {
        super();
    }

    get showGrid() {
        return this.parentKey.length && !!this.parentGridInfo;
    }

    ngOnInit() {
        this.subscribe<GridInfo>(this.gridService.childGridInfoSet, this.parentKey, childGridInfo => this.setChildGridInfo(childGridInfo));
        this.subscribe<boolean>(this.gridService.loading, this.key, isLoading => {
            // Forces component to wait until next macro change check to prevent race conditions
            setTimeout(() => {
                this.loadingData = isLoading;
            });
        });
        this.gridService.createChildSubcription(this.parentKey, this.key);
        this.gridService.requestDetails(this.key, this.dataItem);
    }

    setChildGridInfo(childGridInfo) {
        this.gridInfo = childGridInfo;
        this.gridInfo.showSubGridToolBar = this.parentGridInfo.showExportButtons;
    }
}
