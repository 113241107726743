import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../services/permission.service';

const OPERATORS = {
	AND: 'AND',
	OR: 'OR'
};

@Directive({
	selector: '[hasPermission]'
})
/**
 * Example usage:
 *
 * <ng-container *hasPermission="['page.users.show', 'trella.customer.admin']">
 * <!-- content -->
 * </ng-container
 *
 * or if the html component doesn't have a structural directive already, you can add it directly on it.
 *
 * Shows the element if the user has access to the specifed permissions.
 */
export class HasPermissionDirective implements OnInit {
	_operator: string = OPERATORS.AND;
	private permissions = [];

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private permissionsService: PermissionsService
	) {
	}

	@Input()
	set hasPermission(permissions: string[]) {
		this.permissions = permissions;
	}

	@Input()
	set hasPermissionOperator(operator: string) {
		this._operator = operator.toUpperCase();
	}

	ngOnInit() {
		this.updateView();
	}

	private updateView() {
		if (this.checkPermission()) 
			this.viewContainer.createEmbeddedView(this.templateRef);
		
		else 
			this.viewContainer.clear();
		
	}

	private checkPermission() {
		if (this._operator === OPERATORS.OR) 
			return this.permissions.some(permission => this.permissionsService.hasPermissions(permission));
		
		return this.permissionsService.hasPermissions(...this.permissions);
	}
}
