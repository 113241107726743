import { Injectable } from '@angular/core';
import { GridInfo } from '../models/grid-info.model';
import { GridExport } from '../models/grid/grid-export.model';

@Injectable({
    providedIn: 'root'
})
export class GridExportService {

    private _getExportData: (gridInfo: GridInfo, dataItem?: any) => Promise<GridExport>;

    configure(getExportData: (gridInfo: GridInfo, dataItem?: any) => Promise<GridExport>) {
        this._getExportData = this._getExportData ?? getExportData;
    }

    async getDataToExport(gridInfo: GridInfo, dataItem?: any): Promise<GridExport> {
        return this._getExportData ?
            this._getExportData(gridInfo, dataItem) :
            new Promise<GridExport>((resolve, reject) => resolve(GridExport.withNoData(gridInfo)));
    }
}
