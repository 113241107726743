export class Node {
    children: Node[];
    level?: string;
    display: string;
    value: string;
    parent?: Node;
    checked: boolean;

    toString(): string {
        let text = this.display;
        let current = {...this} as Node;

        while (current.parent) {
            text = `${current.parent.display} / ${text}`;
            current = current.parent;
        }
        return text;
    }

    /**
     * Used by ngFor trackBy so that the UI can refresh as items get checked.
     */
    getHash(): string {
        return `${this.toString()} / ${this.checked}`;
    }
}
