import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MakeProvider } from '../../components/AbstractValueAccessor';
import { Selection } from '../../interfaces/selection.interface';

@Component({
    selector: 'trella-radio-list-box',
    templateUrl: './radio-list-box.component.html',
    styleUrls: ['./radio-list-box.component.scss'],
    providers: [MakeProvider(RadioListBoxComponent)]
})
export class RadioListBoxComponent implements ControlValueAccessor {
    @Input() options: Selection[] = [];
    @Input() disabled = false;
    @Input() height = '450px';
    @Input() value: Selection = null;
    @Output() change = new EventEmitter<Selection>();

    userSearchString = '';
    showSelections = false;
    onChange: (newValue: Selection) => void = () => {
    };

    onTouch: () => void = () => {
    };

    writeValue(selection: Selection): void {
        this.value = selection;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    onClick() {
        this.showSelections = !this.showSelections;
    }

    onSelectionChange(selection: Selection) {
        this.setValue(selection);
    }

    hideComponent(): void {
        this.showSelections = false;
    }

    private setValue(value: Selection) {
        if (!this.disabled) {
            this.value = value;
            this.onChange(this.value);
            this.onTouch();
            this.change.emit(this.value);
            this.showSelections = false;
        }
    }


}
