import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ICustomer } from '../../shared/interfaces/customer.interface';

@Component({
	selector: 'app-customer-filter',
	templateUrl: './customer-filter.component.html',
	styleUrls: ['./customer-filter.component.scss']
})

export class CustomerFilterComponent implements OnInit {
	@Input() showLabel = true;
	@Input() showAllOption = true;
	@Input() showSelectOption = false;

	@Output() customerChanged = new EventEmitter<any>();

	customerData: ICustomer[] = [];
	defaultItem: any = {customerId: null, customerName: 'ALL'};
	selectedCustomer: any;

	constructor(private companyService: CompanyService) {
	}

	ngOnInit() {
		this.getCustomers();
	}

	getCustomers() {
		this.companyService.getAllObsolete().subscribe(data => {
			this.customerData = data;

			if (!this.showAllOption && this.showSelectOption) 
				this.defaultItem = {customerId: null, customerName: 'Please select'};
			
			else if (!this.showAllOption) {
				this.defaultItem = null;
				this.selectedCustomer = {customerid: data[0].customerId, customerName: data[0].customerName};
			}
		});
	}

	onCustomerChange() {
		this.customerChanged.emit(this.selectedCustomer);
	}
}
