import { Pipe, PipeTransform } from '@angular/core';
import { USER_TYPE } from '../../../enums/user-type.enum';
import { USER_TYPE_NAME } from '../../../enums/user-type-name.enum';

@Pipe({name: 'userType'})
export class UserTypePipe implements PipeTransform {
    transform(type: USER_TYPE): string {
        switch (type) {
            case USER_TYPE.Default:
                return USER_TYPE_NAME.Default;
            case USER_TYPE.Manager:
                return USER_TYPE_NAME.Manager;
            default:
                return null;
        }
    }
}
