
export enum GridCellEnumType {
    Check = 'check',
    // eslint-disable-next-line no-shadow
    DestinationType = 'destinationType',
    UpArrow = 'upArrow',
    DownArrow = 'downArrow',
    ComparisonHigherGood = 'comparisonHigherGood',
    ComparisonLowerGood = 'comparisonLowerGood'

}
