import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CrmLogoutComponent } from './crmLogout/crmlogout.component';
import { DataEulaComponent } from './data-eula/data-eula.component';
import { EulaComponent } from './eula/eula.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RedirectComponent } from './redirect/redirect.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginGuard } from './shared/guards/login.guard';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';
import { ROUTE_CONSTANTS } from './shared/constants/route-constants.constant';

export const AppRootRoutes = [
	{path: ROUTE_CONSTANTS.login.route, component: LoginComponent, canActivate: [LoginGuard]},
	{path: ROUTE_CONSTANTS.login.route + '/:source', component: LoginComponent, canActivate: [AuthGuard]},
	{path: ROUTE_CONSTANTS.logout.route, component: LogoutComponent},
	{path: ROUTE_CONSTANTS.crmlogout.route, component: CrmLogoutComponent},
	{path: ROUTE_CONSTANTS.landing.route, component: LandingComponent},
	{path: ROUTE_CONSTANTS.redirect.route, component: RedirectComponent},
	{path: ROUTE_CONSTANTS.unauthorized.route, component: UnauthorizedAccessComponent},
	{
		path: ROUTE_CONSTANTS.eula.route,
		component: EulaComponent,
		canActivate: [AuthGuard]
	},
	{
		path: ROUTE_CONSTANTS.dataEula.route,
		component: DataEulaComponent,
		canActivate: [AuthGuard]
	},
	{path: '', canActivate: [AuthGuard], loadChildren: () => import('./main/main.module').then(m => m.MainModule)},
	{path: '**', component: PageNotFoundComponent}
];

@NgModule({
	imports: [
		RouterModule.forRoot(AppRootRoutes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
