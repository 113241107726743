import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogButtonType } from '../enums/dialog-button-type.enum';
import { TrellaDialogResult } from '../interfaces/trella-dialog-result.interface';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class AlertDialogService {
    private _closed = new BehaviorSubject<TrellaDialogResult>(null);
    private _opened = new BehaviorSubject<AlertDialogComponent>(null);

    public closed = this._closed.asObservable();
    public opened = this._opened.asObservable();

    reportClosed(dialog: AlertDialogComponent, result: DialogButtonType) {
        this._closed.next({dialog, result});
    }

    reportOpened(dialog: AlertDialogComponent) {
        this._opened.next(dialog);
    }
}
