import { GridColumn } from './grid-column.model';
import { DetailRowType } from '../enums/detail-row-type.enum';
import { GridAbilities } from './grid-abilities.model';
import { NpiType } from '../enums/npi-type.enum';
import { PROVIDER_TYPE } from '../enums/provider-type.enum';
import { GridOptions } from './grid-options.model';
import { PostNotes } from '../interfaces/post-notes.interface';
import { ColumnComponent, ColumnVisibilityChangeEvent, PagerSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ReportConfigJson } from '../interfaces/report-config-json.interface';
import { ColumnConfigSlim } from '../interfaces/column-config-slim.interface';

export class GridInfo {
    attributes: { [key: string]: string; };
    columns: GridColumn[] = [];
    detailRowType: DetailRowType;
    originalColumns: GridColumn[] = [];
    gridAbilities?: GridAbilities = new GridAbilities();
    npiField?: string;
    keyField?: string;
    npiType: NpiType | PROVIDER_TYPE;
    reportName: string;
    hasDetailRows?: boolean;
    hideCountAndPageList: boolean;
    hideDisplayName: boolean;
    title: string;
    permissionExport?: string;
    showExportButtons = true;
    rowDefinitions?: string[];
    gridOptions: GridOptions;
    postNotes?: PostNotes;
    pageable: PagerSettings = {
        pageSizes: [25, 50, 100, 200]
    };
    skip = 0;
    showNetworksButton = false;
    showColumnSelector = true;
    showSubGridToolBar? = false;
    showMasterSearch = false;
    sort?: Array<SortDescriptor> = [];
    take = 25;
    lockCheckboxColumn = false;
    displayName?: string;

    constructor(config: ReportConfigJson, reportName: string) {
        if (!config) 
            return;
        

        const {skip, take} = config;

        this.reportName = reportName;
        this.detailRowType = config.detailRowType;
        this.npiType = config.npiType as NpiType || config.providerType as NpiType;
        this.npiField = config.npiField;
        this.keyField = config.keyField || config.npiField;
        this.title = config.title || config.reportName || reportName;
        this.displayName = config.displayName || config.title;
        this.hasDetailRows = config.hasDetailRows;
        this.hideCountAndPageList = config.hideCountAndPageList;
        this.hideDisplayName = config.hideDisplayName;
        this.permissionExport = config.permissionExport;
        this.rowDefinitions = config.rowDefinitions;
        this.postNotes = config.postNotes;
        this.showNetworksButton = config.showAddNetworksButton || this.showNetworksButton;
        this.showColumnSelector = config.showColumnSelector !== null ? config.showColumnSelector : this.showColumnSelector;
        this.showMasterSearch = config.showMasterSearch;
        this.columns = config.columns.map(x => new GridColumn(x));
        this.originalColumns = [...this.columns];
        this.skip = skip;
        this.take = take || this.take;
        this.pageable.pageSizes = config.pageSizes || this.pageable.pageSizes;
        this.lockCheckboxColumn = config.lockCheckboxColumn;
        this.attributes = config.attributes;
        this.gridOptions = new GridOptions();

        if (config.gridAbilities) 
            this.gridAbilities = {...this.gridAbilities, ...config.gridAbilities};
        

        if (config.disableSort) 
            this.gridAbilities.sortable = false;
        
    }

    get isFilterable() {
        return this.gridAbilities && this.gridAbilities.filterable;
    }

    mergeColumnChangeEvent(e: ColumnVisibilityChangeEvent) {
        const changedColumns: ColumnConfigSlim[] = e.columns.map(x => {
            const col: ColumnComponent = x as any;
            return {
                field: col.field,
                hidden: col.hidden
            };
        });

        changedColumns.forEach(x => {
            const currentRef = this.columns.find(current => current.field === x.field);
            if (!currentRef) 
                return;
            

            currentRef.hidden = x.hidden;
        });
    }
}
