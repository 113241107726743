import { ColumnComponent, ColumnVisibilityChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { LegacyGridAbilities } from './legacy-grid-abilities';
import { GridColumn } from './grid-column';
import { QueryOptions } from './query-options';
import { ReportConfigJson } from './report-config';
import { IFilterSlim } from '../interfaces/filter-slim.interface';
import { PostNotes } from '@appcore/interfaces/post-notes.interface';
import { GridConfigSlim } from '@appcore/interfaces/grid-config-slim.interface';
import { ColumnConfigSlim } from '@appcore/interfaces/column-config-slim.interface';

export class LegacyGridInfo {
	columns: GridColumn[] = [];
	_data: GridDataResult;
	emitDataChange = new Subject<GridDataResult>();
	exportTitle?: string;
	footer: any;
	gridAbilities?: LegacyGridAbilities = new LegacyGridAbilities();
	queryOptions?: QueryOptions = new QueryOptions();
	npiField?: string;
	selectedRows: string[] = [];
	providerType: any;
	reportFilters: IFilterSlim[];
	reportName: string;
	postNotes?: PostNotes;
	getNpiFn: () => string;

	get npi() {
		if (!this.getNpiFn || typeof this.getNpiFn !== 'function')
			return null;


		return this.getNpiFn();
	}

	get data(): GridDataResult {
		return this._data;
	}

	set data(data: GridDataResult) {
		this._data = data;
		this.emitDataChange.next(data);
	};

	constructor(config: ReportConfigJson, reportName: string, configSlim?: GridConfigSlim) {
		const {skip, take} = config;
		this.reportName = reportName;
		this.providerType = config.providerType;
		this.npiField = config.npiField;
		this.postNotes = config.postNotes;
		this.exportTitle = config.exportTitle;

		this.columns = config.columns.map(x => new GridColumn(x));
		this.queryOptions.skip = skip;
		this.queryOptions.take = take || 25; // default page is 25
		this.gridAbilities.filterable = config.columns.every(x => !x.filterable);
		this.mergeConfigSlim(configSlim);
	}

	mergeConfigSlim(configSlim: GridConfigSlim) {
		if (!configSlim)
			return;


		this.queryOptions.sort = configSlim.sortConfig || [];

		if (!configSlim.columnConfig || !configSlim.columnConfig.length)
			return;


		configSlim.columnConfig.forEach(x => {
			const currentRef = this.columns.find(current => current.field === x.field);
			if (!currentRef)
				return;


			currentRef.hidden = x.hidden;
		});
	}

	mergeColumnChangeEvent(e: ColumnVisibilityChangeEvent) {
		const changedColumns: ColumnConfigSlim[] = e.columns.map(x => {
			const col: ColumnComponent = x as any;
			return {
				field: col.field,
				hidden: col.hidden
			};
		});

		changedColumns.forEach(x => {
			const currentRef = this.columns.find(current => current.field === x.field);
			if (!currentRef)
				return;


			currentRef.hidden = x.hidden;
		});
	}
}
