import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { ListApi } from 'src/app/api/list.api';
import { AssignAnywhereService } from 'src/app/shared/services/assign-anywhere.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { MarketService } from 'src/app/shared/services/market.service';
import { NpigroupService } from 'src/app/shared/services/npigroup.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { RoleService } from 'src/app/shared/services/role.service';
import { AdminFilterSelections } from '../../shared/models/admin-filter-selections';
import { UserService } from '../../shared/services/user.service';
import { ApplicationCacheService } from '../../shared/services/application-cache.service';
import { GUIDS } from '../../shared/constants/guids.constant';
import { USAGE_FILTER_OPTIONS } from '../../shared/constants/usage-filter-options.constant';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import { FilterRules } from '@appcore/interfaces/filter-rules.interface';
import { ProductName } from '@appcore/enums/product-name.enum';
import { CheckedSelection } from '@appcore/interfaces/checked-selection.interface';
import { FilterGroupService } from '@appcore/services/filter-group.service';
import { ALL_OPTION } from '@appcore/constants/constants';
import { CheckedSelectionGroup } from '@appcore/interfaces/checked-selection-group.interface';


@Component({
	selector: 'admin-filter-group',
	templateUrl: './admin-filter-group.component.html',
	styleUrls: ['./admin-filter-group.component.scss']
})
export class AdminFilterGroupComponent implements OnInit, AfterViewInit {
	@Input() disabledFilterTypes: FILTER_TYPE[] = [];
	@Input() filterTypes: FILTER_TYPE[];
	@Input() filterRules: FilterRules;
	@Input() includeAllCompaniesOption = false;
	@Input() productNames: ProductName[];
	@Output() selectionsChanged: Observable<AdminFilterSelections>;

	FILTER_TYPE = FILTER_TYPE;

	private _selectionsChanged: EventEmitter<AdminFilterSelections> = new EventEmitter<AdminFilterSelections>();
	private _selections: AdminFilterSelections = {};
	private assignAnywhereProductOptions: CheckedSelection[] = [];
	private companyOptions: CheckedSelection[] = [];
	private marketOptions: CheckedSelection[] = [];
	private managerOptions: CheckedSelection[] = [];
	private npiGroupTypeOptions: CheckedSelection[] = [];
	private statusOptions: CheckedSelection[] = [{id: 1, display: 'Enabled'}, {id: 0, display: 'Disabled'}];
	private productOptions: CheckedSelection[] = [];
	private roleOptions: CheckedSelection[] = [];
	private userOptions: CheckedSelection[] = [];
	private usageOptions: CheckedSelection[] = USAGE_FILTER_OPTIONS;

	private filterComponents = ['TRELLA-SIMPLE-FILTER', 'TRELLA-AFFILIATION', 'TRELLA-MULTI-LEVEL-FILTER', 'TRELLA-LOCATION', 'TRELLA-RANGE', 'TRELLA-DATE-FILTER'];

	constructor(private ref: ChangeDetectorRef,
				private applicationCacheService: ApplicationCacheService,
				private assignAnywhereService: AssignAnywhereService,
				private companyService: CompanyService,
				private filterGroupService: FilterGroupService,
				private npiGroupService: NpigroupService,
				private listApi: ListApi,
				private productService: ProductService,
				private marketService: MarketService,
				private roleService: RoleService,
				private userService: UserService) {
		this.selectionsChanged = this._selectionsChanged.asObservable();
	}

	@HostListener('document:click', ['$event']) clickout(event) {
		let target = event.target;
		while (target) {
			if (this.filterComponents.lastIndexOf(target.nodeName) > -1)
				break;

			if (target.nodeName === 'MAIN') {
				target = null;
				this.filterGroupService.closeFilters();
			}
			else
				target = target.parentNode;

		}
	}

	ngOnInit(): void {
		if (this.isIncluded(FILTER_TYPE.ADMIN_ASSIGN_ANYWHERE_PRODUCT)) {
			this.assignAnywhereService.getDestinations().subscribe(products => {
				this.assignAnywhereProductOptions = products.map(c => ({id: c.id, display: c.product}));
			});
		}

		if (this.isIncluded(FILTER_TYPE.ADMIN_COMPANY_ID)) {
			this.companyService.getAllCompanies().subscribe(companies => {
				const filteredCompanies = this.includeAllCompaniesOption ? companies : companies.filter(c => c.id !== GUIDS.ALL_COMPANIES);
				this.companyOptions = filteredCompanies.map(c => ({id: c.id, display: c.name}));
			});
		}

		if (this.isIncluded(FILTER_TYPE.ADMIN_NPI_GROUP_TYPE)) {
			this.npiGroupService.getNpiGroupTypes().subscribe(types => {
				this.npiGroupTypeOptions = types.map(t => ({id: t.groupTypeID, display: t.groupTypeTitle}));
			});
		}

		if (this.isIncluded(FILTER_TYPE.ADMIN_USER_MANAGER)) {

			this.listApi.getAllManagers([]).subscribe(managers => {
				this.managerOptions = this.getSelectionsWithAllOption(managers);
			});
		}

		if (this.isIncluded(FILTER_TYPE.ADMIN_USER_MODULE) || this.isIncluded(FILTER_TYPE.ADMIN_COMPANY_MODULE)) {
			this.productService.getAllProducts().subscribe(products => {
				if (this.isIncluded(FILTER_TYPE.USAGE))
					products = products.filter(p => p.requiresMarketscapeId);
				this.productOptions = products.map(p => ({id: p.id, display: p.display}));
			});
		}

		if (this.isIncluded(FILTER_TYPE.ADMIN_USER_ROLE)) {
			this.roleService.getAll([]).subscribe(roles => {
				this.roleOptions = roles.map(r => ({id: r.title, display: r.title}));
			});
		}

		if (this.isIncluded(FILTER_TYPE.ADMIN_USER_MARKET) || this.isIncluded(FILTER_TYPE.ADMIN_COMPANY_MARKET)) {
			this.marketService.getAllMarkets().subscribe(markets => {
				this.marketOptions = markets.map(m => ({id: m.id, display: m.abbreviation}));
			});
		}

		if (this.isIncluded(FILTER_TYPE.ADMIN_USER_ID) && !this.isIncluded(FILTER_TYPE.ADMIN_COMPANY_ID)) {
			const companyId = this.applicationCacheService.user.companyId;
			this.userService.getCompanyProductUsers(companyId, this.productNames).subscribe(users => {
				this.userOptions = users.map(c => ({id: c.id, display: `${c.firstName} ${c.lastName} (${c.email})`}));
			});
		}
	}

	ngAfterViewInit() {
		if (this.isIncluded(FILTER_TYPE.ADMIN_USER_ID) && this.isIncluded(FILTER_TYPE.ADMIN_COMPANY_ID))
			setTimeout(() => this.filterGroupService.disableFilter(FILTER_TYPE.ADMIN_USER_ID), 0);

	}

	getSelectionsWithAllOption(selections) {
		const allOption: CheckedSelection = {display: ALL_OPTION, id: ALL_OPTION};
		const checkedSelections = selections.map(m => ({id: m.id, display: m.display}));
		return [allOption, ...checkedSelections];
	}

	isIncluded(filterType: FILTER_TYPE) {
		return this.filterTypes.some(x => x === filterType);
	}

	handleSelectionsChanged(selections: CheckedSelectionGroup) {
		switch (selections.filterType) {
			case FILTER_TYPE.ADMIN_ASSIGN_ANYWHERE_PRODUCT:
				this._selections.assignAnywhereProducts = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_COMPANY_ENABLED:
				this._selections.companyEnabled = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_COMPANY_ID:
				this._selections.companies = selections.selections;
				if (this.isIncluded(FILTER_TYPE.ADMIN_USER_MANAGER)) {
					const companyIds = this._selections.companies.filter(c => c.checked).map(c => c.id);
					this.roleService.getAll(companyIds).subscribe(roles => {
						this.roleOptions = roles.map(r => ({id: r.title, display: r.title}));
					});
					this.listApi.getAllManagers(companyIds).subscribe(managers => {
						this.managerOptions = this.getSelectionsWithAllOption(managers);
						if (this._selections.userManagers?.length)
							this._selections.userManagers.filter(m => managers.some(m2 => m.id === m2.id));

					});
				}
				if (this.isIncluded(FILTER_TYPE.ADMIN_USER_ID)) {
					const companyIds = this._selections.companies.filter(c => c.checked).map(c => c.id);
					if (companyIds.length === 1) {
						this.filterGroupService.enableFilter(FILTER_TYPE.ADMIN_USER_ID);
						this.userService.getCompanyProductUsers(companyIds[0], this.productNames).subscribe(users => {
							this.userOptions = users.map(c => ({
								id: c.id,
								display: `${c.firstName} ${c.lastName} (${c.email})`
							}));
						});
					}
					else {
						this.userOptions = [];
						this._selections.users = [];
						this.filterGroupService.disableFilter(FILTER_TYPE.ADMIN_USER_ID);
					}
				}
				break;
			case FILTER_TYPE.ADMIN_COMPANY_MARKET:
				this._selections.companyMarkets = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_COMPANY_MODULE:
				this._selections.companyProducts = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_NPI_GROUP_TYPE:
				this._selections.npiGroupTypes = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_REPORT_START_DATE:
				this._selections.startDate = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_REPORT_END_DATE:
				this._selections.endDate = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_USER_ENABLED:
				this._selections.userEnabled = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_USER_MANAGER:
				this._selections.userManagers = selections.selections.filter(f => f.display !== ALL_OPTION);
				break;
			case FILTER_TYPE.ADMIN_USER_MARKET:
				this._selections.userMarkets = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_USER_MODULE:
				this._selections.userProducts = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_USER_ROLE:
				this._selections.userRoles = selections.selections;
				break;
			case FILTER_TYPE.ADMIN_USER_ID:
				this._selections.users = selections.selections;
				break;
			case FILTER_TYPE.USAGE:
				this._selections.usageOptions = selections.selections;
		}
		this._selectionsChanged.emit(this._selections);
	}

	reset() {
		this.assignAnywhereProductOptions = this.assignAnywhereProductOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.companyOptions = this.companyOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.npiGroupTypeOptions = this.npiGroupTypeOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.managerOptions = this.managerOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.marketOptions = this.marketOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.productOptions = this.productOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.statusOptions = this.statusOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.roleOptions = this.roleOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.userOptions = this.userOptions.map(o => {
			o.checked = false;
			return o;
		});
		this.usageOptions = this.usageOptions.map(o => {
			o.checked = false;
			return o;
		});
		this._selections = {};
		this.ref.detectChanges();
		this._selectionsChanged.emit(this._selections);
		this.filterGroupService.resetFilters();
	}

}
