<trella-hover-tooltip *ngIf="!!getDefinition" [position]="'top'" [tooltipTemplate]="DefinitionTemplate"
                      [underline]="false">
    <div (click)="toggleFilter()"
         [ngClass]="{'disabled' : isDisabled }"
         class="btn text-input d-flex flex-row align-items-center flex-nowrap btn-outline-primary">
		<span class="button-text">
			{{ getTitle }} <span *ngIf="getCount">({{ singleSelect ? selectedName : getCount }})</span>
		</span>
        <i [class.fa-chevron-down]="isOpen" [class.fa-chevron-right]="!isOpen" class="fas"></i>
    </div>
</trella-hover-tooltip>
<ng-template #DefinitionTemplate>
    <div class="p-1 text-center">
        {{ getDefinition }}
    </div>
</ng-template>
<div
        (click)="toggleFilter()"
        *ngIf="!getDefinition"
        [ngClass]="{'disabled' : isDisabled }"
        class="btn text-input d-flex flex-row align-items-center flex-nowrap btn-outline-primary"
>
	<span class="button-text">
		{{ getTitle }} <span *ngIf="getCount">({{ singleSelect ? selectedName : getCount }})</span>
	</span>
    <i [class.fa-chevron-down]="isOpen" [class.fa-chevron-right]="!isOpen" class="fas"></i>
</div>

<div *ngIf="isOpen" class="simple-filter">
    <div [ngClass]="{ 'first-filter': hasAdditionalFilterOptions }">
        <trella-list-box
                (userChange)="handleSelectionsChange($event)"
                [height]="listBoxHeight"
                [options]="filterOptions"
                [singleSelect]="singleSelect"
        ></trella-list-box>
    </div>
    <div *ngIf="hasNote" class="border rounded note-box">
        <p class="note">{{ note }}</p>
    </div>
    <div *ngIf="hasAdditionalFilterOptions" class="second-filter">
        <trella-list-box
                (userChange)="handleAdditionalSelectionsChange($event)"
                [height]="listBoxHeight"
                [options]="additionalFilterOptions"
                [selectAllBorder]="true"
                [singleSelect]="false"
        ></trella-list-box>
    </div>
</div>
