import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Selection } from '@appcore/interfaces/selection.interface';

@Component({
	selector: 'app-assignment-selection',
	templateUrl: './assignment-selection.component.html',
	styleUrls: ['./assignment-selection.component.scss']
})

export class AssignmentSelectionComponent implements OnInit {
	@Input() showLabel = true;
	@Input() showAllOption = true;
	@Input() showSelectOption = false;

	@Output() selectionChanged = new EventEmitter<any>();

	selectionData: Selection[] = [];
	defaultItem: Selection = {display: 'Marketscape Insights & CRM', id: ''};
	selected: any;

	ngOnInit() {
		this.selectionData = [
			this.defaultItem, {display: 'CRM', id: 'crm'}, {display: 'Marketscape Insights', id: 'marketscape'}
		];

		if (!this.showAllOption && this.showSelectOption)
			this.selected = {id: null, display: 'Please select'};

		else if (!this.showAllOption) {
			this.defaultItem = null;
			this.selected = this.defaultItem;
		}
	}


	onSelectionChange() {
		this.selectionChanged.emit(this.selected);
	}
}
