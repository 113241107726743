import {
	Component,
	DestroyRef,
	EventEmitter,
	inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import {
	ColumnVisibilityChangeEvent,
	DataStateChangeEvent,
	GridComponent as Kgrid,
	SelectionEvent
} from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import * as $ from 'jquery';
import { isEmpty, isEqual, round } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LegacyGridAbilities } from '../../shared/models/legacy-grid-abilities';
import { GridColumn } from 'src/app/shared/models/grid-column';
import { QueryOptions } from 'src/app/shared/models/query-options';
import { Command } from '../../shared/command-button';
import { ExportMessage } from '../../shared/models/export-message';
import { LegacyGridInfo } from '../../shared/models/legacy-grid-info';
import { ExcelExportService } from '../../shared/services/excel-export.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ModalProgressBarComponent } from '../modal-progress-bar/modal-progress-bar.component';
import { GridColumnType } from '../../shared/enums/grid-column-type.enum';
import { IFilterSlim } from '../../shared/interfaces/filter-slim.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GRID_COMMAND } from '@appcore/enums/grid-command.enum';
import { GridCommandInfo } from '@appcore/interfaces/grid-command-info.interface';
import { Utils } from '@appcore/helpers/Utils';
import { SpinnerComponent } from '@appcore/components/spinner/spinner.component';

@Component({
	selector: 'app-grid',
	templateUrl: './app-grid.component.html',
	styleUrls: ['./app-grid.component.scss']
})
export class GridComponent implements OnInit, OnChanges {
	@ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
	@ViewChild(SpinnerComponent, {static: true}) spinner: SpinnerComponent;
	@ViewChild(AlertDialogComponent, {static: true}) alertDialog: AlertDialogComponent;
	@ViewChild(ModalProgressBarComponent) progressBarModal: ModalProgressBarComponent;
	@ViewChild('gridComponent', {static: false}) gridComponent: Kgrid;

	@Input() gridInfo: LegacyGridInfo;
	@Input() addActionText = 'Add';
	@Input() importActionText = 'Import';
	@Input() secondImportActionText = 'Import';
	@Input() hasUserDetail = false;
	@Input() reportHasDetailRows = false;
	@Input() reportFilters: IFilterSlim[] = [];
	@Input() saveLocalExcel = false;
	@Input() secondImport = false;
	@Input() showColumnChooser = true;

	@Output() onGridEvent = new EventEmitter();
	@Output() onDataStateChange = new EventEmitter();
	@Output() onColumnChange = new EventEmitter();
	@Output() onAddNewItem = new EventEmitter();
	@Output() onImportData = new EventEmitter();
	@Output() onImportAssignments = new EventEmitter();
	currentTarget: any;
	search: any;
	allowGridStateChange = true;
	searchChange = new Subject<any>();
	exportMessageSubscription: Subscription;
	closeExportMessageSubscription: Subscription;
	viewBtn = new Command({
		show: () => this.gridAbilities.viewable,
		execute: (rowIndex, dataItem, $event) => this.command({
			command: GRID_COMMAND.view,
			rowIndex,
			dataItem,
			sourceEvent: $event
		})
	});
	editBtn = new Command({
		show: () => this.gridAbilities.editCommand,
		execute: (rowIndex, dataItem, $event) => this.command({
			command: GRID_COMMAND.edit,
			rowIndex,
			dataItem,
			sourceEvent: $event
		})
	});
	copyBtn = new Command({
		show: () => this.gridAbilities.copyCommand,
		execute: (rowIndex, dataItem, $event) => this.command({
			command: GRID_COMMAND.copy,
			rowIndex,
			dataItem,
			sourceEvent: $event
		})
	});
	deleteBtn = new Command({
		show: () => this.gridAbilities.deleteRow,
		execute: (rowIndex, dataItem, $event) => this.command({
			command: GRID_COMMAND.delete,
			dataItem,
			rowIndex,
			sourceEvent: $event
		})
	});
	private _destroyRef = inject(DestroyRef);

	/**
	 * This is the height of each row. Used for the virtual scroller to help calculate it's paging.
	 */

	get rowHeight() {
		// current row height for grids using virtual scroller - will need to pass in as option if this changes. (Targets dashboard grid)
		return this.gridInfo && this.gridInfo.gridAbilities && this.gridInfo.gridAbilities.scrollable === 'virtual' && 37;
	}

	get enableCheckbox() {
		return this.gridAbilities.selectable && this.gridAbilities.selectable.enabled;
	}

	get enableAdding() {
		return this.gridAbilities && this.gridAbilities.enableAdding;
	}

	get enableImport() {
		return this.gridAbilities && this.gridAbilities.enableImport;
	}

	get hasPostNotes() {
		return this.gridInfo && this.gridInfo.postNotes && this.gridInfo.postNotes.details;
	}

	get gridAbilities() {
		return this.gridInfo && this.gridInfo.gridAbilities;
	}

	set gridAbilities(val) {
		if (this.gridInfo)
			this.gridInfo.gridAbilities = val;

	}

	constructor(private excelExportService: ExcelExportService) {
	}

	ngOnInit() {
		this.exportMessageSubscription = this.excelExportService.getMessageFromExport().subscribe(messageObject => {
			this.displayExportMessage(messageObject);
		});
		this.closeExportMessageSubscription = this.excelExportService.hideMessageFromExport().subscribe(reportName => {
			this.hideExportMessage(reportName);
		});

		this.searchChange.pipe(
			takeUntilDestroyed(this._destroyRef),
			debounceTime(1000)
		).subscribe(() => {
			this.searchFilter();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		// merge to use defaults
		this.gridAbilities = Object.assign(new LegacyGridAbilities(), this.gridAbilities);
	}

	showSpinner() {
		this.spinner.startSpinner();
	}

	hideSpinner() {
		this.spinner.stopSpinner();
	}

	command(e: GridCommandInfo) {
		this.onGridEvent.emit(e);
	}

	addNewItem() {
		this.onAddNewItem.emit();
	}

	importData() {
		this.onImportData.emit();
	}

	importAssignments() {
		this.onImportAssignments.emit();
	}

	columnChange(e: ColumnVisibilityChangeEvent) {
		this.gridInfo.mergeColumnChangeEvent(e);
	}

	getColDefinition(kendoColumn: GridColumn) {
		return kendoColumn && kendoColumn.definition;
	}

	getColumnClass(col: GridColumn) {
		const columnClassList = ['bg-white'];
		if (!col.alignment)
			return columnClassList;


		columnClassList.push(`text-${col.alignment.toLowerCase()}`);
		return columnClassList;
	}

	toggleDef(kendoColumn: GridColumn, e: any) {
		const colDefintion = this.getColDefinition(kendoColumn);

		const kendoPopup = $('kendo-popup');
		const toolTip = $(e.currentTarget);
		toolTip.attr('title', colDefintion || kendoColumn.title);

		if (kendoPopup.length > 0) {
			this.tooltipDir.hide();
			this.tooltipDir.toggle(e.currentTarget, this.currentTarget !== e.currentTarget);
		}
		else
			this.tooltipDir.toggle(e.currentTarget, true);


		this.currentTarget = e.currentTarget;

		// set to false, so that the dataStateChange event isn't triggered
		this.allowGridStateChange = false;
	}

	dataStateChange(state: DataStateChangeEvent): void {
		if (this.allowGridStateChange) {
			const currentSort = this.gridInfo.queryOptions && this.gridInfo.queryOptions.sort;
			const sortChanged = !isEqual(currentSort, state.sort);

			this.gridInfo.queryOptions = state;
			this.onDataStateChange.emit();

			if (sortChanged)
				this.onColumnChange.emit();

		}

		// set to true, so that other kendo events trigger the dataStateChange event
		this.allowGridStateChange = true;
	}

	isDefaultCol(col: GridColumn) {
		return !col.clickable && !col.sparklineCol && !col.comparison;
	}

	isClickableCol(col: GridColumn) {
		return col.clickable;
	}

	getGridDataRowNpi(data): string {
		return data && data[this.gridInfo.npiField];
	}

	selectionChange(event: SelectionEvent) {
		if (!event.selectedRows) {
			const deselectedNpis = event.deselectedRows.map(row => this.getGridDataRowNpi(row.dataItem));
			this.gridInfo.selectedRows.filter(npi => !deselectedNpis.includes(npi));
		}
		else
			event.selectedRows.forEach(row => this.gridInfo.selectedRows.push(this.getGridDataRowNpi(row.dataItem)));

	}

	triggerFilter() {
		this.searchChange.next(null);
	}

	clearFilter() {
		this.search = '';
		this.searchFilter();
	}

	searchFilter() {
		if (!this.search || !this.search.length) {
			this.gridInfo.queryOptions.filter = null;
			this.onDataStateChange.emit();
			return;
		}

		if (!this.gridInfo)
			return;


		if (!this.gridInfo.queryOptions)
			this.gridInfo.queryOptions = new QueryOptions();


		const quotedPhrase = /"(.*?)"/g;
		const searchPhrase = (this.search.match(quotedPhrase) || []).map(x => x.replace(/["]/g, '').trim());
		const searchWords = this.search.replace(quotedPhrase, '').trim().split(' ').map(x => x.trim());

		const searchParms = searchPhrase.concat(searchWords);

		const allColumns = this.getAllColumns();

		const filterDescriptor = searchParms.map(word => allColumns.map(field => ({
			field,
			operator: 'contains',
			value: word
		})));

		const filters = filterDescriptor.map(filter => ({
			logic: 'or',
			filter
		}));

		this.gridInfo.queryOptions.filter = {
			logic: 'and',
			filters
		};

		this.gridInfo.queryOptions.skip = 0;

		this.onDataStateChange.emit();
	}

	getAllColumns() {
		return this.gridInfo.columns.filter(x => x.filterable && x.columnType !== GridColumnType.preheader).map(x => x.field);
	}

	getSubColumns(col: GridColumn): GridColumn[] {
		return col && col.columns && col.columns.filter(x => !x.hidden);
	}

	isPreheader(col: GridColumn) {
		return col && col.columnType === GridColumnType.preheader;
	}

	saveExcelExport(excelExport: ExcelExportComponent, gridInfo: LegacyGridInfo) {
		if (!this.saveLocalExcel)
			return this.excelExportService.startExcelExport(excelExport, gridInfo);

		return excelExport.save(gridInfo._data);
	}

	displayExportMessage(messageObject: ExportMessage) {
		if (!messageObject.message || messageObject.reportName !== this.gridInfo.reportName)
			return;

		this.alertDialog.open('Export Message', messageObject.message, '');
	}

	hideExportMessage(reportName: string) {
		if (reportName !== this.gridInfo.reportName)
			return;

		this.alertDialog.close();
	}

	displayExportColumnTitle(title: string) {
		return title && !isEmpty(title) ? title : '';
	}

	getDisplayedValue(value: any, column: GridColumn) {
		if (column && column.convertToLocalDate)
			return Utils.convertToLocalDate(({value, formattedValue: value}));


		if (!column || !column.format)
			return value;


		// TODO: Create this transform library on the backend!
		if (Utils.doesFormatFunctionExist(column.format)) {
			const transformFunc = Utils.getFormatFunction(column.format);
			return transformFunc(value);
		}

		if (Utils.exists(value) && column.truncateLength)
			return value.substring(0, column.truncateLength);


		if (!Utils.exists(column.format) || !Utils.exists(value) || Utils.isSpecialValue(value))
			return value;


		if (!value)
			return Utils.getDisplayedValue(value, column.format);



		if (column.format.indexOf('%') > -1) {
			// Turn into percent
			value = this.getPercentValueFromDecimal(value);

			if (!value) {
				// Value is special. Handle this generically
				return Utils.getDisplayedValue(value, column.format);
			}

			return value.toFixed(2) + '%';
		}

		if (column.format.indexOf('DATE') > -1)
			return Utils.convertToLocalDate(value);


		if (column.format.indexOf('I') > -1) {
			// Round to nearest integer
			return round(value);
		}

		if (column.format.indexOf('1') > -1) {
			// Decimal with 1 digit after dot
			return parseFloat(value).toFixed(1);
		}

		return Utils.getDisplayedValue(value, column.format);
	}

	collapseRow() {
		for (let i = 0; i < this.gridInfo.data.data.length; i++)
			this.gridComponent.collapseRow(i);

	}

	getPercentValueFromDecimal(value: any): number {
		if (!Utils.exists(value) || Utils.isSpecialValue(value) || value <= 0)
			return 0;


		return value * 100;
	}

	excelFileName(): string {
		let baseFileName = 'Trella Health Excel File';
		if (this.gridInfo.reportName)
			baseFileName = this.gridInfo.reportName;

		return `${baseFileName}.xlsx`;
	}

	columnNeedsHighlist(column: GridColumn) {
		if (!column)
			return false;

		return column.highlight;
	}

	getColHeaderTitle(kendoColumn: GridColumn) {
		return kendoColumn && kendoColumn.title;
	}

}
