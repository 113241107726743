import { Component } from '@angular/core';
import { BaseChart } from '../base-chart';
import { ChartDataElement, ChartDataSeries } from '../../../../models/chart-data-series.model';
import { PercentileQuality } from '../../../../enums/percentile-quality.enum';
import { InsightOptions } from '../../../../models/insight-options.model';

@Component({
    selector: 'trella-insight',
    templateUrl: './insight.component.html',
    styleUrls: ['./insight.component.scss']
})
export class InsightComponent extends BaseChart {
    datapoints: Array<ChartDataElement> = [{}];
    quality: PercentileQuality;

    constructor() {
        super();
    }

    readData(options: InsightOptions): ChartDataSeries {
        super.readData(options);
        const data = options.data;
        const valueField = options.value;
        const riskCategory = options.riskCategory;

        this.datapoints = data
            ? data.map(datapoint => {
                const newResult = {
                    data: datapoint[valueField].formattedValue || datapoint[valueField].value,
                    percentile: datapoint.percentile && (datapoint.percentile.formattedValue || datapoint.percentile.value),
                    riskCategory: riskCategory && (datapoint[riskCategory].formattedValue || datapoint[riskCategory].value),
                    facilityType:
                        datapoint.facility_type_desc &&
                        (datapoint.facility_type_desc.formattedValue || datapoint.facility_type_desc.value)
                };
                return newResult;
            })
            : [{data: '-'}];

        const resultWithPercentile = data.find(d => d.percentile);

        if (resultWithPercentile) {
            this.quality = this.getQuality(
                options.isHigherBetter,
                options.isNeutral,
                resultWithPercentile.percentile.value >= 0.5,
                resultWithPercentile[valueField].value
            );
        }

        return {
            data,
            categories: [],
            isValidData: !!data
        };
    }

    getQuality(isHigherBetter: boolean, isNeutral: boolean, isHigher: boolean, value: number) {
        const isCountLessThan11 = this.options.dataType === 'count' && value === -1;
        const isQualityMeaningless = value < 0 && !isCountLessThan11;

        if (isNeutral || isQualityMeaningless) 
            return PercentileQuality.Moot;
        

        if (isHigher) 
            return isHigherBetter ? PercentileQuality.Good : PercentileQuality.Bad;
        

        return isHigherBetter ? PercentileQuality.Bad : PercentileQuality.Good;
    }

    postDataRead() {
        // no op
    }

    handleOptionsChange(options: InsightOptions) {
        this.options = options;
    }
}
