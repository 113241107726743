import { isNumber } from 'highcharts';
import { ChartDataType } from '../enums/chart-data-type.enum';
import { Utils } from '../helpers/Utils';

export class ChartUtils {
    static getFormattedChartTooltipType(value: number, dataType: string) {
        if (dataType === ChartDataType.Percent)
            return value <= 0 ? '' : '%';


        if (dataType === ChartDataType.Currency)
            return '$';


        return '';
    }

    static getChartDisplayedValue(value: any, formattedDataType: string) {

        if (value === undefined)
            return '';


        if (formattedDataType === '$') {
            if (value === '$0')
                return 'ins';
             else
                return this.getFormattedCurrencyValue(value);

        }

        if (isNumber(value) && value >= 0)
            return value.toLocaleString() + formattedDataType;


        return Utils.getDisplayedValue(value, '') + formattedDataType;
    }

    static getFormattedCurrencyValue(value: string) {
        const style = {
            style: 'currency',
            currency: 'USD'
        };
        return isNaN(+value)
            ? value
            : Number(value)
                .toLocaleString('en-US', style)
                .replace('.00', '');
    }

    static getFormattedPercentValue(value: string) {
        return `${value.toLocaleString()}%`;
    }
}
