import { Component, DestroyRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { LegacyGridInfo } from '../../shared/models/legacy-grid-info';
import { ReportApi } from '../../api/report.api';
import { GridComponent } from '../../components/app-grid/app-grid.component';
import { IFilterSlim } from '../../shared/interfaces/filter-slim.interface';
import { FilterType } from '../../shared/enums/filter-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SpinnerComponent } from '@appcore/components/spinner/spinner.component';
import { UserFeedbackService } from '@appcore/services/user-feedback.service';

@Component({
	selector: 'app-report-details',
	templateUrl: './report-details.component.html'
})

export class ReportDetailsComponent implements OnInit {
	@Input() data: any;
	@Input() reportName: string;
	@ViewChild(SpinnerComponent, {static: true}) spinner: SpinnerComponent;
	@ViewChild(GridComponent) grid: GridComponent;

	gridInfo: LegacyGridInfo;
	filter: IFilterSlim[];
	WEEKLY_LOGIN = 'weekly_login';
	MONTHLY_LOGIN = 'monthly_login';
	private _destroyRef = inject(DestroyRef);

	constructor(private reportApi: ReportApi, private userFeedbackService: UserFeedbackService) {
	}

	get showGrid() {
		return this.gridInfo && this.gridInfo.data;
	}

	detailReportName() {
		return this.reportName + '_detail';
	}

	ngOnInit() {
		this.filter = [
			{
				filterType: FilterType.CustomerId,
				modelJson: JSON.stringify({
					customerid: this.data.customerid
				})
			}];

		if (this.reportName === this.WEEKLY_LOGIN || this.reportName === this.MONTHLY_LOGIN) {
			this.filter.push({
				filterType: FilterType.Solution,
				modelJson: JSON.stringify([this.data.lob])
			});
		}
		this.initTable();
	}

	initTable() {
		this.showSpinner();
		this.reportApi.getConfig(this.detailReportName())
			.pipe(takeUntilDestroyed(this._destroyRef))
			.subscribe(
				config => {
					this.gridInfo = new LegacyGridInfo(config, this.detailReportName());
					this.gridInfo.gridAbilities.set({
						selectable: null,
						scrollable: 'scrollable',
						enableAdding: null,
						editCommand: null
					});
					this.refreshData();
				},
				err => {
					this.hideSpinner();
					this.userFeedbackService.showUnexpectedError();
				}
			);
	}

	refreshData() {
		this.gridInfo.reportFilters = this.filter;
		this.reportApi.getData(this.detailReportName(), '', this.gridInfo.queryOptions, this.filter)
			.pipe(takeUntilDestroyed(this._destroyRef))
			.subscribe(
				dataResult => {
					if (!dataResult) {
						this.userFeedbackService.showUnexpectedError();
						return;
					}
					this.gridInfo.data = {
						data: dataResult.data,
						total: dataResult.totalRows
					};
					this.hideSpinner();
				},
				err => {
					this.userFeedbackService.showUnexpectedError();
				}
			);
	}

	protected showSpinner() {
		this.spinner.startSpinner();
	}

	protected hideSpinner() {
		this.spinner.stopSpinner();
	}
}
