<kendo-dialog (close)="close()" *ngIf="opened" [class.onTop]="forceOnTop">
    <kendo-dialog-titlebar>
        <div *ngIf="title" class="d-flex flex-row align-items-center text-white">
            <div class="text-heading">{{ title }}</div>
        </div>
    </kendo-dialog-titlebar>

    <div class="row g-0 p-4 g-0">
        <div *ngIf="message" class="col">
            <div [class.loading]="loadingAnimation" [innerHTML]="message" class="text-body"></div>
        </div>
    </div>

    <div *ngFor="let b of buttons">
        <div class="row g-0 pt-3">
            <div class="col"></div>
            <div class="col-auto">
                <button (click)="close(b.buttonType)" [dialog-button-class]="b.buttonType" type="button">{{ b.label }}
                </button>
            </div>
        </div>
    </div>
</kendo-dialog>
