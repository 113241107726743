import { ProductName } from '../../../enums/product-name.enum';
import { ProductAccess } from '../product-access.model';
import { RoleContract } from '../role.model';
import { User } from './user.model';

export class CreateUserContract extends User {
    products: ProductAccess[];
    managedUserIds: string[];
    roles: RoleContract[];

    constructor() {
        super();
        this.products = [];
        this.managedUserIds = [];
        this.roles = [];
    }

    hasProductAccess(product: ProductName): boolean {
        return this.products.some(p => p.productName === product);
    }
}
