<div class="border rounded shadow">
    <trella-spinner [isFullDiv]="true" [show]="isLoading"></trella-spinner>
    <div>
        <div *ngIf="!isLoading" class="d-flex flex-row justify-content-between mx-1 my-2">
            <div class="p-0">
                <select
                        (change)="selectChart()"
                        *ngIf="chartConfigs && chartConfigs.length > 1"
                        [(ngModel)]="selectedOption"
                        class="form-select form-select-md"
                >
                    <option *ngFor="let opt of selectOptions" [ngValue]="opt">{{ opt.display }}</option>
                </select>
                <div *ngIf="filters" class="p-0">
                    <trella-filter-selection-dropdown
                            *ngFor="let selections of filters"
                            [selections]="selections"
                            class="col"
                    ></trella-filter-selection-dropdown>
                </div>
            </div>
            <div class="d-flex flex-row">
                <div *ngIf="allowEButton">
                    <button (click)="openE()" class="btn btn-sm btn-success pointer m-2">E</button>
                </div>
                <i (click)="exportChartImage()" class="far fa-file-download pointer m-2 p-1"
                   title="Download this chart as a PNG image"></i>
                <div *ngIf="selectedChartConfig && selectedChartConfig.addToClipboard">
                    <i (click)="addToClipboard()" class="far fa-clipboard pointer m-2 p-1"
                       title="Add Chart To Your Clipboard"></i>
                </div>
                <div *ngIf="selectedChartConfig && selectedChartConfig.permissionRemove && inDataKit">
                    <i (click)="removeFromDataKit()" class="far fa-trash pointer m-2 p-1" title="Remove Chart"></i>
                </div>
                <div
                        *ngIf="selectedChartConfig && selectedChartConfig.showExpandDialog"
                        class="col-auto align-self-end d-flex flex-row-reverse"
                >
                    <i (click)="openExpandDialog()" class="far fa-expand-alt pointer m-2 p-1" title="Expand Chart"></i>
                </div>
            </div>
        </div>
        <trella-chart
            #chartExport
            (activeLegendItemsChange)="activeLegendItemsChange.emit($event)"
            (clickEvent)="clickEvent.emit($event)"
            [activeLegendItems]="activeLegendItems"
            [chartInfo]="selectedChartConfig"
            [dataChange]="dataChange"
            [target]="target"
            id="{{ selectedChartConfig?.reportName }}_chart"
        >
        </trella-chart>
    </div>
</div>
