import { ModuleWithProviders, NgModule } from '@angular/core';
import { AdminComponentsModule } from '../components/components.module';

@NgModule({
	imports: [
		AdminComponentsModule
	],
	declarations: [],
	exports: [
		AdminComponentsModule
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule
		};
	}
}
