import { Component, Input } from '@angular/core';

@Component({
    selector: 'trella-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
	@Input() show: boolean = false;
	@Input() isFullDiv: boolean = false;
	@Input() viewportDimensions: boolean = false;

	numberOfDots = 12;

	get overlayClass(): string {
		return `${this.isFullDiv ? 'overlayHolder' : ''} ${this.viewportDimensions ? 'viewportDimension' : ''}`;
	}

	public startSpinner(): void {
		this.show = true;
	}

	public stopSpinner(): void {
		this.show = false;
	}
}
