import { Component, Input } from '@angular/core';
import { RouterLinkActive } from '@angular/router';

@Component({
    selector: 'trella-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
    @Input() tabSections = [];
    @Input() localStorageKey: string;
    @Input() makeSticky = false;

    getCurrentTabId(tab: any): number | string {
        if (tab.disabled) 
            return localStorage.getItem(this.localStorageKey);
        

        return tab.id;
    }

    isTabActive(tab: any, routerLinkActive: RouterLinkActive): boolean {
        if (tab.disabled) 
            return false;
        
        return routerLinkActive.isActive;
    }

    tabChanged(tab: any) {
        if (!tab.disabled) 
            localStorage.setItem(this.localStorageKey, tab.id);
        
    }
}
