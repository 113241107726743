import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouteAttr } from '../../interfaces/route-attr.interface';
import { CrossAppNavigationService } from '../../services/cross-app-navigation.service';

@Component({
    selector: 'trella-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent {
    @Input() companyName: string;
    @Input() hasAdminPermission = false;
    @Input() links: RouteAttr[];
    @Input() userEmail: string;
    @Input() userName: string;
    @Output() logoutCallback: EventEmitter<any> = new EventEmitter();

    adminIcon = './assets/img/icons/administrator.svg';

    constructor(private crossAppNavigationService: CrossAppNavigationService) {
    }

    logout() {
        this.logoutCallback.emit();
    }

    navigateToAdmin() {
        this.crossAppNavigationService.navigateToAdmin();
    }
}
