import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseChart } from '../base-chart';
import { ChartComponent } from '@progress/kendo-angular-charts';
import { ChartDataType } from '../../../../enums/chart-data-type.enum';
import { ChartConfig } from '../../../../models/chart-config.model';
import { ChartDataElement, ChartDataSeries } from '../../../../models/chart-data-series.model';
import { FormattedData } from '../../../../interfaces/formatted-data.interface';
import { RAW_VALUE } from '../../../../enums/raw-value.enum';
import { Utils } from '../../../../helpers/Utils';

@Component({
    selector: 'trella-bar-chart',
    templateUrl: './bar-chart.component.html',
    styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent extends BaseChart implements OnInit {
    @ViewChild('kendoimage') kendoImage: ChartComponent;

    type: any; // TODO: Needed? created to enable compile

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    readData(chartInfo: ChartConfig): ChartDataSeries {
        super.readData(chartInfo);
        return chartInfo.adapter && chartInfo.adapter === 'transpose-no-groups'
            ? this.readDataNoGroup(chartInfo)
            : this.readDataGroup(chartInfo);
    }

    readDataGroup(chartInfo: ChartConfig): ChartDataSeries {
        const data = chartInfo.data;
        const categoriesField = chartInfo.categoriesField;
        const valueFields = chartInfo.groups.map(x => ({
            key: x.field,
            label: x.label
        }));
        const barChartData: ChartDataElement[] = [];
        // for each valueField, push a data entry
        valueFields.forEach(field =>
            barChartData.push({
                data: data.map(d => d[field.key].formattedValue || d[field.key]),
                rawData: data.map(d => this.formatGroupValue(d[field.key])),
                label: field.label
            } as ChartDataElement));

        // The categories are the number of data point
        const categories = data.map(d => d[categoriesField].value || d[categoriesField]);
        const validData = this.validateData(barChartData);
        return {
            data: barChartData,
            categories,
            isValidData: validData
        };
    }

    formatGroupValue(value: FormattedData) {
        switch (this.options.dataType) {
            case ChartDataType.Percent:
                return +value.value <= 0 ? 0 : +value.value;
            case ChartDataType.Count:
                return this.getGroupFormattedCountValue(value);
            default:
                return this.getGroupFormattedCountValue(value);
        }
    }

    getGroupFormattedCountValue(value: FormattedData) {
        const valueToRead = value.value || value;
        if (valueToRead === RAW_VALUE.negativeOne)
            return 5;
         else if (+valueToRead <= 0)
            return 0;
         else
            return +valueToRead;

    }

    getNoGroupFormattedCountValue(value) {
        if (value === RAW_VALUE.negativeOne)
            return 5;
         else if (value === RAW_VALUE.negativeTwo || value === RAW_VALUE.negativeFour)
            return 0;
         else
            return value;

    }

    readDataNoGroup(chartInfo: ChartConfig): ChartDataSeries {
        const data = Utils.deepClone(chartInfo.data).map(x => {
            x.value = x.value.value;
            x.key = x.key.value;
            return x;
        });
        const categoriesField = chartInfo.categoriesField;

        const valueFields = chartInfo.groups.map(x => ({
            key: x.field,
            label: x.label
        }));
        const barChartData: ChartDataElement[] = [];

        valueFields.forEach(() =>
            data.forEach((item, index) => {
                const dataArray = new Array(index + 1);
                dataArray[index] = this.formatNoGroupValue(item.value);

                barChartData.push({
                    data: dataArray,
                    rawData: dataArray,
                    label: data[index].key
                } as ChartDataElement);
            }));

        // The categories are the number of data point
        const categories = data.map(d => d[categoriesField]);
        const isValid = this.validateData(barChartData);

        return {
            data: barChartData,
            categories,
            isValidData: isValid
        };
    }

    formatNoGroupValue(value) {
        switch (this.options.dataType) {
            case ChartDataType.Percent:
                return value <= 0 ? 0 : value;
            case ChartDataType.Count:
            default:
                return this.getNoGroupFormattedCountValue(value);
        }
    }

    validateData = (chartData: ChartDataElement[]) => this.validateRange(chartData);

    postDataRead() {
        // no op
    }

    handleOptionsChange(config: ChartConfig) {
        // no op
    }
}
