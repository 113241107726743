import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    CategoryAxisLabels,
    LegendLabels,
    LegendMarkers,
    ValueAxisLabels,
    ValueAxisTitle
} from '@progress/kendo-angular-charts';
import { Observable } from 'rxjs';
import { formatLabelVisual } from './utils';
import { ChartDataType } from '../../../enums/chart-data-type.enum';
import { ChartConfig } from '../../../models/chart-config.model';
import { Target } from '../../../models/target.model';
import { ChartDataElement, ChartDataSeries } from '../../../models/chart-data-series.model';
import { Utils } from '../../../helpers/Utils';
import { DISPLAY_VALUE } from '../../../enums/display-value.enum';
import { ChartUtils } from '../../../models/chart-utils.model';

@Directive()
export abstract class BaseChart implements OnInit {
    @Input() fullScreen = false;
    @Input() dataChange: Observable<ChartConfig>;
    @Input() activeLegendItems: any[] = [];
    @Input() target: Target;
    @Output() activeLegendItemsChange = new EventEmitter();
    // font = this.fullScreen ? this.fontLg : this.fontMd;

    stateAverageKey = 'State';
    countyAverageKey = 'County';

    legendLabelConfig: LegendLabels;
    categoryAxisLabels: CategoryAxisLabels;
    valueAxisLabels: ValueAxisLabels;
    legendMarkers: LegendMarkers;

    xAxisTitle: ValueAxisTitle;
    yAxisTitle: ValueAxisTitle;
    toolTip: string;
    data: ChartDataSeries;
    options: ChartConfig;

    public seriesColors = ['#86A9EC', '#C66080', '#FFAA65', '#22B102', '#B8C5CF', '#006747', '#FFC72C', '#939393'];

    private fontMd = '.68rem \'Roboto Condensed\', sans-serif';
    private fontLg = 'bold 1.25rem \'Roboto Condensed\', sans-serif';

    constructor() {
        this.legendLabelConfig = {
            font: this.fontMd
        };
        this.categoryAxisLabels = {
            font: this.fontMd,
            position: 'onAxis'
        };
        this.valueAxisLabels = {
            font: this.fontMd,
            content: (e: any) => this.formatDisplayValue(e.value),
            position: 'onAxis'
        };
        this.legendMarkers = {
            height: 12
        };
    }

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle
    ngOnInit() {
        this.dataChange.subscribe(chartInfo => {
            this.options = chartInfo;
            this.xAxisTitle = {text: this.options && this.options.xLabel};
            this.yAxisTitle = {text: this.options && this.options.yLabel};
            if (this.options.xAxisLabelRotation)
                this.categoryAxisLabels.rotation = this.options.xAxisLabelRotation;
             else
                this.categoryAxisLabels.visual = formatLabelVisual;

            this.data = this.readData(chartInfo);
            this.postDataRead();
            this.handleOptionsChange(chartInfo);
        });
    }

    public onLegendItemClick(e): void {
        e.preventDefault();
        this.activeLegendItems = !this.activeLegendItems.length ? this.data.data.map(c => c.label) : this.activeLegendItems; // This populates the list with all items when the page is first loaded or when the list is cleared out some other way
        // If we're trying to disable the last active item, do nothing
        if (this.activeLegendItems.length === 1 && this.activeLegendItems.find(f => f === e.text))
            return;

        this.activeLegendItems = Utils.toggle(this.activeLegendItems, e.text);
        if (this.activeLegendItems.length === this.data.data.length)
            this.activeLegendItems = [];

        this.activeLegendItemsChange.emit(this.activeLegendItems);
    }

    public filterSeriesByLegend = (label: string) => {
        const showSeries = !this.activeLegendItems.length || this.activeLegendItems.includes(label);
        return showSeries;
    };

    readData(chartInfo: ChartConfig): ChartDataSeries {
        if (!chartInfo)
            throw Error('No config.');

        if (!chartInfo.data || !Array.isArray(chartInfo.data))
            throw Error('Incoming data must be array.');

        return {
            data: [],
            categories: [],
            isValidData: true
        };
    }

    public getFormattedName = (e: any) => {
        if (!this.options.wrapText)
            return e.value;


        if (!e.value)
            return '';


        // let's get an array storing each part of the name & npi
        const spacesOnNewLine: string[] = e.value.split(' ');

        // get the npi that we will appended at the end
        const npi = spacesOnNewLine.pop();

        // initialize empty array and helper variable
        const combinedArray = [];
        let currentLine = '';

        for (let counter = 0; counter <= spacesOnNewLine.length - 1;) {
            // as long as current line is under character limit, concatenate next string
            if (currentLine.length <= this.options.maxCharacters) {
                currentLine += spacesOnNewLine[counter] + ' ';
                counter += 1;

                // if counter is same as array length, reached the end, add onto array and exit out
                if (counter === spacesOnNewLine.length) {
                    combinedArray.push(currentLine);
                    break;
                }

                // check if current line is over the character limit
                if (currentLine.length > this.options.maxCharacters) {
                    combinedArray.push(currentLine);
                    currentLine = ''; // reset to start new line
                }
            }
        }

        combinedArray.push(npi); // add npi at the end

        // return the array with line breaks
        return combinedArray.join('\n');
    };

    isValidData() {
        return !!this.data;
    }

    protected validateRange(chartData: ChartDataElement[]): boolean {
        const rawData = chartData.map(x => x.rawData).reduce((p, c) => [...p, ...c], []);
        const data = chartData.map(x => x.data).reduce((p, c) => [...p, ...c], []);

        if (!rawData.length || !data.length)
            return false;


        return true;
    }

    protected formatDisplayValue(value: string): string {
        if (value === DISPLAY_VALUE.insufficient || value === DISPLAY_VALUE.lessThanEleven || value === DISPLAY_VALUE.dash || !value)
            return value;


        switch (this.options.dataType) {
            case ChartDataType.Currency:
                return ChartUtils.getFormattedCurrencyValue(value);
            case ChartDataType.Percent:
                return ChartUtils.getFormattedPercentValue(value);
            default:
                return value;
        }
    }

    abstract postDataRead();

    abstract handleOptionsChange(config: ChartConfig);
}
