import { Component } from '@angular/core';
import { EulaApi } from '../api/eula.api';
import { NavbarService } from '../main/navbar/navbar.service';
import { ApplicationCacheService } from '../shared/services/application-cache.service';
import { NavigationService } from '../shared/services/navigation.service';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';

@Component({
	selector: 'app-eula',
	templateUrl: './eula.component.html'
})
export class EulaComponent {
	constructor(
		private applicationCacheService: ApplicationCacheService,
		private eulaApi: EulaApi,
		private navBarService: NavbarService,
		private navigationService: NavigationService,
		private userFeedbackService: UserFeedbackService,
	) {}

	async updateEula(choice: boolean) {
		this.userFeedbackService.startSpinner();
		try {
			await this.eulaApi.updateEula(choice).toPromise();
		} catch (ex) {
			this.userFeedbackService.showUnexpectedError();
		} finally {
			this.userFeedbackService.stopSpinner();
		}

		if (!choice) {
			await this.navigationService.navigateToLogin();
			return;
		}

		this.applicationCacheService.user.showEula = false;
		await this.navBarService.loadLastPage();
	}
}
