import { Utils } from '../../../helpers/Utils';
import { LocationType } from '../../../enums/location-type.enum';
import { Locality } from '../../../interfaces/locality.interface';

export class LocationNode {
    guid = Utils.generateGuid();
    children: LocationNode[] = [];
    checked = false;

    constructor(public type: LocationType, public data: string, public parent?: LocationNode) {
        if (this.parent)
            this.parent.children.push(this);

    }

    get configModel() {
        const model: Locality = {
            market: null,
            county: null,
            city: null,
            zipcode: null
        };

        const modelStaging = this.getConfigModel(this);
        modelStaging.forEach(x => {
            switch (x.type) {
                case LocationType.Market:
                    model.market = x.val;
                    break;

                case LocationType.County:
                    model.county = x.val;
                    break;

                case LocationType.City:
                    model.city = x.val;
                    break;

                case LocationType.Zip:
                    model.zipcode = x.val;
                    break;
            }
        });

        return model;
    }

    get isRoot() {
        return this.type === LocationType.Root;
    }

    get html() {
        return this.getdisplay(this, true);
    }

    private getConfigModel(node: LocationNode): { type: LocationType; val: string }[] {
        if (!node || node.isRoot)
            return [];


        if (!node.parent) {
            return [
                {
                    type: node.type,
                    val: node.data
                }
            ];
        }

        return [
            {
                type: node.type,
                val: node.data
            }
        ].concat(this.getConfigModel(node.parent));
    }

    private getActiveSpan(data) {
        return `<span class="search-hit">${data}</span>`;
    }

    private getdisplay(node: LocationNode, isFirst: boolean) {
        if (!node || node.isRoot)
            return '';


        if (!node.parent)
            return isFirst ? this.getActiveSpan(node.data) : `${node.data} / `;


        const parentDisplay = this.getdisplay(node.parent, false);
        const thisDisplay = isFirst ? this.getActiveSpan(node.data) : node.data;

        return `${parentDisplay ? parentDisplay + ' / ' : ''}${thisDisplay}`;
    }
}
