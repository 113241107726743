import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SSOService } from '../shared/services/sso.service';
import { ROUTE_CONSTANTS } from '../shared/constants/route-constants.constant';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	username: string;
	message: string;
	returnUrl: string;

	constructor(private activatedRoute: ActivatedRoute,
				private userFeedbackService: UserFeedbackService,
				private ssoService: SSOService) {
	}

	ngOnInit() {
		this.returnUrl = this.activatedRoute.snapshot.queryParams[ROUTE_CONSTANTS.returnUrlLabel];
		this.message = '';

		// If source is already specified, we do not need to stop at this page. Forward to actual login
		const source = this.ssoService.determineSource(this.activatedRoute.snapshot);
		if (source) 
			this.ssoService.navigateToSSObySource(source);
		

	}

	async login() {
		this.message = '';
		if (!this.username) {
			this.message = 'Email address is required';
			return;
		}

		this.userFeedbackService.startSpinner();
		/* Here we navigate regardless of email found or not. If not found we navigate to default Trella login */
		const success = await this.ssoService.navigateToSSObyEmail(this.username);
		if (!success) 
			this.message = 'Error processing request.';
		

		this.userFeedbackService.stopSpinner();
	}
}
