import { Injectable } from '@angular/core';
import { UrlSegment, UrlTree } from '@angular/router';
import { PORTAL_TYPE } from '../enums/portal-type.enum';
import { ROUTE_CONSTANTS } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class InsightsNavigatorService {
    constructor() {
    }

    getDefaultRoute = (targetProduct: PORTAL_TYPE) => `${targetProduct}/${ROUTE_CONSTANTS.home.route}`;

    getNavigationUrl(targetProduct: PORTAL_TYPE, currentUrlTree: UrlTree): string {
        const segments = currentUrlTree.root.children.primary?.segments;
        const defaultRoute = this.getDefaultRoute(targetProduct);
        if (segments.length < 2) 
            return defaultRoute;
        

        const currentPage = segments[1].path;

        switch (currentPage) {
            case ROUTE_CONSTANTS.analyze.route:
                return this.getUrlFromAnalyze(targetProduct, segments);
            case ROUTE_CONSTANTS.explore.route:
                return this.getUrlFromExplore(targetProduct, segments);
            case ROUTE_CONSTANTS.presentations.route:
                return this.getUrlFromEngage(targetProduct);
            case ROUTE_CONSTANTS.home.route:
                return this.getUrlFromHome(targetProduct, segments);
            case ROUTE_CONSTANTS.reports.route:
                return this.getUrlFromReport(targetProduct, segments);
        }

        return defaultRoute;
    }

    private getUrlFromAnalyze(targetProduct: PORTAL_TYPE, segments: UrlSegment[]): string {
        if (segments.length < 4) 
            return this.getDefaultRoute(targetProduct);
        
        const analyzePageType = segments[2].path;
        const id = segments[3].path;
        return `${targetProduct}/${ROUTE_CONSTANTS.analyze.route}/${analyzePageType}/${id}`;
    }

    private getUrlFromExplore(targetProduct: PORTAL_TYPE, segments: UrlSegment[]): string {
        if (segments.length < 3) 
            return `${targetProduct}/${ROUTE_CONSTANTS.explore.route}`;
        
        const explorePageType = segments[2].path;
        return `${targetProduct}/${ROUTE_CONSTANTS.explore.route}/${explorePageType}`;

    }

    private getUrlFromEngage(targetProduct: PORTAL_TYPE): string {
        return `${targetProduct}/${ROUTE_CONSTANTS.presentations.route}`;
    }

    private getUrlFromHome(targetProduct: PORTAL_TYPE, segments: UrlSegment[]): string {
        if (segments.length < 3) 
            return this.getDefaultRoute(targetProduct);
        

        const userType = segments[2].path;
        if (segments.length < 4) 
            return `${targetProduct}/${ROUTE_CONSTANTS.home.route}/${userType}`;
        

        const providerType = segments[3].path;
        return `${targetProduct}/${ROUTE_CONSTANTS.home.route}/${userType}/${providerType}`;
    }

    private getUrlFromReport(targetProduct: PORTAL_TYPE, segments: UrlSegment[]): string {
        if (segments.length < 3) 
            return this.getDefaultRoute(targetProduct);
        

        const reportPageType = segments[2].path;
        return `${targetProduct}/${ROUTE_CONSTANTS.reports.route}/${reportPageType}`;
    }
}
