import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NpiSearchOption, NpiSearchRequest, NpiVerifyRequest } from '../shared/models/npi';
import { ApiService } from './api.service';
import { Utils } from '@appcore/helpers/Utils';

@Injectable({
	providedIn: 'root'
})
export class NpiApi {

	private _controllerName = 'admin/Npi';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	search(npiSearchRequest: NpiSearchRequest): Observable<NpiSearchOption[]> {
		const params = Utils.encodeQueryParam(npiSearchRequest);
		return this.api.get(this._controllerName, 'Search?' + params);
	}

	verify(npiVerifyRequest: NpiVerifyRequest): Observable<NpiSearchOption[]> {
		return this.api.post(this._controllerName, 'Verify', npiVerifyRequest);
	}
}
