import { CommandButtonOptions } from '../interfaces/command-button-options.interface';

/**
 * Class for defining commands (e.g. buttons/ links)
 */
// @dynamic
export class Command {
    public label: (obj?) => string;
    public execute?: (p1?, p2?, p3?) => void;
    public disabled: (obj?) => boolean;
    public show: (obj?) => boolean;
    public classes: (obj?) => string;
    public active: boolean;
    public faIcon: string;

    constructor(options: CommandButtonOptions) {
        Object.assign(this, options);
    }

    static noop() {
        return new Command({
            label: () => '',
            execute: () => {
            }
        });
    }
}