<div class="row justify-content-center">
    <div class="col-8">
        <div class="content">
            <h1 class="text-center fw-bold">DATA USE AGREEMENT</h1>
            <br/>
            <p>
                <b>THIS DATA USE AGREEMENT</b> (this <b>“Agreement”</b>) governs the use of the Trella Service as
                defined in the End User License Agreement, and certain information and intellectual property (the <b>“Provided
                Data”</b>) provided, or to be provided, by the Service to you the end user (<b>“Recipent”</b>) by Trella
                Health LLC, a Delaware limited liability company (<b>“Trella Health”</b>). Please read this Agreement
                carefully before accepting the terms hereof or exporting the Provided Data, as it describes the terms
                and conditions under which Recipient may use the Provided Data and Service. Trella Health may amend this
                Agreement from time to time by posting an amended version.
            </p>
            <p>
                This Agreement shall apply to any Provided Data, whether previously or thereafter exported by Recipient.
                Trella Health has entered into an agreement with Recipient’s employer (<b>“Employer”</b>) pursuant to
                which Trella Health shall provide the Service to Employer, subject to confidentiality restrictions set
                forth therein and in this Agreement. In connection therewith, Recipient shall be provided access to the
                Service to export the Provided Data to Recipient’s computer. In connection with Recipient’s use and
                access to the Provided Data, Trella Health, your Employer, and the Recipient agree:
            </p>
            <ol>
                <li>
                    <b>Ownership</b>. Trella Health and its licensors own their respective rights, title and interests
                    in and to all Provided Data, including third-party content and all related materials and technology,
                    from the Service, and retain exclusive ownership of the Provided Data and all copies and derivatives
                    thereof, and all worldwide intellectual property rights therein.
                </li>
                <li>
                    <b>Permitted Uses</b>. Except as otherwise specified herein, Recipient may make all uses and
                    disclosures of the Provided Data necessary for sales, marketing, internal metrics, and competitive
                    benchmarking to permit Employer to identify and market to promote care coordination and improved
                    provider performance in support of patient provider choice (the <b>“Purpose”</b>). Recipient will
                    use appropriate administrative, physical and technical safeguards to prevent use or disclosure of
                    the Provided Data restricted by this Agreement. For the avoidance of doubt, Recipient may not use
                    the Provided Data for sales, marketing, internal metrics, competitive benchmarking, or any other
                    purpose for the benefit of any person or entity other than Employer (other than the clients or
                    customers of Employer on behalf of Employer).
                </li>
                <li>
                    <b>Restrictions on Use</b>. Recipient may not: (a) sublicense, transfer, or otherwise assign the
                    Provided Data or its rights under this Agreement to any third party nor allow any third party to
                    access or use the Provided Data; (b) copy or modify the Provided Data other than in connection with
                    the Purpose; (c) use or disclose the Provided Data for any purpose other than the Purpose; (d) use
                    the Provided Data or any derivatives of the Provided Data for any marketing purposes other than in
                    connection with the Purpose; or (e) sell, share, or distribute the Provided Data other than in
                    connection with the Purpose and within the permitted use specified in Section 2. The Provided Data
                    may only be used for lawful purposes. For the avoidance of doubt, the Recipient may not disclose any
                    of the Provided Data to any other employee or contractor of the Employer unless such person has also
                    entered into a data agreement with Trella Health. Further, Recipient will ensure that any agent,
                    including a subcontractor, to whom it provides the Provided Data, agrees to the same restrictions
                    and conditions that apply through this Agreement to the Recipient with respect to the Provided Data.
                    Recipient will report to Trella Health any use or disclosure of the Provided Data not provided for
                    by this Agreement of which the Recipient becomes aware within 15 days of becoming aware of such use
                    or disclosure.
                </li>
                <li>
                    <b>Effectiveness; Termination</b>. The terms of this Agreement shall be effective on the date of
                    acceptance by Recipient, and shall remain in effect until all Provided Data provided to the
                    Recipient is destroyed or returned to Trella Health. Upon request by Trella Health, Recipient will
                    return the Provided Data, including all copies thereof, and all abstracts, summaries, or documents
                    produced using the Provided Data, or, if so directed by Trella Health in writing, Recipient will
                    destroy all copies of the Provided Data (including abstracts, summaries, or documents produced using
                    the Provided Data) and will certify to Trella Health in writing that all copies, abstracts,
                    summaries, and documents have been destroyed.
                </li>
                <li>
                    <b>Enforcement</b>. Recipient acknowledges that Trella Health would have no adequate remedy at law
                    should Recipient breach its obligations under this Section and agrees that Trella Health will be
                    entitled to enforce its rights under this Section by seeking appropriate equitable relief including
                    a temporary restraining order and an injunction. Trella Health’s right to obtain injunctive relief
                    shall not limit its right to seek further remedies. No delay or failure by Trella Health in
                    exercising any right under this Agreement will be construed to be a waiver of that right or of the
                    right to assert a claim with respect to any future breach of this Agreement.
                </li>
                <li>
                    <b>Miscellaneous</b>. In any suit or other proceeding to enforce any right or remedy under this
                    Agreement or to interpret any provision of this Agreement, the prevailing party will be entitled to
                    recover its costs, including reasonable attorneys’ fees, and all costs and fees incurred on appeal
                    or in a bankruptcy or similar action. A party may not assign or sublicense its rights or obligations
                    under this Agreement without the prior express written consent of the other party and any attempted
                    assignment in violation of this Agreement shall be null and void. This Agreement shall be construed
                    and enforced in accordance with the laws of the State of Georgia, without regard to its conflicts of
                    laws rules. The waiver of a breach or default hereunder shall not constitute a waiver of a
                    subsequent breach or default, or establish a course of dealing, or modify this Agreement or its
                    obligations.
                </li>
                <li>
                    <b>Indemnification</b>. Recipient and Employer agree to indemnify, defend, and hold Trella Health,
                    and its managers, officers, members, employees, and agents harmless of and from any claims,
                    liabilities, losses, damages, causes of action, amounts paid in settlement, or injuries, together
                    with costs and expenses, including reasonable attorney’s fees, arising out of or resulting from any
                    breach of this Agreement or any unauthorized use of the Service or Provided Data by Recipient.
                </li>
            </ol>
            <div class="p-5 float-end">
                <button (click)="accept()" class="btn btn-primary mx-3" type="button">Accept</button>
                <button (click)="decline()" class="btn btn-danger" type="button">Decline</button>
            </div>
        </div>
    </div>
</div>
