import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { ReportConfigJson, ReportDataResult } from '../shared/models/report-config';
import { ApiService } from './api.service';
import { IFilterSlim } from '../shared/interfaces/filter-slim.interface';

@Injectable({
	providedIn: 'root'
})
export class ReportApi {

	private _controllerName = 'admin/Report';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getConfig(reportName: string): Observable<ReportConfigJson> {
		if (!reportName) 
			throw new Error('Invalid Report Name');
		
		const data = this.api.get(this._controllerName, `${reportName}/config`);
		return data;
	}

	getData(reportName: string, npi: string, gridFilters: DataStateChangeEvent, singleFilters: Array<IFilterSlim>): Observable<ReportDataResult> {
		if (!reportName) 
			throw new Error('Invalid Report Name');
		
		const data = this.api.post(this._controllerName, `${reportName}/data`, {
			npi,
			gridFilters,
			singleFilters
		});
		return data;
	}

	export(reportName: string, npi: string, gridFilters: DataStateChangeEvent, singleFilters: Array<IFilterSlim>): Observable<any[]> {
		const url = `admin/report-export/${reportName}`;
		const body = {
			npi,
			gridFilters,
			singleFilters
		};
		return this.api.post(url, null, body);
	}
}
