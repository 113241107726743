import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { GridApi, GridDataRequest } from '../../api/grid.api';
import { AdminGridExportService } from '../../shared/services/admin-grid-export-service';
import { ComponentWithSubscription } from '@appcore/components/component-with-subscription';
import { AlertDialogComponent } from '@appcore/components/alert-dialog/alert-dialog.component';
import { FilterSelection } from '@appcore/models/filter-selection.model';
import { Filter } from '@appcore/models/filter.model';
import { GridAbilities } from '@appcore/models/grid-abilities.model';
import { Utils } from '@appcore/helpers/Utils';
import { GridInfo } from '@appcore/models/grid-info.model';
import { QueryOptions } from '@appcore/models/query-options.model';
import { GridSortEvent } from '@appcore/models/grid/grid-sort-event.model';
import { UserFeedbackService } from '@appcore/services/user-feedback.service';
import { FilterSelectionService } from '@appcore/services/filter-selection.service';
import { AlertDialogService } from '@appcore/services/alert-dialog.service';
import { GridService } from '@appcore/services/grid.service';
import { GridSortService } from '@appcore/services/grid-sort.service';
import { GridExportService } from '@appcore/services/grid-export.service';
import { FILTER_TYPE } from '@appcore/enums/filter-type.enum';
import { GRID_COMMAND } from '@appcore/enums/grid-command.enum';
import { GridExport } from '@appcore/models/grid/grid-export.model';
import { DialogButtonType } from '@appcore/enums/dialog-button-type.enum';
import { ConfigType } from '@appcore/enums/config-type.enum';
import { ReportDataResult } from '@appcore/interfaces/report-data-result';
import { GridColumn } from '@appcore/models/grid-column.model';
import { GridColumnType } from '@appcore/enums/grid-column-type.enum';
import { FormattedResult } from '@appcore/interfaces/formatted-result.interface';


@Component({
	selector: 'admin-grid',
	templateUrl: './admin-grid.component.html',
	styleUrls: ['./admin-grid.component.scss']
})
export class AdminGridComponent extends ComponentWithSubscription implements OnInit, OnDestroy {
	@ViewChild(AlertDialogComponent) alertDialog: AlertDialogComponent;
	@Input() disabled = false;
	@Input() filterOptions: FilterSelection[] = [];
	@Input() filters: Filter[] = [];
	@Input() gridAbilities: GridAbilities;
	@Input() hasDetailRows = false;
	@Input() hasShowSelectedToggle = false;
	@Input() hideDisplayName = false;
	@Input() key = Utils.generateGuid();
	@Input() reportNames: string[];
	@Input() refreshConfig: Subject<any> = new Subject<any>();
	@Input() refreshData: Subject<any> = new Subject<any>();
	@Input() resetPaging$ = new Subject();
	@Input() searchPlaceholderText = 'Search Data';
	@Input() showMasterSearch: boolean;
	@Output() selectedChange = new Subject();
	@Output() copyCallback: EventEmitter<string> = new EventEmitter<string>();
	@Output() editCallback: EventEmitter<string> = new EventEmitter<string>();
	@Output() deleteCallback: EventEmitter<string> = new EventEmitter<string>();
	configs: GridInfo[] = [];
	loading = false;
	onDataChange = new Subject<GridInfo>();
	selectedFilters: Map<string, Filter> = new Map<string, Filter>();
	selectedGridInfo: GridInfo;
	selectedRows: string[] = [];
	private _queryOptions: QueryOptions = new QueryOptions();
	private _childQueryOptions: QueryOptions = new QueryOptions();
	private searchTextFilter: any = null;
	private _sortEvent?: GridSortEvent;
	private _detailReportSuffix = '_detail';
	private childGridInfo: GridInfo;
	private _snowflakeMaxTimeout = 100931731;

	constructor(
		private gridApi: GridApi,
		private userFeedbackService: UserFeedbackService,
		private filterSelection: FilterSelectionService,
		private alertDialogService: AlertDialogService,
		private gridService: GridService,
		private gridSortService: GridSortService,
		private gridExportService: GridExportService,
		private adminGridExportService: AdminGridExportService
	) {
		super();
	}

	get customSearchFilterType() {
		const filterType = this.selectedGridInfo?.attributes && this.selectedGridInfo?.attributes.SearchFilter;
		if (!filterType)
return;
		return this.selectedGridInfo?.attributes && this.selectedGridInfo?.attributes.SearchFilter &&
			Object.values(FILTER_TYPE).find(ft => ft === filterType);
	}

	get shouldApplyCustomSearch() {
		return this.customSearchFilterType && this.searchTextFilter && this.customSearchFilterType === this.searchTextFilter.filterType;
	}

	get detailRowFilters() {
		return this.selectedGridInfo?.attributes && this.selectedGridInfo?.attributes.DetailRowFilters && JSON.parse(this.selectedGridInfo?.attributes.DetailRowFilters);
	}

	ngOnInit() {
		this.getConfig();

		this.refreshConfig.subscribe(() => {
			this.getConfig();
		});

		this.refreshData.subscribe(() => {
			this.getData();
		});

		this.subscribe(this.gridSortService.sortRequested, this.key, event => {
			this.handleSortEvent(event);
		});

		this.subscribe(this.gridService.commandIssued, this.key, command => {
			switch (command.command) {
				case GRID_COMMAND.edit:
					this.editCallback.emit(command.dataItem);
					break;
				case GRID_COMMAND.copy:
					this.copyCallback.emit(command.dataItem);
					break;
				case GRID_COMMAND.delete:
					this.deleteCallback.emit(command.dataItem);
					break;
				default:
					break;
			}
		});

		this.subscribe(this.gridService.queryOptionsChanged, this.key, event => {
			this._queryOptions = event;
			this.handleSortEvent({sortDescriptors: event.sort});
			this.getData();
		});

		this.subscribe(this.gridService.searchRequested, this.key, searchText => {
			this.updateSearchFilter(searchText);
			this.resetGridToFirstPage();
			this.getData();
		});

		this.resetPaging$.subscribe(_ => {
			this.resetGridToFirstPage();
			const focusEls = document.getElementsByClassName('k-link');
			Array.from(focusEls).forEach(el => {
				(el as HTMLElement).blur();
			});
		});
		this.filterSelection.selectedFilter.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			if (data && this.filterOptions && this.filterOptions.find(s => s.key === data.key))
				this.selectedFilters.set(data.key, data.filter);

		});

		this.subscribe(this.gridService.childSubscriptionSet, this.key, childKey => {
			this.gridApi.getConfig(this.getDetailReportName()).subscribe(
				config => {
					const gridInfo = new GridInfo(config, this.getDetailReportName());
					this.setDetailConfigProperties(gridInfo);
					this.childGridInfo = gridInfo;
					this.gridService.setChildGridInfo(this.key, gridInfo);
				}
			);
			this.subscribe(this.gridService.detailsRequested, childKey, dataItem => {
				this.subscribe(this.gridService.queryOptionsChanged, childKey, childQueryOptions => {
					this._childQueryOptions = childQueryOptions;
					this.getDetailData(childKey, dataItem, childQueryOptions);
				});
				this.getDetailData(childKey, dataItem);
			});
		});

		this.subscribe(this.gridService.selectionsChanged, this.key, selections => {
			this.selectedRows = selections;
			this.selectedChange.next(selections);
		});

		this.adminGridExportService.register(this);
		this.gridExportService.configure((gridInfo: GridInfo, dataItem?: any) => this.adminGridExportService.getExportData(gridInfo, dataItem));
	}

	updateSearchFilter(searchText) {
		const searchParams = this.getSearchParams(searchText);
		if (this.customSearchFilterType)
			this.searchTextFilter = this.getCustomSearchFilter(this.customSearchFilterType, searchParams);

		else
			this.searchTextFilter = this.getDefaultSearchTextFilter(searchParams);
	}

	getCustomSearchFilter(filterType, searchParams): Filter {
		return searchParams ? new Filter({
				filterType,
				modelJson: JSON.stringify(searchParams)
			}) :
			null;
	};


	handleGridInfoRequest(gridInfo: GridInfo, childDataItem?: any): Promise<GridExport> {
		const message = 'Up to 5,000 records will be exported.';
		this.alertDialog.open(`Exporting ${gridInfo.title}`, message, [{buttonType: DialogButtonType.Cancel}], true, true);

		const extraFilters = childDataItem ? this.getRequestExtraFilters(childDataItem) : [];
		const options = childDataItem ? this._childQueryOptions : this._queryOptions;
		const request = this.getDataRequest(gridInfo.reportName, options, extraFilters);

		request.skipAllCounts = true;
		request.overrideTimeout = this._snowflakeMaxTimeout;
		request.gridFilters.take = 5000;
		request.type = ConfigType.Grid;

		return new Promise<GridExport>(resolve => {
			this.gridApi.getData(request)
				.pipe(
					finalize(() => this.loading = false)
				)
				.subscribe(
					dataResult => {
						if (!dataResult) {
							this.userFeedbackService.showUnexpectedError();
							return;
						}

						this.alertDialog.close(DialogButtonType.Ok);
						const data: GridExport = {gridInfo, result: this.extractGridData(dataResult)};
						resolve(data);
					},
					() => {
						this.userFeedbackService.showUnexpectedError();
					}
				);

			this.alertDialogService.closed.subscribe(result => {
				if (result && result.dialog === this.alertDialog && result.result === DialogButtonType.Cancel)
					resolve(GridExport.withNoData(gridInfo));

			});
		});
	}

	handleSortEvent(e: GridSortEvent) {
		if (this.configs?.length) {
			this._queryOptions.sort = e.sortDescriptors;
			this._sortEvent = e;
		}
	}

	canHandle(gridInfo: GridInfo) {
		const exportingReportName = this.isDetailReport(gridInfo.reportName) ? gridInfo.reportName.replace(this._detailReportSuffix, '') : gridInfo.reportName;
		return this.selectedGridInfo?.reportName === exportingReportName;
	}

	showTimeoutErrorMessage() {
		this.userFeedbackService.showError('The selected list was too large. Please add filters or reduce the number of selected metrics.');
	}

	setFilters(value: Filter[]) {
		this.filters = value;
	}

	getDetailRowsFilters(dataItem) {
		return this.detailRowFilters.map(column => ({
			filterType: FILTER_TYPE.HARD_CODED,
			paramName: column,
			modelJson: dataItem[column]?.value
		}));
	}

	resetGridToFirstPage() {
		this._queryOptions.skip = 0; // Reset to grid page 0
	}

	ngOnDestroy() {
		this.adminGridExportService.unregister(this);
	}

	private getConfig() {
		if (this.reportNames && this.reportNames.length) {
			this.loading = true;
			const reportName = this.reportNames[0];
			this.gridApi.getConfig(reportName).subscribe(config => {
					this.configs = [new GridInfo(config, reportName)];
					this.setConfigProperties(this.configs[0]);
					this._queryOptions.take = config.take || this._queryOptions.take;
					this.setConfig(this.configs);
					this.getData();
				},
				() => {
					this.loading = false;
					this.userFeedbackService.showUnexpectedError();
				}
			);
		}
	}

	private getData() {
		if (this.reportNames && this.reportNames.length) {
			this.loading = true;
			const request = this.getDataRequest(this.reportNames[0], this._queryOptions);
			this.gridApi.getData(request)
				.pipe(
					finalize(() => this.loading = false)
				)
				.subscribe(
					dataResult => {
						if (!dataResult) {
							this.userFeedbackService.showUnexpectedError();
							return;
						}
						this.setData(dataResult);
					},
					() => {
						this.userFeedbackService.showUnexpectedError();
					}
				);
		}
	}

	private getDataRequest(reportName: string, queryOptions: QueryOptions, extraFilters: Filter[] = []): Partial<GridDataRequest> {
		const externalFilters: Filter[] = this.getFiltersCopy();
		const selectedFilters: Filter[] = this.getSelectedFilters();
		let allFilters = externalFilters.concat(selectedFilters);
		allFilters = extraFilters.length ? allFilters.concat(extraFilters) : allFilters;

		const gridFilterFilters = {...queryOptions};

		if (!this.customSearchFilterType)
			gridFilterFilters.filter = this.searchTextFilter;

		const request: Partial<GridDataRequest> = {
			reportName,
			sqlFilters: allFilters,
			gridFilters: gridFilterFilters,
			queryType: 'other',
			type: ConfigType.Grid
		};
		return request;
	}

	private getDetailReportName() {
		return `${this.selectedGridInfo.reportName}${this._detailReportSuffix}`;
	}

	private isDetailReport(reportName: string) {
		return reportName.endsWith(this._detailReportSuffix);
	}

	private getRequestExtraFilters(dataItem: any) {
		let extraFilters: Filter[] = [];


		if (dataItem && dataItem.bundleId && dataItem.bundleId.value)
			extraFilters = [{filterType: 'HardCoded', paramName: 'bundleId', modelJson: `${dataItem.bundleId.value}`}];

		if (dataItem && dataItem.userId && dataItem.userId.value)
			extraFilters = [{filterType: 'HardCoded', paramName: 'userId', modelJson: `${dataItem.userId.value}`}];

		if (dataItem && dataItem.reportCollectionId && dataItem.reportCollectionId.value) {
			extraFilters = [{
				filterType: 'HardCoded',
				paramName: 'reportCollectionId',
				modelJson: `${dataItem.reportCollectionId.value}`
			}];
		}
		if (dataItem && dataItem.companyId && dataItem.companyId.value) {
			extraFilters = [{
				filterType: 'HardCoded',
				paramName: 'companyId',
				modelJson: `${dataItem.companyId.value}`
			}];
		}
		if (dataItem && dataItem.codesetid && dataItem.codesetid.value) {
			extraFilters = [{
				filterType: 'Bucket',
				paramName: 'bucket_id',
				modelJson: JSON.stringify([`${dataItem.codesetid.value}`])
			},
				{
					filterType: 'AdminCompanyId',
					paramName: 'companyId',
					modelJson: JSON.stringify([`${dataItem.companyId.value}`])
				}];
		}
		if (dataItem && dataItem.codeSetRequestId && dataItem.codeSetRequestId.value) {
			extraFilters = [{
				filterType: 'HardCoded',
				paramName: 'codeSetRequestId',
				modelJson: `${dataItem.codeSetRequestId.value}`
			}];
		}
		if (this.detailRowFilters && this.detailRowFilters.length)
			extraFilters = this.getDetailRowsFilters(dataItem);

		if (this.shouldApplyCustomSearch)
			extraFilters.push(this.searchTextFilter);

		return extraFilters;
	}

	private getDetailData(childKey: string, dataItem: any, childQueryOptions?: QueryOptions) {
		if (!childQueryOptions)
			childQueryOptions = new QueryOptions;


		this.gridService.setLoading(childKey, true);
		// TODO: move this to parent component so that the userId isn't hard-coded here
		const extraFilters = this.getRequestExtraFilters(dataItem);

		const request = this.getDataRequest(this.getDetailReportName(), childQueryOptions, extraFilters);
		this.gridApi.getData(request).subscribe(
			response => {
				if (!response) {
					this.userFeedbackService.showUnexpectedError();
					return;
				}
				const data = this.extractGridData(response);
				this.gridService.setData(childKey, data);
			},
			() => {
				this.userFeedbackService.showUnexpectedError();
			},
			() => {
				this.gridService.setLoading(childKey, false);
			}
		);
	}

	private setConfig(configs: any): void {
		const newInfos = this.reportNames.map(name => {
			const index = this.reportNames.indexOf(name);
			const config = configs[index];
			return new GridInfo(config, name);
		});
		this.selectedGridInfo = newInfos[0];
		this._queryOptions.take = this.selectedGridInfo.take || this._queryOptions.take;
		this.configs = newInfos;
		this.applySort();
		this.onDataChange.next(this.selectedGridInfo);
		this.loading = true;
	}

	private setData(response: ReportDataResult): void {
		const gridData: GridDataResult = this.extractGridData(response);
		if (!gridData)
			return;


		this.gridService.setData(this.key, gridData);
		this.gridService.setFooter(this.key, response.footer);

		this.selectedGridInfo.columns = this.filterColumnsWithNoData(this.selectedGridInfo.columns, response.data);

		this.onDataChange.next(this.selectedGridInfo);
		this.loading = false;
	}

	private extractGridData(response: ReportDataResult): GridDataResult {
		if (!response)
			return null;


		return {
			data: response.data as any,
			total: response.totalRows
		};
	}

	private getSearchParams(searchText) {
		if (!searchText || !searchText.length)
			return null;

		const quotedPhrase = /"(.*?)"/g;
		const searchPhrase = (searchText.match(quotedPhrase) || []).map(x => x.replace(/["]/g, '').trim());
		const searchWords = searchText
			.replace(quotedPhrase, '')
			.trim()
			.split(' ')
			.map(x => x.trim());

		return searchPhrase.concat(searchWords);
	}

	private getDefaultSearchTextFilter(searchParams) {
		if (!searchParams || !searchParams.length)
			return;
		const flattenedColumns = this.getFlattenedColumns();
		const searchable = flattenedColumns.filter(x => x.filterable && x.columnType !== GridColumnType.preheader).map(x => x.field);

		const filterDescriptor = searchParams.map(word =>
			searchable.map(field => ({
				field,
				operator: 'contains',
				value: word
			}))
		);

		const orFilters = filterDescriptor.map(filters => ({
			logic: 'or',
			filters
		}));

		const newFilter = {
			logic: 'and',
			filters: orFilters
		};

		return newFilter;
	}

	private getFlattenedColumns(): GridColumn[] {
		return this.selectedGridInfo.columns.reduce(
			(accumulator, currentColumn) => accumulator.concat(currentColumn?.columns?.length ? currentColumn?.columns : currentColumn),
			[]
		);
	}

	private applySort() {
		if (this._sortEvent) {
			this._queryOptions.sort = this._sortEvent.sortDescriptors;
			this.gridSortService.sort(this.key, this._queryOptions.sort);
		}
	}

	private getFiltersCopy() {
		const withoutCustomSearch = f => f.filterType !== this.customSearchFilterType;
		if (this.shouldApplyCustomSearch)
			this.filters = this.filters.filter(withoutCustomSearch).concat(this.searchTextFilter);
		else
			this.filters = this.filters.filter(withoutCustomSearch);

		return this.filters ? [...this.filters] : [];
	}

	private getSelectedFilters(): Filter[] {
		this.updateFilterOptionsFromSelectedFilters();
		const filters = this.getFiltersFromFilterOptions();
		return filters;
	}

	private updateFilterOptionsFromSelectedFilters() {
		if (this.selectedFilters) {
			Array.from(this.selectedFilters.keys()).forEach(key => {
				const selectedFilter: Filter = this.selectedFilters.get(key);
				const existingOption = this.filterOptions.find(f => f.key === key);
				if (existingOption)
					existingOption.filter = selectedFilter;

			});
		}
	}

	private getFiltersFromFilterOptions(): Filter[] {
		if (this.filterOptions && this.filterOptions.length) {
			const mapped = this.filterOptions.map(filterOption => {
				const filter = filterOption.filter;

				// AR - This is kinda whack - It looks like we are using local storage as our method to pass data around. The filter key on the FilterSelection MUST be unique.
				const value = filterOption.values.find(v => v.key === localStorage.getItem(filterOption.key)) || filterOption.values[0];
				if (value)
					filter.modelJson = value.value;

				return filter;
			});
			return mapped;
		}

		return [];
	}

	private filterColumnsWithNoData(columns: GridColumn[], data: FormattedResult[]): GridColumn[] {
		if (!data || !data.length)
			return columns;


		const copy = [...columns];
		for (const i in copy) {
			if (this.isColumnInData(copy[i], data))
				continue; // the column is in the data, no need to remove it

			if (copy[i].columns && copy[i].columns.length)
				copy[i].columns = this.filterColumnsWithNoData(copy[i].columns, data);


			if (copy[i].columns && !copy[i].columns.length)
				columns = columns.filter(c => c !== copy[i]); // filter out column

			else
				continue;


			if (!this.isColumnInData(copy[i], data))
				columns = columns.filter(c => c !== copy[i]); // filter out column

		}
		return columns;
	}

	private isColumnInData(column: GridColumn, data: FormattedResult[]) {
		// will return true if there is no data.
		return !data.length || (data.length && data[0].hasOwnProperty(column.field));
	}

	private setConfigProperties(config: GridInfo) {
		config.showMasterSearch = this.showMasterSearch;
		config.gridAbilities = this.gridAbilities;
		config.hasDetailRows = this.hasDetailRows;
		config.showColumnSelector = true;
	}

	private setDetailConfigProperties(config: GridInfo) {
		config.showMasterSearch = false;
		config.gridAbilities = new GridAbilities();
		config.gridAbilities.hideSelectableCheckbox = true;
		config.hasDetailRows = false;
		config.showColumnSelector = true;
	}
}
