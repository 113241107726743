import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Selection } from '@appcore/interfaces/selection.interface';
import { CheckedSelection } from '@appcore/interfaces/checked-selection.interface';

@Component({
	selector: 'admin-list-box',
	templateUrl: './admin-list-box.component.html',
	styleUrls: ['./admin-list-box.component.scss']
})
export class AdminListBoxComponent {
	@Input() height = '15em';
	@Input() options: CheckedSelection[] = [];
	@Input() selectedOption: Selection;
	@Input() isRequired = false;
	@Input() width = '33%';
	@Output() onChanged = new EventEmitter();

	showSelector = false;

	constructor() {
	}

	toggleShowSelector() {
		this.showSelector = !this.showSelector;
	}

	onSelect(event: any) {
		this.toggleShowSelector();

		this.onChanged.emit(event);
	}
}
