<div class="row p-3">
	<div class="col-auto mx-auto">
		<div class="row">
			<div class="col cover-page-width">
				<div class="row">
					<div class="col-auto logo">
						<img class="img-fluid" src="./assets/img/logo.svg"/>
					</div>
				</div>
				<div class="row text-center">
					<div class="col">
						<p class="text-primary fa-15x">You are now logged out.</p>
						<div (click)="toLogin()" class="btn btn-lg btn-primary mt-5">Return to Log In screen</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
