import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ComponentWithSubscription } from '../component-with-subscription';
import { NpiSearchResult } from '../../models/npi/npi-search-result.model';
import { SearchItem } from '../../models/search/search-item.model';
import { CheckedSelection } from '../../interfaces/checked-selection.interface';
import { ALL_OPTION, ProviderTypeConfigs } from '../../constants/constants';
import { SearchType } from '../../enums/search-type.enum';
import { PROVIDER_TYPE } from '../../enums/provider-type.enum';

@Component({
    selector: 'trella-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent extends ComponentWithSubscription implements OnInit, OnChanges {
    @Output() errorCallback: EventEmitter<void> = new EventEmitter();
    @Output() searchCallback: EventEmitter<any> = new EventEmitter();
    @Output() itemSelectCallback: EventEmitter<NpiSearchResult> = new EventEmitter();
    @Input() minSearchLength = 0;
    @Input() searchResults: SearchItem[];
    @Input() showLoadBar: boolean;
    @Input() options = [];
    @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;
    @ViewChild('searchInput') searchInput: ElementRef;

    inputChangeSub = new Subject<string>();
    myControl = new UntypedFormControl();
    searchText: string;
    searchPlaceholderText = 'Search by ACO/Provider Name, Location, or ID';

    public selectedProviderType: CheckedSelection;

    constructor() {
        super();
    }

    ngOnInit() {
        this.options.unshift({id: ALL_OPTION, display: 'All'});
        this.inputChangeSub.pipe(takeUntil(this.ngUnsubscribe), debounceTime(500)).subscribe(
            async search => {
                search = search.trim();
                if (!search || search.length < this.minSearchLength) 
                    return;
                
                this.showLoadBar = true;
                await this.onInputChangeInternal(search, this.selectedProviderType);
            },
            err => this.errorCallback.emit()
        );

        this.selectedProviderType = this.options[0];
    }

    ngOnChanges() {
        this.showLoadBar = false;
    }

    onChange(search: string) {
        this.searchText = search;
        this.inputChangeSub.next(search);
    }

    selectItem(event: MatAutocompleteSelectedEvent) {
        const info = event.option.value;
        this.itemSelectCallback.emit(info);
        this.autoComplete.openPanel();
        this.searchInput.nativeElement.value = '';
        this.searchInput.nativeElement.blur();
    }

    handleFilterChanged(provider: CheckedSelection) {
        this.selectedProviderType = provider;
        this.autoComplete.openPanel();
        this.onInputChangeInternal(this.searchText, this.selectedProviderType);
    }

    openProviderType(event) {
        event.stopPropagation();
        this.autoComplete.openPanel();
    }

    getSelectedColorClass(searchType: SearchType) {
        const providerType = this.getProviderTypeBySearchType(searchType);
        const selectedProvider = ProviderTypeConfigs[providerType];
        return selectedProvider && selectedProvider.colorClass;
    }

    private async onInputChangeInternal(search: string, selection: CheckedSelection) {
        let selectedProviderTypes = [selection];
        if (selection.id === ALL_OPTION)
            selectedProviderTypes = this.options.filter(option => option.id !== ALL_OPTION);

        this.searchCallback.emit({query: search, filters: selectedProviderTypes});
    }

    private getProviderTypeBySearchType(type: SearchType): PROVIDER_TYPE {
        switch (type) {
            case SearchType.Hha:
                return PROVIDER_TYPE.homehealth;
            case SearchType.Hos:
                return PROVIDER_TYPE.hospice;
            case SearchType.Hosp:
                return PROVIDER_TYPE.hospital;
            case SearchType.PhysApp:
                return PROVIDER_TYPE.physician;
            case SearchType.Snf:
                return PROVIDER_TYPE.skilledNursing;
            default:
                return null;
        }
    }
}
