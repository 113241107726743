
export enum DestinationType {
    Deceased = 'DECEASED',
    Home = 'HOME',
    HomeHealth = 'HHA',
    Hospice = 'HOS',
    Inpatient = 'INP',
    InpatientEr = 'INPER',
    OutpatientErObservation = 'OUTPEROBS',
    ResumptionOfHomeHealth = 'RHHA',
    SkilledNursing = 'SNF'
}
