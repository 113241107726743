import { Component, EventEmitter, Output } from '@angular/core';
import Sticky from 'src/app/shared/Sticky';

@Component({
	selector: 'app-alert-dialog',
	templateUrl: './alert-dialog.component.html'
})
export class AlertDialogComponent {
	@Output() okPressed = new EventEmitter();
	@Output() cancelPressed = new EventEmitter();
	@Output() openDialog = new EventEmitter();

	title: string;
	message: string;
	okButtonLabel: string;
	cancelButtonLabel: string;
	opened = false;

	open(title: string, message: string, okButtonLabel = 'OK', cancelButtonLabel?: string) {
		this.title = title;
		this.message = message;
		this.okButtonLabel = okButtonLabel;
		this.cancelButtonLabel = cancelButtonLabel;
		this.opened = true;
		this.openDialog.emit();
		Sticky.removeStickyAnalyzeHeaders();
	}

	close() {
		this.opened = false;
		Sticky.addStickyAnalyzeHeaders();
	}

	ok() {
		this.okPressed?.emit();
		this.close();
	}

	cancel() {
		this.cancelPressed?.emit();
		this.close();
	}

}
