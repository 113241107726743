import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { remove } from 'lodash';
import { debounceTime, finalize, startWith } from 'rxjs/operators';
import { NpiSearchOption, NpiSearchRequest } from 'src/app/shared/models/npi';
import { NpiApi } from 'src/app/api/npi.api';
import {
	PROVIDER_TYPE
} from '@appcore/enums/provider-type.enum';
import { Utils } from '@appcore/helpers/Utils';


@Component({
	selector: 'app-npi-search-select',
	templateUrl: './npi-search-select.component.html',
	styleUrls: ['./npi-search-select.component.scss']

})
export class NpiSearchSelectComponent implements OnInit {

	@Input() provider: string;
	@Input() selectedNpis: any[] = [];
	@Output() selectedNpisChange: EventEmitter<any> = new EventEmitter<any[]>();

	searchInput = new UntypedFormControl();
	addError = false;
	addErrorText: string;
	height = '200px';
	hover = false;
	resultFilterText: string;
	searchOptions: NpiSearchOption[];
	showSpinner = false;

	constructor(private npiApi: NpiApi) {
	}

	get selectedNpisThatMeetSearchCriteria() {
		if (!this.resultFilterText || this.resultFilterText === '')
			return this.selectedNpis;

		return this.selectedNpis.filter(n => n.display.includes(this.resultFilterText.toUpperCase()) || n.npi.includes(this.resultFilterText));
	}

	get showProviderPatientCount() {
		return [PROVIDER_TYPE.homehealth.toString(), PROVIDER_TYPE.hospice.toString(), PROVIDER_TYPE.skilledNursing.toString()].includes(this.provider);
	}


	optionDisplay(option) {
		const aliasString = option.alias ? '(' + option.alias + ')' : '';
		return `${aliasString} ${option.display}`.trim();
	}

	ngOnInit() {
		this.searchInput.valueChanges
			.pipe(
				startWith(''),
				debounceTime(500)
			)
			.subscribe((latest => this.search(latest)));
	}

	public async search(searchInput) {
		this.addError = false;
		this.addErrorText = '';
		if (typeof searchInput !== 'string')
			return;

		searchInput = searchInput.trim();
		if (!searchInput || !this.provider)
			return;

		this.showSpinner = true;

		const npiSearchRequest: NpiSearchRequest = new NpiSearchRequest(searchInput, this.provider);

		this.npiApi.search(npiSearchRequest).pipe(
			finalize(() => this.showSpinner = false)
		).subscribe((result: NpiSearchOption[]) => {
			this.searchOptions = result;
			this.searchOptions = this.getUniqueNpiProviderPortal(this.searchOptions); // remove duplicates
			this.searchOptions.sort();
		});
	}

	updateFilterText(text) {
		this.resultFilterText = text;
	}

	removeNpi(info: any) {
		remove(this.selectedNpis, e => e.npi === info.npi);
		this.updateSelectedNpisReference();
		this.selectedNpisChange.emit(this.selectedNpis);
	}

	removeAllNpi() {
		this.selectedNpis = [];
	}

	updateSelectedNpisReference() {
		this.selectedNpis = [...this.selectedNpis]; // needed for cdk viewport to rerender
	}

	addNpi(info: NpiSearchOption) {
		const exists = this.selectedNpis.find(n => n.npi === info.npi);
		if (!exists) {
			this.selectedNpis = [...this.selectedNpis, info];
			this.selectedNpisChange.emit(this.selectedNpis);
		}
		else
			this.showError(`NPI ${info.display} (${info.npi}) has already been added.`);

	}

	showError(message: string) {
		this.addErrorText = message;
		this.addError = true;
	}

	icon(provider: PROVIDER_TYPE) {
		return Utils.getRouteAttrByProviderType(provider).icon;
	}

	display(): string {
		return '';
	}

	private getUniqueNpiProviderPortal(array) {
		const set = new Set();
		return array.map((v, index) => {
			const key = v.npi + '|' + v.provider;

			if (set.has(key))
				return false;

			else {
				set.add(key);
				return index;
			}

		}).map(e => array[e]).filter(e => e);
	}
}
