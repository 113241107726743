import { Injectable } from '@angular/core';
import { AdminConfig } from '../models/user';
import { AppUser } from '../models/app-user';
import { ADMIN_PAGE_TYPE } from '../enums/admin-page-type.enum';

@Injectable({
	providedIn: 'root'
})
export class ApplicationCacheService {
	page: ADMIN_PAGE_TYPE;
	configOptions: AdminConfig;
	user: AppUser;

	get userId() {
		return this.user && this.user.marketscapeId;
	}

	setPageType(type: ADMIN_PAGE_TYPE) {
		if (!type) {
			console.warn('Invalid Page');
			return;
		}

		this.page = type;
	}

	setUser(user: AppUser) {
		this.user = user;
	}

	setConfigOptions(configOptions: AdminConfig) {
		this.configOptions = configOptions;
	}

	setReturnUrl(url: string) {
		if (url) 
			localStorage.setItem('returnurl', url);
		
		else 
			localStorage.removeItem('returnurl');
		
	}

	getReturnUrl() {
		return localStorage.getItem('returnurl');
	}
}
