import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApplicationCacheService } from './application-cache.service';
import { SSOService } from './sso.service';
import { ROUTE_CONSTANTS } from '../constants/route-constants.constant';
import { AuthenticationService } from './authentication.service';
import { EnvService } from '@appcore/services/env.service';
import {
	ProductName
} from '@appcore/enums/product-name.enum';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	urlStack = null;

	constructor(
		private router: Router,
		private environmentService: EnvService,
		private applicationCacheService: ApplicationCacheService,
		private ssoService: SSOService,
		private authService: AuthenticationService) {
	}

	async navigateToUnauthorized() {
		return this.navigateToInternal([ROUTE_CONSTANTS.unauthorized.route]);
	}

	async navigateToPrevious() {
		return this.navigateTo(null, document.referrer);
	}

	async navigateToApp(...states: string[]) {
		return this.navigateTo(null, ...states);
	}

	async navigateToExcelApp(token: string, product: ProductName) {
		if (product === ProductName.Strategy) 
			return this.navigateToStrategy();
		if (product === ProductName.Crm) 
			return this.navigateToCrm();
		return this.navigateToExternal(`${this.environmentService.appUrl}/${ROUTE_CONSTANTS.sso.redirectRouteLabel}?${ROUTE_CONSTANTS.sso.portalGetLabel}=${product || ''}&${ROUTE_CONSTANTS.sso.tokenGetLabel}=${token}`);
	}

	async navigateToStrategy() {
		this.navigateToExternal(`${this.environmentService.strategyUrl}`);
	}

	navigateToCrm() {
		this.authService.getCognitoToken().then(token => {
			this.navigateToExternalApp(`${this.environmentService.crmUrl}`, token);
		});
	}

	/** **************************************************
	 ********************* Common ***********************
	 ****************************************************/


	async navigateToLogin(returnUrl?: string, fromLogout = false, source: string = null) {
		const paramObj = {};
		paramObj[ROUTE_CONSTANTS.returnUrlLabel] = returnUrl || '';
		if (!fromLogout) 
			return this.ssoService.navigateToSSObySource(source);
		
		return this.navigateToUrl(ROUTE_CONSTANTS.login);
	}

	navigateAfterLogin(returnUrl: any) {
		const navigateToLanding = returnUrl && returnUrl.includes('login') ? true : false;
		if (returnUrl) {
			if (navigateToLanding)
				return this.navigateToUrl(ROUTE_CONSTANTS.landing);

			this.applicationCacheService.setReturnUrl(null); // clear return URL for next time.
			return this.navigateToUrl(returnUrl);
		}
		return this.navigateToUrl(ROUTE_CONSTANTS.landing);
	}

	async navigateToLogOut() {
		window.location.href = this.environmentService.redirectSignOut;
	}

	initUrlStack() {
		if (this.urlStack !== null) 
			return;
		

		this.urlStack = [];
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.urlStack.push(event.url);
			});
	}

	async navigateTo(params?: object, ...states: string[]) {

		const statesArray = [];
		for (const state of states) 
			statesArray.push(state);
		
		return this.navigateToInternal(statesArray, params);
	}

	async navigateToUrl(url) {
		return this.router.navigateByUrl(url);
	}

	async navigateToInternal(relativePath?: Array<string>, params?: any): Promise<boolean> {
		const extras = params ? {queryParams: params} as NavigationExtras : {};

		if (!relativePath)
			return this.router.navigateByUrl('', extras);

		const url = this.router.createUrlTree([...relativePath], extras);
		return this.router.navigateByUrl(url);
	}


	navigateToExternal(url: string) {
		window.open(url);
	}

	navigateToExternalApp(url: string, token: string, method = 'POST') {
		const ssoFormGroup = [{
			key: `${ROUTE_CONSTANTS.sso.tokenGetLabel}`,
			value: token
		}];
		this.navigateUsingSubmitForm(url, method, ssoFormGroup);
	}

	async navigateToEula() {
		return this.navigateToInternal([ROUTE_CONSTANTS.eula.route]);
	}

	async navigateToDataEula() {
		return this.navigateToInternal([ROUTE_CONSTANTS.dataEula.route]);
	}

	navigateUsingSubmitForm(action, method, formGroup) {
		// Here we forward the user by creating/submitting a form with a payload
		// This avoids adding the payload as a URL parameter. (it is more secure)
		const form = document.createElement('form');
		form.style.display = 'none';
		form.method = method;
		form.action = action;
		for (const item of formGroup) {
			const input = document.createElement('input');
			input.name = item.key;
			input.id = item.key;
			input.value = item.value;
			form.appendChild(input);
		}
		document.body.appendChild(form);
		form.submit();
	}
}
