import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Feature } from '@appcore/models/directory/feature.model';

@Injectable({
	providedIn: 'root'
})
export class FeatureApi {

	private _controllerName = 'Feature';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getAll(): Observable<Feature[]> {
		return this.api.get(this._controllerName);
	}

}
