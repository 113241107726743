import { Observable, Subscription } from 'rxjs';

export class Keyed<T> {
    key: string;
    value: T;
}

export const subscribeToKeyed = <T>(observable: Observable<Keyed<T>>, key: string, observe: (t: T) => void): Subscription =>
    observable.subscribe(result => {
        if (result?.key === key) 
            observe(result.value);
        
    });

export const subscribeToKey = (observable: Observable<string>, key: string, observe: () => void): Subscription =>
    observable.subscribe(observedKey => {
        if (observedKey === key) 
            observe();
        
    });
