<div class="row min-height d-flex flex-row align-items-stretch">
    <div class="col flex-2 d-flex flex-column">
        <span class="text-label">{{ availableSelectionsTitle }}</span>
        <input
                [(ngModel)]="availableSelectionsSearchQuery"
                [class.hidden]="!searchable"
                class="mt-1 mb-1 w-100 k-textbox searchFilter"
                name="searchSource"
                placeholder="Search"
                type="text"
        />
        <div [style.height]="height" class="border rounded">
            <cdk-virtual-scroll-viewport [style.height]="height" itemSize="25">
                <div
                        *cdkVirtualFor="let item of availableSelections | search: 'display':availableSelectionsSearchQuery"
                        [class.active]="highlightedAvailableSelections.includes(item)"
                >
                    <div (click)="highlightAvailableSelections(item)" class="p-2 pointer">
                        <mat-list-item class="user-select-none">
                            {{ item.display }}
                        </mat-list-item>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
    <div class="col d-flex flex-column justify-content-around flex-1 mt-5">
        <button (click)="moveAllToActiveSelections()" *ngIf="addAll" class="btn btn-primary btn-sm" type='button'>
            Add All <i class="fas fa-chevron-right fa-1x pointer"></i>
        </button>
        <button (click)="moveToActiveSelections()" class="btn btn-primary btn-sm" type='button'>
            Add Selected <i class="fas fa-chevron-right fa-1x pointer"></i>
        </button>
        <button (click)="moveToAvailableSelections()" class="btn btn-primary btn-sm" type='button'>
            <i class="fas fa-chevron-left fa-1x pointer"></i> Remove Selected
        </button>
        <button (click)="moveAllToAvailableSelections()" class="btn btn-primary btn-sm" type='button'>
            <i class="fas fa-chevron-left fa-1x pointer"></i> Remove All
        </button>
    </div>
    <div class="col flex-2 d-flex flex-column">
        <span class="text-label">{{ activeSelectionsTitle }}</span>
        <input
                [(ngModel)]="activeSelectionsSearchQuery"
                [class.hidden]="!searchable"
                class="mt-1 mb-1 w-100 k-textbox searchFilter"
                name="searchDestination"
                placeholder="Search"
                type="text"
        />
        <div [ngClass]="{'required': activeSelections.length == 0 && isRequired}" [style.height]="height"
             class="border rounded">
            <cdk-virtual-scroll-viewport [style.height]="height" itemSize="25">
                <div
                        *cdkVirtualFor="let item of activeSelections | search: 'display':activeSelectionsSearchQuery"
                        [class.active]="highlightedActiveSelections.includes(item)"
                >
                    <div (click)="highlightActiveSelections(item)" class="p-2 pointer">
                        <mat-list-item class="user-select-none">
                            {{ item.display }}
                        </mat-list-item>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>
