import {
	PROVIDER_TYPE
} from '@appcore/enums/provider-type.enum';

export class NpiSearchOption {
	npi: string;
	display: string;
	alias?: string;
	provider?: PROVIDER_TYPE;
	search?: string;
	icon?: string;
	count: string;
}

export class NpiSearchRequest {
	search: string;
	provider: string;
	lob: string;

	constructor(search: string, provider: string) {
		if (!search) 
			return;
		
		this.search = search;
		this.provider = provider;
	}
}

export class NpiVerifyRequest {
	npis: string[];
	provider?: string;
	lobFilter?: string[];
	marketFilter?: string[];

	constructor(npis: string[], provider?: string) {
		if (!npis || !npis.length) 
			return;
		
		this.npis = npis;
		this.provider = provider;
	}
}
