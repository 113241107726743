import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterSelections } from '../../models/filter-selections.model';
import { Locality } from '../../interfaces/locality.interface';
import { FilterRules } from '../../interfaces/filter-rules.interface';
import { FILTER_TYPE } from '../../enums/filter-type.enum';
import { FilterGroupService } from '../../services/filter-group.service';
import { SelectionsWithRangeSelection } from '../../models/selections-with-range-selection.model';
import { CheckedSelection } from '../../interfaces/checked-selection.interface';
import { RangeSelection } from '../../models/ranges/range-selection.model';
import { CheckedSelectionGroup } from '../../interfaces/checked-selection-group.interface';

@Component({
    selector: 'trella-filter-group',
    templateUrl: './filter-group.component.html',
    styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent {
    @Input()
    defaultLocationTypes: {
        [FILTER_TYPE.ACO_BENEFICIARY_LOCATION]: boolean;
        [FILTER_TYPE.ACO_PROVIDER_LOCATION]: boolean;
        [FILTER_TYPE.BENEFICIARY_LOCATION]: boolean;
        [FILTER_TYPE.PROVIDER_LOCATION]: boolean;
        [FILTER_TYPE.PHYSICIAN_GROUP_BENEFICIARY_LOCATION]: boolean;
    };
    @Output() selectionsChanged: Observable<FilterSelections>;
    @Output() myLocationSaved: Observable<{
        locationType: string;
        locations: Locality[]
    }>;

    @Output() myLocationToggled: Observable<{
        locationType: string;
        isActive: boolean
    }>;

    @Input() definitionFactory: (FILTER_TYPE) => string;
    @Input() filterTypes: FILTER_TYPE[];
    @Input() filterRules: FilterRules;
    @Input() isMyLocationsLoading: boolean;
    @Input() myLocations: Locality[];
    @Input() titleFactory: (FILTER_TYPE) => string;
    FILTER_TYPE = FILTER_TYPE;

    private _acos: Node[] = [];
    private _affiliations: SelectionsWithRangeSelection;
    private _beneficiaryLocations: Locality[] = [];
    private _medicalGroups: CheckedSelection[] = [];
    private _networks: CheckedSelection[] = [];
    private _providerLocations: Locality[] = [];
    private _rangeFilters: RangeSelection[] = [];
    private _selections: FilterSelections = {};
    private _specialties: Node[] = [];
    private _myLocationSaved: EventEmitter<{ locationType: string; locations: Locality[] }> = new EventEmitter<{
        locationType: string;
        locations: Locality[];
    }>();
    private _myLocationToggled: EventEmitter<{ locationType: string; isActive: boolean }> = new EventEmitter<{
        locationType: string;
        isActive: boolean;
    }>();
    private _selectionsChanged: EventEmitter<FilterSelections> = new EventEmitter<FilterSelections>();
    private filterComponents = [
        'TRELLA-SIMPLE-FILTER',
        'TRELLA-AFFILIATION',
        'TRELLA-MULTI-LEVEL-FILTER',
        'TRELLA-LOCATION',
        'TRELLA-RANGE'
    ];

    constructor(private filterGroupService: FilterGroupService) {
        this.selectionsChanged = this._selectionsChanged.asObservable();
        this.myLocationSaved = this._myLocationSaved.asObservable();
        this.myLocationToggled = this._myLocationToggled.asObservable();
    }

    @Input()
    get acos() {
        return this._acos;
    }

    set acos(acos: Node[]) {
        this._acos = acos;
        this._selections.acos = acos;
    }

    @Input()
    get affiliations() {
        return this._affiliations;
    }

    set affiliations(affiliations: SelectionsWithRangeSelection) {
        this._affiliations = affiliations;
        this._selections.affiliations = affiliations;
    }

    @Input()
    get beneficiaryLocations() {
        return this._beneficiaryLocations;
    }

    set beneficiaryLocations(locations: Locality[]) {
        this._beneficiaryLocations = locations;
        this._selections.beneficiaryLocations = locations;
    }

    @Input()
    get medicalGroups() {
        return this._medicalGroups;
    }

    set medicalGroups(medicalGroups: CheckedSelection[]) {
        this._medicalGroups = medicalGroups;
        this._selections.medicalGroups = medicalGroups;
    }

    @Input()
    get networks() {
        return this._networks;
    }

    set networks(networks: CheckedSelection[]) {
        this._networks = networks;
        this._selections.networks = networks;
    }

    @Input()
    get providerLocations() {
        return this._providerLocations;
    }

    set providerLocations(locations: Locality[]) {
        this._providerLocations = locations;
        this._selections.providerLocations = locations;
    }

    @Input()
    get rangeFilters() {
        return this._rangeFilters;
    }

    set rangeFilters(rangeFilters: RangeSelection[]) {
        this._rangeFilters = rangeFilters;
        this._selections.rangeFilters = rangeFilters;
    }

    @Input()
    get specialties() {
        return this._specialties;
    }

    set specialties(specialties: Node[]) {
        this._specialties = specialties;
        this._selections.specialties = specialties;
    }

    @HostListener('document:click', ['$event']) clickout(event) {
        let target = event.target;
        while (target) {
            if (this.filterComponents.lastIndexOf(target.nodeName) > -1)
                break;


            if (target.nodeName === 'MAIN') {
                target = null;
                this.filterGroupService.closeFilters();
            } else
                target = target.parentNode;

        }
    }

    isIncluded(filterType: FILTER_TYPE) {
        return this.filterTypes.some(x => x === filterType);
    }

    handleAffiliationsChanged(affiliations: SelectionsWithRangeSelection) {
        this._selections.affiliations = affiliations;
        this._selectionsChanged.emit(this._selections);
    }

    handleBeneficiaryLocationChanged(locations: Locality[]) {
        this._selections.beneficiaryLocations = locations;
        this._selectionsChanged.emit(this._selections);
    }

    handleLocationChanged(locations: Locality[]) {
        this._selections.providerLocations = locations;
        this._selectionsChanged.emit(this._selections);
    }

    handleMyLocationSaved(data: { locationType: string; locations: Locality[] }) {
        this._myLocationSaved.emit(data);
    }

    handleMyLocationToggled(data: { locationType: string; isActive: boolean }) {
        this._myLocationToggled.emit(data);
    }

    handleRangeChanged(ranges: RangeSelection[]) {
        this._selections.rangeFilters = this._selections.rangeFilters.map(x => {
            const range = ranges.find(r => r.name === x.name);
            x.minSelected = range.minSelected;
            x.maxSelected = range.maxSelected;
            return x;
        });
        this._selectionsChanged.emit(this._selections);
    }

    handleSelectionsChanged(selections: CheckedSelectionGroup) {
        switch (selections.filterType) {
            case FILTER_TYPE.MEDICAL_GROUP:
                this._selections.medicalGroups = selections.selections;
                break;
            case FILTER_TYPE.NETWORK:
                this._selections.networks = selections.selections;
                break;
        }
        this._selectionsChanged.emit(this._selections);
    }

    handleSpecialtyChanged(selection: Node[]) {
        this._selections.specialties = selection;
        this._selectionsChanged.emit(this._selections);
    }

    handleCmsAcoChanged(selection: Node[]) {
        this._selections.acos = selection;
        this._selectionsChanged.emit(this._selections);
    }

    reset() {
        this.resetLocationFilters();
        this.filterGroupService.resetFilters();
    }

    private resetLocationFilters() {
        const locationTypes = [
            FILTER_TYPE.ACO_BENEFICIARY_LOCATION,
            FILTER_TYPE.ACO_PROVIDER_LOCATION,
            FILTER_TYPE.BENEFICIARY_LOCATION,
            FILTER_TYPE.PROVIDER_LOCATION,
            FILTER_TYPE.PHYSICIAN_GROUP_BENEFICIARY_LOCATION
        ];
        locationTypes.forEach(type => this.handleMyLocationToggled({locationType: type, isActive: false}));
    }
}
