import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiSelectItem } from '../../shared/models/multi-select-item';

@Component({
	selector: 'app-multi-select-list-box',
	templateUrl: './multi-select-list-box.component.html',
	styleUrls: ['./multi-select-list-box.component.scss']
})
export class MultiSelectListBoxComponent {
	@Input() id = '';
	@Input() source: MultiSelectItem[] = [];
	@Input() destination: MultiSelectItem[] = [];
	@Input() sourceListTitle = ''; // title for source list
	@Input() destinationListTitle = ''; // title for destination list
	@Input() sourceSearchable = false;
	@Input() destinationSearchable = false;
	@Input() shift = false;
	@Input() ctrl = false;
	@Input() isRequired = false;
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	lastSourceClick = null;
	lastDestinationClick = null;

	selectedSourceItems: MultiSelectItem[] = [];
	selectedDestinationItems: MultiSelectItem[] = [];

	searchSource: string;
	searchDestination: string;

	get displaySelectedSourceItemDisplay() {
		return this.selectedSourceItems.length > 0 && this.lastSourceClick;
	}

	get selectedSourceItemDisplay() {
		if (!this.selectedSourceItemDescription) 
			return this.lastSourceClick.display;
		
		return `${this.lastSourceClick.display} (${this.selectedSourceItemDescription})`;
	}

	get selectedSourceItemDescription() {
		return this.lastSourceClick && this.lastSourceClick.description;
	}

	get displaySelectedDestinationItemDisplay() {
		return this.selectedDestinationItems.length > 0 && this.lastDestinationClick;
	}

	get selectedDestinationItemDisplay() {
		if (!this.selectedDestinationItemDescription) 
			return this.lastDestinationClick.display;
		

		return `${this.lastDestinationClick.display} (${this.selectedDestinationItemDescription})`;
	}

	get selectedDestinationItemDescription() {
		return this.lastDestinationClick && this.lastDestinationClick.description;
	}

	highlightSourceItem(item: MultiSelectItem) {
		if (this.lastSourceClick && this.shift) {
			this.selectedSourceItems = [];
			if (this.source.indexOf(this.lastSourceClick) < this.source.indexOf(item)) {
				for (let i = this.source.indexOf(this.lastSourceClick); i <= this.source.indexOf(item); i++) 
					this.selectedSourceItems.push(this.source[i]);
				
			}
			if (this.source.indexOf(this.lastSourceClick) > this.source.indexOf(item)) {
				for (let j = this.source.indexOf(item); j <= this.source.indexOf(this.lastSourceClick); j++) 
					this.selectedSourceItems.push(this.source[j]);
				
			}
		}
		else if (this.lastSourceClick && this.ctrl) {
			if (!this.selectedSourceItems.includes(item)) {
				this.selectedSourceItems.push(item);
				this.lastSourceClick = item;
			}
			else {
				const index = this.selectedSourceItems.indexOf(item, 0);
				if (index > -1) 
					this.selectedSourceItems.splice(index, 1);
				
				this.lastSourceClick = this.selectedSourceItems[this.selectedSourceItems.length - 1];
			}
		}
		else {
			this.selectedSourceItems = [];
			this.selectedSourceItems.push(item);
			this.lastSourceClick = item;
		}

	}

	highlightDestinationItem(item: MultiSelectItem) {
		if (this.lastDestinationClick && this.shift) {
			this.selectedDestinationItems = [];
			if (this.destination.indexOf(this.lastDestinationClick) < this.destination.indexOf(item)) {
				for (let i = this.destination.indexOf(this.lastDestinationClick); i <= this.destination.indexOf(item); i++) 
					this.selectedDestinationItems.push(this.destination[i]);
				
			}
			if (this.destination.indexOf(this.lastDestinationClick) > this.destination.indexOf(item)) {
				for (let j = this.destination.indexOf(item); j <= this.destination.indexOf(this.lastDestinationClick); j++) 
					this.selectedDestinationItems.push(this.destination[j]);
				
			}
		}
		else if (this.lastDestinationClick && this.ctrl) {
			if (!this.selectedDestinationItems.includes(item)) {
				this.selectedDestinationItems.push(item);
				this.lastDestinationClick = item;
			}
			else {
				const index = this.selectedDestinationItems.indexOf(item, 0);
				if (index > -1) 
					this.selectedDestinationItems.splice(index, 1);
				
				this.lastDestinationClick = this.selectedDestinationItems[this.selectedDestinationItems.length - 1];
			}
		}
		else {
			this.selectedDestinationItems = [];
			this.selectedDestinationItems.push(item);
			this.lastDestinationClick = item;
		}
	}

	moveAllToDestinationList() {
		if (!this.source.length) 
			return;
		

		this.destination = this.destination.concat(this.source);

		// due to virtual scrolling, need to re-set list object
		this.source = [];

		// sort the items
		this.destination.sort((a, b) => a.display.localeCompare(b.display));

		this.selectedSourceItems = [];

		this.onChange.emit();
	}

	moveToDestinationList() {
		if (!this.selectedSourceItems.length) 
			return;
		

		// add selected item to source, along with existing items already there
		this.destination = this.destination.concat(this.selectedSourceItems);

		// remove from source list
		this.source = this.source.filter(s => !this.selectedSourceItems.includes(s));

		// due to virtual scrolling, need to re-set list object
		this.source = [...this.source];

		// sort the items
		this.destination.sort((a, b) => a.display.localeCompare(b.display));

		this.selectedSourceItems = [];

		this.onChange.emit();
	}

	moveAllToSourceList() {
		if (!this.destination.length) 
			return;
		

		this.source = this.source.concat(this.destination);

		// due to virtual scrolling, need to re-set list object
		this.destination = [];

		// sort the items
		this.source.sort((a, b) => a.display.localeCompare(b.display));

		this.selectedSourceItems = [];

		this.onChange.emit();
	}

	moveToSourceList() {
		if (!this.selectedDestinationItems.length) 
			return;
		
		// add selected item to destination, along with existing items already there
		this.source = this.source.concat(this.selectedDestinationItems);

		// remove from destination list
		this.destination = this.destination.filter(s => !this.selectedDestinationItems.includes(s));

		// due to virtual scrolling, need to re-set list object
		this.destination = [...this.destination];

		// sort the items
		this.source.sort((a, b) => a.display.localeCompare(b.display));

		this.selectedDestinationItems = [];

		this.onChange.emit();
	}
}
