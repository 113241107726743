import { Pipe, PipeTransform } from '@angular/core';
import { PURPOSE } from '../../../enums/purpose.enum';
import { PURPOSE_NAME } from '../../../enums/purpose-name.enum';

@Pipe({name: 'purpose'})
export class PurposePipe implements PipeTransform {
    transform(type: PURPOSE): string {
        switch (type) {
            case PURPOSE.CompanyAccount:
                return PURPOSE_NAME.CompanyAccount;
            case PURPOSE.CustomerLive:
                return PURPOSE_NAME.CustomerLive;
            case PURPOSE.DemoAccount:
                return PURPOSE_NAME.DemoAccount;
            case PURPOSE.PartnerAccount:
                return PURPOSE_NAME.PartnerAccount;
            case PURPOSE.TrialAccount:
                return PURPOSE_NAME.TrialAccount;
            default:
                return null;
        }
    }
}
