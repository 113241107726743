<kendo-dialog (close)="close()" *ngIf="opened">
	<kendo-dialog-titlebar>
		<div *ngIf="title" class="d-flex flex-row align-items-center text-white">
			<div class="text-heading"> {{ title }}</div>
		</div>
	</kendo-dialog-titlebar>

	<div class="row g-0 p-4">
		<div *ngIf="message" class="col">
			<div [innerHTML]="message" class="text-body"></div>
		</div>
	</div>

	<div class="row g-0 pt-3">
		<div class="col"></div>
		<div class="col-auto">
			<button (click)="cancel()" *ngIf="cancelButtonLabel" class="btn btn-light me-3"
					type="button">{{ cancelButtonLabel }}
			</button>
			<button (click)="ok()" class="btn btn-success" type="button">{{ okButtonLabel }}</button>
		</div>
	</div>

</kendo-dialog>
