import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Product } from '@appcore/models/directory/product.model';

@Injectable({
	providedIn: 'root'
})
export class ProductApi {
	private _controllerName = 'Product';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getProducts(): Observable<Product[]> {
		return this.api.get(this._controllerName);
	}
}
