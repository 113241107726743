import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignAnywhereApi } from 'src/app/api/assign-anywhere.api';
import { AccountAssignmentDestination } from '@appcore/models/directory/account-assignment-destination.model';

@Injectable({
	providedIn: 'root'
})

export class AssignAnywhereService {
	constructor(private accountAssignmentApi: AssignAnywhereApi) {
	}

	getDestinations(): Observable<AccountAssignmentDestination[]> {
		return this.accountAssignmentApi.getDestinations();
	}
}
