import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterEvent } from '../models/filter-event.model';
import { Filter } from '../models/filter.model';

@Injectable({
    providedIn: 'root'
})
export class FilterSelectionService {
    public readonly selectedFilter: Observable<FilterEvent>;
    private filter: BehaviorSubject<FilterEvent> = new BehaviorSubject(null);
    
    constructor() {
        this.selectedFilter = this.filter.asObservable();
    }

    select(filter: Filter, key: string) {
        this.filter.next({filter, key});
    }
}
