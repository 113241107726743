import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { MarketApi } from 'src/app/api/market.api';
import { Market } from '@appcore/models/directory/market.model';

@Injectable({
	providedIn: 'root'
})

export class MarketService {
	private _markets: Market[] = [];

	constructor(private marketApi: MarketApi) {
	}

	public getAllMarkets(forceRefresh = false): Observable<Market[]> {
		if (this._markets.length && !forceRefresh)
			return of(this._markets);

		return this.marketApi.getMarkets().pipe(
			tap(markets => {
				this._markets = markets;
			}));
	}
}
