import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InfoCircleService {
    public deSelect$: Observable<boolean>;
    private deSelectChange$ = new Subject<boolean>();

    constructor() {
        this.deSelect$ = this.deSelectChange$.asObservable();
    }

    deSelect() {
        this.deSelectChange$.next(false);
    }
}
