import { Injectable } from '@angular/core';
import { IGridInfoRequestHandler } from '../interfaces/grid-info-request-handler.interface';
import { GridInfo } from '@appcore/models/grid-info.model';
import { GridExport } from '@appcore/models/grid/grid-export.model';

@Injectable({
	providedIn: 'root'
})
export class AdminGridExportService {
	gridInfoRequestHandlers: IGridInfoRequestHandler[] = [];

	register(handler: IGridInfoRequestHandler) {
		this.gridInfoRequestHandlers.push(handler);
	}

	unregister(handler: IGridInfoRequestHandler) {
		const existingHandler = this.gridInfoRequestHandlers.find(existing => existing === handler);
		if (existingHandler)
			this.gridInfoRequestHandlers = this.gridInfoRequestHandlers.filter(existing => existing !== existingHandler);

		else
			console.warn('could not find handler to unregister');

	}

	getExportData(gridInfo: GridInfo, dataItem?: any): Promise<GridExport> {
		const requestHandler = this.gridInfoRequestHandlers.find(handler => handler.canHandle(gridInfo));
		return requestHandler?.handleGridInfoRequest(gridInfo, dataItem) ?? new Promise<GridExport>((resolve, reject) => {
			resolve(GridExport.withNoData(gridInfo));
		});
	}
}
