<div (clickOutside)="hideComponent()" appClickOutside class="dropdown w-100" tabindex="-1">
    <div (click)="onClick()"
         class="list-box-holder"> {{ value?.display || 'Select' }} <span><i class="fas fa-chevron-down"></i></span>
    </div>
    <div *ngIf="showSelections" class="list-box-content">
        <div [class.disabled]="disabled" class="row g-0">
            <div class="col d-flex flex-column">
                <input [(ngModel)]="userSearchString" [disabled]="disabled" class="form-control w-100 search-input"
                       placeholder="Search" type="text"/>
                <div class="border rounded">
                    <cdk-virtual-scroll-viewport [style.height]="height" itemSize="26">
                        <div class="ps-2">
                            <mat-radio-group (change)="onSelectionChange($event.value)" [(ngModel)]="value" aria-label="Select an option"
                                             class="radio-group" tabindex="-1">
                                <div *cdkVirtualFor="let option of options | search: 'display':userSearchString; templateCacheSize: 0">
                                    <mat-radio-button [value]="option" class="radio-button">{{ option.display }}
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
        </div>
    </div>
</div>



