import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginResponse } from '../shared/models/authentication';
import { ApiService } from './api.service';
import { ICognitoClient } from '../shared/interfaces/cognito-client.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthApi {

	private _controllerName = 'Authentication';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	loginCognito(jwtToken: string): Observable<LoginResponse> {
		return this.api.post(this._controllerName, 'LoginCognito', {
			jwtToken
		});
	}

	login(username, password): Observable<LoginResponse> {
		return this.api.post(this._controllerName, 'Login', {
			username,
			password
		});
	}

	logout(): Observable<any> {
		return this.api.post(this._controllerName, 'Logout');
	}

	getCognitoClient(source: string): Observable<ICognitoClient> {
		return this.api.get(this._controllerName, 'CognitoClient', {
			source
		});
	}

	identifyClient(email): Observable<ICognitoClient> {
		return this.api.post(this._controllerName, 'IdentifyClient', {
			email
		});
	}
}
