import { Injectable } from '@angular/core';
import { Utils } from '../helpers/Utils';

@Injectable({
    providedIn: 'root'
})
export class ReplaceValueMappingService {
    private valueMap = new Map<string, string>();

    setMap(map: { [key: string]: string }) {
        if (!map) 
            return;
        
        this.valueMap.clear();
        for (const key of Object.keys(map)) {
            const upper = key.toUpperCase();
            this.valueMap.set(upper, map[key]);
        }
    }

    runReplacement(text: string): string {
        if (!text)
            return text;

        let tagStart = text.indexOf('[[');
        let tagEnd = text.indexOf(']]');
        let returnText = text;

        while (tagStart !== -1) {
            if (tagStart < 0 || tagEnd < 0) 
                return returnText;
            

            if (tagStart > tagEnd) 
                return returnText;
            

            const replacementString = returnText.substring(tagStart + 2, tagEnd);
            returnText = returnText.replace(`[[${replacementString}]]`, this.getReplacement(replacementString));

            tagStart = returnText.indexOf('[[');
            tagEnd = returnText.indexOf(']]');
        }

        return returnText;
    }

    updateReplacement(key: string, value: string) {
        this.valueMap.set(key, value);
    }

    private getValue(key: string) {
        const upperKey = key.toUpperCase();
        if (this.valueMap.has(upperKey))
            return this.valueMap.get(upperKey);

        return key;
    }

    /**
     * Returns the replaced string value based on the replacement map or the standard QmX syntax
     *
     * @param key the key corresponding to the map that specifies replacement values
     */
    private getReplacement(key: string): string {
        const replacement = this.getValue(key);

        if (replacement !== key)
            return replacement;


        return Utils.getParsedGridColumnHeader(key);
    }
}
