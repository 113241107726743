<app-alert-dialog></app-alert-dialog>
<div class="mw-100 div-overlay-wrapper">
	<trella-spinner [isFullDiv]="true"></trella-spinner>
	<div *ngIf="gridInfo" closable="true" kendoTooltip position="top" showOn="none">
		<kendo-grid #gridComponent (columnVisibilityChange)="columnChange($event)"
					(dataStateChange)="dataStateChange($event)" (selectionChange)="selectionChange($event)"
					[data]="gridInfo.data"
					[filter]="gridInfo.queryOptions && gridInfo.queryOptions.filter"
					[filterable]="gridAbilities.filterable"
					[kendoGridSelectBy]="gridInfo.npiField"
					[pageSize]="gridInfo.queryOptions && gridInfo.queryOptions.take"
					[pageable]="gridAbilities.pageable"
					[rowHeight]="rowHeight"
					[scrollable]="gridAbilities.scrollable"
					[selectable]="gridAbilities.selectable" [selectedKeys]="gridInfo.selectedRows"
					[skip]="gridInfo.queryOptions && gridInfo.queryOptions.skip"
					[sort]="gridInfo.queryOptions && gridInfo.queryOptions.sort"
					[sortable]="gridAbilities.sortable"
					class="mw-100">

			<kendo-excelexport #excelExport fileName="{{excelFileName()}}">
				<ng-container *ngFor="let column of gridInfo.columns">
					<kendo-excelexport-column-group *ngIf="isPreheader(column)"
													[headerCellOptions]="{ textAlign: 'center' }"
													title="{{displayExportColumnTitle(column.title)}}"
													width="{{column.width}}">
						<ng-container *ngFor="let subColumn of getSubColumns(column)">
							<kendo-excelexport-column [hidden]="column.hidden" field="{{subColumn.field}}"
													  title="{{displayExportColumnTitle(subColumn.title)}}"
													  width="{{subColumn.width}}"></kendo-excelexport-column>
						</ng-container>
					</kendo-excelexport-column-group>
					<kendo-excelexport-column [hidden]="column.hidden" field="{{column.field}}"
											  title="{{displayExportColumnTitle(column.title)}}"
											  width="{{column.width}}"></kendo-excelexport-column>
				</ng-container>
			</kendo-excelexport>

			<ng-template kendoGridToolbarTemplate>
				<div class="d-flex flex-row align-items-center">
					<kendo-grid-column-chooser *ngIf="showColumnChooser"
											   class="me-3 exploreChooserButton"></kendo-grid-column-chooser>

					<div class="input-group input-group-width-auto">
						<input (keyup)="triggerFilter()" [(ngModel)]="search" class="ms-1 me-1 k-textbox searchFilter"
							   name="search" placeholder="Search"
							   type="text">
						<button (click)="clearFilter()" *ngIf="search" class="btn bg-transparent btn-clear-input"
								id="searchWrapper">
							<i class="fa fa-times"></i>
						</button>
						<button (click)="saveExcelExport(excelExport, gridInfo)" kendoGridExcelCommand type="button">
							<i class="fal fa-file-excel"></i> Excel
						</button>
					</div>

					<div class="flex1"></div>
					<button (click)="importData()" *ngIf="enableImport"
							class="btn btn-sm btn-success me-3">{{ importActionText }}
					</button>
					<button (click)="importAssignments()" *ngIf="enableImport && secondImport"
							class="btn btn-sm btn-success me-3">{{ secondImportActionText }}
					</button>
					<button (click)="addNewItem()" *ngIf="enableAdding"
							class="btn btn-sm btn-success me-3">{{ addActionText }}
					</button>
				</div>
			</ng-template>

			<kendo-grid-checkbox-column *ngIf="enableCheckbox" class="bg-white" showSelectAll="true"
										width="10"></kendo-grid-checkbox-column>

			<ng-template *ngIf="reportHasDetailRows" kendoGridDetailTemplate let-dataItem>
				<app-report-details [data]="dataItem" [reportName]="gridInfo.reportName"></app-report-details>
			</ng-template>

			<kendo-grid-column *ngIf="editBtn.show()" [filterable]="false" [headerClass]="{'borderHeader': true}"
							   [sortable]="false"
							   class="bg-white" width="30">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span (click)="editBtn.execute(rowIndex, dataItem, $event)"
						  class="app-grid-detail fas fa-pencil-alt pointer text-center h-100 w-100"></span>
				</ng-template>
			</kendo-grid-column>

			<kendo-grid-column *ngIf="copyBtn.show()" [filterable]="false" [headerClass]="{'borderHeader': true}"
							   [sortable]="false"
							   class="bg-white" width="30">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span (click)="copyBtn.execute(rowIndex, dataItem, $event)"
						  class="app-grid-detail fas fa-copy pointer text-center h-100 w-100"></span>
				</ng-template>
			</kendo-grid-column>

			<kendo-grid-column *ngIf="viewBtn.show()" [filterable]="false" [sortable]="false" class="bg-white"
							   width="50">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span (click)="viewBtn.execute(rowIndex, dataItem, $event)"
						  class="app-grid-view fas fa-eye pointer text-center h-100 w-100"></span>
				</ng-template>
			</kendo-grid-column>

			<ng-container *ngFor="let column of gridInfo.columns">
				<kendo-grid-column [class]="{'columnStyles': columnNeedsHighlist(column)}" [field]="column.field"
								   [filter]="column.filterType"
								   [filterable]="column.filterable" [format]="column.format"
								   [headerClass]="{'borderHeader': true}" [hidden]="column.hidden || column.exportOnly"
								   [includeInChooser]="column.includeInColumnChooser"
								   [sortable]="column.sortable"
								   [title]="column.title"
								   [width]="column.width"
								   class="bg-white">

					<ng-template kendoGridHeaderTemplate let-column="column" let-columnIndex="columnIndex">
						<div class="row">
							<div class="col">
								<span class="columnHeaderText text-label-table text-white">{{ column.title }}</span>
								<span (click)="toggleDef(column, $event)" *ngIf="getColDefinition(column)"
									  class="fas fa-info-circle pointer text-white report-grid-def-handler ms-2"
									  title="{{column.title}}"></span>
							</div>
						</div>
					</ng-template>

					<ng-template *ngIf="gridInfo.footer" kendoGridFooterTemplate>
						<span
							class="text-data-xs text-white">{{ getDisplayedValue(gridInfo.footer[column.field], column) }}</span>
					</ng-template>

					<ng-template *ngIf="isDefaultCol(column)" kendoGridCellTemplate let-dataItem
								 let-field="column.field" let-rowIndex="rowIndex">
						<span [class.wordBreak]="column.wrapText"
							  class="text-data-xs word-break">{{ getDisplayedValue(dataItem[column.field], column) }}</span>
					</ng-template>

					<ng-template *ngIf="isClickableCol(column)" kendoGridCellTemplate let-dataItem
								 let-field="column.field" let-rowIndex="rowIndex">
						<span (click)="viewBtn.execute(rowIndex, dataItem, $event)"
							  class="text-data-xs hover-underline pointer text-primary word-break">{{ dataItem[field] }}</span>
					</ng-template>

				</kendo-grid-column>
				<kendo-grid-column-group *ngIf="column.columnType"
										 [headerClass]="{'borderHeader': true, 'wide-column-header': column.isWideColumn, 'small-column-header': column.isSmallColumn}"
										 [title]="column.title"
										 [width]="column.width">
					<ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
						<div class="row">
							<div class="col text-center">
								<span class="preHeader">{{ column.title }}</span>
								<span (click)="toggleDef(column, $event)" *ngIf="getColDefinition(column)"
									  class="fas fa-info-circle pointer text-white report-grid-def-handler ms-2 no-print"
									  title="{{column.title}}"></span>
							</div>
						</div>
					</ng-template>

					<kendo-grid-column *ngFor="let col of column.columns"
									   [class]="{'columnStyles': columnNeedsHighlist(col), 'wide-column': col.isWideColumn, 'small-column': col.isSmallColumn}"
									   [field]="col.field"
									   [filter]="col.filterType" [filterable]="col.filterable"
									   [footerClass]="{'footerClass': true}"
									   [format]="col.format"
									   [headerClass]="{'borderHeader':true, 'wide-column-header': col.isWideColumn, 'small-column-header': col.isSmallColumn}"
									   [hidden]="col.hidden"
									   [includeInChooser]="col.includeInColumnChooser"
									   [locked]="col.locked"
									   [sortable]="col.sortable"
									   [title]="col.title"
									   [width]="col.width">
						<ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
							<span class="row d-inline-block header-column">
								<div class="col">
									<span class="text-label-table text-white">{{ getColHeaderTitle(col) }}</span>
									<span (click)="toggleDef(col, $event)" *ngIf="getColDefinition(col)"
										  class="fas fa-info-circle pointer text-white report-grid-def-handler ms-2 no-print"
										  title="{{col.title}}"></span>
								</div>
							</span>
						</ng-template>

						<ng-template *ngIf="isDefaultCol(col)" kendoGridCellTemplate let-dataItem let-field="col.field"
									 let-rowIndex="rowIndex">
							<span
								class="text-data-xs word-break">{{ getDisplayedValue(dataItem[col.field], col) }}</span>
						</ng-template>
					</kendo-grid-column>
				</kendo-grid-column-group>
			</ng-container>

			<kendo-grid-column *ngIf="deleteBtn.show()" [filterable]="false" [sortable]="false" class="bg-white"
							   width="10">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<i (click)="deleteBtn.execute(rowIndex, dataItem, $event)"
					   aria-hidden="true"
					   class="app-grid-delete fas fa-times text-danger pointer text-center h-100 w-100"></i>
				</ng-template>
			</kendo-grid-column>
		</kendo-grid>
	</div>
</div>

<element-notes *ngIf="hasPostNotes" [gridInfo]="gridInfo"></element-notes>

<app-modal-progress-bar progressType="determinate"></app-modal-progress-bar>

