<div
        [titleTemplate]="titleTemplate"
        [tooltipTemplate]="tooltipTemplate"
        closable="true"
        kendoTooltip
        position="{{ position }}"
        position="top"
        showOn="none"
        style="display: inline-block;"
>
	<span (click)="select($event)" class="pointer no-print">
    <i *ngIf="!selected && !darkBackground" class="far fa-info-circle unselected-light"></i>
    <i *ngIf="selected && !darkBackground" class="fas fa-info-circle selected-light"></i>
		<i *ngIf="!selected && darkBackground" class="fas fa-info-circle unselected-dark-fad"></i>
		<i *ngIf="selected && darkBackground" class="fas fa-info-circle selected-dark-fad"></i>
	</span>
</div>
<ng-template #definitionTemplate let-anchor>
    <div [innerHTML]="tooltipText"></div>
</ng-template>

