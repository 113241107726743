import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ScheduledReport } from '../shared/models/scheduled-report';

@Injectable({
	providedIn: 'root'
})

export class ScheduledReportsApi {

	private _controllerName = 'ScheduledReports';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	get(scheduledReportId: number): Observable<ScheduledReport> {
		return this.api.get(this._controllerName, `${scheduledReportId}`);
	}

	update(scheduledReport: ScheduledReport) {
		return this.api.put(this._controllerName, null, scheduledReport);
	}

	create(scheduledReports: ScheduledReport[]) {
		return this.api.post(this._controllerName, null, scheduledReports);
	}
}
