import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { apiPrimitiveToSelectList } from './api.utils';
import {Selection } from '@appcore/interfaces/selection.interface';

@Injectable({
	providedIn: 'root'
})
export class ListApi {

	private _controllerName = 'admin/List';

	constructor(private api: ApiService,
				protected http: HttpClient) {
	}


	getSpecialties(): Observable<Array<Selection>> {
		return this.api.get(this._controllerName, 'Specialties').pipe(apiPrimitiveToSelectList());
	}

	getAllManagers(customerIds: string[]): Observable<Array<Selection>> {
		return this.api.post(this._controllerName, 'AllManagers', customerIds);
	}


}
