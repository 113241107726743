export enum NpiType {
    homeHealthAgency = 'HHA',
    hospice = 'HOS',
    hospital = 'HOSP',
    otherFacility = 'OTHF',
    otherPhysician = 'OTHP',
    otherPhysicianMosaic = 'OTHP-MOS',
    physician = 'PHYS-APP',
    physicianGroup = 'PHYS-GRP',
    skilledNursingFacility = 'SNF',
    supplier = 'SUPP',
    pharmacy = 'PHARM'
}
