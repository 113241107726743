<div class="mw-100 div-overlay-wrapper">
    <div [ngClass]="{'div-overlay-disable' : disabled}"></div>
    <div *ngIf="gridInfo" id="{{ gridInfo.reportName }}_table">
        <kendo-grid #grid
                    (columnVisibilityChange)="columnChange($event)"
                    (dataStateChange)="dataStateChange($event)"
                    (selectionChange)="selectionChange($event)"
                    (sortChange)="sortChange($event)"
                    [data]="dataResponse"
                    [filter]="queryOptions.filter"
                    [hideHeader]="false"
                    [kendoGridSelectBy]="selectKeyfield"
                    [pageSize]="queryOptions.take"
                    [pageable]="gridInfo.hideCountAndPageList || gridInfo.pageable"
                    [rowClass]="rowCallback"
                    [rowHeight]="rowHeight"
                    [scrollable]="gridAbilities.scrollable"
                    [selectable]="gridAbilities.selectable"
                    [selectedKeys]="selectedRows"
                    [skip]="queryOptions.skip"
                    [sort]="queryOptions.sort"
                    [sortable]="gridAbilities.sortable"
                    class="mw-100"
        >
            <kendo-grid-excel [fetchData]="displayedData" fileName="{{getExcelFileName()}}"></kendo-grid-excel>

            <ng-template *ngIf="!parentKey || gridInfo.showSubGridToolBar" kendoGridToolbarTemplate>
                <div class="grid-base-toolbar-content w-100 d-flex flex-row align-items-center justify-content-center pb-2">
                    <kendo-grid-column-chooser *ngIf="gridInfo.showColumnSelector"
                                               class="me-3"></kendo-grid-column-chooser>
                    <ng-container *ngIf="showMasterSearch">
                        <div class="input-group input-group-width-auto">
                            <input
                                    (keyup)="triggerTextFilter()"
                                    [(ngModel)]="searchText"
                                    [placeholder]="searchPlaceholderText"
                                    class="ms-1 me-1 k-textbox searchTextFilter"
                                    name="searchText"
                                    type="text"
                            />
                            <button
                                    (click)="clearTextFilter()"
                                    *ngIf="searchText"
                                    class="btn bg-transparent btn-clear-input"
                                    id="searchWrapper"
                            >
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                    </ng-container>
                    <button (click)="clearSort()" *ngIf="showClearSortButton" class="btn btn-sm btn-secondary me-3">
                        Clear Sort
                    </button>
                    <div
                            *ngIf="showFavoritesButton"
                            [tooltipTemplate]="textHoverTemplate"
                            class="top"
                            filter=".exploreFavoriteButton"
                            kendoTooltip
                            position="top"
                            showOn="hover"
                            tooltipClass="exploreHoverTooltipClass"
                    >
                        <button
                                (click)="openCreateFavoriteListModal()"
                                [attr.tooltTipText]="favoriteHoverText"
                                class="btn btn-sm btn-secondary me-3 exploreFavoriteButton"
                                id="addFavoriteButton"
                        >
                            <i aria-hidden="true" class="fa fa-heart"></i>
                        </button>
                    </div>
                    <div
                            *ngIf="showTargetsButton"
                            [tooltipTemplate]="textHoverTemplate"
                            class="top"
                            filter=".exploreTargetButton"
                            kendoTooltip
                            position="top"
                            showOn="hover"
                            tooltipClass="exploreHoverTooltipClass"
                    >
                        <button
                                (click)="openCreateTargetListModal()"
                                [attr.tooltTipText]="targetHoverText"
                                class="btn btn-sm btn-secondary me-3 exploreTargetButton"
                                id="addTargetButton"
                        >
                            <i aria-hidden="true" class="fa fa-bullseye"></i>
                        </button>
                    </div>
                    <div
                            *ngIf="showAssigneesButton"
                            [tooltipTemplate]="textHoverTemplate"
                            class="top"
                            filter=".exploreAssigneeButton"
                            kendoTooltip
                            position="top"
                            showOn="hover"
                            tooltipClass="exploreHoverTooltipClass"
                    >
                        <button
                                (click)="openCreateAssigneeListModal()"
                                [attr.tooltTipText]="assigneeHoverText"
                                class="btn btn-sm btn-secondary me-3 exploreAssigneeButton"
                                id="addAssigneeButton"
                        >
                            <i aria-hidden="true" class="fa fa-user-plus"></i>
                        </button>
                    </div>
                    <div
                            *ngIf="showUnAssigneeButton"
                            [tooltipTemplate]="textHoverTemplate"
                            class="top"
                            filter=".exploreRemoveAssigneeButton"
                            kendoTooltip
                            position="top"
                            showOn="hover"
                            tooltipClass="exploreHoverTooltipClass"
                    >
                        <button
                                (click)="openRemoveAssigneeModal()"
                                [attr.tooltTipText]="removeAssigneeHoverText"
                                class="btn btn-sm btn-secondary me-3 exploreRemoveAssigneeButton"
                                icon="fa-bullseye"
                                id="removeAssignedUserButton"
                        >
                            <i aria-hidden="true" class="fas fa-user-minus"></i>
                        </button>
                    </div>
                    <div
                            *ngIf="showComparisonsButton"
                            [tooltipTemplate]="textHoverTemplate"
                            class="top"
                            filter=".exploreComparisonButton"
                            kendoTooltip
                            position="top"
                            showOn="hover"
                            tooltipClass="exploreHoverTooltipClass"
                    >
                        <button
                                (click)="openAddToNpiGroupModal()"
                                [attr.tooltTipText]="comparisonHoverText"
                                class="btn btn-sm btn-secondary me-3 exploreComparisonButton"
                                id="addComparisonButton"
                        >
                            <i aria-hidden="true" class="fas fa-repeat"></i>
                        </button>
                    </div>
                    <div class="flex1"></div>
                    <i
                            (click)="addReport()"
                            *ngIf="allowAdd"
                            class="far fa-clipboard me-3 text-primary pointer"
                            id="{{ gridInfo.reportName }}_addToCartButton"
                    ></i>
                    <button (click)="openE()" *ngIf="allowEButton" class="btn btn-sm btn-success me-3">
                        E
                    </button>
                    <button *ngIf="gridInfo.showExportButtons" kendoGridExcelCommand type="button">
                        <i class="fas fa-file-excel"></i> Excel
                    </button>
                </div>
                <div *ngIf="hasShowSelectedToggle || gridInfo.showNetworksButton"
                     class="d-flex flex-row align-items-center pt-2">
                    <ng-container *ngIf="hasShowSelectedToggle">
                        <mat-slide-toggle (change)="toggleShowSelected($event)" [(ngModel)]="showSelected"
                        >Show Selected {{ numberSelected }}
                        </mat-slide-toggle>
                        <button (click)="clearSelected()" class="btn btn-danger btn-sm ms-2">Clear Selected</button>
                    </ng-container>
                    <div
                            *ngIf="gridInfo.showNetworksButton"
                            [tooltipTemplate]="textHoverTemplate"
                            class="top"
                            filter="button"
                            kendoTooltip
                            position="top"
                            showOn="hover"
                            tooltipClass="exploreHoverTooltipClass"
                    >
                        <button
                                (click)="openNetworkModal()"
                                [attr.tooltTipText]="'Click here to add to Network'"
                                [disabled]="!anySelected"
                                class="btn btn-secondary btn-sm ms-2 exploreAddToNetworkButton"
                                id="addToNetworkButton"
                        >
                            Add to Network
                        </button>
                    </div>
                </div>
            </ng-template>

            <kendo-grid-checkbox-column
                    *ngIf="enableCheckbox"
                    [headerStyle]="{ 'vertical-align': 'bottom' }"
                    [locked]="gridInfo.lockCheckboxColumn && canLock"
                    [showSelectAll]="true"
                    width="20"
            >
            </kendo-grid-checkbox-column>

            <kendo-grid-column *ngIf="false" [filterable]="false" [sortable]="false" width="15">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <i class="ellipsis text-center text-secondary h-100 w-100"
                       text="Drag and Drop to reorder rows!"></i>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column *ngIf="editBtn.show()" [filterable]="false" [sortable]="false" width="15">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span
                            (click)="editBtn.execute(rowIndex, dataItem, $event)"
                            class="app-grid-detail fas fa-pencil-alt pointer text-center text-secondary h-100 w-100"
                    ></span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column *ngIf="copyBtn.show()" [filterable]="false" [sortable]="false" width="15">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span
                            (click)="copyBtn.execute(rowIndex, dataItem, $event)"
                            class="app-grid-detail fas fa-copy pointer text-center text-secondary h-100 w-100"
                    ></span>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column *ngIf="viewBtn.show()" [filterable]="false" [sortable]="false" width="50">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					<span
                            (click)="viewBtn.execute(rowIndex, dataItem, $event)"
                            class="app-grid-view fas fa-eye pointer text-center h-100 w-100"
                    ></span>
                </ng-template>
            </kendo-grid-column>

            <ng-template *ngIf="hasDetailRows" hover kendoGridDetailTemplate let-dataItem>
            </ng-template>

            <ng-container *ngFor="let column of gridInfo.columns">
                <kendo-grid-column
                        *ngIf="!column.columns?.length"
                        [class]="{ 'columnStyles': isHighlighted(column) }"
                        [field]="column.field"
                        [format]="column.format"
                        [headerClass]="{ 'borderHeader': columnNeedsBorder(column) }"
                        [headerStyle]="{ 'vertical-align': 'bottom' }"
                        [hidden]="column.hidden"
                        [includeInChooser]="column.includeInColumnChooser"
                        [locked]="column.locked && canLock"
                        [sortable]="column.sortable"
                        [title]="column.title"
                        [width]="column.width"
                >
                    <ng-template kendoGridHeaderTemplate>
                        <div class="row d-inline-block headerColumn">
                            <div class="col pe-0">
                                <span class="columnHeaderText text-label-table text-white">{{ column.title }}</span>
                                <trella-info-circle
                                        (click)="this.allowGridStateChange = false"
                                        *ngIf="getColDefinition(column)"
                                        [darkBackground]="true"
                                        [tooltipText]="this.createCustomDefinition(column)"
                                        class="text-white report-grid-def-handler ms-2"
                                ></trella-info-circle>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template *ngIf="footer" kendoGridFooterTemplate>
                        <span class="text-label-table text-white">{{ getDisplayedValue(footer[column.field], column) }}</span>
                    </ng-template>

                    <ng-template
                            *ngIf="isDefaultCol(column)"
                            kendoGridCellTemplate
                            let-dataItem
                            let-field="column.field"
                            let-rowIndex="rowIndex"
                    >
						<span *ngIf="column.enumType || column.metadata && column.metadata.enumType" class="mx-1">
							<i
                                    [grid-cell-icon]="{
									enumType: column.enumType || column.metadata && column.metadata.enumType,
									data: dataItem[column.enumType ? column.field : column.metadata.field].value
								}"
                            >
							</i>
						</span>

                        <span *ngIf="!column.maxCharacters" class="text-data-xs"
                              style="white-space: normal; height: auto;">

							<span *ngIf="!column.hasHtml">
								{{ getDisplayedValue(dataItem[column.field], column) }}
							</span>
							<div *ngIf="column.hasHtml"
                                 [innerHTML]="getDisplayedValue(dataItem[column.field], column)"></div>
							</span
                            >

                        <span
                                *ngIf="column.maxCharacters"
                                [attr.title]="
								doesTextNeedTruncation(dataItem[column.field], column)
									? getDisplayedValue(dataItem[column.field], column)
									: ''
							"
                                [class.pointer]="doesTextNeedTruncation(dataItem[column.field], column)"
                                class="text-data-xs"
                                kendoTooltip
                                showOn="click"
                                style="white-space: normal; height: auto;"
                        >
							{{ getDisplayedValue(dataItem[column.field], column) | textTruncate: [column.maxCharacters] }}</span
                        >
                    </ng-template>

                    <ng-template
                            *ngIf="isClickableCol(column)"
                            kendoGridCellTemplate
                            let-dataItem
                            let-field="column.field"
                            let-rowIndex="rowIndex"
                    >
						<span
                                (click)="viewBtn.execute(rowIndex, dataItem, $event)"
                                *ngIf="!rowLinkFactory"
                                class="text-data-xs hover-underline pointer text-primary word-break"
                        >{{ getDisplayedValue(dataItem[column.field], column) }}</span
                        >
                        <span
                                *ngIf="rowLinkFactory && !npiFieldNotEmpty(dataItem)"
                                class="text-data-xs text-primary word-break"
                        >{{ getDisplayedValue(dataItem[column.field], column) }}</span
                        >
                        <a *ngIf="rowLinkFactory && gridInfo && gridInfo.npiField && (dataItem[gridInfo.npiField] || dataItem[gridInfo.keyField]) && npiFieldNotEmpty(dataItem)"
                           [href]="buildLink(dataItem)"
                           class="text-data-xs text-primary word-break">
                            {{ getDisplayedValue(dataItem[column.field], column) }}</a>

                    </ng-template>

                    <ng-template
                            *ngIf="isTargetColumn(column)"
                            kendoGridCellTemplate
                            let-dataItem
                            let-field="column.field"
                            let-rowIndex="rowIndex"
                    >
						<span class="hidden-print pointer k-grid-ignore-click">
							<i
                                    (click)="toggleTarget(dataItem)"
                                    [class.target-active]="isTarget(dataItem)"
                                    aria-hidden="true"
                                    class="target-icon-default"
                            ></i>
						</span>
                    </ng-template>

                    <ng-template
                            *ngIf="isFavoriteColumn(column)"
                            kendoGridCellTemplate
                            let-dataItem
                            let-field="column.field"
                            let-rowIndex="rowIndex"
                    >
						<span class="hidden-print pointer k-grid-ignore-click">
							<i
                                    (click)="toggleFavorite(dataItem)"
                                    [class.favorite-active]="isFavorite(dataItem)"
                                    aria-hidden="true"
                                    class="favorite-icon-default"
                            ></i>
						</span>
                    </ng-template>

                    <ng-template
                            *ngIf="isComparisonCol(column)"
                            kendoGridCellTemplate
                            let-dataItem
                            let-field="column.field"
                            let-rowIndex="rowIndex"
                    >
                        <div class="compareChartContainer d-flex h-100">
                            <div
                                    [ngStyle]="{ width: getPercentValueFromDecimal(dataItem[column.field]) + '%' }"
                                    class="bar {{ getProgressBarClass(dataItem, column) }}"
                            ></div>
                            <span class="text-data-xs">{{ getDisplayedValue(dataItem[column.field], column) }}</span>
                        </div>
                    </ng-template>

                    <ng-template *ngIf="isSparklineCol(column)" kendoGridCellTemplate let-dataItem
                                 let-rowIndex="rowIndex">
                        <trella-sparkline-area
                                [rawData]="getRawSparklineArray(dataItem, column)"
                                [sparklineData]="getSparklineArray(dataItem, column)"
                                [width]="column.width"
                        >
                        </trella-sparkline-area>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column-group
                        *ngIf="column.columns?.length"
                        [headerClass]="{ 'borderHeader': columnNeedsBorder(column) }"
                        [headerStyle]="{ 'border-start': '1px solid rgba(0, 0, 0, 0.08)' }"
                        [width]="column.width"
                >
                    <ng-template kendoGridHeaderTemplate>
                        <div class="row d-inline-block headerColumn">
                            <div class="col pe-0">
                                <div class="preHeader">{{ column.title }}</div>
                            </div>
                        </div>
                    </ng-template>
                    <kendo-grid-column
                            *ngFor="let col of column.columns"
                            [class]="{ 'columnStyles': isHighlighted(column) }"
                            [field]="col.field"
                            [format]="col.format"
                            [headerClass]="{ 'borderHeader': columnNeedsBorder(column) }"
                            [hidden]="col.hidden"
                            [includeInChooser]="column.includeInColumnChooser"
                            [sortable]="col.sortable"
                            [title]="col.title"
                            [width]="col.width"
                    >
                        <ng-template kendoGridHeaderTemplate>
                            <div class="row d-inline-block headerColumn">
                                <div class="col pe-0">
                                    <span class="columnHeaderText text-label-table text-white">{{ col.title }}</span>
                                    <trella-info-circle
                                            (click)="this.allowGridStateChange = false"
                                            *ngIf="getColDefinition(col)"
                                            [darkBackground]="true"
                                            [tooltipText]="this.createCustomDefinition(col)"
                                            class="text-white report-grid-def-handler ms-2"
                                    ></trella-info-circle>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template *ngIf="isClickableCol(col)" kendoGridCellTemplate let-dataItem
                                     let-rowIndex="rowIndex">
							<span
                                    (click)="viewBtn.execute(rowIndex, dataItem, $event)"
                                    class="text-label-table hover-underline pointer text-primary word-break"
                            >{{ getDisplayedValue(dataItem[col.field], col) }}</span
                            >
                        </ng-template>

                        <ng-template
                                *ngIf="isTargetColumn(col)"
                                kendoGridCellTemplate
                                let-dataItem
                                let-field="col.field"
                                let-rowIndex="rowIndex"
                        >
							<span class="hidden-print pointer k-grid-ignore-click">
								<i
                                        (click)="toggleTarget(dataItem)"
                                        [class.target-active]="isTarget(dataItem)"
                                        aria-hidden="true"
                                        class="target-icon-default"
                                ></i>
							</span>
                        </ng-template>

                        <ng-template
                                *ngIf="isFavoriteColumn(col)"
                                kendoGridCellTemplate
                                let-dataItem
                                let-field="col.field"
                                let-rowIndex="rowIndex"
                        >
							<span class="hidden-print pointer k-grid-ignore-click">
								<i
                                        (click)="toggleFavorite(dataItem)"
                                        [class.favorite-active]="isFavorite(dataItem)"
                                        aria-hidden="true"
                                        class="favorite-icon-default"
                                ></i>
							</span>
                        </ng-template>

                        <ng-template *ngIf="footer" kendoGridFooterTemplate>
                            <span class="text-label-table text-white">{{ getDisplayedValue(footer[col.field], col) }}</span>
                        </ng-template>

                        <ng-template
                                *ngIf="isDefaultCol(col)"
                                kendoGridCellTemplate
                                let-dataItem
                                let-field="col.field"
                                let-rowIndex="rowIndex"
                        >
							<span *ngIf="col.enumType || col.metadata && col.metadata.enumType" class="mx-1">
								<i [grid-cell-icon]="getEnumTypeData(col, dataItem)">
								</i>
								<span *ngIf="isComparisonEnumType(col)"
                                      [smart-comparison]="getEnumTypeData(col, dataItem)"
                                      class="mx-1">
									{{ getComparisonEnumTypeDisplay(dataItem[col.enumType ? col.field : col.metadata.field].formattedValue) }}
								</span>
							</span>
                            <span *ngIf="!col.maxCharacters" class="text-data-xs"
                                  style="white-space: normal; height: auto;">
								{{ getDisplayedValue(dataItem[col.field], col) }}</span
                            >
                            <span
                                    *ngIf="col.maxCharacters"
                                    [attr.title]="
									doesTextNeedTruncation(dataItem[col.field], col) ? getDisplayedValue(dataItem[col.field], col) : ''
								"
                                    [class.pointer]="doesTextNeedTruncation(dataItem[col.field], col)"
                                    class="text-data-xs"
                                    kendoTooltip
                                    showOn="click"
                                    style="white-space: normal; height: auto;"
                            >
								{{ getDisplayedValue(dataItem[col.field], col) | textTruncate: [col.maxCharacters] }}</span
                            >
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid-column-group>
            </ng-container>
            <kendo-grid-column *ngIf="deleteBtn.show()" [filterable]="false" [sortable]="false" width="20">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <i
                            (click)="deleteBtn.execute(rowIndex, dataItem, $event)"
                            aria-hidden="true"
                            class="app-grid-delete fas fa-times text-danger pointer text-center h-100 w-100"
                    ></i>
                </ng-template>
            </kendo-grid-column>

            <ng-template *ngIf="gridInfo.hideCountAndPageList && !parentKey" kendoPagerTemplate
                         let-currentPage="currentPage" let-totalPages="totalPages">
                <i
                        (click)="goBack()"
                        [mosaic-navigation-button]="{
						direction: NavigationDirection.Back,
						isEnabled: currentPage > 1
					}"
                ></i>
                {{ currentPage }}
                <i
                        (click)="goForward()"
                        [mosaic-navigation-button]="{
						direction: NavigationDirection.Forward,
						isEnabled: dataResponse?.data?.length === queryOptions.take
					}"
                ></i>
                <kendo-pager-page-sizes [pageSizes]="gridInfo.pageable.pageSizes"></kendo-pager-page-sizes>
            </ng-template>
        </kendo-grid>
    </div>
</div>
<ng-template #textHoverTemplate class="exploreHoverTooltips" let-anchor>
    <div class="col">{{ anchor.nativeElement.getAttribute('tooltTipText') }}</div>
</ng-template>
<trella-post-notes *ngIf="hasPostNotes  && !parentKey" [gridInfo]="gridInfo"></trella-post-notes>
