declare global {
    interface String {
        /**
         * Checks whether the string is a valid JSON.
         *
         * This method attempts to parse the string as JSON. If the string
         * is successfully parsed, it returns `true`, indicating that the
         * string is a valid JSON format. If parsing fails due to a syntax
         * error or any other issue, it catches the error and returns `false`.
         *
         * Example usage:
         *
         * ```typescript
         * const jsonString = '{"CO": "Carbon", "H": "Hydrogen"}';
         * console.log(jsonString.isValidJson()); // true
         *
         * const invalidJsonString = '{"CO": "Carbon", "H": "Hydrogen"';
         * console.log(invalidJsonString.isValidJson()); // false
         * ```
         *
         * @returns {boolean} `true` if the string is valid JSON, `false` otherwise.
         */
        isValidJson(): boolean;
    }
}

String.prototype.isValidJson = function (): boolean {
    try {
        JSON.parse(this);
        return true;
    } catch (_) {
        return false;
    }
};
export {};