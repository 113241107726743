import { DISPLAY_VALUE } from '../enums/display-value.enum';
import { PROVIDER_TYPE } from '../enums/provider-type.enum';
import { TARGET_TYPE } from '../enums/target-type.enum';
import { CLASSIFICATION_TYPE } from '../enums/classification_type.enum';
import { TargetTypeConfigKey } from '../types/target-type-config-key.type';
import { TargetTypeConfig } from '../interfaces/target-type-config.interface';
import { ProviderTypeConfigKey } from '../types/provider-type-config-key.type';
import { ProviderTypeConfig } from '../interfaces/provider-type-config.interface';
import { FacilityType } from '../enums/facility-type.enum';
import { PORTAL_TYPE } from '../enums/portal-type.enum';
import { PERMISSIONS } from './permissions.constant';
import { NpiGroupTypeConfigKey } from '../types/npi-group-type-config-key.type';
import { NpiGroupTypeConfig } from '../interfaces/npi-group-type-config.interface';
import { NPI_GROUP_TYPE } from '../enums/npi-group-type.enum';
import { NpiType } from '../enums/npi-type.enum';
import { SHARE_LEVELS } from '../enums/share-levels.enum';
import { RISK_CATEGORY } from '../enums/risk-category.enum';


export const ERROR_MESSAGES = {
    default: 'Sorry, an unexpected error occurred while attempting to contact the server. Please try again.',
    insufficientChartData: 'There is not enough data to render this chart.'
};

export const NavButtonColors = {
    Enabled: '#5B6770',
    Disabled: '#939393'
};

export const SpecialColors = {
    AveragePercentile: '#b35c00',
    AverageRisk: '#ff8200',
    BadPercentile: '#C63663',
    GoodPercentile: '#43B02A',
    HighRisk: '#c63663',
    LowRisk: '#43b02a',
    MootPercentile: '$primary'
};

export const SPECIAL_DATA_VALUES = [DISPLAY_VALUE.lessThanEleven, DISPLAY_VALUE.dash, DISPLAY_VALUE.insufficient];

export const ProviderTypeArray = Object.values(PROVIDER_TYPE);

export const TARGET_TYPE_OPTIONS = [
    {
        display: `Underutilizing (${TARGET_TYPE.UU})`,
        value: TARGET_TYPE.UU
    },
    {
        display: `Unaffiliated (${TARGET_TYPE.UA})`,
        value: TARGET_TYPE.UA
    },
    {
        display: `Competitor Affiliated (${TARGET_TYPE.CA})`,
        value: TARGET_TYPE.CA
    },
    {
        display: `User Selected (${TARGET_TYPE.US})`,
        value: TARGET_TYPE.US
    }
];
// TODO: fix ts compiler issue https://stackoverflow.com/questions/42966362/how-to-use-object-values-with-typescript/42967397
export const TARGET_TYPES = [];

// TODO: fix ts compiler issue https://stackoverflow.com/questions/42966362/how-to-use-object-values-with-typescript/42967397
export const CLASSIFICATION_TYPES = Object.values(CLASSIFICATION_TYPE);

export const TargetTypeConfigs: { [key in TargetTypeConfigKey]: TargetTypeConfig } = {
    UA: {
        enum: TARGET_TYPE.UA,
        label: 'Unaffiliated'
    },
    CA: {
        enum: TARGET_TYPE.CA,
        label: 'Competitor Affiliated'
    },
    UU: {
        enum: TARGET_TYPE.UU,
        label: 'Underutilizing'
    },
    US: {
        enum: TARGET_TYPE.US,
        label: 'User Selected'
    }
};

export const TargetTypeConfigArray = [TargetTypeConfigs.UA, TargetTypeConfigs.CA, TargetTypeConfigs.UU, TargetTypeConfigs.US];

export const ProviderTypeConfigs: { [key in ProviderTypeConfigKey]: ProviderTypeConfig } = {
    physician: {
        enum: PROVIDER_TYPE.physician,
        routeString: FacilityType.PHYS,
        label: 'Physicians',
        labelSingular: 'Physician',
        icon: 'fas fa-user-md',
        colorClass: 'text-physician',
        bgClass: 'bg-physician'
    },
    physiciangroup: {
        enum: PROVIDER_TYPE.physicianGroup,
        routeString: FacilityType.PHYS_GRP,
        label: 'Physician Groups',
        labelSingular: 'Physician Group',
        icon: 'fas fa-user-class',
        colorClass: 'text-physician-group',
        bgClass: 'bg-physician-group'
    },
    facility: {
        enum: PROVIDER_TYPE.facility,
        routeString: 'facility',
        label: 'Facilities',
        labelSingular: 'Facility',
        icon: 'fas fa-hospital',
        colorClass: 'text-facility',
        bgClass: 'bg-facility'
    },
    homehealth: {
        enum: PROVIDER_TYPE.homehealth,
        routeString: FacilityType.HHA,
        label: 'Home Health Agencies',
        labelSingular: 'Home Health Agency',
        icon: 'fas fa-home',
        colorClass: 'text-agency',
        bgClass: 'bg-agency'
    },
    hospice: {
        enum: PROVIDER_TYPE.hospice,
        routeString: FacilityType.HOS,
        label: 'Hospices',
        labelSingular: 'Hospice',
        icon: 'fas fa-hands-heart',
        colorClass: 'text-agency',
        bgClass: 'bg-agency'
    },
    hospital: {
        enum: PROVIDER_TYPE.hospital,
        routeString: FacilityType.HOSP,
        label: 'Hospitals',
        labelSingular: 'Hospital',
        icon: 'fas fa-hospital',
        colorClass: 'text-facility',
        bgClass: 'bg-facility'
    },
    skillednursing: {
        enum: PROVIDER_TYPE.skilledNursing,
        routeString: FacilityType.SNF,
        label: 'Skilled Nursing Facilities',
        labelSingular: 'Skilled Nursing Facility',
        icon: 'fas fa-user-nurse',
        colorClass: 'text-agency',
        bgClass: 'bg-agency'
    },
    noProvider: {
        enum: PROVIDER_TYPE.noProvider,
        label: 'None'
    }
};


// TODO: MAR-5357 Create one constant or enum for route strings so that all code references can guarantee a match. Consolidate w/above & below
const ROUTE_STRINGS = {
    analyze: 'analyze',
    codeSets: 'codesets',
    dashboard: 'dashboard',
    engage: 'engage',
    explore: 'explore',
    marketEvaluation: 'marketEvaluation',
    reports: 'reports',
    totalCostOfCare: 'totalCostCare',
};

// TODO: MAR-5357 Refactor these constants too. What is the purpose of this constant vs the above?
export const ROUTE_CONSTANTS = {
    sso: {
        tokenGetLabel: 'sso',
        portalGetLabel: 'portal',
        redirectRouteLabel: 'redirect'
    },
    returnUrlLabel: 'returnUrl',
    passwordResetUrlLabel: 'token',
    newRouteParam: 'new',
    idRouteParam: 'id',
    settingsRouteParam: 'settings',
    redirect: {
        route: 'redirect',
        label: 'Redirect',
        icon: ''
    },
    hos: {
        route: PORTAL_TYPE.HOS,
        label: 'Hospice'
    },
    hha: {
        route: PORTAL_TYPE.HHA,
        label: 'Home Health'
    },
    hme: {
        route: PORTAL_TYPE.HME,
        label: 'HME/Infusion'
    },
    snf: {
        route: PORTAL_TYPE.SNF,
        label: 'Skilled Nursing'
    },
    login: {
        route: 'login',
        label: 'Login',
        icon: ''
    },
    passwordReset: {
        route: 'reset',
        label: 'Reset',
        icon: ''
    },
    choosePortal: {
        route: 'choosePortal',
        label: 'choosePortal',
        icon: ''
    },
    logout: {
        route: 'logout',
        label: 'Logout',
        icon: ''
    },
    physician: {
        route: ProviderTypeConfigs.physician.routeString,
        label: ProviderTypeConfigs.physician.label,
        icon: ProviderTypeConfigs.physician.icon
    },
    physicianGroup: {
        route: ProviderTypeConfigs.physiciangroup.routeString,
        label: ProviderTypeConfigs.physiciangroup.label,
        icon: ProviderTypeConfigs.physiciangroup.icon
    },
    facility: {
        route: ProviderTypeConfigs.facility.routeString,
        label: ProviderTypeConfigs.facility.label,
        icon: ProviderTypeConfigs.facility.icon
    },
    hospice: {
        route: ProviderTypeConfigs.hospice.routeString,
        label: ProviderTypeConfigs.hospice.label,
        icon: ProviderTypeConfigs.hospice.icon
    },
    hospital: {
        route: ProviderTypeConfigs.hospital.routeString,
        label: ProviderTypeConfigs.hospital.label,
        icon: ProviderTypeConfigs.hospital.icon
    },
    homehealth: {
        route: ProviderTypeConfigs.homehealth.routeString,
        label: ProviderTypeConfigs.homehealth.label,
        icon: ProviderTypeConfigs.homehealth.icon
    },
    skilledNursing: {
        route: ProviderTypeConfigs.skillednursing.routeString,
        label: ProviderTypeConfigs.skillednursing.label,
        icon: ProviderTypeConfigs.skillednursing.icon
    },
    settings: {
        route: 'settings',
        label: 'Settings'
    },
    repDashboard: {
        route: 'rep'
    },
    managerDashboard: {
        route: 'manager'
    },
    explore: {
        route: ROUTE_STRINGS.explore,
        label: 'Explore',
        icon: 'fas fa-binoculars',
        permission: PERMISSIONS.PAGE_EXPLORE_SHOW
    },
    analyze: {
        route: ROUTE_STRINGS.analyze,
        label: 'Analyze',
        icon: 'fas fa-chart-bar',
        permission: PERMISSIONS.PAGE_ANALYZE_SHOW
    },
    presentations: {
        route: ROUTE_STRINGS.engage,
        label: 'Engage',
        icon: '',
        svg: '/assets/img/icons/engage.svg#PAC-2415-Engage-Icon',
        svgActive: '/assets/img/icons/engage-active.svg#PAC-2415-Engage-Icon',
        permission: PERMISSIONS.PAGE_ENGAGE_SHOW
    },
    unauthorized: {
        route: 'unauthorized'
    },
    eula: {
        route: 'eula',
        label: 'eula',
        icon: ''
    },
    dataEula: {
        route: 'dataEula',
        label: 'dataEula',
        icon: ''
    },
    codeSets: {
        route: ROUTE_STRINGS.codeSets,
        label: 'Code Sets'
    },
    home: {
        route: ROUTE_STRINGS.dashboard,
        label: 'Dashboard'
    },
    marketEvaluation: {
        route: ROUTE_STRINGS.marketEvaluation,
        label: 'Market Evaluation'
    },
    reports: {
        route: ROUTE_STRINGS.reports,
        label: 'Reports'
    }
};

export const LOCAL_STORAGE_KEY = {
    JWT: 'excel_health_access_token',
    ANALYZE_PAGE: 'excel_health_analyze',
    ANALYZE_PAGE_VIEW: 'excel_analyze_page_view_chart_view'
};

export const NpiGroupTypeConfigs: { [key in NpiGroupTypeConfigKey]: NpiGroupTypeConfig } = {
    myAgency: {
        enum: NPI_GROUP_TYPE.myAgency,
        label: 'My Agency'
    },
    competitor: {
        enum: NPI_GROUP_TYPE.competitor,
        label: 'Competitor'
    },
    comparison: {
        enum: NPI_GROUP_TYPE.comparison,
        label: 'Comparison'
    }
};

export const AGENCY_PROVIDER_TYPES = [PROVIDER_TYPE.homehealth, PROVIDER_TYPE.hospice, NpiType.homeHealthAgency, NpiType.hospice];

export const FORMAT_FUNCTION_TARGET_TYPE = 'targetTypeTransform';
export const FORMAT_FUNCTIONS = [FORMAT_FUNCTION_TARGET_TYPE];

export const SHARE_LEVEL_OPTIONS = [
    {value: SHARE_LEVELS.PRIVATE, label: 'Private'},
    {value: SHARE_LEVELS.COMPANY, label: 'My Organization', permission: PERMISSIONS.DATAKIT_SHARE_PUBLIC_OPTION},
    {value: SHARE_LEVELS.CUSTOM, label: 'Custom List', permission: PERMISSIONS.DATAKIT_SHARE_CUSTOM_OPTION}
];

export const DEFAULT_CHART_HEIGHT = 450;

export const FILTER_CATEGORY = {
    ALL: {display: 'All Patients', value: 'ALL'},
    AGE: {display: 'Age', value: 'age'},
    BPCI: {display: 'BPCI', value: 'BPCI '},
    MDC: {display: 'MDC', value: 'MDC'},
    RPG: {display: 'RPGs', value: 'RPG'},
    ACUITY: {display: 'Acuity', value: 'acuity'},
    CAP: {display: 'Total Days from INP Discharge', value: 'cap'},
    CONSTRAINT: {display: 'Care Lapse Constraint', value: 'constraint'},
    ACO_FLAG: {display: 'ACO', value: 'ACO_FLAG'},
    ACO_ID: {display: 'ACO', value: 'aco_id'}
};

export const BPCI_OPTIONS = [
    {id: 1, display: 'Acute myocardial infarction', category: FILTER_CATEGORY.BPCI},
    {id: 2, display: 'Amputation', category: FILTER_CATEGORY.BPCI},
    {id: 3, display: 'Atherosclerosis', category: FILTER_CATEGORY.BPCI},
    {id: 4, display: 'Automatic implantable cardiac defibrillator generator or lead', category: FILTER_CATEGORY.BPCI},
    {id: 5, display: 'Back and neck except spinal fusion', category: FILTER_CATEGORY.BPCI},
    {id: 6, display: 'Cardiac arrythmia', category: FILTER_CATEGORY.BPCI},
    {id: 7, display: 'Cardiac defibrillator', category: FILTER_CATEGORY.BPCI},
    {id: 8, display: 'Cardiac valve', category: FILTER_CATEGORY.BPCI},
    {id: 9, display: 'Cellulitis', category: FILTER_CATEGORY.BPCI},
    {id: 10, display: 'Cervical spinal fusion', category: FILTER_CATEGORY.BPCI},
    {id: 11, display: 'Chest pain', category: FILTER_CATEGORY.BPCI},
    {id: 12, display: 'Chronic obstructive pulmonary disease, bronchitis/asthmae', category: FILTER_CATEGORY.BPCI},
    {id: 13, display: 'Combined anterior posterior spinal fusion', category: FILTER_CATEGORY.BPCI},
    {id: 14, display: 'Complex non-Cervical spinal fusion', category: FILTER_CATEGORY.BPCI},
    {id: 15, display: 'Congestive heart failure', category: FILTER_CATEGORY.BPCI},
    {id: 16, display: 'Coronary artery bypass graft surgery', category: FILTER_CATEGORY.BPCI},
    {id: 17, display: 'Diabetes', category: FILTER_CATEGORY.BPCI},
    {id: 18, display: 'Double joint replacement of the lower extremity', category: FILTER_CATEGORY.BPCI},
    {id: 19, display: 'Esophagitis, gastroenteritis and other digestive disorders', category: FILTER_CATEGORY.BPCI},
    {id: 20, display: 'Fractures femur and hip/pelvis', category: FILTER_CATEGORY.BPCI},
    {id: 21, display: 'Gastrointestinal hemorrhage', category: FILTER_CATEGORY.BPCI},
    {id: 22, display: 'Gastrointestinal obstruction', category: FILTER_CATEGORY.BPCI},
    {id: 23, display: 'Hip and femur procedures except major joint', category: FILTER_CATEGORY.BPCI},
    {id: 24, display: 'Lower extremity and humerus procedure except hip, foot, femur', category: FILTER_CATEGORY.BPCI},
    {id: 25, display: 'Major bowel', category: FILTER_CATEGORY.BPCI},
    {id: 26, display: 'Major cardiovascular procedure', category: FILTER_CATEGORY.BPCI},
    {id: 27, display: 'Major joint replacement of the lower extremity', category: FILTER_CATEGORY.BPCI},
    {id: 28, display: 'Major joint replacement of upper extremity', category: FILTER_CATEGORY.BPCI},
    {id: 29, display: 'Medical non-infectious orthopedic', category: FILTER_CATEGORY.BPCI},
    {id: 30, display: 'Medical peripheral vascular disorders', category: FILTER_CATEGORY.BPCI},
    {id: 33, display: 'Nutritional and metabolic disorders', category: FILTER_CATEGORY.BPCI},
    {id: 34, display: 'Other knee procedures', category: FILTER_CATEGORY.BPCI},
    {id: 35, display: 'Other respiratory', category: FILTER_CATEGORY.BPCI},
    {id: 36, display: 'Other vascular surgery', category: FILTER_CATEGORY.BPCI},
    {id: 37, display: 'Pacemaker', category: FILTER_CATEGORY.BPCI},
    {id: 38, display: 'Percutaneous coronary intervention', category: FILTER_CATEGORY.BPCI},
    {id: 39, display: 'Red blood cell disorders', category: FILTER_CATEGORY.BPCI},
    {id: 40, display: 'Removal of orthopedic devices', category: FILTER_CATEGORY.BPCI},
    {id: 41, display: 'Renal failure', category: FILTER_CATEGORY.BPCI},
    {id: 42, display: 'Revision of the hip or knee', category: FILTER_CATEGORY.BPCI},
    {id: 43, display: 'Sepsis', category: FILTER_CATEGORY.BPCI},
    {id: 44, display: 'Simple pneumonia and respiratory infections', category: FILTER_CATEGORY.BPCI},
    {id: 45, display: 'Spinal fusion (non-Cervical)', category: FILTER_CATEGORY.BPCI},
    {id: 46, display: 'Stroke', category: FILTER_CATEGORY.BPCI},
    {id: 47, display: 'Syncope and collapse', category: FILTER_CATEGORY.BPCI},
    {id: 48, display: 'Transient ischemia', category: FILTER_CATEGORY.BPCI},
    {id: 49, display: 'Urinary tract infection', category: FILTER_CATEGORY.BPCI}
];

export const RPG_OPTIONS = [
    {value: 'AMI', display: 'Acute Myocardial Infarction', category: FILTER_CATEGORY.RPG},
    {value: 'COPD', display: 'Chronic Obstructive Pulmonary Disease', category: FILTER_CATEGORY.RPG},
    {value: 'CABG', display: 'Coronary Artery Bypass Graft Surgery', category: FILTER_CATEGORY.RPG},
    {
        value: 'THKA',
        display: 'Elective Primary Total Hip Arthroplasty and/or Total Knee Arthroplasty',
        category: FILTER_CATEGORY.RPG
    },
    {value: 'HF', display: 'Heart Failure', category: FILTER_CATEGORY.RPG},
    {value: 'PN', display: 'Pneumonia', category: FILTER_CATEGORY.RPG},
    {value: 'SK', display: 'Stroke', category: FILTER_CATEGORY.RPG}
];

export const AGE_OPTIONS = [
    {value: '<65', display: '<65', category: FILTER_CATEGORY.AGE},
    {value: '65-74', display: '65-74', category: FILTER_CATEGORY.AGE},
    {value: '75-84', display: '75-84', category: FILTER_CATEGORY.AGE},
    {value: '85+', display: '85+', category: FILTER_CATEGORY.AGE}
];

export const ACUITY_OPTIONS = [
    {value: 'High', display: 'High', category: FILTER_CATEGORY.ACUITY},
    {value: 'Med', display: 'Medium', category: FILTER_CATEGORY.ACUITY},
    {value: 'Low', display: 'Low', category: FILTER_CATEGORY.ACUITY}
];

export const CAP_OPTIONS = [
    {value: '30-day', display: '30-day', category: FILTER_CATEGORY.CAP},
    {value: '60-day', display: '60-day', category: FILTER_CATEGORY.CAP},
    {value: '90-day', display: '90-day', category: FILTER_CATEGORY.CAP}
];

export const PATIENTS_LIVE_OPTIONS = [
    {value: 'phys', display: 'Total Patient Cost for Patients that lived during the follow-up period'},
    {value: 'phys_mort', display: 'Total Patient Cost for Patients that died during the follow-up period'}
];

export const CONSTRAINT_OPTIONS = [{value: '30-day', display: '30-day', category: FILTER_CATEGORY.CONSTRAINT}];

export const ALL_OPTION = 'ALL';
export const SUNBURST_ALL_OPTION = ALL_OPTION;
export const TCC_ALL_OPTION = ALL_OPTION;
export const JOURNEY_ROOT_PATH = '0';
export const ACO_FLAG_DEFAULT_VALUE = '1';
export const MAX_NUMBER_OF_STARS = 5;

export const ALLOWED_RATING_ARRAY = [NpiType.hospital, NpiType.otherFacility, NpiType.homeHealthAgency];

export const RISK_CATEGORIES = Object.values(RISK_CATEGORY);

export const NO_SUB_SPECIALTY = 'No Subspecialty';

export const UPSELL_EXTERNAL_LINKS = {
    crm: 'https://discover.trellahealth.com/lp/in-product/marketscape-crm/',
    insights: 'https://discover.trellahealth.com/lp/in-product/marketscape-insights/',
    strategy: 'https://discover.trellahealth.com/lp/in-product/marketscape-strategy/'
};
