<trella-spinner></trella-spinner>

<div class="container-fluid" id="eulaPage">
	<div class="row py-3 justify-content-center">
		<div class="col-auto">
			<img class="img-fluid" src="../../assets/img/logo.jpg"/>
		</div>
	</div>
	<trella-data-eula (onAccept)="acceptEula()" (onDecline)="showConfirm()"></trella-data-eula>
</div>

<app-alert-dialog (okPressed)="onDecline()"></app-alert-dialog>
