import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs-compat/add/observable/of';
import { ApiService } from './api.service';
import { AccountAssignmentDestination } from '@appcore/models/directory/account-assignment-destination.model';

@Injectable({
	providedIn: 'root'
})
export class AssignAnywhereApi {
	private _controllerName = 'admin/AccountAssignment';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getDestinations(): Observable<AccountAssignmentDestination[]> {
		return this.api.get(this._controllerName);
	}
}
