import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	ImportAssignments,
	ImportAssignmentsResults,
	ImportAssignmentsValidation
} from '../shared/models/import-assignments';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AssigneeApi {


	private _controllerName = 'Assignee';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	validateAssignmentImport(importUserObj: ImportAssignments): Observable<ImportAssignmentsValidation> {
		return this.api.post(this._controllerName, 'ValidateAssignmentImport', importUserObj);
	}

	finalizeAssignmentImport(fileId: number, invalidRows: number[], customerId: string, target): Observable<ImportAssignmentsResults> {
		return this.api.post(this._controllerName, 'FinalizeAssignmentImport', {
			fileId,
			invalidRows,
			customerId,
			target
		});
	}

	cleanUpAssignmentImport(fileId: number): Observable<boolean> {
		return this.api.post(this._controllerName, 'CleanUpAssignmentImport', {
			fileId
		});
	}

	getUserAssignmentsCount(userId: string): Observable<number> {
		return this.api.get(this._controllerName, `Count/${userId}`);
	}
}
