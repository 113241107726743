import { Observable } from 'rxjs';
import { ImportNpiGroup } from '../shared/models/npi-group';
import { Utils } from '@appcore/helpers/Utils';
import { Selection } from '@appcore/interfaces/selection.interface';
export const apiPrimitiveToSelectList = () => (source: Observable<any[]>) =>
	new Observable<Selection[]>(observer => source.subscribe({
			next: result => {
				const list = Utils.getSelectListObjectFromPrimitive(result);
				observer.next(list);
			},
			error: err => {
				observer.error(err);
			},
			complete: () => {
				observer.complete();
			}
		}));

export const apiNpiGroupToSelectListDisplay = () => (source: Observable<ImportNpiGroup[]>) =>
	new Observable<Selection[]>(observer => source.subscribe({
			next: list => {
				if (!list || !list.length) {
					observer.next([]);
					return;
				}

				const selectList: Selection[] = list.map(x => ({
						id: x.npiGroupId,
						display: x.title
					}));

				observer.next(selectList);
			},
			error: err => {
				observer.error(err);
			},
			complete: () => {
				observer.complete();
			}
		}));

