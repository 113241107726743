export enum GRID_COMMAND {
    copy = 'copy',
    edit = 'edit',
    view = 'view',
    delete = 'delete',
    favorite = 'favorite',
    target = 'target',
    compare = 'compare',
    npiGroup = 'npiGroup',
    select = 'select',
    assignee = 'assignee'
}
