<nav #tabs (selectedTabChange)="tabChanged($event)" [ngClass]="{ 'sticky-top': makeSticky }" [tabPanel]="tabPanel" class="top-tab-nav-bar"
     mat-tab-nav-bar>
    <a #rla="routerLinkActive"
       (click)="tabChanged(tab)"
       *ngFor="let tab of tabSections"
       [active]="isTabActive(tab, rla)"
       [disableRipple]="tab.disabled"
       [ngClass]="{ 'disabled-tab': tab.disabled }"
       [routerLink]="getCurrentTabId(tab)"
       class="top-tab-link"
       mat-tab-link
       routerLinkActive>
        <div [ngClass]="{ 'disabled-tab': tab.disabled }">{{ tab.title }}</div>
        <trella-info-circle *ngIf="tab.disabled" [position]="'bottom'" [tooltipTemplate]="template"
                            class="ms-1"></trella-info-circle>
        <ng-template #template><span>{{ tab.tooltipText }}</span></ng-template>
    </a>
</nav>
<mat-tab-nav-panel #tabPanel>
    <ng-content></ng-content>
</mat-tab-nav-panel>
