import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';

export class QueryOptions implements DataStateChangeEvent {
	skip: number;
	take: number;
	sort?: Array<SortDescriptor> = [];
	group?: Array<GroupDescriptor> = [];
	filter?: CompositeFilterDescriptor;
	npi?: string;

	constructor() {
		this.skip = 0;
		this.take = 25;
	}
}
