<table>
    <tr>
        <th colspan="3">{{ category }}</th>
    </tr>
    <tr *ngFor="let point of points">
        <td>
            <span [style.backgroundColor]="getLegendColor(point)" class="k-chart-shared-tooltip-marker"></span>
        </td>
        <td>
            <!-- TODO: Don't make these functions so they don't execute 1 million times -->
            {{ getLegendName(point) }}
        </td>
        <td>
            {{ getSpecialValue(point) }}
        </td>
    </tr>
</table>
