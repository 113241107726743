<div *ngIf="chartInfo">
    <div [ngSwitch]="chartInfo.type">
        <trella-line-chart #chartimage *ngSwitchCase="CHART_TYPE.line" [dataChange]="dataChangeSub"
                           [target]="target"></trella-line-chart>
        <trella-column-chart #chartimage
                             (activeLegendItemsChange)="activeLegendItemsChange.emit($event)"
                             *ngSwitchCase="CHART_TYPE.column"
                             [activeLegendItems]="activeLegendItems"
                             [dataChange]="dataChangeSub"
        ></trella-column-chart>

        <trella-column-chart-with-averages #chartimage
                                           (activeLegendItemsChange)="activeLegendItemsChange.emit($event)"
                                           *ngSwitchCase="CHART_TYPE.columnWithAverages"
                                           [activeLegendItems]="activeLegendItems"
                                           [dataChange]="dataChangeSub"
        ></trella-column-chart-with-averages>

        <trella-sunburst-chart #chartimage
                               (clickEvent)="onClickEvent($event)"
                               *ngSwitchCase="CHART_TYPE.sunburst"
                               [dataChange]="dataChangeSub"
        ></trella-sunburst-chart>
        <trella-bar-chart #chartimage *ngSwitchCase="CHART_TYPE.bar" [dataChange]="dataChangeSub"></trella-bar-chart>
        <trella-pie-chart #chartimage *ngSwitchCase="CHART_TYPE.pie" [dataChange]="dataChangeSub"></trella-pie-chart>
        <trella-insight #chartimage *ngSwitchCase="CHART_TYPE.insight" [dataChange]="dataChangeSub"></trella-insight>
        <trella-top-insight #chartimage *ngSwitchCase="CHART_TYPE.topInsight"
                            [dataChange]="dataChangeSub"></trella-top-insight>
        <div *ngSwitchDefault class="insight-large-text text-uppercase text-secondary-light ng-star-inserted">
            UNKNOWN CHART
        </div>
    </div>
</div>
