import { Component } from '@angular/core';
import { DialogButton } from '../../interfaces/dialog-button.interface';
import { AlertDialogService } from '../../services/alert-dialog.service';
import { DialogButtonType } from '../../enums/dialog-button-type.enum';

@Component({
    selector: 'trella-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {
    buttons: DialogButton[] = [];
    forceOnTop = false;
    loadingAnimation = false;
    message: string;
    opened = false;
    title: string;

    constructor(private alertDialogService: AlertDialogService) {
    }

    close(buttonType: DialogButtonType) {
        this.opened = false;
        this.alertDialogService.reportClosed(this, buttonType);
    }

    open(title: string, message: string, buttons: DialogButton[], forceOnTop?: boolean, loadingAnimation?: boolean) {
        this.buttons = buttons;
        this.title = title;
        this.message = message;
        this._ensureLabels(buttons);
        this.opened = true;
        this.alertDialogService.reportOpened(this);
        this.forceOnTop = forceOnTop;
        this.loadingAnimation = loadingAnimation;
    }

    private _getDefaultLabel(buttonType: DialogButtonType) {
        if (!buttonType) 
            return null;
        

        switch (buttonType) {
            case DialogButtonType.Ok:
                return 'OK';
            case DialogButtonType.Cancel:
                return 'Cancel';
        }
    }

    private _ensureLabels(buttons: DialogButton[]) {
        if (!buttons)
            return;

        buttons.forEach(button => {
            button.label = button.label || this._getDefaultLabel(button.buttonType);
        });
    }
}
