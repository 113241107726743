import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { EditableCompany } from '../shared/models/editable-company';
import { CrmRole } from '../shared/models/crm-role';
import { ICustomer } from '../shared/interfaces/customer.interface';
import { ProductName } from '@appcore/enums/product-name.enum';
import { CompanyContract } from '@appcore/models/directory/company/company-contract.model';
import { ModuleName } from '@appcore/enums/module-name.enum';
import { ProductAccess } from '@appcore/models/directory/product-access.model';
import { RoleContract } from '@appcore/models/directory/role.model';
import { Selection } from '@appcore/interfaces/selection.interface';


@Injectable({
	providedIn: 'root'
})

export class CompanyApi {

	private _controllerName = 'Company';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getAllObsolete(): Observable<ICustomer[]> {
		return this.api.get(this._controllerName);
	}

	getAll(forceAdmin = false): Observable<CompanyContract[]> {
		return this.api.get(this._controllerName, `new/${forceAdmin}`);
	}

	getCompaniesRolesCanBeCopied(): Observable<ICustomer[]> {
		return this.api.get(this._controllerName, 'cancopyroles');
	}

	getCompanyPurposes(): Observable<Selection[]> {
		return this.api.get(this._controllerName, 'purposes');
	}

	get(id: any): Observable<ICustomer> {
		return this.api.get(this._controllerName, id);
	}

	getByProduct(product: ProductName, module: ModuleName): Observable<Array<CompanyContract>> {
		return this.api.get(this._controllerName, `getByProduct?product=${product}&module=${module}`);
	}

	getCompanyProductAccesses(id: string): Observable<ProductAccess[]> {
		return this.api.get(this._controllerName, `${id}/productAccesses`);
	}

	getRoles(id: any): Observable<RoleContract[]> {
		return this.api.get(this._controllerName, `${id}/roles`);
	}

	getCrmRoles(id: any): Observable<CrmRole[]> {
		return this.api.get(this._controllerName, `${id}/roles/crm`);
	}

	getEditableCompany(id: any): Observable<EditableCompany> {
		return this.api.get(this._controllerName, `${id}/editable`);
	}

	create(company: EditableCompany) {
		return this.api.post(this._controllerName, null, company);
	}

	update(company: EditableCompany) {
		return this.api.put(this._controllerName, null, company);
	}

	delete(customerId: string) {
		return this.api.delete(this._controllerName, `${customerId}`);
	}
}
