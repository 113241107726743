import { FilterableSettings, PagerSettings, SelectableSettings, SortSettings } from '@progress/kendo-angular-grid';

export class GridAbilities {
    pageable?: PagerSettings = {};

    sortable?: SortSettings = {
        allowUnsort: true,
        mode: 'single',
        initialDirection: 'desc'
    };

    selectable: SelectableSettings = {
        checkboxOnly: true,
        enabled: true,
        mode: 'multiple'
    };
    hideSelectableCheckbox = false;

    enableAdding = false;
    filterable?: FilterableSettings = true;

    compareCommand = false;
    copyCommand = false;
    editCommand?: any;
    favoriteCommand = false;
    groupedActionColumn = false;
    npiGroupCommand = false;
    targetCommand = false;

    deleteRow?: any;
    viewable = false;

    scrollable?: 'none' | 'scrollable' | 'virtual';
    showMagnifyingGlass = false;
    isDragAndDrop = false;

    static GetAbilitiesForGroupedAction() {
        const abilities = new GridAbilities();
        abilities.groupedActionColumn = true;
        abilities.favoriteCommand = true;
        abilities.targetCommand = true;
        abilities.compareCommand = true;
        abilities.npiGroupCommand = true;
        return abilities;
    }
}
