import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { Amplify } from 'aws-amplify';
import { authInterceptorProviders } from './api/jwt-interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { CrmLogoutComponent } from './crmLogout/crmlogout.component';
import { DataEulaComponent } from './data-eula/data-eula.component';
import { EulaComponent } from './eula/eula.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RedirectComponent } from './redirect/redirect.component';
import { BaseComponent } from './shared/base.component';
import { GlobalErrorHandler } from './shared/global-error-handler';
import { EnvServiceProvider } from './shared/services/env.service.provider';
import { SharedModule } from './shared/shared.module';
import { UnauthorizedAccessComponent } from './unauthorized-access/unauthorized-access.component';
import { LOCAL_STORAGE_KEY } from './shared/constants/local-storage-key.constant';
import { ComponentsModule } from '@appcore/components/components.module';

const cfg = window['__env']['awsconfig'];

try {
	const clientId = localStorage.getItem(LOCAL_STORAGE_KEY.SSO_CLIENTID);
	if (clientId) {
		cfg.aws_user_pools_web_client_id = clientId;
		cfg.oauth.cognitoClientId = clientId;
		const authurl = localStorage.getItem(LOCAL_STORAGE_KEY.SSO_AUTH);
		if (authurl)
			cfg.oauth.domain = authurl;

	}
} catch {
}

Amplify.configure(cfg);

@NgModule({
	imports: [
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule.forRoot(),
		ChartsModule,
		ReactiveFormsModule,
		DateInputsModule,
		DialogsModule,
		DropDownListModule,
		ComponentsModule
	],
	declarations: [
		AppComponent,
		BaseComponent,
		LoginComponent,
		LogoutComponent,
		CrmLogoutComponent,
		LandingComponent,
		PageNotFoundComponent,
		RedirectComponent,
		UnauthorizedAccessComponent,
		EulaComponent,
		DataEulaComponent
	],
	providers: [
		EnvServiceProvider,
		authInterceptorProviders,
		{provide: ErrorHandler, useClass: GlobalErrorHandler}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
