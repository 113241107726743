import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { BaseFilterComponent } from '../base-filter.component';
import { LocationNode } from './location-node';
import { LocationTree } from './location-tree';
import { Observable } from 'rxjs';
import { FILTER_TYPE } from '../../../enums/filter-type.enum';
import { Locality } from '../../../interfaces/locality.interface';
import { ConfirmationDialogConfig } from '../../../interfaces/confirmation-dialog-config.interface';
import { FilterGroupService } from '../../../services/filter-group.service';

@Component({
    selector: 'trella-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent extends BaseFilterComponent implements OnChanges {
    @ViewChild('scrollLocationTop', {static: false}) scrollLocationTop: ElementRef;
    @ViewChild('scrollLocationBottom', {static: false}) scrollLocationBottom: ElementRef;
    @ViewChild(ConfirmationDialogComponent, {static: true}) confirmDialog: ConfirmationDialogComponent;
    @Input() filterType: FILTER_TYPE;
    @Input() header = 'Locations';
    @Input() isMyLocationsLoading = false;
    @Input() isMyLocationActivated: boolean;
    @Input() stateCountyOnly = false;
    @Input() definitionFactory: (FILTER_TYPE) => string;
    @Input() titleFactory: (FILTER_TYPE) => string;
    @Output() locationsChanged:Observable<Locality[]>;
    @Output() myLocationSaved: Observable<{ locationType: FILTER_TYPE; locations: Locality[] }>;
    @Output() myLocationToggled: Observable<{ locationType: FILTER_TYPE; isActive: boolean }>;

    filteredLocations: LocationNode[] = [];
    locationTree: LocationTree;
    searchQuery: string;

    public confirmDialogConfig: ConfirmationDialogConfig = {
        title: 'Change Default Location',
        message: '<div class=\'row\'>Are you sure you want to update your default location?</div>',
        okButtonLabel: 'Confirm',
        cancelButtonLabel: 'Cancel'
    };
    private _allLocations: Locality[];
    private _myLocations: Locality[];
    private _selectedLocations: Locality[];
    private _selectedNode: LocationNode;
    private _locationsChanged = new EventEmitter<Locality[]>();
    private _myLocationSaved = new EventEmitter<{ locationType: FILTER_TYPE; locations: Locality[] }>();
    private _myLocationToggled = new EventEmitter<{ locationType: FILTER_TYPE; isActive: boolean }>();


    constructor(protected filterGroupService: FilterGroupService) {
        super(filterGroupService);
        this.locationsChanged = this._locationsChanged.asObservable();
        this.myLocationSaved = this._myLocationSaved.asObservable();
        this.myLocationToggled = this._myLocationToggled.asObservable();
    }

    get getDefinition(): string {
        return this.definitionFactory(this.filterType) ?? '';
    }


    get getTitle(): string {
        return this.titleFactory && this.titleFactory(this.filterType) || this.header;
    }

    get getCount(): number {
        return !this.locationTree || !this.locationTree.selectedCount ? 0 : this.locationTree.selectedCount;
    }

    get hasCheckedNodes() {
        return this.locationTree && this.locationTree.checkedNodes && this.locationTree.checkedNodes.length;
    }

    get hasCities() {
        return this.hasCheckedNodes && this.selectedCities && this.selectedCities.length;
    }

    get hasCounties() {
        return this.hasCheckedNodes && this.selectedCounties && this.selectedCounties.length;
    }

    get hasMarkets() {
        return this.hasCheckedNodes && this.selectedMarkets && this.selectedMarkets.length;
    }

    get hasMyLocations() {
        return this._myLocations && this._myLocations.length;
    }

    get hasZips() {
        return this.hasCheckedNodes && this.selectedZips && this.selectedZips.length;
    }

    get searchPlaceholderText(): string {
        return this.stateCountyOnly ? 'State or County' : 'State, County, City, or Zip';
    }

    get selectedCounties(): LocationNode[] {
        return this.locationTree && this.locationTree.selectedCounties;
    }

    get selectedMarkets(): LocationNode[] {
        return this.locationTree && this.locationTree.selectedMarkets;
    }

    get selectedCities(): LocationNode[] {
        return this.locationTree && this.locationTree.selectedCities;
    }

    get selectedZips(): LocationNode[] {
        return this.locationTree && this.locationTree.selectedZips;
    }

    @Input() set allLocations(value: Locality[]) {
        this._allLocations = value;
    }

    @Input() set myLocations(value: Locality[]) {
        this._myLocations = value;
    }

    @Input() set selectedLocations(value: Locality[]) {
        this._selectedLocations = value;
    }

    handleSearchResultSelection(node: LocationNode): void {
        this.locationTree.selectSearchResult(node);
        this.setParentModel();
        this.filteredLocations = [];
        this.handleMyLocationActivation(false);
    }

    hasChildren(node: LocationNode): boolean {
        return this._selectedNode === node;
    }

    isSelectedNode(node: LocationNode): boolean {
        return this.locationTree && this.locationTree.selectedNodes.some(x => x.guid === node.guid);
    }

    ngOnChanges(): void {
        this.loadTree();
        if (this.locationTree && this.locationTree.checkedNodes.length === 0) {
            this.locationTree.setDefaultMenuView();
            this.locationTree.selectFirstNode();
            this._selectedNode = this.locationTree.getFirstNode();
        }
    }

    onMyLocationClicked(): void {
        const isMyLocationActivated = !this.isMyLocationActivated;
        if (this._myLocations && this._myLocations.length && isMyLocationActivated)
            this.loadMyLocation();

        this._myLocationToggled.emit({locationType: this.filterType, isActive: isMyLocationActivated});
    }

    onMyLocationSaved(): void {
        const selectedLocations = this.locationTree.getSelectedLocations();
        if (this.stateCountyOnly) {
            selectedLocations.forEach(location => {
                location.city = null;
                location.zipcode = null;
            });
        }
        this._myLocationSaved.emit({locationType: this.filterType, locations: selectedLocations});
    }

    onSearchKeyup(_: KeyboardEvent): void {
        if (this.searchQuery.length < 2) {
            this.filteredLocations = [];
            return;
        }

        this.filteredLocations = this.locationTree.onSearch(this.searchQuery);
    }

    openSaveMyLocation(): void {
        this.confirmDialog.open();
    }

    scrollLocation(): void {
        const scrollLocationTop = this.scrollLocationTop.nativeElement as HTMLElement;
        const scrollLocationBottom = this.scrollLocationBottom.nativeElement as HTMLElement;
        scrollLocationBottom.scrollLeft = scrollLocationTop.scrollLeft;
    }

    selectAndToggleNode(node: LocationNode): void {
        this.selectNode(node);
        this.toggleNode(node);
        this.handleMyLocationActivation(false);
    }

    selectNode(node: LocationNode): void {
        this.locationTree.selectNode(node);
        this._selectedNode = node;
    }

    private handleMyLocationActivation(isActive: boolean): void {
        if (this.isMyLocationActivated !== isActive) {
            this.isMyLocationActivated = isActive;
            this._myLocationToggled.emit({locationType: this.filterType, isActive: this.isMyLocationActivated});
        }
    }

    private loadMyLocation(): void {
        this.locationTree.loadFromNodeModels(this._myLocations);
        this.locationTree.checkedNodes.forEach(node => this.locationTree.selectNode(node));
        this.setParentModel();
    }

    private loadTree(): void {
        if (!(this._allLocations && this._selectedLocations))
            return;


        if (!this.locationTree)
            this.locationTree = new LocationTree(this._allLocations, this.stateCountyOnly);


        this.locationTree.loadFromNodeModels(this._selectedLocations);
        this.locationTree.checkedNodes.forEach(node => {
            if (this._selectedNode && this._selectedNode.guid !== node.guid)
                this.locationTree.selectNode(node);
             else if (!this._selectedNode)
                this.locationTree.selectNode(node);

        });
        if (this._selectedNode && !this.isMyLocationActivated)
            this.locationTree.selectNode(this._selectedNode);

    }

    private setParentModel(): void {
        this._locationsChanged.emit(this.locationTree.getSelectedLocations());
    }

    private toggleNode(node: LocationNode): void {
        this.locationTree.toggleNode(node);
        this.setParentModel();
    }
}
