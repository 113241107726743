<trella-hover-tooltip [position]="'top'" [tooltipTemplate]="DefinitionTemplate" [underline]="false">
    <div (click)="toggleFilter()"
         class="btn text-input d-flex flex-row align-items-center flex-nowrap btn-outline-primary">
	<span class="me-3">
		{{ getTitle }} <span *ngIf="getCount">({{ getCount }})</span>
	</span>
        <i [class.fa-chevron-down]="isOpen" [class.fa-chevron-right]="!isOpen" class="fas"></i>
    </div>
</trella-hover-tooltip>
<ng-template #DefinitionTemplate>
    <div class="p-1 text-center">
        {{ getDefinition }}
    </div>
</ng-template>

<div *ngIf="isOpen" class="range-filter d-flex flex-column align-items-center justify-content-center">
    <div *ngFor="let filter of rangeSelections" class="d-flex flex-row w-100">
        <div class="range-filter-title d-flex me-2 align-items-center">
            {{ getRangeFilterNameByType(filter.name) }}
        </div>
        <div class="d-flex flex-column w-100 justify-content-center align-items-center">
            <div class="d-flex flex-row w-100 justify-content-between">
                <div>
                    {{ filter.minSelected }}
                </div>
                <div>
                    {{ filter.maxSelected }}
                </div>
            </div>
            <div class="w-100 pe-4">
                <mat-slider
                        [max]="getRules(filter).maxAllowed"
                        [min]="getRules(filter).minAllowed"
                        [step]="getRules(filter).step || 1"
                        class="w-100"
                        discrete
                >
                    <input (valueChange)="filterChanged()" [(value)]="filter.minSelected" matSliderStartThumb/>
                    <input (valueChange)="filterChanged()" [(value)]="filter.maxSelected" matSliderEndThumb/>
                </mat-slider>
            </div>
        </div>
    </div>
</div>
