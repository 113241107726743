import { isArray } from 'lodash';
import { ComparisonOptions } from '../interfaces/comparison-options.interface';
import { ComparisonBarOptions } from '../interfaces/comparison-bar-options.interface';
import { GridColumnType } from '../enums/grid-column-type.enum';
import { ComparisonShadeOptions } from '../interfaces/comparison-shade-options.interface';
import { GridDataType } from '../enums/grid-data-type.enum';
import { DataSubType } from '../enums/data-sub-type.enum';
import { Replacement } from '../interfaces/replacement.interface';
import { SparklineGridColOptions } from '../interfaces/sparkline-grid-col-options.interface';
import { ConfigColumnModel } from '../interfaces/config-column-model.interface';
import { Utils } from '../helpers/Utils';
import { DefaultColumnWidth } from '../constants/default-column-width.constant';
import { numericDataTypes } from '../constants/numeric-data-types.constant';
import { GridFilterType } from '../enums/grid-filter-type.enum';
import { SparklineOptions } from '../interfaces/sparkline-options.interface';

export class GridColumn {
    columnType: GridColumnType;
    columns?: GridColumn[] = [];
    comparison?: ComparisonOptions;
    comparisonBar?: ComparisonBarOptions;
    comparisonShade?: ComparisonShadeOptions;
    convertToLocalDate?: boolean;
    clickable = false;
    dataType?: GridDataType;
    dataSubType: DataSubType;
    decimalPoints = 1;
    definition: string;
    rawDefinition: string;
    replacements?: Replacement[];

    enumType?: string;
    favoriteable = false;
    field: string;
    filterable = true;
    filterType: string;
    format: string;
    hasHtml = false;
    hidden = false;
    hideTitle = false;
    highlight = false;
    includeInColumnChooser = true;
    locked = false;
    maxCharacters?: number;
    medicalDirector = false;
    metadata?: GridColumn;
    sortable = true;
    sparklineCol?: SparklineGridColOptions;
    targetable = false;
    title: string;
    uniq: number;
    width: any;

    constructor(config: Partial<ConfigColumnModel>) {
        if (!config) 
            return;
        

        this.columns = (config.columns || []).map(x => new GridColumn(x));
        this.metadata = config.metadata ? new GridColumn(config.metadata) : null;
        this.width = this.getWidth(config.columns, config.width);
        this.replacements = config.replacements;

        this.setCommonOptions(config);
        this.setSparklineOptions(config.sparklineOptions);
    }

    getWidth = (columns: ConfigColumnModel[], defaultWidth: number) =>
        (columns || []).map(column => column.width).reduce((p, c) => p + c, defaultWidth);

    private setCommonOptions(config: Partial<ConfigColumnModel>) {
        this.field = config.field;
        this.title = Utils.getParsedGridColumnHeader(config.title);
        this.definition = Utils.replaceQuarterWithinString(config.definition);
        this.enumType = config.enumType;
        this.width = config.width || DefaultColumnWidth;
        this.comparison = config.comparisonOptions;
        this.comparisonBar = config.comparisonBarOptions;
        this.comparisonShade = config.comparisonShadeOptions;
        this.convertToLocalDate = config.convertToLocalDate;
        this.hasHtml = !!config.hasHtml;
        this.hidden = !!config.hidden;
        this.hideTitle = config.hideTitle;
        this.highlight = config.highlight;
        this.filterable = config.filterable;
        this.decimalPoints = config.decimalPoints;
        this.maxCharacters = config.maxCharacters;
        this.uniq = config.uniq;

        if (config.sparklineOptions) {
            // Sparklines don't need a data type
            return;
        }

        this.format = config.flags;
        this.dataType = config.dataType;
        this.dataSubType = config.dataSubType;

        const isNumericType = this.dataType && numericDataTypes.find(x => x === this.dataType);
        this.filterType = isNumericType ? GridFilterType.numeric : GridFilterType.text;

        this.clickable = config.clickable;
        this.favoriteable = config.favoriteable;
        this.targetable = config.targetable;
        this.includeInColumnChooser = config.includeInColumnChooser;
        this.medicalDirector = config.medicalDirector;
        this.locked = config.locked;

        // Hide number filters for now as well anything with a fiterable value of false/undefined
        if (this.filterType === GridFilterType.numeric || !config.filterable) 
            this.filterable = false;
        
    }

    private setSparklineOptions(options: SparklineOptions) {
        if (!options) 
            return;
        

        const fields = options.fields;
        if (isArray(fields)) {
            // Concatenate with a valid field character to get around console warnings
            this.field = fields.join('_');
        }

        this.sparklineCol = {
            subtitles: options.subtitle.map(subtitle => Utils.getParsedGridColumnHeader(subtitle)),
            fields
        };

        this.filterType = null;
        this.filterable = false;
        this.clickable = false;
        this.sortable = false;
        this.dataType = null;
    }
}
