<div class="collapse navbar-collapse no-print navbar-parent" id="navbar-content">
    <ul class="navbar-nav ms-auto">
        <li class="navbar-item border-start ps-3 dropdown user-dropdown">
            <a class="nav-link top-nav-link dropdown-toggle d-flex align-items-center pointer"
               data-bs-toggle="dropdown">
                <i class="profile-icon fa-light fa-circle-user text-primary"></i>
                <i class="caret-icon fa-solid fa-caret-down text-primary"></i>
            </a>
            <div class="dropdown-menu">
                <div class="meta">
                    <div class="meta-label">Signed in as:</div>
                    <div class="meta-username ellipsis">{{ userName }}</div>
                    <div class="meta-useremail ellipsis">{{ userEmail }}</div>
                    <div class="meta-companyname ellipsis">{{ companyName }}</div>
                </div>
                <div *ngIf="links?.length" class="links">
                    <a *ngFor="let link of links" [routerLink]="link.route" class="dropdown-item pointer">
                        <i class="fa-light {{ link.icon }} dropdown-item-icon"></i>
                        {{ link.label }}
                    </a>
                </div>
                <ng-container *ngIf="hasAdminPermission">
                    <a (click)="navigateToAdmin()" class="dropdown-item pointer admin-link">
                        <img [src]="adminIcon" class="dropdown-item-icon dropdown-item-admin"/>
                        Admin
                    </a>
                </ng-container>
                <a (click)="logout()" class="dropdown-item pointer">
                    <i class="fa-light fa-arrow-right-from-bracket dropdown-item-icon"></i>
                    Log Out
                </a>
            </div>
        </li>
    </ul>
</div>
