export enum FilterType {
	Status = 'IsEnabled',
	DateRange = 'daterange',
	CustomerId = 'Customerid',
	CustomerGuid = 'CustomerGuid',
	Portal = 'Portal',
	Solution = 'Solution',
	MarketscapeId = 'MarketscapeId',
	NpiGroupType = 'NpiGroupTypeId',
}
