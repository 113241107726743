import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RangeSelection } from '../../../models/ranges/range-selection.model';
import { FILTER_TYPE } from '../../../enums/filter-type.enum';
import { RangeFilterRules } from '../../../models/ranges/range-filter-rules.model';
import { FilterGroupService } from '../../../services/filter-group.service';
import { getRangeFilterNameByType } from '../../../constants/get-range-filter-name-by-type.constant';
import { RangeFilterRulesJson } from '../../../interfaces/range-filter-rules-json.interface';

@Component({
    selector: 'trella-range',
    templateUrl: './range.component.html',
    styleUrls: ['./range.component.scss']
})
export class RangeComponent extends BaseFilterComponent {
    @Input() definitionFactory: (FILTER_TYPE) => string;
    @Input() rangeSelections: RangeSelection[] = [];
    @Input() titleFactory: (FILTER_TYPE) => string;
    @Output() rangeChanged: Observable<RangeSelection[]>;

    filterType: FILTER_TYPE = FILTER_TYPE.RANGE;
    getRangeFilterNameByType = getRangeFilterNameByType;
    private _rangeChanged: EventEmitter<RangeSelection[]> = new EventEmitter<RangeSelection[]>();
    private _rules: RangeFilterRules[];

    constructor(protected filterGroupService: FilterGroupService) {
        super(filterGroupService);
        this.rangeChanged = this._rangeChanged.asObservable().pipe(debounceTime(1250));
    }
    
    get getDefinition(): string {
        return this.definitionFactory(this.filterType) ?? '';
    }

    get getTitle(): string {
        return this.titleFactory && this.titleFactory(this.filterType) || 'Range Filters';
    }

    get getCount(): number {
        const selected = [];

        this.rangeSelections.forEach(rangeSelection => {
            const rule = this.getRules(rangeSelection);
            if (rangeSelection.minSelected !== rule.minAllowed || rangeSelection.maxSelected !== rule.maxAllowed) 
                selected.push(rangeSelection);
            
        });

        return selected.length;
    }

    @Input() set rules(rules: RangeFilterRulesJson[]) {
        this._rules = rules.map(r => new RangeFilterRules(r));
    }

    getRules(selection: RangeSelection) {
        return this._rules.find(f => f.name === selection.name);
    }

    filterChanged() {
        this._rangeChanged.emit(this.rangeSelections);
    }
}
