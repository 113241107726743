<div class="row w-100 position-relative justify-content-center" data-point>
    <table>
        <thead>
        <tr>
            <ng-container *ngFor="let head of header">
                <th *ngIf="!head.hidden" class="table-data-text">{{ head.label }}</th>
            </ng-container>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of displayData">
            <ng-container *ngFor="let list of item.data">
                <td *ngIf="!list.hidden" class="table-data-text">
                    <span *ngIf="!list.clickable && !list.hidden">{{ list.data }}</span>
                    <a *ngIf="list.clickable && !list.hidden" [routerLink]="getRouterLink(list)"
                       class="table-data-text">{{ list.data }}</a>
                </td>
            </ng-container>
        </tr>
        </tbody>
    </table>
</div>