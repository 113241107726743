<div *ngIf="options" class="row border g-0 div-overlay-wrapper lookup-card bg-white">
    <div class="col">
        <div *ngIf="options.displayTitleBar"
             class="row g-0 p-3 justify-content-between align-items-center border-bottom">
            <div class="col-auto insight-text-heading w-100">
                Demographic Card
            </div>
        </div>
        <div class="row g-0 border text-secondary dc-height-control">
            <div class="col-12 col-md-4 border-0 border-md-right p-3">
                <div class="row g-0 align-items-center">
                    <div *ngIf="icon" class="col-auto me-4">
                        <i class="{{ icon }} {{ color }} fa-2x"></i>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col text-label-md">
                                <span> {{ info?.display || '' }} </span>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col text-data text-primary {{ color }}">
                                <span class="fw-bold pe-1">CMS ACO</span>
                                <span>{{ info?.id || '' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md border-0 border-md-right p-3">
                <div class="row">
                    <div class="col text-label">
                        Location
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col text-data">
                        {{ formatMarkets(info?.markets || '') }}
                    </div>
                </div>
            </div>
            <div class="col-6 col-md border-0 border-md-right p-3">
                <div class="row">
                    <div class="col-auto">
                        <div class="row">
                            <div class="col text-label">
                                Contact
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col text-data">
                                {{ info?.phone || '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                    *ngIf="options.displayDetails"
                    class="col-12 border-top py-3 col-md-auto border-md-0 py-md-0 d-flex align-items-center no-print"
            >
                <i
                        (click)="toggleDetails()"
                        *ngIf="options.displayDetails"
                        [class.fa-angle-down]="!open"
                        [class.fa-angle-up]="open"
                        class="far fa-2x px-3 pointer"
                ></i>
            </div>
        </div>

        <div *ngIf="open && options.displayDetails" class="row g-0 border border-top-0 text-data text-secondary">
            <div class="col p-3">
                <div class="row">
                    <div class="col text-label">
                        Contact
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-auto">
                        <i class="fas fa-envelope"></i>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input class="form-control" placeholder="Email" type="text"/>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        {{ info?.email || '' }}
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-auto">
                        <i class="fas fa-phone"></i>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input class="form-control" placeholder="Phone" type="text"/>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        {{ info?.phone || '' }}
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-auto">
                        <i class="fas fa-fax"></i>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input class="form-control" placeholder="Fax" type="text"/>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        {{ info?.fax || '' }}
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-auto">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="col text-black">
                        <div class="row">
                            <div *ngIf="editMode" class="col">
                                <input class="form-control" placeholder="Address 1" type="text"/>
                            </div>
                            <div *ngIf="!editMode" class="col">
                                {{ info?.address1 || '' }}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div *ngIf="editMode" class="col">
                                <input class="form-control" placeholder="Address 2" type="text"/>
                            </div>
                            <div *ngIf="!editMode" class="col">
                                {{ info?.address2 || '' }}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div *ngIf="editMode" class="col">
                                <div class="row">
                                    <div class="col-auto">
                                        <input class="form-control" placeholder="city" type="text"/>
                                    </div>
                                    <div class="col-auto">
                                        <input class="form-control" placeholder="state" style="width: 80px;"
                                               type="text"/>
                                    </div>
                                    <div class="col-auto">
                                        <input class="form-control" placeholder="zip" type="text"/>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!editMode"
                                 class="col">{{ info?.city || '' }} {{ info?.state || '' }} {{ info?.zip || '' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col p-3 text-data">
                <div class="row">
                    <div class="col-6 text-label">
                        Other
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-auto other-label-width">
                        <trella-hover-tooltip [position]="'right'" [tooltipTemplate]="TrackNameTemplate">Track Name
                        </trella-hover-tooltip>
                        <ng-template #TrackNameTemplate>
                            <p>
                                Name of different participation options that allow ACOs to assume various levels of
                                risk.
                            </p>
                            <p>
                                Additional Information:
                                <a
                                        href="https://www.cms.gov/Medicare/Medicare-Fee-for-Service-Payment/sharedsavingsprogram/about"
                                        target="_blank"
                                >https://www.cms.gov/Medicare/Medicare-Fee-for-Service-Payment/sharedsavingsprogram/about</a
                                >.
                            </p>
                        </ng-template>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input [(ngModel)]="info.track" class="form-control" placeholder="Track Name" type="text"/>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.track" class="text-black">{{ info.track }}</span>
                        <span *ngIf="!info.track" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-auto other-label-width">
                        <trella-hover-tooltip [position]="'right'" [tooltipTemplate]="RevenueTemplate">Revenue
                        </trella-hover-tooltip>
                        <ng-template #RevenueTemplate>
                            <p>
                                <strong>High revenue ACO:</strong> an ACO whose total Medicare Parts A and B FFS revenue
                                of its ACO
                                participants is <u>at least 35 percent</u> of the total Medicare Parts A and B FFS
                                expenditures for the
                                ACO’s assigned beneficiaries.
                            </p>
                            <p>
                                <strong>Low revenue ACO:</strong> an ACO whose total Medicare Parts A and B FFS revenue
                                of its ACO
                                participants is <u>less than 35 percent</u> of the total Medicare Parts A and B FFS
                                expenditures for the
                                ACO’s assigned beneficiaries.
                            </p>
                        </ng-template>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input [(ngModel)]="info.revenue" class="form-control" placeholder="Revenue" type="text"/>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.revenue" class="text-black">{{ info.revenue }}</span>
                        <span *ngIf="!info.revenue" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-auto other-label-width">
                        <trella-hover-tooltip [position]="'right'" [tooltipTemplate]="PayTemplate">Pay
                        </trella-hover-tooltip>
                        <ng-template #PayTemplate>
                            <p>
                                Indicates whether CMS provides an up front payment of shared savings which may or may
                                not need to be paid
                                back depending on shared savings actually achieved.
                            </p>
                            <p>
                                Additional information:
                                <a href="https://innovation.cms.gov/innovation-models/advance-payment-aco-model"
                                   target="_blank"
                                >https://innovation.cms.gov/innovation-models/advance-payment-aco-model</a
                                >.
                            </p>
                        </ng-template>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input [(ngModel)]="info.pay" class="form-control" placeholder="Pay" type="text"/>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.pay" class="text-black">{{ info.pay }}</span>
                        <span *ngIf="!info.pay" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-auto other-label-width">
                        <trella-hover-tooltip [position]="'right'" [tooltipTemplate]="AssignmentTemplate"
                        >Assignment ACO
                        </trella-hover-tooltip
                        >
                        <ng-template #AssignmentTemplate>
                            <p>
                                <strong>Prospective:</strong> Identifies whether the ACO beneficiaries are assigned
                                based on the 12 month
                                period prior to the Performance Year. Assignment is known in advance but there’s no
                                guarantee those patients
                                are actually seen within the ACO network.
                            </p>
                            <p>
                                <strong>Retrospective:</strong> Beneficiaries are assigned based on services provided
                                during the Performance
                                Year. Ensures that patients visit the ACO network during the Performance Year.
                            </p>
                        </ng-template>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input [(ngModel)]="info.assignment" class="form-control" placeholder="Assignment" type="text"/>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.assignment" class="text-black">{{ info.assignment }}</span>
                        <span *ngIf="!info.assignment" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-auto other-label-width">
                        <trella-hover-tooltip [position]="'right'" [tooltipTemplate]="SNF3DayRuleWaiverTemplate"
                        >SNF 3-Day Rule Waiver
                        </trella-hover-tooltip
                        >
                        <ng-template #SNF3DayRuleWaiverTemplate>
                            <p>
                                Requires the ACO to provide sufficient information to demonstrate that the ACO has the
                                capacity to identify
                                and manage beneficiaries who, under the waiver, would be either directly admitted to a
                                SNF or admitted to a
                                SNF after an inpatient hospitalization of fewer than 3 days.
                            </p>
                        </ng-template>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.assignment" class="text-black">Yes</span>
                        <span *ngIf="!info.assignment" class="text-black">No</span>
                    </div>
                </div>

                <div *ngIf="editMode" class="row mt-5">
                    <div class="col"></div>
                    <div class="col-auto">
                        <button (click)="save()" [disabled]="!canSave" class="btn btn-success btn-wide" type="button">
                            Save
                        </button>
                    </div>
                    <div class="col-auto">
                        <button (click)="cancel()" class="btn btn-secondary btn-wide" type="button">Cancel</button>
                    </div>
                </div>
            </div>

            <div *ngIf="options.editInfo" class="col-auto p-3 text-data">
                <div (click)="toggleEditMode()" class="row pointer px-3">
                    <i [class.text-primary]="editMode" [class.text-secondary-light]="!editMode"
                       class="fas fa-pencil me-1"></i>
                    <span class="text-underline fw-bold text-uppercase">Edit</span>
                </div>
            </div>

            <div class="col p-3 bg-secondary-light text-black"></div>
        </div>
    </div>
</div>
