import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentWithSubscription } from './component-with-subscription';

@Component({template: ''})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AbstractValueAccessor extends ComponentWithSubscription implements ControlValueAccessor {
    _value: any = '';
    get value(): any {
        return this._value;
    }

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    writeValue(value: any) {
        this._value = value;
        // warning: comment below if only want to emit on user intervention
        this.onChange(value);
    }

    onChange = _ => {
    };
    onTouched = () => {
    };

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function MakeProvider(type: any) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => type),
        multi: true
    };
}
