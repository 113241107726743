import * as $ from 'jquery';

export default class Sticky {

	static windowWidth = 1100;


	static addStickyAnalyzeHeaders() {
		$('.lookup-card-sticky-top-removed').addClass('lookup-card-sticky-top');
		$('.lookup-card-sticky-top').removeClass('lookup-card-sticky-top-removed');
		$('.mat-mdc-tab-header').addClass('mat-mdc-tab-header-sticky-top');
		$('.chartview-toggle-sticky-removed').addClass('chartview-toggle-sticky');
		$('.chartview-toggle-sticky').removeClass('chartview-toggle-sticky-removed');
		if ($(window).width() >= this.windowWidth) {
			$('.mat-mdc-tab-header-sticky-top').css('top', $('.lookup-card').height() + 58);
			$('.chartview-toggle-sticky').css('top', $('.lookup-card').height() + 68);
		}
	}

	static removeStickyAnalyzeHeaders() {
		$('.mat-mdc-tab-header').css('top', '');
		$('.chartview-toggle-sticky').css('top', '');
		$('.lookup-card-sticky-top').addClass('lookup-card-sticky-top-removed');
		$('.lookup-card-sticky-top-removed').removeClass('lookup-card-sticky-top');
		$('.mat-mdc-tab-header').removeClass('mat-mdc-tab-header-sticky-top');
		$('.chartview-toggle-sticky').addClass('chartview-toggle-sticky-removed');
		$('.chartview-toggle-sticky-removed').removeClass('chartview-toggle-sticky');
	}
}
