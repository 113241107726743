import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../main/navbar/navbar.service';
import { ROUTE_CONSTANTS } from '../shared/constants/route-constants.constant';
import { CurrentUserResponse } from '../shared/models/current-user-response';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NavigationService } from '../shared/services/navigation.service';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';

@Component({
	selector: 'app-redirect',
	template: ''
})
export class RedirectComponent implements OnInit {
	ssoToken: string;

	constructor(private activatedRoute: ActivatedRoute, 
				private authService: AuthenticationService,
				private navBarService: NavbarService,
				private navigationService: NavigationService, 
				private userFeedbackService: UserFeedbackService) {
	}

	ngOnInit() {
		this.userFeedbackService.startSpinner();

		this.ssoToken = this.activatedRoute.snapshot.queryParams[ROUTE_CONSTANTS.sso.tokenGetLabel];

		if (!this.ssoToken) 
			return this.onAuthError();
		

		this.authService.getCurrentUser()
			.then((response: CurrentUserResponse) => {
				if (!response || !response.user) {
					// Something went wrong
					return this.onAuthError();
				}
				this.userFeedbackService.stopSpinner();
				this.navBarService.loadLastPage();
			})
			.catch(() => this.onAuthError());
	}

	private onAuthError() {
		this.navigationService.navigateToLogin();
		this.userFeedbackService.stopSpinner();
		return false;
	}

}
