import { RangeFilterType } from '../../enums/range-filter-type.enum';
import { RangeFilterRulesJson } from '../../interfaces/range-filter-rules-json.interface';

export class RangeFilterRules {
    minAllowed?: number;
    maxAllowed?: number;
    name: RangeFilterType;
    step?: number;

    constructor(json: RangeFilterRulesJson) {
        Object.assign(this, json);
    }
}
