export enum CHART_TYPE {
    bar = 'bar',
    pie = 'pie',
    line = 'line',
    column = 'column',
    insight = 'insight',
    sunburst = 'sunburst',
    topInsight = 'topInsight',
    venn = 'venn',
    donut = 'donut',
    bubble = 'bubble',
    columnWithAverages = 'columnWithAverages',
    insightStarRating = 'insightStarRating',
    labelDataExtra = 'labelDataExtra',
    benchMarkInsight = 'benchMarkInsight',
    topPlacesInsight = 'topPlacesInsight',

}
