import { GridInfo } from '../grid-info.model';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { FormattedResult } from '../../interfaces/formatted-result.interface';

export class GridExport {
    gridInfo: GridInfo;
    result?: GridDataResult;

    static withNoData(gridInfo: GridInfo) {
        const noData = {
            data: [] as FormattedResult[],
            total: 0
        };

        return {gridInfo, result: noData};
    }
}
