<kendo-chart #kendoimage (legendItemClick)="onLegendItemClick($event)" *ngIf="data?.isValidData" [seriesColors]="seriesColors"
             style="height: 400px;">
    <kendo-chart-tooltip [shared]="true">
        <ng-template kendoChartSharedTooltipTemplate let-category="category" let-points="points">
            <trella-chart-tooltip
                    [category]="category"
                    [chartDataType]="options.dataType"
                    [chartType]="type"
                    [data]="data.data"
                    [points]="points"
            ></trella-chart-tooltip>
        </ng-template>
    </kendo-chart-tooltip>

    <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [categories]="data.categories" [labels]="legendLabelConfig"
                                        [title]="xAxisTitle">
            <kendo-chart-category-axis-item-labels [content]="getFormattedName" [font]="legendLabelConfig.font">
            </kendo-chart-category-axis-item-labels>
        </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [labels]="valueAxisLabels" [title]="yAxisTitle"></kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-series>
        <kendo-chart-series-item
                *ngFor="let series of data.data"
                [data]="series.rawData"
                [name]="series.label"
                [stack]="true"
                [visible]="filterSeriesByLegend(series.label)"
                type="bar"
        ></kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [labels]="legendLabelConfig" [visible]="options.showLegend" position="top"></kendo-chart-legend>
</kendo-chart>

<div *ngIf="!data?.isValidData" class="insight-large-text text-uppercase text-secondary-light ng-star-inserted">
    NO DATA AVAILABLE
</div>
