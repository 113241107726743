import { Selection } from '@appcore/interfaces/selection.interface';
import dayjs from 'dayjs';
import qoyPlugin from 'dayjs/plugin/quarterOfYear';
import weekdayPlugin from 'dayjs/plugin/weekday';

dayjs.extend(qoyPlugin);
dayjs.extend(weekdayPlugin);

export default class AdminUtils {

	static sortStringItemsinList(items: any) {
		return items.sort((a, b) => a.display.localeCompare(b.display));
	}

	static getProviderTypeList(): Selection[] {
		return [
			{id: 'physician', display: 'Physician'},
			{id: 'facility', display: 'Facility'},
			{id: 'homehealth', display: 'Home Health'},
			{id: 'hospice', display: 'Hospice'},
			{id: 'skillednursing', display: 'Skilled Nursing'}
		];
	}

	static getAgencyProviderTypeList(): Selection[] {
		return [
			{id: 'homehealth', display: 'Home Health'},
			{id: 'hospice', display: 'Hospice'},
			{id: 'skillednursing', display: 'Skilled Nursing'}
		];
	}

	static formatNumber(num: number): string {
		return num.toLocaleString();
	}

	static formatDateValue(date: Date) {
		return date ? dayjs(date).format('MMMM Do YYYY, h:mm:ss a') : 'N/A';
	}

	static convertFileToBase64(file: File): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result.toString());
			reader.onerror = error => reject(error);
		});
	}
}
