import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import $ from 'jquery';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ComponentWithSubscription } from '../../components/component-with-subscription';
import { SearchItem } from '../../models/search/search-item.model';
import { NpiSearchResult } from '../../models/npi/npi-search-result.model';

@Component({
    selector: 'trella-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent extends ComponentWithSubscription implements OnInit, OnChanges {
    @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;
    @ViewChild('searchInput') searchInput: ElementRef;
    
    @Input() searchResults: SearchItem[];
    @Input() showSpinner: boolean;
    @Input() removeOnSelect = true;
    @Input() leaveOpen = true;
    @Input() makeSticky = false;
    @Input() searchPlaceholderText = 'Search by NPI Number or Name';
    @Input() showOutline = false;
    @Input() showSpecialty = false;
    @Input() physSpecalties: object;
    
    @Output() specialtyChange: EventEmitter<string> = new EventEmitter();
    @Output() onError: EventEmitter<void> = new EventEmitter();
    @Output() onSearch: EventEmitter<any> = new EventEmitter();
    @Output() onItemSelect: EventEmitter<NpiSearchResult> = new EventEmitter();

    inputChangeSub = new Subject<string>();
    myControl = new UntypedFormControl();
    searchText: string;
    selectedPhysSpecialty = '';

    constructor(private element: ElementRef) {
        super();
    }

    get rightSpinnerElement() {
        return $(this.element.nativeElement).find('.right-spinner');
    }
    
    ngOnInit() {

        this.inputChangeSub.pipe(takeUntil(this.ngUnsubscribe), debounceTime(500)).subscribe(
            async search => {
                search = search.trim();
                if (!search) 
                    return;
                
                this.showSpinner = true;
                await this.onInputChangeInternal(search);
            },
            err => this.onError.emit()
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        this.showSpinner = false;
    }

    onChange(search: string) {
        this.searchText = search;
        this.inputChangeSub.next(search);
    }

    stopSpinner() {
        this.showSpinner = false;
    }

    private async onInputChangeInternal(search: string) {
        this.onSearch.emit(search);
    }


    selectItem(event: MatAutocompleteSelectedEvent) {
        const info = event.option.value;

        this.myControl.setValue(this.searchText);
        if (this.removeOnSelect) 
            this.searchResults = this.searchResults.filter(x => x !== info); // TODO: change to remove
        
        this.onItemSelect.emit(info);
        if (this.leaveOpen) 
            setTimeout(() => this.resetAutoCompleteState(), 1);
         else {
            // hide list AND blur the selection component
            setTimeout(() => this.blurAfterSelect(), 1);
        }
    }

    blurAfterSelect() {
        this.searchInput.nativeElement.blur();
    }

    resetAutoCompleteState() {
        this.autoComplete.openPanel();
    }

    onSpecialtyChange() {
        this.specialtyChange.emit(this.selectedPhysSpecialty);
        this.searchResults = [];
        if (!this.searchText) 
            return;
        
        this.showSpinner = true;
        this.inputChangeSub.next(this.searchText);
        this.autoComplete.openPanel();
    }
}
