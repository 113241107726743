<trella-confirmation-dialog (okPressed)="onMyLocationSaved()"
                            [config]="confirmDialogConfig"></trella-confirmation-dialog>
<trella-hover-tooltip [position]="'top'" [tooltipTemplate]="DefinitionTemplate" [underline]="false">
    <div (click)="toggleFilter()"
         class="btn text-input d-flex flex-row align-items-center flex-nowrap btn-outline-primary">
		<span class="me-3">
			{{ getTitle }} <span *ngIf="getCount">({{ getCount }})</span>
		</span>
        <i [class.fa-chevron-down]="isOpen" [class.fa-chevron-right]="!isOpen" class="fas"></i>
    </div>
</trella-hover-tooltip>
<ng-template #DefinitionTemplate>
    <div class="p-1 text-center">
        {{ getDefinition }}
    </div>
</ng-template>

<div *ngIf="isOpen" class="location-filter w-100">
    <trella-spinner [isFullDiv]="true" [show]="isMyLocationsLoading"></trella-spinner>
    <div class="row g-0 border rounded location-wrapper p-2">
        <div class="col">
            <div class="row limit-location-height">
                <div *ngIf="hasCheckedNodes" class="col-auto text-heading pe-3">
                    Selected Locations
                </div>

                <div *ngIf="hasMarkets" class="col-auto px-3">
                    <div class="row">
                        <div class="col text-heading">
                            Markets
                        </div>
                    </div>

                    <div *ngFor="let node of selectedMarkets" class="row">
                        <div (click)="selectAndToggleNode(node)" class="col-auto text-data pointer">{{ node.data }}
                        </div>
                        <div class="col-auto ms-2 position-relative">
                            <i (click)="selectAndToggleNode(node)"
                               class="fas fa-times position-absolute close-icon pointer"></i>
                        </div>
                    </div>
                </div>

                <div *ngIf="hasCounties" class="col-auto px-3">
                    <div class="row">
                        <div class="col text-heading">
                            Counties
                        </div>
                    </div>

                    <div *ngFor="let node of selectedCounties" class="row">
                        <div (click)="selectAndToggleNode(node)" class="col-auto text-data pointer">{{ node.data }}
                        </div>
                        <div class="col-auto ms-2 position-relative">
                            <i (click)="selectAndToggleNode(node)"
                               class="fas fa-times position-absolute close-icon pointer"></i>
                        </div>
                    </div>
                </div>

                <div *ngIf="hasCities && !stateCountyOnly" class="col-auto px-3">
                    <div class="row">
                        <div class="col text-heading">
                            Cities
                        </div>
                    </div>

                    <div *ngFor="let node of selectedCities" class="row">
                        <div (click)="selectAndToggleNode(node)" class="col-auto text-data pointer">{{ node.data }}
                        </div>
                        <div class="col-auto ms-2 position-relative">
                            <i (click)="selectAndToggleNode(node)"
                               class="fas fa-times position-absolute close-icon pointer"></i>
                        </div>
                    </div>
                </div>

                <div *ngIf="hasZips && !stateCountyOnly" class="col-auto px-3">
                    <div class="row">
                        <div class="col text-heading">
                            ZIP Codes
                        </div>
                    </div>

                    <div *ngFor="let node of selectedZips" class="row">
                        <div (click)="selectAndToggleNode(node)" class="col-auto text-data pointer">{{ node.data }}
                        </div>
                        <div class="col-auto ms-2 position-relative">
                            <i (click)="selectAndToggleNode(node)"
                               class="fas fa-times position-absolute close-icon pointer"></i>
                        </div>
                    </div>
                </div>

                <div class="col-auto px-3 ms-auto my-location-controls">
                    <button (click)="openSaveMyLocation()" [disabled]="!hasCheckedNodes || isMyLocationActivated"
                            class="btn btn-primary">
                        Save As My Location
                    </button>
                    <div *ngIf="hasMyLocations" class="form-check form-switch">
                        <input
                                (click)="onMyLocationClicked()"
                                [(ngModel)]="isMyLocationActivated"
                                class="form-check-input pointer"
                                id="ViewMyLocation"
                                role="switch"
                                type="checkbox"
                        />
                        <label class="form-check-label pointer" for="ViewMyLocation" style="max-height: 30px;">My
                            Location</label>
                    </div>
                </div>
            </div>

            <div class="g-0 border rounded location-wrapper">
                <div class="row g-0">
                    <input
                            (keyup)="onSearchKeyup($event)"
                            [(ngModel)]="searchQuery"
                            [placeholder]="searchPlaceholderText"
                            class="form-control w-100"
                    />
                </div>

                <div *ngIf="filteredLocations && filteredLocations.length" class="g-0 border location-search-box">
                    <div *ngFor="let l of filteredLocations" class="location-search-item border-top p-2 pointer">
                        <div (click)="handleSearchResultSelection(l)" [innerHTML]="l.html"></div>
                    </div>
                </div>

                <div *ngIf="!filteredLocations.length">
                    <div
                            #scrollLocationTop
                            (scroll)="scrollLocation()"
                            *ngIf="!stateCountyOnly"
                            class="row g-0 border border-top-0 flex-nowrap location-menu-header"
                    >
                        <div class="col-auto list-width text-center">
                            State
                        </div>
                        <div class="col-auto list-width text-center border-left">
                            County
                        </div>
                        <div class="col-auto list-width text-center border-left">
                            City
                        </div>
                        <div class="col-auto list-width text-center border-left">
                            ZipCode
                        </div>
                    </div>

                    <div
                            #scrollLocationTop
                            (scroll)="scrollLocation()"
                            *ngIf="stateCountyOnly"
                            class="row g-0 border border-top-0 flex-nowrap location-menu-header"
                    >
                        <div class="col-auto w-50 text-center">
                            State
                        </div>
                        <div class="col-auto w-50 text-center border-left">
                            County
                        </div>
                    </div>

                    <div
                            #scrollLocationBottom
                            *ngIf="locationTree.selectedNodes"
                            [(searchContainer)]="searchQuery"
                            class="row g-0 border border-top-0 flex-nowrap location-menu-container"
                    >
                        <div
                                *ngFor="let nodeList of locationTree.visibleLoctionMenu"
                                [class.list-width]="nodeList && nodeList.length && !stateCountyOnly"
                                [class.w-50]="nodeList && nodeList.length && stateCountyOnly"
                                class="col-auto"
                        >
                            <div *ngIf="nodeList && nodeList.length" class="row g-0 location-search-box">
                                <div class="col">
                                    <div
                                            *ngFor="let n of nodeList"
                                            [class.active-selection]="isSelectedNode(n)"
                                            class="row align-items-center pointer px-2"
                                    >
                                        <div class="col-auto">
                                            <mat-checkbox (change)="selectAndToggleNode(n)" [checked]="n.checked"
                                                          color="primary">
                                            </mat-checkbox>
                                        </div>
                                        <div (click)="selectNode(n)" class="col">{{ n.data }}</div>
                                        <div class="col-auto flex-center">
                                            <i class="fas fa-caret-right text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
