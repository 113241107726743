<kendo-dialog (close)="cancel()" *ngIf="opened" [minWidth]="600">
    <kendo-dialog-titlebar>
        <div class="d-flex flex-row align-items-center justify-content-center text-white">
            <i class="fas fa-repeat mx-3"></i>
            <div class="text-heading">Select {{ title }}</div>
        </div>
    </kendo-dialog-titlebar>

    <div class="row px-4">
        <div class="col">
            <div class="row mt-3">
                <div class="col text-label">Select up to {{ max }} {{ title }} for comparison:</div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <trella-search
                            (onItemSelect)="selectNpi($event)"
                            (onSearch)="search($event)"
                            (specialtyChange)="removeUnmatchedSpecialties($event)"
                            [physSpecalties]="physSpecalties"
                            [searchPlaceholderText]="'Search by Location, Name, or NPI'"
                            [searchResults]="npiSearchResults"
                            [showOutline]="true"
                            [showSpecialty]="showSpecialty()"
                    ></trella-search>
                </div>
            </div>

            <div class="row mt-3 pt-2 border-top">
                <div class="col d-flex flex-column fa-xs">
                    <span class="text-label">Selected {{ title }} ({{ committedComparisons.length }})</span>
                    <div [style.height]="height" class="border rounded">
                        <cdk-virtual-scroll-viewport [style.height]="height" itemSize="25">
                            <div *ngFor="let npi of committedComparisons">
                                <div class="ps-1">
                                    <mat-list-item>
                                        <div class="row justify-content-between p-1">
                                            <div class="col-md-auto">
                                                {{ npi.npi }} - {{ npi.display }} {{ npi.city }} {{ npi.state }}
                                                {{ showSpecialty() ? npi.specialty : '' }}
                                            </div>
                                            <div (click)="removeFromList(npi)" class="col-md-auto pointer">
                                                <i class="far fa-trash"></i>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </div>
                </div>
            </div>

            <div class="row g-0 mt-5">
                <div class="col text-danger">{{ warning }}</div>
                <div class="col-auto">
                    <button (click)="cancel()" class="btn btn-light" type="button">Cancel</button>
                </div>
                <div class="col-auto ms-2">
                    <button (click)="ok()" [disabled]="!canSave" class="btn btn-success" type="button">Apply</button>
                </div>
            </div>
        </div>
    </div>
</kendo-dialog>
