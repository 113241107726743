import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvService } from '@appcore/services/env.service';


export interface HttpOptions {
	skipStringify?: boolean;
	headers?: {
		[name: string]: any
	};
	responseType?: any;
}

interface HttpClientOptions {
	headers?: HttpHeaders | {
		[header: string]: string | string[];
	};
	params?: HttpParams | {
		[param: string]: string | string[];
	};
	reportProgress?: boolean;
	withCredentials?: boolean;
	responseType?: any;
}

@Injectable({
	providedIn: 'root'
})
export class HttpRequest {

	standardRequestHeaders = [
		{key: 'Cache-Control', value: 'no-cache'},
		{key: 'Pragma', value: 'no-cache'},
		{key: 'Expires', value: 'Sat, 01 Jan 2000 00: 00: 00 GMT'},
		{key: 'Content-Type', value: 'application/json'}
	];

	overrideRequestHeaders = [
		{key: 'X-HTTP-Method-Override', value: 'GET'}
	];

	constructor(private http: HttpClient, private environmentService: EnvService) {
		this.handleError = this.handleError.bind(this);
	}

	get(url: string, getParams?: any, useHttpOverride?: boolean): Observable<any> {
		if (useHttpOverride) {
			// We want to send this in as a POST request wth a body, but let the server know this is actually a get
			return this.getWithHttpOverride(url, getParams);
		}

		let headers = new HttpHeaders();

		this.standardRequestHeaders.forEach(value => {
			headers = headers.set(value.key, value.value);
		});

		if (!getParams || isEmpty(getParams)) {
			return this.wrapObservable(this.http.get(url, {
				headers,
				withCredentials: true
			}));
		}

		let params: HttpParams = new HttpParams();
		for (const key in getParams) {
			if (getParams.hasOwnProperty(key)) 
				params = params.set(key, getParams[key]);
			
		}
		return this.wrapObservable(this.http.get(url, {
			headers,
			params,
			withCredentials: true
		}));
	}

	post(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.post(url, params, clientOptions));
	}

	patch(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.patch(url, params, clientOptions));
	}

	put(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.put(url, params, clientOptions));
	}

	delete(url: string, options?: HttpOptions): Observable<any> {
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.delete(url, clientOptions));
	}

	getUrl(baseUrl: string, route: string) {
		let url = this.environmentService.apiUrl;

		if (baseUrl) 
			url += `/${baseUrl}`;
		

		if (route) 
			url += `/${route}`;
		

		return url;
	}

	private getWithHttpOverride(url: string, getParams?: any) {
		let headers = new HttpHeaders();

		const allHeaders = this.standardRequestHeaders.concat(this.overrideRequestHeaders);
		allHeaders.forEach(value => {
			headers = headers.set(value.key, value.value);
		});

		const clientOptions = this.getClientOptions();
		clientOptions.headers = headers;

		return this.wrapObservable(this.http.post(url, getParams, clientOptions));
	}

	private getClientOptions(options?: HttpOptions): HttpClientOptions {
		const props: HttpClientOptions = {
			withCredentials: true
		};

		let headers = new HttpHeaders();

		this.standardRequestHeaders.forEach(value => {
			headers = headers.set(value.key, value.value);
		});

		if (options && options.headers) {
			Object.keys(options.headers).forEach(k => {
				headers = headers.set(k, options.headers[k]);
			});
		}
		props.headers = headers;

		if (options && (options.responseType || options.responseType === 0)) 
			props.responseType = options.responseType;
		

		return props;
	}

	private wrapObservable(observable: Observable<any>): Observable<any> {
		return observable
			.pipe(
				catchError(this.handleError)
			);
	}

	private handleError(error: HttpErrorResponse) {
		let problemDetails = 'An unexpected error has occurred.';
		if (error?.error?.detail != null) {
			try {
				const parsedProblemDetails = JSON.parse(problemDetails);
				problemDetails = parsedProblemDetails.detail;
			} catch (e) {
				problemDetails = error.error.detail;
			}
		}
		return throwError(() => new Error(problemDetails));
	}
}
