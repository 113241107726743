<div class="row g-0 p-3">
	<div class="col-auto mx-auto">

		<div class="row">
			<div class="col cover-page-width">
				<div class="row">
					<div class="col-auto logo ">
						<img class="img-fluid" src="../../assets/img/TrellaLogo.svg"/>
					</div>
				</div>

				<div class="row">
					<div class="col col pt-5 fa-15x message-text">
						<p class="text-primary">Are you using the right account?</p>
						<p class="text-primary">You are logged in with your Trella Health account as</p>
						<p class="text-primary"><strong>{{ username }}</strong></p>
						<p class="text-primary">but you do not have access to this page.</p>
						<button (click)="toPrevious()" class="btn btn-primary text-heading" type="button">Go Back
						</button>
						<button (click)="logout()" class="btn btn-link text-underline text-heading" type="button">Log in
							with a different account
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
