import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	ExistingNpiGroupInfo,
	ImportFileRequest,
	ImportNpiGroup,
	NewNpiGroupInfo,
	NpiGroup,
	NpiGroupType,
	NpiGroupVerification
} from '../shared/models/npi-group';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class NpiGroupApi {

	private _controllerName = 'NpiGroups';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	get(id: any): Observable<NpiGroup> {
		return this.api.get(this._controllerName, `get/${id}`);
	}

	create(npiGroup: NpiGroup): Observable<any> {
		return this.api.post(this._controllerName, 'admin', npiGroup);
	}

	update(npiGroup: NpiGroup): Observable<any> {
		return this.api.post(this._controllerName, 'admin/modify', npiGroup);
	}

	delete(npiGroupId: any): Observable<boolean> {
		if (!npiGroupId) 
			throw new Error('NPI Group Id Required');
		

		return this.api.delete(this._controllerName, `${npiGroupId}`);
	}

	getNpiGroupTypes(): Observable<NpiGroupType[]> {
		return this.api.get(this._controllerName, 'npigrouptypes');
	}

	getMyAgencies(): Observable<Array<NpiGroup>> {
		return this.api.get(this._controllerName, 'myAgencies');
	}

	getAvailableNpiGroups(companyId: string): Observable<ImportNpiGroup[]> {
		return this.api.get(this._controllerName, `company/${companyId}`);
	}

	verifyExisting(existingNpiGroupInfo: ExistingNpiGroupInfo): Observable<NpiGroupVerification> {
		return this.api.post(this._controllerName, 'importexistingverify', existingNpiGroupInfo);
	}

	verifyNew(newNpiGroupInfo: NewNpiGroupInfo): Observable<NpiGroupVerification> {
		return this.api.post(this._controllerName, 'admin/importnewverify', newNpiGroupInfo);
	}

	finalizeImport(fileRequest: ImportFileRequest): Observable<boolean> {
		return this.api.post(this._controllerName, 'finalizeimport', fileRequest);
	}

	cleanupImport(fileRequest: ImportFileRequest): Observable<boolean> {
		return this.api.post(this._controllerName, 'importcleanup', fileRequest);
	}
}
