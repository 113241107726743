import { Selection } from '../../interfaces/selection.interface';

declare global {
    interface Array<T> {
        /**
         * Returns the difference between the current selection array and the comparison selection array.
         * It filters out elements from the current selection array that have the same `id` as any element in the comparison selection array.
         *
         * @param comparisonList - The selection array to compare against.
         * @returns A new selection array containing elements from the original array that are not present in the comparison selection array.
         */
        getDifference<T extends Selection>(comparisonList: T[]): T[];

        /**
         * Returns the selection array sorted by the `display` property.
         * It sorts selection array in alphabetical order.
         *
         * @returns A new selection array containing elements from the original array sorted in alphabetical order by the `display` property.
         */
        sortByDisplay<T extends Selection>(): T[];
    }
}

Array.prototype.getDifference = function <T extends Selection>(this: T[], comparisonList: T[]): T[] {
    const idsInComparisonList = new Set(comparisonList.map(item => item.id));
    return this.filter(item => !idsInComparisonList.has(item.id));
};

Array.prototype.sortByDisplay = function <T extends Selection>(): T[] {
    return this.sort((a, b) => a.display.localeCompare(b.display));
};
