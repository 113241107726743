import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Selection } from '../../interfaces/selection.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { KeyboardEventService } from '../../services/keyboard-event.service';

@Component({
    selector: 'trella-dual-list-box',
    templateUrl: './dual-list-box.component.html',
    styleUrls: ['./dual-list-box.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DualListBoxComponent,
            multi: true
        }
    ]
})
export class DualListBoxComponent implements OnInit, ControlValueAccessor {
    @Input() activeSelectionsTitle = '';
    @Input() addAll = true;
    @Input() availableSelectionsTitle = '';
    @Input() height = '200px';
    @Input() isRequired = false;
    @Input() searchable = true;
    @Output() activeSelectionsChanged: EventEmitter<Selection[]> = new EventEmitter();
    @Output() change: EventEmitter<Selection[]> = new EventEmitter();
    activeSelectionsSearchQuery: string;
    availableSelectionsSearchQuery: string;
    highlightedActiveSelections: Selection[] = [];
    highlightedAvailableSelections: Selection[] = [];
    private _ctrlKeyIsPressed = false;
    private _currentShiftHighlightedActiveSelections: Selection[] = [];
    private _currentShiftHighlightedAvailableSelections: Selection[] = [];
    private _lastClickedActiveSelection: Selection;
    private _lastClickedAvailableSelection: Selection;
    private _shiftKeyIsPressed = false;
    private _activeSelections: Selection[] = [];
    private _availableSelections: Selection[] = [];

    constructor(private keyboardEventService: KeyboardEventService) {
    }

    @Input()
    get activeSelections(): Selection[] {
        return this._activeSelections;
    }

    set activeSelections(selections: Selection[]) {
        this._activeSelections = selections.sortByDisplay();
    }

    @Input()
    get availableSelections(): Selection[] {
        return this._availableSelections;
    }

    set availableSelections(selections: Selection[]) {
        this._availableSelections = selections.sortByDisplay();
    }

    ngOnInit() {
        this.keyboardEventService.ctrl$.subscribe(ctrl => this._ctrlKeyIsPressed = ctrl);
        this.keyboardEventService.shift$.subscribe(shift => {
            if (!shift) {
                this._currentShiftHighlightedActiveSelections = [];
                this._currentShiftHighlightedAvailableSelections = [];
            }
            this._shiftKeyIsPressed = shift;
        });
    }

    highlightActiveSelections(selection: Selection) {
        this.onTouch();
        if (!this._shiftKeyIsPressed && !this._ctrlKeyIsPressed) {
            this.highlightedActiveSelections = [selection];
            this._lastClickedActiveSelection = selection;
            return;
        }
        if (this._ctrlKeyIsPressed) {
            this.highlightedActiveSelections = this.ctrlSelect(selection, this.highlightedActiveSelections);
            this._lastClickedActiveSelection = selection;
            return;
        }

        this.highlightedActiveSelections = this.highlightedActiveSelections.getDifference(this._currentShiftHighlightedActiveSelections);
        this._currentShiftHighlightedActiveSelections = this.shiftSelect(selection, this.activeSelections, this._lastClickedActiveSelection);
        this.highlightedActiveSelections = [...new Set([...this.highlightedActiveSelections, ...this._currentShiftHighlightedActiveSelections])];
        if (this.highlightedActiveSelections.length === 1) 
            this._lastClickedActiveSelection = selection;
        
    }

    highlightAvailableSelections(selection: Selection) {
        this.onTouch();
        if (!this._shiftKeyIsPressed && !this._ctrlKeyIsPressed) {
            this.highlightedAvailableSelections = [selection];
            this._lastClickedAvailableSelection = selection;
            return;
        }
        if (this._ctrlKeyIsPressed) {
            this.highlightedAvailableSelections = this.ctrlSelect(selection, this.highlightedAvailableSelections);
            this._lastClickedAvailableSelection = selection;
            return;
        }

        this.highlightedAvailableSelections = this.highlightedAvailableSelections.getDifference(this._currentShiftHighlightedAvailableSelections);
        this._currentShiftHighlightedAvailableSelections = this.shiftSelect(selection, this.availableSelections, this._lastClickedAvailableSelection);
        this.highlightedAvailableSelections = [...new Set([...this.highlightedAvailableSelections, ...this._currentShiftHighlightedAvailableSelections])];
        if (this.highlightedAvailableSelections.length === 1) 
            this._lastClickedAvailableSelection = selection;
        
    }

    moveAllToActiveSelections() {
        this.highlightedActiveSelections = [];
        this.highlightedAvailableSelections = [];
        this.setSelections(this.activeSelections.concat(this.availableSelections), []);
    }

    moveAllToAvailableSelections() {
        this.highlightedActiveSelections = [];
        this.highlightedAvailableSelections = [];
        this.setSelections([], this.availableSelections.concat(this.activeSelections));
    }

    moveToActiveSelections() {
        if (!this.highlightedAvailableSelections.length) 
            return;
        
        const active = this.activeSelections.concat(this.highlightedAvailableSelections);
        const available = this.availableSelections.filter(item => !this.highlightedAvailableSelections.includes(item));
        this.highlightedAvailableSelections = [];
        this.setSelections(active, available);
    }

    moveToAvailableSelections() {
        if (!this.highlightedActiveSelections.length) 
            return;
        
        const active = this.activeSelections.filter(item => !this.highlightedActiveSelections.includes(item));
        const available = this.availableSelections.concat(this.highlightedActiveSelections);
        this.highlightedActiveSelections = [];
        this.setSelections(active, available);
    }

    onChange: (newValue: Selection[]) => void = () => {
    };

    onTouch: () => void = () => {
    };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(selection: Selection[]): void {
        if (selection)
            this.activeSelections = selection;
    }

    private ctrlSelect(selection: Selection, highlightedSelections: Selection[]) {
        const highlightedSelectionIdSet = new Set<any>(highlightedSelections.map(x => x.id));
        if (!highlightedSelectionIdSet.has(selection.id)) {
            highlightedSelections.push(selection);
            highlightedSelectionIdSet.add(selection.id);
        } else {
            highlightedSelections = highlightedSelections.filter(s => s.id !== selection.id);
            highlightedSelectionIdSet.delete(selection.id);
        }
        return highlightedSelections;
    }

    private setSelections(active: Selection[], available: Selection[]) {
        this.activeSelections = active.sortByDisplay();
        this.availableSelections = available.sortByDisplay();
        this.onChange(this.activeSelections);
        this.onTouch();
        this.activeSelectionsChanged.emit(this.activeSelections);
        this.change.emit(this.activeSelections);
    }

    private shiftSelect(selection: Selection, selections: Selection[], firstShiftClickedSelection: Selection) {
        if (!firstShiftClickedSelection || selection.id === firstShiftClickedSelection.id) 
            return [selection];
        
        const highlightedSelections = [];
        const startIndex = selections.indexOf(firstShiftClickedSelection);
        const endIndex = selections.indexOf(selection);
        const lowestIndex = Math.min(startIndex, endIndex);
        const highestIndex = Math.max(startIndex, endIndex);
        for (let i = lowestIndex; i <= highestIndex; i++) 
            highlightedSelections.push(selections[i]);
        
        return highlightedSelections;
    }

}
