import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FILTER_TYPE } from '../enums/filter-type.enum';

@Injectable({
    providedIn: 'root'
})
export class FilterGroupService {
    public filterDisabled: Observable<FILTER_TYPE>;
    public filterEnabled: Observable<FILTER_TYPE>;
    public filtersToggled: Observable<FILTER_TYPE>;
    public filtersClosed: Observable<any>;
    public resetRequested: Observable<void>;
    
    private _filterDisable = new Subject<FILTER_TYPE>();
    private _filterEnable = new Subject<FILTER_TYPE>();
    private _filtersToggled = new Subject<FILTER_TYPE>();
    private _filtersClosed = new Subject();
    private _resetRequested = new Subject<void>();

    constructor() {
        this.filterDisabled = this._filterDisable.asObservable();
        this.filterEnabled = this._filterEnable.asObservable();
        this.filtersToggled = this._filtersToggled.asObservable();
        this.filtersClosed = this._filtersClosed.asObservable();
        this.resetRequested = this._resetRequested.asObservable();
    }
    disableFilter(filterType: FILTER_TYPE) {
        this._filterDisable.next(filterType);
    }

    enableFilter(filterType: FILTER_TYPE) {
        this._filterEnable.next(filterType);
    }

    resetFilters() {
        this.toggleFilters(null);
        this._resetRequested.next();
    }

    toggleFilters(filterType: FILTER_TYPE) {
        this._filtersToggled.next(filterType);
    }

    closeFilters() {
        this._filtersToggled.next(null);
    }
}
