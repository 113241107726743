<div class="row g-0 p-3">
	<div class="col-auto mx-auto">
		<div class="row">
			<div class="col cover-page-width">
				<div class="row">
					<div class="col-auto logo text-center mb-2">
						<img class="img-fluid" src="../../assets/img/logo.jpg"/>
					</div>
				</div>
				<div class="row">
					<div class="col-auto pt-3 login-container">
						<form (ngSubmit)="login()" class="text-center">
							<div class="text-center fw-bold mt-4">
								Please enter your email address
							</div>
							<div *ngIf="message" class="text-center text-danger">
								{{ message }}
							</div>
							<div class="input-group mt-4">
								<div class="input-group-prepend">
									<i class="input-group-text fa fa-user text-secondary"></i>
								</div>
								<input [(ngModel)]="username" autofocus class="form-control loginTextBox" id="username"
									   name="username"
									   placeholder="Email Address" type="text"/>
							</div>

							<button class="btn btn-primary btn-block mt-5" id="login" type="submit">Continue to Log In
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
