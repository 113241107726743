import { Injectable } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable } from 'rxjs';
import { Keyed } from '../models/keyed';
import { GridSortEvent } from '../models/grid/grid-sort-event.model';

@Injectable({
    providedIn: 'root'
})
export class GridSortService {
    readonly sortRequested: Observable<Keyed<GridSortEvent>>;
    private _sortRequested = new BehaviorSubject<Keyed<GridSortEvent>>(null);
    
    constructor() {
        this.sortRequested = this._sortRequested.asObservable();
    }

    sort(key: string, sort: SortDescriptor[], isRefreshRequested = false) {
        this._sortRequested.next({value: {sortDescriptors: sort, isRefreshRequested}, key});
    }
}
