import { Component, Input } from '@angular/core';
import { LegacyGridInfo } from '../../shared/models/legacy-grid-info';

@Component({
	selector: 'element-notes',
	templateUrl: './element-notes.component.html',
	styleUrls: ['./element-notes.component.scss']
})
export class ElementNotesComponent {
	@Input() gridInfo: LegacyGridInfo;

	constructor() {
	}

	get isPostNoteNewLine() {
		return this.gridInfo && this.gridInfo.postNotes && this.gridInfo.postNotes.newline;
	}

	get PostNoteTitle() {
		return this.gridInfo && this.gridInfo.postNotes && `${this.gridInfo.postNotes.title}:`;
	}

	get isMultiplePostNotes() {
		return this.gridInfo && this.gridInfo.postNotes && this.gridInfo.postNotes.details && this.gridInfo.postNotes.details.length > 1;
	}
}
