import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NavigationService } from '../shared/services/navigation.service';

@Component({
	selector: 'app-crm-logout',
	templateUrl: './crmlogout.component.html'
})
export class CrmLogoutComponent implements OnInit {
	constructor(private authService: AuthenticationService, private navigationService: NavigationService) {
	}

	/*
		This is a stub component.
		Amazon's Auth library FORCES a redirect. There isn't a way around it
		So this is the stub component PAGE they can land on to log people out
	*/
	ngOnInit(): void {
		try {
			this.authService.logout().then(() => this.navigationService.navigateToLogOut());
		} catch (e) {
			throw Error(e);
		}
	}
}
