<div [class.disabled]=isDisabled class="row g-0">
	<div class="col d-flex flex-column">
		<input (keyup)="filterList()" [(ngModel)]="_userSearchString" [disabled]="disabled"
			   class="from-control w-100 search-input" type="text"/>
		<div class="border rounded ps-2">
			<cdk-virtual-scroll-viewport [style.height]="height" itemSize="25">
				<div *cdkVirtualFor="let opt of displayFilteredList">
					<mat-checkbox (change)="toggleVal(opt)" [checked]="isChecked(opt)" [disabled]="disabled"
								  class="text-input" color="primary">{{ opt.display }}
					</mat-checkbox>
				</div>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
</div>
