<trella-alert-dialog></trella-alert-dialog>
<div class="row div-overlay-wrapper g-0 w-100">
	<div class="col">
		<span
			*ngIf="selectedGridInfo && selectedGridInfo.displayName && !selectedGridInfo.hideDisplayName && !hideDisplayName"
			[ngClass]="{'text-disable' : disabled}"
			class="fw-bold ms-4 mb-2 text-diabled"
		>
			{{ selectedGridInfo.displayName }}
		</span>
		<trella-multi-grid
			[configs]="configs"
			[dataChange]="onDataChange"
			[disabled]="disabled"
			[filters]="filterOptions"
			[hasShowSelectedToggle]="hasShowSelectedToggle"
			[isLoading]="loading"
			[key]="key"
			[searchPlaceholderText]="searchPlaceholderText">
			>
		</trella-multi-grid>
	</div>
</div>
