import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { ProductApi } from 'src/app/api/product.api';
import { Product } from '@appcore/models/directory/product.model';

@Injectable({
	providedIn: 'root'
})

export class ProductService {
	private _products: Product[] = [];
	constructor(private productApi: ProductApi) {
	}

	public getAllProducts(forceRefresh = false): Observable<Product[]> {
		if (this._products.length && !forceRefresh)
			return of(this._products);

		return this.productApi.getProducts().pipe(
			tap(products => {
				this._products = products;
			}));
	}
}
