// TODO: Deprecate PROVIDER_TYPE. We only have Claim Types and Npi Types now.

export enum PROVIDER_TYPE {
    alfltc = 'alfltc',
    facility = 'facility',
    homehealth = 'homehealth',
    hospice = 'hospice',
    hospital = 'hospital',
    noProvider = 'noProvider',
    organization = 'organization',
    physician = 'physician',
    physicianGroup = 'physiciangroup',
    skilledNursing = 'skillednursing'
}
