<div [ngClass]="{ 'sticky-top': makeSticky }" class="row pt-2">
    <mat-form-field [appearance]="showOutline ? 'outline' : 'fill'" class="col">
        <mat-label><i class="fa fa-search pe-1"></i>{{ searchPlaceholderText }}</mat-label>
        <input
            #searchInput
            (input)="onChange($event.target.value)"
            [formControl]="myControl"
            [matAutocomplete]="auto"
            aria-label="Number"
            matInput
            type="text"
        />
        <mat-progress-bar [hidden]="!(showSpinner && showSpecialty)" mode="indeterminate"></mat-progress-bar>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectItem($event)">
            <mat-option *ngFor="let option of searchResults" [value]="option">
                <div class="d-flex flex-row">
                    <i class="{{ option.icon }} pt-2 px-2" height="25"></i>
                    <div>
                        <div>
                            <span>{{ option.display }}</span>
                        </div>
                        <div>
                            <small
                            ><span *ngIf="!option.isAco()">{{ option.city }}
                                ,</span> {{ option.state | textTruncate: [22] }}<span
                                    *ngIf="!option.isAco()"> - {{ option.npi || option.id }}</span></small
                            >
                        </div>
                    </div>
                </div>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <trella-spinner *ngIf="!showSpecialty" [show]="showSpinner" class="col-2"></trella-spinner>
    <div *ngIf="showSpecialty" class="col-4">
        <select (change)="onSpecialtyChange()" [(ngModel)]="selectedPhysSpecialty" class="form-select specialty-select">
            <option [ngValue]="''" selected="true">Any Specialty</option>
            <option *ngFor="let specialty of physSpecalties" [ngValue]="specialty">{{ specialty }}</option>
        </select>
    </div>
</div>
