<trella-hover-tooltip [position]="'top'" [tooltipTemplate]="DefinitionTemplate" [underline]="false">
    <div (click)="toggleFilter()"
         class="btn text-input d-flex flex-row align-items-center flex-nowrap btn-outline-primary">
	<span class="me-3">
		{{ getTitle }} <span *ngIf="getCount">({{ getCount }})</span>
	</span>
        <i [class.fa-chevron-down]="isOpen" [class.fa-chevron-right]="!isOpen" class="fas"></i>
    </div>
</trella-hover-tooltip>
<ng-template #DefinitionTemplate>
    <div class="p-1 text-center">
        {{ getDefinition }}
    </div>
</ng-template>

<div *ngIf="isOpen" class="affiliation-filter">
    <trella-list-box (userChange)="handleSelectedAffiliationsChange()" [options]="selectedAffiliations.items"
                     height="200px"></trella-list-box>
    <div class="range-filter d-flex flex-row justify-content-center align-items-center">
        <div class="d-flex flex-row w-100 ps-2">
            <div class="range-filter-title d-flex align-items-center">
                {{ rangeFilterName }}
            </div>
            <div class="d-flex flex-column w-100 pe-4 align-items-center justify-content-center">
                <div class="w-100 d-flex flex-row justify-content-between">
                    <div>
                        {{ selectedAffiliations.range.minSelected }}
                    </div>
                    <div>
                        {{ selectedAffiliations.range.maxSelected }}
                    </div>
                </div>
                <div class="w-100 pe-3">
                    <mat-slider
                            [max]="rangeRules.maxAllowed"
                            [min]="rangeRules.minAllowed"
                            [step]="rangeRules.step || 1"
                            class="w-100"
                            discrete
                    >
                        <input (valueChange)="handleRangeChange()" [(value)]="selectedAffiliations.range.minSelected"
                               matSliderStartThumb/>
                        <input (valueChange)="handleRangeChange()" [(value)]="selectedAffiliations.range.maxSelected"
                               matSliderEndThumb/>
                    </mat-slider>
                </div>
            </div>
        </div>
    </div>
</div>
