export enum FILTER_TYPE {
    ACOS = 'Acos',
    ACO_BENEFICIARY_LOCATION = 'AcoBeneficiaryLocation',
    ACO_PROVIDER_LOCATION = 'AcoProviderLocation',
    ACTIVE = 'Active',
    ACUITY = 'Acuity',
    ADMIN_ASSIGN_ANYWHERE_PRODUCT = 'AdminAssignAnywhereProduct',
    ADMIN_COMPANY_ACCESS = 'AdminCompanyAccess',
    ADMIN_COMPANY_ENABLED = 'AdminCompanyEnabled',
    ADMIN_COMPANY_ID = 'AdminCompanyId',
    ADMIN_COMPANY_MARKET = 'AdminCompanyMarket',
    ADMIN_COMPANY_MODULE = 'AdminCompanyModule',
    ADMIN_NPI_GROUP_TYPE = 'AdminNpiGroupType',
    ADMIN_REPORT_DATE = 'AdminReportDate',
    ADMIN_REPORT_END_DATE = 'AdminReportEndDate',
    ADMIN_REPORT_START_DATE = 'AdminReportStartDate',
    ADMIN_USER_ACCESS = 'AdminUserAccess',
    ADMIN_USER_ENABLED = 'AdminUserEnabled',
    ADMIN_USER_ID = 'AdminUserId',
    ADMIN_USER_MANAGER = 'AdminUserManager',
    ADMIN_USER_MARKET = 'AdminUserMarket',
    ADMIN_USER_MODULE = 'AdminUserModule',
    ADMIN_USER_ROLE = 'AdminUserRole',
    CODESET_COMPANY = 'CodesetCompany',
    CODESETSEARCH = 'CodeSetSearch',
    AGE = 'Age',
    AFFILIATIONS = 'Affiliations',
    AGENCY = 'Agency',
    AGENCY_FACILITY = 'AgencyFacility',
    AGENCY_PHYSICIAN = 'AgencyPhysician',
    ALIAS = 'Alias',
    ASSIGNED_USERS = 'AssignedUsers',
    ASSIGNMENTS = 'Assignments',
    BENEFICIARY_LOCATION = 'BeneficiaryLocation',
    BILLING_NPI = 'BillingNpi',
    BPCI = 'Bpci',
    CARE_PATH_TYPE = 'CarePathType',
    CATEGORY = 'Category',
    CHRONIC_CONDITIONS = 'ChronicConditions',
    CITY = 'City',
    CLAIM_TYPE = 'ClaimType',
    COLUMN_COMPARISON = 'ColumnComparison',
    COLUMN_RANGE_FILTER = 'ColumnRangeFilter',
    COMPETITOR_AFFILIATION = 'CompetitorAffiliation',
    COUNTY = 'County',
    CUSTOM_LIST = 'CustomList',
    CUSTOM_TAG = 'CustomTag',
    DATE_RANGE = 'DateRange',
    DRG = 'Drg',
    DYNAMIC = 'Dynamic',
    FAC_AFFILIATIONS = 'FacAffiliations',
    FACILITY_SPECIFIC_HOSPITALIZATION_RATE_HHA = 'FacilitySpecificHospRateHha',
    FACILITY_SPECIFIC_HOSPITALIZATION_RATE_HOS = 'FacilitySpecificHospRateHos',
    FACILITY_SPECIFIC_HOSPITALIZATION_RATE_SNF = 'FacilitySpecificHospRateSnf',
    FACILITY_TYPE = 'FacilityType',
    FILTER_GROUP = 'FilterGroup',
    FULL_TEXT = 'FullText',
    GRID = 'Grid',
    GROWTH_SHOW_SELECTED = 'GrowthShowSelected',
    HARD_CODED = 'HardCoded',
    HOSPITAL_DISCHARGE_SETTING = 'HospitalDischargeSetting',
    LEAD_AUDITS = 'LeadAudits',
    LEAD_GENERATOR_NPIS = 'LeadGeneratorNpis',
    MA_PAYER = 'MaPayer',
    MA_PLAN = 'MaPlan',
    MARKET = 'Market',
    MEDICAL_GROUP = 'MedicalGroup',
    MEDICARE_REIMBURSEMENT = 'MedicareReimbursement',
    METRICS = 'Metrics',
    MONTHS = 'Months',
    NETWORK = 'Network',
    NETWORK_DELTA = 'NetworkΔ',
    NETWORK_MODEL = 'NetworkModel',
    NETWORK_MODEL_MEMBERSHIP = 'NetworkModelMembership',
    NETWORK_SCOPE = 'NetworkScope',
    NETWORKS_AND_ACOS = 'NetworksAndAcos',
    NPI_LIST = 'NpiList',
    PATH_LIST = 'PathList',
    PATTERN_LIST = 'PatternList',
    PHYSICIAN_COUNTIES = 'PhysicianCounties',
    PHYSICIAN_GROUP = 'PhysicianGroup',
    PHYSICIAN_GROUP_BENEFICIARY_LOCATION = 'PhysicianGroupBeneficiaryLocation',
    PHYSICIAN_GROUP_MSSP_ACO = 'PhysicianGroupMsspAco',
    PROVIDER_LOCATION = 'ProviderLocation',
    PROVIER_TYPE = 'ProviderType',
    RANGE = 'RangeFilters',
    RATING = 'Rating',
    RISK_ADJUSTED = 'RiskAdjusted',
    RISK_CATEGORY = 'RiskCategory',
    SELF_AFFILIATION = 'SelfAffiliation',
    SPECIALTY = 'Specialty',
    SPECIALTY_AND_SUBSPECIALTIES = 'SpecialtyAndSubspecialties',
    STATE = 'State',
    STATUS = 'Status',
    TOP_DESTINATION = 'TopDestination',
    USAGE = 'Usage',
    YEAR = 'Year',
    ZIP_CODE = 'ZipCode'
}
