<kendo-chart #kendoimage (legendItemClick)="onLegendItemClick($event)" (render)="renderTarget($event); highlightColumns($event)"
             *ngIf="isValidData()" [seriesColors]="seriesColors"
             style="height: 400px;">
    <kendo-chart-tooltip [shared]="true">
        <ng-template kendoChartSharedTooltipTemplate let-category="category" let-points="points">
            <trella-chart-tooltip
                    [category]="category"
                    [chartDataType]="options.dataType"
                    [chartType]="type"
                    [data]="data.data"
                    [points]="points"
            ></trella-chart-tooltip>
        </ng-template>
    </kendo-chart-tooltip>

    <kendo-chart-category-axis>
        <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [labels]="valueAxisLabels" [title]="yAxisTitle"
                                         name="valueAxis"></kendo-chart-value-axis-item>
        </kendo-chart-value-axis>
        <kendo-chart-category-axis-item [categories]="options.labels" [title]="xAxisTitle" name="categoryAxis">
            <kendo-chart-category-axis-item-labels [rotation]="'auto'"></kendo-chart-category-axis-item-labels>
        </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-series>
        <kendo-chart-series-item
                *ngFor="let series of data.data"
                [dashType]="getLineType(series.label)"
                [data]="series.rawData"
                [name]="series.label"
                [visible]="filterSeriesByLegend(series.label)"
                missingValues="gap"
                type="line"
        >
        </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [labels]="legendLabelConfig" [spacing]="10" [visible]="options.showLegend"
                        position="top"></kendo-chart-legend>
</kendo-chart>

<div *ngIf="!isValidData()" class="insight-large-text text-uppercase text-secondary-light ng-star-inserted">
    NO DATA AVAILABLE
</div>
