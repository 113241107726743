import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { NavigationService } from '../services/navigation.service';
import { ApplicationCacheService } from '../services/application-cache.service';
import { CurrentUserResponse } from '../models/current-user-response';
import { SSOService } from '../services/sso.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {
	constructor(private authService: AuthenticationService,
				private appCache: ApplicationCacheService,
				private navigationService: NavigationService,
				private ssoService: SSOService) {
	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const token = await this.authService.getCognitoToken();
		if (token) {
			// We have a valid token
			if (this.appCache.user) 
				return true;
			
			// Haven't retrieved the current user yet, so do that now
			return this.authService.getCurrentUser()
				.then((response: CurrentUserResponse) => {
					const {user} = response;
					if (!user) {
						// Something went wrong
						return this.onAuthError(state, route);
					}

					if (!user.userIsAdmin) {
						// User isn't an admin
						return this.onAuthError(state, route);
					}
					return true;
				})
				.catch(() => this.onAuthError(state, route));
		}
		return this.onAuthError(state, route);
	}

	private onAuthError(state, route: ActivatedRouteSnapshot) {
		const sourceToken = this.ssoService.determineSource(route);
		this.navigationService.navigateToLogin(undefined, undefined, sourceToken);
		return false;
	}
}
