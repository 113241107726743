import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { ApplicationCacheService } from '../shared/services/application-cache.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NavigationService } from '../shared/services/navigation.service';
import { LoginResponse } from '../shared/models/authentication';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html'
})
export class LandingComponent implements OnInit {
	landingMessage = '';
	private _destroyRef = inject(DestroyRef);
	private _userMessages = {
		processing_login: 'Processing...',
		processing_requesting: 'Requesting access to Trella...',
		login_failed: 'Login attempt failed. We apologize for the inconvenience - please contact the Trella Health Help Desk.',
		authentication_failed: 'Authentication failed. We apologize for the inconvenience - please contact the Trella Health Help Desk.',
		user_not_found: 'Oops! It looks like you don\'t have access to Marketscape. Contact your Trella Health administrator.'
	};

	constructor(private navigationService: NavigationService,
				private applicationCacheService: ApplicationCacheService,
				private authenticationService: AuthenticationService) {
	}

	ngOnInit() {
		this.landingMessage = this._userMessages.processing_login;
		Auth.currentAuthenticatedUser({bypassCache: true}).then(user => {
			if (user) {
				this.landingMessage = this._userMessages.processing_requesting;
				Auth.currentSession().then(res => {
					const jwt = res.getIdToken().getJwtToken();
					this.login(jwt);
				});

			}
			else 
				this.landingMessage = this._userMessages.login_failed;
			
		}).catch(() => {
			this.landingMessage = this._userMessages.authentication_failed;
		});
	}

	login(jwt: string) {
		this.authenticationService.loginCognito(jwt)
			.pipe(takeUntilDestroyed(this._destroyRef))
			.subscribe(
				async (obj: LoginResponse) => {
					obj.token = jwt;
					if (!obj.authenticated) {
						this.landingMessage = this._userMessages.user_not_found;
						return;
					}
					try {
						this.authenticationService.onLogin(obj);
						await this.authenticationService.getCurrentUser();
						this.navigationService.navigateAfterLogin(this.applicationCacheService.getReturnUrl());
					} catch (ex) {
						console.log(ex);
						this.landingMessage = this._userMessages.authentication_failed;
					}
				},
				() => {
					this.landingMessage = this._userMessages.authentication_failed;
					return;
				});
	}

}
