export enum ADMIN_PAGE_TYPE {
	actionGroup = 'actiongroup',
	roles = 'roles',
	companies = 'companies',
	users = 'users',
	npiGroup = 'npiGroups',
	reports = 'reports',
	talkingpoints = 'talkingpoints',
	codeSets = 'codeSets',
}
