import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { ApplicationCacheService } from '../services/application-cache.service';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard {
	constructor(private authService: AuthenticationService, private appCache: ApplicationCacheService) {
	}

	async canActivate() {
		const token = await this.authService.getCognitoToken();
		if (!token) 
			return this.onAuthError();
		

		if (this.appCache.user) 
			return false;
		

		try {
			const response = await this.authService.getCurrentUser();
			const {user} = response;

			if (!user) 
				return this.onAuthError();
			

			// No need to login... Navigate to Main
			return false;
		} catch (ex) {
			return this.onAuthError();
		}
	}

	private onAuthError() {
		return true;
	}
}
