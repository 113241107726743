import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Selection } from '@appcore/interfaces/selection.interface';

@Component({
	selector: 'admin-list-box-local',
	templateUrl: './list-box.component.html',
	styleUrls: ['./list-box.component.scss']
})
export class ListBoxComponent implements OnChanges {
	@Input() options: Array<Selection> = [];
	@Input() disabled = false;
	@Input() height = '450px';
	@Input() singleSelect: boolean;
	@Input() isDisabled: boolean;
	@Output() userChange = new EventEmitter();

	value: any;

	_userSearchString = '';
	displayFilteredList: Selection[] = [];

	get searchString() {
		return this._userSearchString.toLowerCase();
	}

	get modelCount() {
		return (this.model && this.model.length) || 0;
	}

	get model(): any[] {
		return this.value;
	}

	set model(val: any[]) {
		this.value = val;
	}

	ngOnChanges(changes: SimpleChanges) {
		this.filterList();
	}

	isChecked(listObj: Selection) {
		return this.model && (this.model as string[]).indexOf(listObj.id) > -1;
	}

	toggleVal(listObj: Selection) {
		this.model = this.model || [];

		if (this.singleSelect) {
			this.runSingleSelectFilterLogic(listObj);
			return;
		}

		const index = this.model.indexOf(listObj.id);
		if (index > -1) {
			// item exists, so just remove this item
			this.model.splice(index, 1);
		}
		else {
			// New Item, add to the model
			this.model.push(listObj.id);
		}

		// Let listeners know we changed models
		this.userChange.emit(listObj);
	}

	runSingleSelectFilterLogic(newListObj: Selection) {
		if (!this.singleSelect || !this.model)
			return;


		if (!this.modelCount)
			this.model.push(newListObj.id);

		else {
			// If the new item is already in the model, the user was de-selecting the item
			this.model = this.model[0] === newListObj.id ? [] : [newListObj.id];
		}

		// Let listeners know we changed models
		this.userChange.next(null);
	}

	filterList() {
		if (!this.searchString)
			this.displayFilteredList = this.options;

		else {
			this.displayFilteredList = [];

			for (const opt of this.options) {
				const displayCompareVal = opt && opt.display && opt.display.toLowerCase();
				if (displayCompareVal.indexOf(this.searchString) > -1)
					this.displayFilteredList.push(opt);

			}
		}

		const modelsToAdd = this.getModelValuesNotInOptions();
		this.displayFilteredList = this.displayFilteredList.concat(modelsToAdd);
	}


	getModelValuesNotInOptions() {
		if (!this.model)
			return [];


		const list = [];
		this.model.forEach(m => {
			if (!this.options.find(x => x.id === m)) {
				list.push({
					checked: true,
					display: m,
					id: m
				});
			}
		});

		return list;
	}
}
