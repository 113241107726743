import { ChangeDetectorRef, Component, Input, Output } from '@angular/core';
import { KeyValue } from '@angular/common';
import { ComponentWithSubscription } from '../../components/component-with-subscription';
import { Filter } from '../../models/filter.model';
import { FilterSelectionService } from '../../services/filter-selection.service';
import { FilterSelection } from '../../models/filter-selection.model';

@Component({
    selector: 'trella-filter-selection-dropdown',
    templateUrl: './filter-selection-dropdown.component.html',
    styleUrls: ['./filter-selection-dropdown.component.scss']
})
export class FilterSelectionDropdownComponent extends ComponentWithSubscription {
    @Output() options: KeyValue<string, string>[];

    label?: string;
    selection: KeyValue<string, string>;
    private filter: Filter;
    private key: string;

    constructor(private filterSelection: FilterSelectionService, private cdr: ChangeDetectorRef) {
        super();
    }

    @Input() set selections(selections: FilterSelection) {
        this.filter = selections.filter;
        this.key = selections.key;
        this.label = selections.label;
        this.options = selections.values;

        if (this.options && this.options.length && !this.selection)
            this.selection = this.options.find(o => o.key === localStorage.getItem(this.key)) || this.options[0];

    }

    selectFilter() {
        const filter = {...this.filter};
        filter.modelJson = this.selection.value;
        localStorage.setItem(this.key, this.selection.key);
        this.filterSelection.select(filter, this.key);
    }
}
