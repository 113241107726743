import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ComponentWithSubscription } from '../component-with-subscription';
import { MedicalEntityTypeConfig } from '../../models/medical-entity-type-config.model';
import { Aco } from '../../models/aco/aco.model';
import { DemographicCardPermissions } from '../../models/demographic-card-permissions.model';
import { Utils } from '../../helpers/Utils';
import { DemographicCardOptions } from '../../models/demographic-card-options.model';

const PHYSICIAN_FACILITY_DATAKIT_HOVER = 'Create Plan My Visit Presentation';
const AGENCY_DATAKIT_HOVER = 'Create Comparison Presentation';

@Component({
    selector: 'trella-aco-demographic-card',
    templateUrl: './aco-demographic-card.component.html',
    styleUrls: ['./aco-demographic-card.component.scss']
})
export class AcoDemographicCardComponent extends ComponentWithSubscription implements OnChanges {
    @Input() config: MedicalEntityTypeConfig;
    @Input() info: Aco;
    @Input() options: DemographicCardOptions;
    @Input() permissions: DemographicCardPermissions;
    @Input() dataUpdate = false;


    @Output() refreshNpiInfoEmitter: EventEmitter<any> = new EventEmitter();
    @Output() saveEmitter: EventEmitter<any> = new EventEmitter();


    pristineInfo: Aco;
    open = false;
    editMode = false;

    constructor() {
        super();
    }
    get icon() {
        return this.config && this.config.icon;
    }

    get color() {
        return this.config && this.config.colorClass;
    }

    get canSave() {
        return this.info;
    }

    ngOnChanges() {
        if (!this.config || !this.info || !this.options || this.open) 
            return;
        
        this.open = false;
        this.refreshNpiInfo();
    }


    refreshNpiInfo() {
        this.refreshNpiInfoEmitter.emit();
    }

    toggleDetails() {
        this.open = !this.open;
    }

    toggleEditMode() {
        return;
    }

    save() {
        this.saveEmitter.emit();
        this.editMode = false;
    }

    cancel() {
        this.info = Utils.deepClone(this.pristineInfo);
        this.editMode = false;
    }

    formatMarkets(markets) {
        return markets?.join(', ') ?? '';
    }

}
