import { FilterableSettings, PagerSettings, SelectableSettings, SortSettings } from '@progress/kendo-angular-grid';
import { IGridAbilities } from '../interfaces/grid-abilities.interface';

export class LegacyGridAbilities implements IGridAbilities {
	pageable?: PagerSettings = {};
	sortable?: SortSettings = {
		allowUnsort: true,
		mode: 'single',
		initialDirection: 'desc'
	};

	selectable?: SelectableSettings = {
		checkboxOnly: true,
		enabled: true,
		mode: 'multiple'
	};

	enableAdding?: boolean = false;
	filterable?: FilterableSettings = true;
	editCommand?: boolean;
	copyCommand?: boolean;
	deleteRow?: boolean;
	viewable?: boolean = false;
	enableImport?: boolean = false;

	groupedActionColumn?: boolean = false;
	favoriteCommand?: boolean = false;
	targetCommand?: boolean = false;
	compareCommand?: boolean = false;
	npiGroupCommand?: boolean = false;
	scrollable?: 'none' | 'scrollable' | 'virtual' = 'scrollable';
	showMagnifyingGlass?: boolean = false;

	static GetAbilitiesForGroupedAction() {
		const abilities = new LegacyGridAbilities();
		abilities.groupedActionColumn = true;
		abilities.favoriteCommand = true;
		abilities.targetCommand = true;
		abilities.compareCommand = true;
		abilities.npiGroupCommand = true;
		return abilities;
	}

	public set(abilities: IGridAbilities) {
		if (!abilities) 
			return;
		

		Object.assign(this, abilities);
	}
}
