export interface CommandButtonOptions {
	/**
	 * Optional function to return the label of the button
	 */
	label?: (obj?) => string;
	/**
	 * Function to execute when the button is clicked
	 */
	execute?: (rowIndex, dataItem, $event) => void,
	/**
	 * Optional function to determine if the button is disabled
	 */
	disabled?: (obj?) => boolean;
	/**
	 * Option function to determine if the button should be shown
	 */
	show?: (obj?) => boolean;
	/**
	 * Optional function to specify the classes to apply to the button
	 */
	classes?: (obj?) => string;
	/**
	 * For buttons with active/neutral states
	 */
	active?: boolean;
	/**
	 * Have a glyph?
	 */
	faIcon?: string;
}

/**
 * Class for defining commands (e.g. buttons/ links)
 */
export class Command {

	public label: (obj?) => string;
	public execute?: (rowIndex, dataItem, $event) => void;
	public disabled: (obj?) => boolean;
	public show: (obj?) => boolean;
	public classes: (obj?) => string;
	public active: boolean;
	public faIcon: string;

	constructor(options: CommandButtonOptions) {
		Object.assign(this, options);
	}

}
