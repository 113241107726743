import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleApi } from 'src/app/api/role.api';
import { Role, RoleContract, UpdateRoleContract } from '@appcore/models/directory/role.model';

@Injectable({
	providedIn: 'root'
})
export class RoleService {
	constructor(private roleApi: RoleApi) {
	}

	getAll(companyIds: string[]): Observable<RoleContract[]> {
		return this.roleApi.getAll(companyIds);
	}

	createRole(actionGroup: Role): Observable<string> {
		return this.roleApi.create(actionGroup);
	}

	updateRole(actionGroup: UpdateRoleContract): Observable<void> {
		return this.roleApi.update(actionGroup);
	}

	getRole(roleId: string): Observable<RoleContract> {
		if (!roleId)
			return;

		return this.roleApi.get(roleId);
	}
}
