import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ListBoxSortStrategy } from '../../interfaces/list-box-sort-strategy.interface';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MakeProvider } from '../../components/AbstractValueAccessor';
import { CheckedSelection } from '../../interfaces/checked-selection.interface';
import { ALL_OPTION } from '../../constants/constants';

@Component({
    selector: 'trella-list-box',
    templateUrl: './list-box.component.html',
    styleUrls: ['./list-box.component.scss'],
    providers: [MakeProvider(ListBoxComponent)]
})
export class ListBoxComponent implements OnInit {
    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

    @Input() options: CheckedSelection[] = [];
    @Input() disabled = false;
    @Input() height = '450px';
    @Input() listBoxSortStrategy: ListBoxSortStrategy;
    @Input() searchBoxPlaceholder = 'Search';
    @Input() selectAllByDefault = false;
    @Input() selectAllBorder = false;
    @Input() showSelectAll = false;
    @Input() singleSelect = false;
    @Input() isDisabled: boolean;
    @Output() userChange = new EventEmitter<CheckedSelection>();

    userSearchString = '';
    private selectAllChecked = false;
    private selectAllText = 'Select All';

    constructor(private cdr: ChangeDetectorRef) {
    }

    get modelCount() {
        return this.getCheckedOptions.length;
    }

    get selectedOption() {
        return this.getCheckedOptions()[0];
    }

    set selectedOption(listObj: CheckedSelection) {
        // do nothing - handled by toggleVal
    }

    ngOnInit(): void {
        if (this.showSelectAll && this.selectAllByDefault && this.options.length) {
            this.setSelectAll(true);
            this.options.forEach(e => {
                e.checked = this.selectAllChecked;
                this.userChange.emit(e);
            });
        }
        this.applySortStrategy();
    }

    getCheckedOptions(): CheckedSelection[] {
        return this.options.filter(o => o.checked);
    }

    hasAllOption(selection: CheckedSelection): boolean {
        return selection.id === ALL_OPTION && this.selectAllBorder;
    }

    isChecked(listObj: CheckedSelection) {
        return listObj.checked;
    }

    toggleSelectAll(listObj: CheckedSelection) {
        this.setSelectAll(!this.selectAllChecked);
        this.options.forEach(e => e.checked = this.selectAllChecked);
        this.userChange.emit(listObj);
        this.applySortStrategy();
    }

    toggleVal(listObj: CheckedSelection) {
        if (this.singleSelect && !listObj.checked)
            this.options.forEach(e => e.checked = false);

        listObj.checked = !listObj.checked;
        if (this.options.every(o => o.checked))
            this.setSelectAll(true);
        else
            this.setSelectAll(false);

        this.userChange.emit(listObj);
        this.applySortStrategy();
    }

    applySortStrategy() {
        if (this.listBoxSortStrategy && this.options) {
            setTimeout(() => { // wrapped in a timeout to allow the virtual scroll time to render
                this.options = [...this.listBoxSortStrategy.sortList(this.options, this.singleSelect)];
            }, 1);
            if (this.singleSelect)
                this.viewport?.scrollToIndex(0);
        }
    }

    private setSelectAll(selected: boolean) {
        this.selectAllChecked = selected;
        this.selectAllText = this.selectAllChecked ? 'Deselect All' : 'Select All';
    }
}
