// TODO:MAR-5357 Deprecate FacilityType. We only have Claim Types and Npi Types now.
/*
These are the facilityTypes in the ehdata.facilityTypes table:
PHYS
OUTP
HHA
SNF
HOS
INP
HOSP
*/

export enum FacilityType {
    HOS = 'HOS',
    SNF = 'SNF',
    HHA = 'HHA',
    PHYS = 'PHYS',
    PHYS_GRP = 'PHYSGRP',
    HOSP = 'HOSP',
    INP = 'INP',
    OUTP = 'OUTP'
}
