import { BehaviorSubject, Observable } from 'rxjs';
import { Keyed } from '../models/keyed';

export class DataVisualizationBaseService {
    readonly eButtonClicked: Observable<string>;
    readonly eButtonPermissionChecked: Observable<Keyed<boolean>>;
    private _eButtonClicked: BehaviorSubject<string> = new BehaviorSubject(null);
    private _eButtonPermissionChecked: BehaviorSubject<Keyed<boolean>> = new BehaviorSubject(null);
    
    constructor() {
        this.eButtonClicked = this._eButtonClicked.asObservable();
        this.eButtonPermissionChecked = this._eButtonPermissionChecked.asObservable();
    }

    allowEButton(key: string, isAllowed: boolean) {
        this._eButtonPermissionChecked.next({key, value: isAllowed});
    }

    reportEButtonClick(key: string) {
        this._eButtonClicked.next(key);
    }
}
