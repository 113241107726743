import { PERMISSIONS } from '@appcore/constants/permissions.constant';


export const ROUTE_CONSTANTS = {
	sso: {
		tokenGetLabel: 'sso',
		portalGetLabel: 'portal',
		redirectRouteLabel: 'redirect'
	},
	returnUrlLabel: 'returnUrl',
	mosaicLabel: 'mosaic',
	passwordResetUrlLabel: 'token',
	newRouteParam: 'new',
	idRouteParam: 'id',
	settingsRouteParam: 'settings',
	redirect: {
		route: 'redirect',
		label: 'Redirect',
		icon: ''
	},
	login: {
		route: 'login',
		label: 'Login',
		icon: ''
	},
	logout: {
		route: 'logout',
		label: 'Logout',
		icon: ''
	},
	crmlogout: {
		route: 'crmlogout',
		label: 'crmlogout',
		icon: ''
	},
	loginlegacy: {
		route: 'loginlegacy',
		label: 'Loginlegacy',
		icon: ''
	},
	landing: {
		route: 'landing',
		label: 'Landing',
		icon: ''
	},
	error: {
		route: 'error',
		label: 'Error'
	},
	passwordReset: {
		route: 'reset',
		label: 'Reset',
		icon: ''
	},
	sprouts: {
		route: 'sprouts',
		label: 'Sprouts',
		icon: 'fa-solid fa-seedling',
		permission: PERMISSIONS.PAGE_LEAD_GENERATOR_SHOW
	},
	leadGenerator_bundle_manager: {
		route: 'bundlemanager',
		label: 'Bundle Manager',
		icon: '',
		permission: PERMISSIONS.PAGE_LEAD_GENERATOR_SHOW
	},
	leadGenerator_feedback: {
		route: 'feedback',
		label: 'Feedback',
		icon: '',
		permission: PERMISSIONS.PAGE_LEAD_GENERATOR_SHOW
	},
	leadGenerator_history: {
		route: 'history',
		label: 'History',
		icon: '',
		permission: PERMISSIONS.PAGE_LEAD_GENERATOR_SHOW
	},
	leadGenerator_manager: {
		route: 'manager',
		label: 'Profile Manager',
		icon: '',
		permission: PERMISSIONS.PAGE_LEAD_GENERATOR_SHOW
	},
	actionGroup: {
		route: 'actiongroup',
		label: 'Action Groups',
		icon: 'far fa-object-group',
		permission: PERMISSIONS.PAGE_ACTION_GROUPS_SHOW
	},
	users: {
		route: 'users',
		label: 'Users',
		icon: 'fas fa-user',
		permission: PERMISSIONS.PAGE_USERS_SHOW
	},
	companies: {
		route: 'companies',
		label: 'Companies',
		icon: 'far fa-building',
		permission: PERMISSIONS.PAGE_COMPANIES_SHOW
	},
	roles: {
		route: 'roles',
		label: 'Roles',
		icon: 'fas fa-user-tag',
		permission: PERMISSIONS.PAGE_ROLES_SHOW
	},
	npiGroup: {
		route: 'npigroup',
		label: 'NPI Groups',
		icon: 'fas fa-layer-group',
		permission: PERMISSIONS.PAGE_NPI_GROUPS_SHOW
	},
	codeSets: {
		route: 'codesets',
		label: 'Code Sets',
		icon: 'fas fa-list',
		permission: PERMISSIONS.ADMIN_CODESETS_SHOW
	},
	codesets_view: {
		route: 'view',
		label: 'View Code Sets',
		icon: '',
		permission: PERMISSIONS.ADMIN_CODESETS_SHOW
	},
	codesets_company_assignments: {
		route: 'assignments/company',
		label: 'Manage Company Code Set Assignments',
		icon: '',
		permission: PERMISSIONS.ADMIN_CODESETS_SHOW
	},
	codesets_user_assignments: {
		route: 'assignments/user',
		label: 'Manage User Code Set Assignments',
		icon: '',
		permission: PERMISSIONS.ADMIN_CODESETS_SHOW
	},
	codesets_requests: {
		route: 'requests',
		label: 'Manage Requests',
		icon: '',
		permission: PERMISSIONS.ADMIN_CODESETS_SHOW
	},
	talkingPoints: {
		route: 'talkingpoints',
		label: 'Talking Points',
		icon: 'fa fa-spoon',
		permission: PERMISSIONS.ADMIN_CONVERSATION_STARTERS
	},
	permissions: {
		route: 'permissions',
		label: 'Permissions',
		icon: 'fas fa-shield-alt',
		permission: PERMISSIONS.TRELLA_SUPER_ADMIN
	},
	allPermissions: {
		route: 'allPermissions',
		label: 'All Permissions',
		icon: '',
		permission: PERMISSIONS.TRELLA_SUPER_ADMIN
	},
	myPermissions: {
		route: 'myPermissions',
		label: 'My Permissions',
		icon: '',
		permission: PERMISSIONS.TRELLA_SUPER_ADMIN
	},
	userPermissions: {
		route: 'userPermissions',
		label: 'User Permissions',
		icon: '',
		permission: PERMISSIONS.TRELLA_SUPER_ADMIN
	},
	unauthorized: {
		route: 'unauthorized',
		label: 'Unauthorized'
	},
	eula: {
		route: 'eula',
		label: 'eula',
		icon: ''
	},
	dataEula: {
		route: 'dataEula',
		label: 'dataEula',
		icon: ''
	},
	reportsUsage: {
		route: 'reports',
		label: 'Reports - Usage',
		icon: 'far fa-chart-bar',
		permission: PERMISSIONS.ADMIN_REPORTS
	},
	reportsScheduled: {
		route: 'scheduled-reports',
		label: 'Reports - Scheduled',
		icon: 'far fa-clock',
		permission: 'TEMPORARILY DISABLED. ORIGINAL: PERMISSIONS.ADMIN_SCHEDULED_REPORTS'
	},
	scheduled_reports_daily: {
		route: 'scheduled-reports-daily',
		label: 'Scheduled Reports Daily',
		icon: '',
		permission: PERMISSIONS.ADMIN_SCHEDULED_REPORTS
	},
	scheduled_reports_weekly: {
		route: 'scheduled-reports-weekly',
		label: 'Scheduled Reports Weekly',
		icon: '',
		permission: PERMISSIONS.ADMIN_SCHEDULED_REPORTS
	},
	reportsSftp: {
		route: 'data-bi-feed-reports',
		label: 'Reports - Data BI Feed',
		icon: 'far fa-right-left-large',
		permission: PERMISSIONS.ADMIN_SFTP_REPORTS_SHOW
	},
	talking_points_sales_rep: {
		route: 'sales-rep',
		label: 'Sales Representative Report',
		icon: '',
		permission: '',
		bypassPermission: true
	},
	talking_points_marketing: {
		route: 'marketing',
		label: 'Marketing Report',
		icon: '',
		permission: '',
		bypassPermission: true
	},
	userNetworks: {
		route: 'usernetworks',
		label: 'User Networks',
		icon: 'fas fa-users-medical',
		permission: PERMISSIONS.PAGE_MOSAIC_USER_NETWORKS_SHOW
	}
};
