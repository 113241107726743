import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'trella-eula',
    templateUrl: './eula.component.html',
    styleUrls: ['./eula.component.scss']
})
export class EulaComponent { 
    
    @Output() onUpdateEula: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    updateEula(choice: boolean) {
        this.onUpdateEula.emit(choice);
    }
}
