<trella-hover-tooltip [position]="'top'" [tooltipTemplate]="DefinitionTemplate" [underline]="false">
    <div (click)="toggleFilter()"
         class="btn text-input d-flex flex-row align-items-center flex-nowrap btn-outline-primary">
	<span class="me-3">
		{{ getTitle }} <span *ngIf="getCount">({{ getCount }})</span>
	</span>
        <i [class.fa-chevron-down]="isOpen" [class.fa-chevron-right]="!isOpen" class="fas"></i>
    </div>
</trella-hover-tooltip>
<ng-template #DefinitionTemplate>
    <div class="p-1 text-center">
        {{ getDefinition }}
    </div>
</ng-template>

<div *ngIf="isOpen" class="multi-level-filter">
    <div class="g-0 border rounded location-wrapper">
        <div class="row g-0">
            <input (keyup)="onSearchKeyup($event)" [(ngModel)]="searchQuery" class="form-control w-100"
                   placeholder="Search {{ caption }}"/>
        </div>

        <div *ngIf="filteredNodes && filteredNodes.length" class="g-0 border search-box">
            <div *ngFor="let n of filteredNodes" class="location-search-item border-top p-2 pointer">
                <div (click)="handleSearchResultSelection(n)">
                    {{ n.toString() }}
                </div>
            </div>
        </div>

        <div>
            <div class="row g-0 border border-top-0 flex-nowrap location-menu-header">
                <div *ngFor="let level of levels; let levelIndex = index"
                     [class]="'col-auto list-width text-center border-end column-' + levelIndex">
                    {{ level }}
                </div>
            </div>
            <div [(searchContainer)]="searchQuery"
                 class="row g-0 border border-top-0 flex-nowrap location-menu-container">
                <div
                        *ngFor="let nodes of visibleNodesByLevel; let levelIndex = index; trackBy: getArrayHash; let isLast = last"
                        [class]="'col-auto list-width column-' + levelIndex"
                >
                    <div *ngIf="true" class="row g-0 item-column">
                        <div class="col">
                            <div *ngIf="withSelectAllOption(levelIndex) && getParent() as parentNode"
                                 class="row align-items-center pointer px-1">
                                <div class="col-auto">
                                    <mat-checkbox (click)="toggleNode(parentNode)" [checked]="areChildrenChecked(parentNode)"
                                                  color="primary"></mat-checkbox>
                                </div>
                                <div (click)="handleNodeSelection(parentNode)"
                                     class="col">{{ displaySelectDeselectAll(parentNode) }}
                                </div>
                                <div class="col-auto flex-center">
                                    <i [class.fa-caret-right]="!isLast" class="fas text-white"></i>
                                </div>
                            </div>
                            <div
                                    *ngFor="let node of nodes; trackBy: getHash"
                                    [class.active-selection]="isSelectedNode(node)"
                                    class="row align-items-center pointer px-1"
                            >
                                <ng-container
                                        *ngIf="withFirstLevelCheckboxes(levelIndex); then withCheckboxes else withoutCheckboxes">
                                </ng-container>
                                <ng-template #withCheckboxes>
                                    <div class="col-auto">
                                        <mat-checkbox (click)="toggleNode(node)" [checked]="node.checked"
                                                      color="primary"></mat-checkbox>
                                    </div>
                                    <div (click)="handleNodeSelection(node)" class="col">{{ node.display }}</div>
                                </ng-template>
                                <ng-template #withoutCheckboxes>
                                    <div (click)="handleNodeSelection(node)"
                                         class="col flex-center">{{ node.display }}
                                    </div>
                                </ng-template>
                                <div class="col-auto flex-center">
                                    <i [class.invisible]="isLast || !isFocusedNode(node)"
                                       class="fas text-white fa-caret-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
