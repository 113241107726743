import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProductName } from '../../enums/product-name.enum';
import { CrossAppNavigationService } from '../../services/cross-app-navigation.service';
import { PORTAL_TYPE } from '../../enums/portal-type.enum';
import { ROUTE_CONSTANTS, UPSELL_EXTERNAL_LINKS } from '../../constants/constants';

@Component({
    selector: 'trella-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
    @Input() accessibleProducts: ProductName[] = [];
    @Input() currentProductName: ProductName;
    @Output() changePendoPortalEmitter: EventEmitter<ProductName> = new EventEmitter();

    currentPortal = {route: null};
    menuOpen = false;
    isInsights = false;
    isStrategy = false;

    private _insightProducts = [ProductName.Hha, ProductName.Hme, ProductName.Hos, ProductName.Snf];

    constructor(private crossAppNavigationService: CrossAppNavigationService, private route: Router) {
    }

    get hasCRMAccess() {
        return this.accessibleProducts.includes(ProductName.Crm);
    }

    get hasInsightsProductAccess() {
        return this.accessibleInsightsProducts.length;
    }

    get hasStrategyAccess() {
        return this.accessibleProducts.includes(ProductName.Strategy);
    }

    get accessibleInsightsProducts(): ProductName[] {
        return this.accessibleProducts.filter(product => this._insightProducts.includes(product));
    }

    ngOnInit(): void {
        this.isInsights = this._insightProducts.includes(this.currentProductName);
        this.isStrategy = this.currentProductName === ProductName.Strategy;

        if (this.isInsights) 
            this.currentPortal = this.getPortal(this.currentProductName);
        
    }

    toggleMenu() {
        this.menuOpen = !this.menuOpen;
    }

    getPortalType(productName: ProductName): PORTAL_TYPE {
        return this.getPortal(productName).route;
    }

    getPortalLabel(productName: ProductName): string {
        return this.getPortal(productName).label;
    }

    isCurrentPortal(productName: ProductName): boolean {
        return this.getPortalType(productName) === this.currentPortal.route;
    }

    navigateToInsightsPortal(productName: ProductName) {
        if (this.isInsights) {
            this.navigateToInsightsPortalFromInsights(productName);
            return;
        }

        const portal = this.getPortalType(productName);
        if (this.hasInsightsProductAccess && this.accessibleProducts.includes(productName)) 
            this.crossAppNavigationService.navigateToInsightsPortal(portal);
         else 
            this.crossAppNavigationService.navigateToExternal(UPSELL_EXTERNAL_LINKS.insights);
        
    }

    navigateToInsightsPortalFromInsights(productName: ProductName) {
        this.changePendoPortal(productName);
        if (this.hasInsightsProductAccess && this.accessibleProducts.includes(productName)) {
            const portal = this.getPortalType(productName);
            const urlTree = this.route.parseUrl(this.route.url);
            this.crossAppNavigationService.navigateToInsightsPortalFromInsights(portal, urlTree);
        } else 
            this.crossAppNavigationService.navigateToExternal(UPSELL_EXTERNAL_LINKS.insights);
        
    }

    navigateToStrategy() {
        if (this.hasStrategyAccess) 
            this.crossAppNavigationService.navigateToStrategy();
         else 
            this.crossAppNavigationService.navigateToExternal(UPSELL_EXTERNAL_LINKS.strategy);
        
    }

    navigateToCRM() {
        if (this.hasCRMAccess) 
            this.crossAppNavigationService.navigateToSparkLanding();
         else 
            this.crossAppNavigationService.navigateToExternal(UPSELL_EXTERNAL_LINKS.crm);
        
    }

    private changePendoPortal(productName: ProductName) {
        if (this.isInsights) 
            this.changePendoPortalEmitter.emit(productName);
        
    }

    private getPortal(productName: ProductName) {
        switch (productName) {
            case ProductName.Hha: {
                return ROUTE_CONSTANTS.hha;
            }
            case ProductName.Hme: {
                return ROUTE_CONSTANTS.hme;
            }
            case ProductName.Hos: {
                return ROUTE_CONSTANTS.hos;
            }
            case ProductName.Snf: {
                return ROUTE_CONSTANTS.snf;
            }
        }
        throw new Error('No portal found.');
    }
}
