import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { DialogButtonType } from '../../enums/dialog-button-type.enum';

@Directive({
    selector: '[dialog-button-class]'
})
export class AlertDialogDirective implements OnChanges {
    @Input('dialog-button-class') content: DialogButtonType;
    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.content) {
            this.renderer.addClass(this.el.nativeElement, 'btn');
            this.renderer.addClass(this.el.nativeElement, `btn-${this.getButtonClass(this.content)}`);
        }
    }

    getButtonClass(buttonType: DialogButtonType) {
        switch (buttonType) {
            case DialogButtonType.Ok:
                return 'success';
            case DialogButtonType.Cancel:
                return 'danger';
        }
    }
}
