import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownOption } from '../../interfaces/dropdown-option.interface';

@Component({
    selector: 'trella-button-dropdown',
    templateUrl: './button-dropdown.component.html',
    styleUrls: ['./button-dropdown.component.scss']
})
export class ButtonDropdownComponent {
    @Input() title!: string;
    @Input() options!: DropdownOption[];
    @Input() buttonClass = '';
    @Input() buttonDisabled = false;

    @Output() optionSelected = new EventEmitter<string>();

    showDropdown = false;

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }

    executeCallback(option: DropdownOption) {
        if (!option.disabled) {
            this.optionSelected.emit(option.action);
            this.showDropdown = false;
        }
    }

    hideComponent() {
        this.showDropdown = false;
    }
}
