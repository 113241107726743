import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthApi } from '../../api/auth.api';
import { ICognitoClient } from '../interfaces/cognito-client.interface';
import { ROUTE_CONSTANTS } from '../constants/route-constants.constant';
import { LOCAL_STORAGE_KEY } from '../constants/local-storage-key.constant';
import { EnvService } from '@appcore/services/env.service';

@Injectable({
  providedIn: 'root'
})
export class SSOService {
  sourceParam = 'source';
  cognitoClient: ICognitoClient;

  constructor(
    private environmentService: EnvService,
    private authApi: AuthApi,
    private router: Router
  ) {}

  async navigateToSSObySource(sourceToken: string = null) {
    if (sourceToken) {
      await this.getCognitoClientBySource(sourceToken);
      if (this.cognitoClient)
        return this.navigateToSSO();
      else
        return this.navigateToError();
    } else
        return this.navigateToLogin();
  }

  navigateToError() {
    // Cannot use NavigationService - injection would cause circular reference
    this.router.navigateByUrl(ROUTE_CONSTANTS.error.route);
  }

  navigateToLogin() {
    // Cannot use NavigationService - injection would cause circular reference
    this.router.navigateByUrl(ROUTE_CONSTANTS.login.route);
  }

  async navigateToSSObyEmail(userEmail: string = null) {
    try {
      await this.getCognitoClientByEmail(userEmail);
      await this.navigateToSSO();
    } catch (err) {
        console.log(err);
    }
    return !!this.cognitoClient;
  }

  async getCognitoClientBySource(source: string) {
    console.log(source);
    this.cognitoClient = null;
    this.cognitoClient = (await this.authApi
      .getCognitoClient(source)
      .toPromise()
      .catch(x => this.onError(x))) as ICognitoClient;
  }

  async getCognitoClientByEmail(userEmail: string) {
    this.cognitoClient = null;
    await this.authApi
      .identifyClient(userEmail)
      .toPromise()
      .then(client => {
        this.cognitoClient = client;
        this.storeUnconfirmedSSOSource(client.source);
      })
      .catch(x => this.onError(x));
  }

  lookupDestination() {
    return this.environmentService.cognitoUrl(this.cognitoClient.clientId);
  }

  determineSource(route: ActivatedRouteSnapshot) {
    // Source can be specified in the login PATH, in the URL, or can be stored in a cookie
    let ssoSource: string = null;
    try {
      ssoSource = route.paramMap.get(this.sourceParam);
    } catch (e) {}
    if (!ssoSource)
      ssoSource = route.queryParamMap.get(this.sourceParam);

    if (!ssoSource)
      ssoSource = this.getSourceFromStorage();

    if (ssoSource && ssoSource.toLowerCase() === 'none') {
      this.clearSSOSource();
      ssoSource = null;
    }

    this.storeUnconfirmedSSOSource(ssoSource);
    return ssoSource;
  }

  storeSSOauth(url: string) {
    const urlParts = url.match(
      /https:\/\/([-_A-Za-z0-9\.]*).*client_id=([-_A-Za-z0-9]*)&?/
    );
    const urlAuth = urlParts[1] ? urlParts[1] : '';
    const clientId = urlParts[2] ? urlParts[2] : '';
    localStorage.setItem(LOCAL_STORAGE_KEY.SSO_AUTH, urlAuth);
    localStorage.setItem(LOCAL_STORAGE_KEY.SSO_CLIENTID, clientId);
  }

  convertUnconfirmedSSOSource() {
    const source = localStorage.getItem(
      LOCAL_STORAGE_KEY.SSO_SOURCE_UNCONFIRMED
    );
    localStorage.setItem(LOCAL_STORAGE_KEY.SSO_SOURCE, source);
    localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_SOURCE_UNCONFIRMED);
  }

  storeUnconfirmedSSOSource(sourceToken: string) {
    localStorage.setItem(LOCAL_STORAGE_KEY.SSO_SOURCE_UNCONFIRMED, sourceToken);
  }

  clearSSOSource() {
    localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_SOURCE_UNCONFIRMED);
    localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_SOURCE);
    localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_CLIENTID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.SSO_AUTH);
  }

  getSourceFromStorage() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.SSO_SOURCE);
  }

  getClientIdFromStorage() {
    return localStorage.getItem(LOCAL_STORAGE_KEY.SSO_CLIENTID);
  }

  private async navigateToSSO() {
    const goToUrl = this.lookupDestination();
    /* Here we store Client/Auth for the final stage of the Cognito login process (landing page)
     It is not stored for subsequent logins, instead source is used to lookup Client/Auth for each new login
     */
    this.storeSSOauth(goToUrl);
    window.location.href = goToUrl;
  }

  private onError(error: any) {
    console.log('error!!');
  }
}
