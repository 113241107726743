<trella-spinner [isFullDiv]="true" [show]="isLoading"></trella-spinner>
<div *ngIf="gridInfos && gridInfos.length">
    <div *ngIf="filters || !(gridInfos && this.gridInfos.length === 1)" class="row mb-2 ms-1">
        <div *ngIf="!(gridInfos && this.gridInfos.length === 1)" class="col-auto">
            <select (change)="selectGrid()" [(ngModel)]="selectedOption" class="form-select form-select-md">
                <option *ngFor="let opt of selectOptions" [ngValue]="opt">{{ opt.display }}</option>
            </select>
        </div>
        <div *ngIf="filters && filterHasValues()" class="row">
            <trella-filter-selection-dropdown
                    *ngFor="let selections of filters"
                    [selections]="selections"
                    class="col"
            ></trella-filter-selection-dropdown>
        </div>
    </div>

    <trella-grid
            [rowLinkFactory]="rowLinkFactory"
            [allowAdd]="showAddToClipboard"
            [class.no-search]="showMagnifyingGlass"
            [customerId]="customerId"
            [disabled]="disabled"
            [gridInfo]="selectedGridInfo"
            [hasShowSelectedToggle]="hasShowSelectedToggle"
            [key]="key"
            [searchPlaceholderText]="searchPlaceholderText"
            [showAssigneesButton]="showAssigneesButton"
            [showComparisonsButton]="showComparisonsButton"
            [showFavoritesButton]="showFavoritesButton"
            [showSelected]="showSelected"
            [showTargetsButton]="showTargetsButton"
            [showUnAssigneeButton]="showUnAssigneeButton"
            [userFavorites]="userFavorites"
            [userTargets]="userTargets"
    ></trella-grid>
</div>
