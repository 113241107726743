<div class="row justify-content-center">
    <div class="col-8">
        <h1 class="fw-bold text-size-large">
            Welcome to the Trella Health web-based business intelligence and data analytics service (the “Service”)
            provided by Trella Health LLC (“Licensor”).
        </h1>
        <div style="color: black;">
            <div class="fw-bold">
                <div class="mb-2"><span class="red">CAUTION:</span> The Service permits you to submit data to the
                    Service in Notes.
                </div>
                <div class="mb-2"><span class="red">YOU ARE NOT PERMITTED TO INPUT PROTECTED HEALTH INFORMATION OR INDIVIDUALLY IDENTIFIABLE INFORMATION</span>
                    into the Service.
                </div>
            </div>
            <div class="pt-3">By accessing the Service, You, the end user Recipient as defined in the incorporated Data
                Use Agreement, agree:
            </div>
            <div class="ms-3">
                <div>
                    1. You will not submit Protected Health Information or Individually Identifiable Information into
                    the Service at any time.
                </div>
                <div>
                    2. That Licensor and its licensors own their respective rights, title and interests in and to all
                    output and provided data, including third-party content and all related materials and technology,
                    from the Service (“Provided Data”). You agree that, except for the limited rights expressly granted
                    to you in this Data Use Agreement, Trella and its licensors reserve all of their right, title and
                    interest in and to the Provided Data, and that Provided Data will only be used by Recipient for the
                    Purpose as defined in the Data Use Agreement, and other internal business purposes.
                </div>
                <div>
                    3. You will not allow any third party to access or use the Service. A “third party” is any
                    individual or entity who has not entered into a data agreement with Licensor.
                </div>
                <div>
                    4. You will not provide, sublicense, or share the Provided Data with any any third party nor allow
                    any third party to access or use the Provided Data.
                </div>
                <div>
                    5. You agree to the terms and conditions of the Data Use Agreement.
                </div>
            </div>
        </div>
        <div class="p-5 float-end">
            <button (click)="updateEula(true)" class="btn btn-primary mx-3" type="button">Accept</button>
            <button (click)="updateEula(false)" class="btn btn-danger" type="button">Decline</button>
        </div>
    </div>
</div>
