<div (clickOutside)="hideComponent()" appClickOutside class="dropdown-container">
    <button (click)="toggleDropdown()" [disabled]="buttonDisabled" [ngClass]="buttonClass" class="btn">
        {{ title }}
    </button>
    <ul *ngIf="showDropdown">
        <li (click)="executeCallback(option)" *ngFor="let option of options" class="btn btn-secondary">
            {{ option.name }}
        </li>
    </ul>
</div>
