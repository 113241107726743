<div class="ms-3 mt-2 text-data-xs">
    <span class="fw-bold">{{ PostNoteTitle }}</span>
    <ng-container *ngIf="isPostNoteNewLine"><br/></ng-container>
    <ng-container *ngIf="isMultiplePostNotes">
        <ul class="post-note-list">
            <li *ngFor="let note of gridInfo.postNotes.details">{{ note }}</li>
        </ul>
    </ng-container>
    <ng-container *ngIf="!isMultiplePostNotes">
        {{ gridInfo.postNotes.details }}
    </ng-container>
</div>
