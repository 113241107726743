export class User {
    cognitoClientId: number;
    companyId: string;
    crmId: number;
    managerId: string;
    email: string;
    emailSent: Date;
    expiration: Date;
    firstName: string;
    isEnabled: boolean;
    lastName: string;
    notes: string;
    phone: string;
    salesforceId: string;
    title: string;
    userTypeId: number;

    constructor() {
        this.isEnabled = true;
    }
}
