import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChart } from '../base-chart';
import { ChartConfig } from '../../../../models/chart-config.model';
import { ChartDataElement, ChartDataSeries } from '../../../../models/chart-data-series.model';


const basePieChartOptions = {
    chart: {
        type: 'pie'
    },
    credits: {
        enabled: false // remove highchars watermark
    },
    title: {
        text: ''
    },
    tooltip: {
        pointFormat: '{point.percentage:.1f}%'
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        pie: {
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '{point.percentage:.1f}% ({point.name})'
            }
        },
        series: {
            point: {
                events: {
                    legendItemClick: () => false
                }
            }
        }
    },
    series: []
};

@Component({
    selector: 'trella-pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent extends BaseChart {
    Highcharts = Highcharts; // required

    fontSize;
    fontFamily;
    fontWeight;

    chartOptions;

    constructor() {
        super();
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnInit() {
        super.ngOnInit();

        this.fontSize = '.68rem';
        this.fontFamily = '\'Roboto Condensed\', sans-serif';
        this.fontWeight = this.fullScreen ? 'bold' : '';
    }

    getFormattedData() {
        return this.data.data.map(d => ({
            name: d.label,
            y: d.data
        }));
    }

    readData(chartInfo: ChartConfig): ChartDataSeries {
        super.readData(chartInfo);
        const data = chartInfo.data;
        const results = data.map(datapoint => {
            const mappedData: ChartDataElement = {
                label: datapoint[chartInfo.key].value,
                data: +datapoint[chartInfo.value].value
            };

            return mappedData;
        });

        const validData = this.validateData(results);
        return {
            data: results,
            categories: [],
            isValidData: validData
        };
    }

    postDataRead() {
        this.chartOptions = {
            ...basePieChartOptions,
            series: [
                {
                    color: this.seriesColors[0],
                    colorByPoint: false,
                    data: [...this.getFormattedData()]
                }
            ]
        };
    }

    validateData(data: ChartDataElement[]): boolean {
        if (!data || !data.length) 
            return false;
        

        const anyValidPoint = data.some(x => {
            const numberVal = Number(x.data) || 0;
            return numberVal > 0;
        });

        return anyValidPoint;
    }

    handleOptionsChange(config: ChartConfig) {
        // no op
    }
}
