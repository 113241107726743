import { Injectable } from '@angular/core';
import Mousetrap from 'mousetrap';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class KeyboardEventService {
    shift$ = new Subject<boolean>();
    ctrl$ = new Subject<boolean>();

    constructor() {
        this.mouseTrapBind();
    }

    private mouseTrapBind() {
        Mousetrap.bind(
            'shift',
            x => this.shift$.next(true),
            'keydown'
        );
        Mousetrap.bind(
            'shift',
            x => this.shift$.next(false),
            'keyup'
        );
        Mousetrap.bind(
            ['command', 'ctrl'],
            x => this.ctrl$.next(true),
            'keydown'
        );
        Mousetrap.bind(
            ['command', 'ctrl'],
            x => this.ctrl$.next(false),
            'keyup'
        );
    }
}