import { Injectable } from '@angular/core';
import { CompanyApi } from '../../api/company.api';
import { Observable, of, tap } from 'rxjs';
import { EditableCompany } from '../models/editable-company';
import { CrmRole } from '../models/crm-role';
import { ICustomer } from '../interfaces/customer.interface';
import {
	ProductName
} from '@appcore/enums/product-name.enum';
import { ModuleName } from '@appcore/enums/module-name.enum';
import { ProductAccess } from '@appcore/models/directory/product-access.model';
import { CompanyContract } from '@appcore/models/directory/company/company-contract.model';
import { RoleContract } from '@appcore/models/directory/role.model';
import { Selection } from '@appcore/interfaces/selection.interface';

@Injectable({
	providedIn: 'root'
})

export class CompanyService {

	private _companyPurposes: Selection[] = [];

	constructor(private companyApi: CompanyApi) {
	}

	getAllObsolete() {
		return this.companyApi.getAllObsolete();
	}

	getByProduct(product: ProductName, module: ModuleName) {
		return this.companyApi.getByProduct(product, module);
	}

	getCompanyProductAccesses(id: string): Observable<ProductAccess[]> {
		return this.companyApi.getCompanyProductAccesses(id);
	}

	getEditableCompany(companyId: string): Observable<EditableCompany> {
		if (!companyId)
			return;

		return this.companyApi.getEditableCompany(companyId);
	}

	create(company: EditableCompany) {
		if (!company)
			return;

		return this.companyApi.create(company);
	}

	update(company: EditableCompany) {
		if (!company || company.id === '')
			return;

		return this.companyApi.update(company);
	}

	delete(customerId: string) {
		if (!customerId)
			return;

		return this.companyApi.delete(customerId);
	}

	getAllCompanies(): Observable<CompanyContract[]> {
		return this.companyApi.getAll();
	}

	public getCompanyPurposes(forceRefresh = false): Observable<Selection[]> {
		if (this._companyPurposes.length && !forceRefresh)
			return of(this._companyPurposes);

		return this.companyApi.getCompanyPurposes().pipe(
			tap(companyPurposes => {
				this._companyPurposes = companyPurposes;
			}));
	}

	getCompaniesRolesCanBeCopied(): Observable<ICustomer[]> {
		return this.companyApi.getCompaniesRolesCanBeCopied();
	}

	getRoles(id: any): Observable<RoleContract[]> {
		return this.companyApi.getRoles(id);
	}

	getCrmRoles(id: any): Observable<CrmRole[]> {
		return this.companyApi.getCrmRoles(id);
	}
}
