import { Injectable } from '@angular/core';
import {
	ExcelExportComponent,
	ExcelExportData,
	Workbook,
	WorkbookSheet,
	WorkbookSheetRow,
	WorkbookSheetRowCell
} from '@progress/kendo-angular-excel-export';
import { LegacyGridInfo } from '../models/legacy-grid-info';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ReportApi } from '../../api/report.api';
import { process } from '@progress/kendo-data-query';
import { saveAs } from '@progress/kendo-file-saver';
import { isEmpty, round } from 'lodash';
import { ExportMessage } from '../models/export-message';
import { GridColumn } from '../models/grid-column';
import AdminUtils from '../AdminUtils';
import Sticky from '../Sticky';
import { ApplicationCacheService } from './application-cache.service';
import { GridColumnType } from '../enums/grid-column-type.enum';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { ADMIN_PAGE_TYPE } from '../enums/admin-page-type.enum';
import { Utils } from '@appcore/helpers/Utils';

@Injectable({
	providedIn: 'root'
})

export class ExcelExportService {

	gridInfo: LegacyGridInfo = null;

	// unsubscribe subject
	excelDataUnsubscribe: Subject<void> = new Subject<void>();

	// subject for moving messages around
	messageToGrid = new Subject<any>();
	hideExportMessage = new Subject<any>();

	// default colors
	backgroundColor = '#7a7a7a';
	foregroundColor = '#fff';

	// default cellHeight
	cellHeight = 20;

	// display message interval
	messageInterval = null;

	constructor(private userFeedbackService: UserFeedbackService,
				private reportSvc: ReportApi,
				private appCache: ApplicationCacheService) {
	}

	get excelReportName() {
		return this.gridInfo && this.gridInfo.exportTitle || this.gridInfo && this.gridInfo.reportName;
	}

	get excelFileName(): string {
		let baseFileName = 'Trella Health Excel File';
		if (this.excelReportName)
			baseFileName = this.excelReportName;

		return `${baseFileName}.xlsx`;
	}

	// functions for communicating with grid
	sendMessageToGrid(messageObject: any) {
		this.messageToGrid.next(messageObject);
	}

	getMessageFromExport(): Observable<any> {
		return this.messageToGrid.asObservable();
	}

	closeExportMessage(reportName: string) {
		this.hideExportMessage.next(reportName);
	}

	hideMessageFromExport(): Observable<any> {
		return this.hideExportMessage.asObservable();
	}

	// Beginning of main functions
	async startExcelExport(excelExport: ExcelExportComponent, inputGridInfo: LegacyGridInfo) {
		// show message
		this.permenantMessage({message: 'Only exporting the first 10,000 rows', reportName: inputGridInfo.reportName});

		// wait for 2 seconds
		await this.delay(2000);

		this.permenantMessage({message: 'Exporting data to excel please wait', reportName: inputGridInfo.reportName});

		// if there are no columns get out
		const columns = inputGridInfo && inputGridInfo.columns;
		if (!columns) {
			this.exitWithError('Error - Excel Export 6001: Problem with the Grid Information');
			return;
		}

		this.gridInfo = inputGridInfo;

		// try to get the npi
		const exportNpi = null;

		// try to get the report name
		const gridReportName = this.gridInfo && this.gridInfo.reportName;

		// make call to get data and wait for it.
		this.getExportData(exportNpi, gridReportName).pipe(takeUntil(this.excelDataUnsubscribe))
			.subscribe(async (excelExportData: ExcelExportData) => {
				// once we get the data build the excel file
				await this.buildExcelFile(exportNpi, columns, excelExport, excelExportData);
			});
	}

	private getExportData(exportNpi: string, exportReportName: string): Observable<ExcelExportData> {
		const singleFilters = this.gridInfo && this.gridInfo.reportFilters;
		const gridFilters = this.gridInfo && this.gridInfo.queryOptions;

		if (!exportNpi && !exportReportName) {
			this.exitWithError('Error - Excel Export 6002: The Grid Information is missing the NPI or Report Name');
			return;
		}

		return this.reportSvc.export(exportReportName, exportNpi, gridFilters, singleFilters).pipe(
			map(returnedData => this.parseRawExportData(returnedData))
		);
	}

	private parseRawExportData(data: any[]): ExcelExportData {
		const columns = this.gridInfo && this.gridInfo.columns;
		data = data.map(d => {
			const formattedData = {};
			for (const key in d) {
				if (key) {
					let column = columns.find(col => key === col.field);
					if (!column) {
						const preheader = columns.find(p => p.columns.some(c => c.field === key));
						if (preheader)
							column = preheader.columns.find(c => key === c.field);

					}

					formattedData[key] = this.getDisplayedValue(d[key], column);
				}
			}
			return formattedData;
		});

		return {
			data: process(data, {}).data
		};
	}

	private async buildExcelFile(exportNpi: string, columns: GridColumn[], excelExport: ExcelExportComponent, excelExportData: ExcelExportData) {
		const options = excelExport.workbookOptions();
		const exportRows = options.sheets[0].rows;
		const exportColumns = options.sheets[0].columns;
		const exportFooter = this.gridInfo.footer;

		// set autocolumn width
		exportColumns.forEach(x => {
			delete x.width;
			x.autoWidth = true;
		});

		const workbook = await new Workbook({
			sheets: [{
				columns: exportColumns,
				rows: this.buildExportRows(exportNpi, exportRows, columns, excelExportData, exportFooter)
			}] as WorkbookSheet[]
		});

		await workbook.toDataURL().then(async dataUrl => {
			await saveAs(dataUrl, this.excelFileName);
		});
		this.hideMessage(this.gridInfo.reportName);

		// we need to switch this depending on the page, for "reports" we need to remove the sticky headers
		if (this.appCache.page === ADMIN_PAGE_TYPE.reports)
Sticky.removeStickyAnalyzeHeaders();
	}

	private buildExportRows(npi: string, existingRows: WorkbookSheetRow[], columns: GridColumn[], data: ExcelExportData, footerData: any): WorkbookSheetRow[] {
		const rows = data.data;
		const dataSet = [];
		let cleanData = [];

		// get the column data
		const exportColumns = this.getExportColumns(columns, rows);

		// get the existing header background and font color
		const singleExistinCell = existingRows && existingRows[0] && existingRows[0].cells[0];
		if (singleExistinCell) {
			this.backgroundColor = singleExistinCell.background;
			this.foregroundColor = singleExistinCell.color;
		}

		const mergedColumnCount = exportColumns.length;

		// get report name row
		const rowReportName = this.getReportNameData(mergedColumnCount);

		// add in the column headers
		const rowHeaders = existingRows;

		// get the footer data
		const rowFooter = this.getFooterData(footerData, exportColumns);

		// get the empty row
		const rowEmpty = this.getEmptyRow(mergedColumnCount);

		const breakUpCount = 1;

		for (let i = 0; i < breakUpCount; i++) {
			dataSet.push(rowReportName);
			dataSet.push(rowHeaders);
			dataSet.push(this.getExportMainData(rows, exportColumns, i));
			dataSet.push(rowFooter);

			// push an empty row
			dataSet.push(rowEmpty);
		}

		// combine everything only if its not null
		dataSet.forEach(singleArray => {
			if (Array.isArray(singleArray))
				cleanData = [...cleanData, ...singleArray];

		});

		return cleanData;
	}

	private getExportColumns(columns: GridColumn[], data: any[]): GridColumn[] {
		let gridExportColumns = [];

		columns.forEach(singleColumn => {
			if (singleColumn.hidden)
				return;


			const columnValue = singleColumn.columnType === GridColumnType.preheader ?
				singleColumn.columns.filter(x => !x.hidden) : [singleColumn];

			gridExportColumns = gridExportColumns.concat(columnValue);
		});

		return gridExportColumns;
	}

	private getExportMainData(rows: any[], columns: GridColumn[], iteration: number): WorkbookSheetRow[] {
		const dataRows: WorkbookSheetRow[] = [];
		// Handle Main Data
		rows.forEach(singleRow => {
			const cells: WorkbookSheetRowCell[] = [];
			columns.forEach(columnInfo => {
				const cellValue = singleRow[columnInfo.field];
				const cellDataType = columnInfo.dataType;
				const cellFormatAndValue = this.getCellFormatAndValue(cellDataType, 1, cellValue);
				cells.push({
					format: cellFormatAndValue.format,
					value: singleRow[columnInfo.field] || singleRow[columnInfo.field] === 0 ? cellFormatAndValue.value : '',
					wrap: false
				});
			});

			dataRows.push({
				cells,
				height: this.cellHeight
			});
		});

		return dataRows;
	}

	private getFooterData(footerData: any, columns: GridColumn[]) {
		if (!footerData || isEmpty(footerData))
			return null;


		const footerCells: WorkbookSheetRowCell[] = [];
		columns.forEach(columnInfo => {
			const cellValue = footerData[columnInfo.field] ? footerData[columnInfo.field] : '';
			footerCells.push({
				value: this.getDisplayedValue(cellValue, columnInfo),
				background: this.backgroundColor,
				color: this.foregroundColor
			});
		});

		const footerRow: WorkbookSheetRow[] = [{
			cells: footerCells
		}];

		return footerRow;
	}

	private getReportNameData(mergedColumnCount: number) {
		const reportNameRows: WorkbookSheetRow[] = [];
		reportNameRows.push({
			height: 30,
			cells: [{
				background: this.backgroundColor,
				bold: true,
				color: this.foregroundColor,
				fontSize: 30,
				textAlign: 'left',
				value: this.gridInfo.exportTitle || this.gridInfo.reportName,
				colSpan: mergedColumnCount
			}] as WorkbookSheetRowCell[]
		});
		return reportNameRows;
	}

	private getEmptyRow(mergedColumnCount: number) {
		return [
			{
				height: 40,
				cells: [{
					value: '',
					colSpan: mergedColumnCount
				}] as WorkbookSheetRowCell[]
			}
		] as WorkbookSheetRow[];
	}

	private getCellFormatAndValue(columnDataType: string, decimalPoints, cellValue: any) {
		const defaultNoDecimal = '###,###,###';
		const defaultDecimal = '###,###,###.##';
		const empty = '';

		if (!cellValue) {
			return {
				format: empty,
				value: cellValue
			};
		}

		if (isEmpty(columnDataType)) {
			return {
				format: empty,
				value: cellValue
			};
		}

		cellValue = cellValue.toString().replace(/\s/g, '');

		if (cellValue.indexOf('$') !== -1) {
			return {
				format: '$ #,###.##',
				value: parseFloat(cellValue.replace('$', ''))
			};
		}

		if (cellValue.indexOf('%') !== -1) {
			const floatValue = parseFloat(cellValue.replace('%', ''));
			let percentValue = 0;
			if (floatValue !== 0)
				percentValue = floatValue / 100;


			let cellFormat = '0';
			if (decimalPoints && decimalPoints > 0)
				cellFormat = `${cellFormat}.${'0'.repeat(decimalPoints)}`;


			return {
				format: `${cellFormat}%`,
				value: percentValue
			};
		}

		if (cellValue.indexOf('.') !== -1) {
			// in case it has a comma
			return {
				format: defaultDecimal,
				value: parseFloat(cellValue.split(',').join(''))
			};
		}

		if (cellValue.indexOf(',') !== -1) {
			// remove comma and turn into integer
			return {
				format: defaultNoDecimal,
				value: parseInt(cellValue.split(',').join(''), 10)
			};
		}

		// try to parse it as an integer
		const cellInt = parseInt(cellValue, 10);
		if (Number.isNaN(cellInt)) {
			return {
				format: empty,
				value: cellValue
			};
		}

		return {
			format: defaultNoDecimal,
			value: cellInt
		};
	}

	private exitWithError(message: string) {
		this.userFeedbackService.showError(message);
	}


	private permenantMessage(exportMessage: ExportMessage) {
		if (!exportMessage.message || !exportMessage.reportName)
			return;

		this.sendMessageToGrid(exportMessage);
	}

	private hideMessage(reportName: string) {
		clearInterval(this.messageInterval);
		this.closeExportMessage(reportName);
	}

	private async delay(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	private getDisplayedValue(value: any, column: GridColumn) {
		if (!column || !column.format)
			return value;


		// TODO: Create this transform library on the backend!
		if (Utils.doesFormatFunctionExist(column.format)) {
			const transformFunc = Utils.getFormatFunction(column.format);
			return transformFunc(value);
		}

		if (Utils.exists(value) && column.truncateLength)
			return value.substring(0, column.truncateLength);


		if (!Utils.exists(column.format) || !Utils.exists(value) || Utils.isSpecialValue(value))
			return value;


		if (!value)
			return Utils.getDisplayedValue(value, column.format);



		if (column.format.indexOf('%') > -1) {
			// Turn into percent
			value = this.getPercentValueFromDecimal(value);

			if (!value) {
				// Value is special. Handle this generically
				return Utils.getDisplayedValue(value, column.format);
			}

			return value.toFixed(2) + '%';
		}

		if (column.format.indexOf('DATE') > -1)
			return AdminUtils.formatDateValue(value);


		if (column.format.indexOf('I') > -1) {
			// Round to nearest integer
			return round(value);
		}

		if (column.format.indexOf('1') > -1) {
			// Decimal with 1 digit after dot
			return parseFloat(value).toFixed(1);
		}

		return Utils.getDisplayedValue(value, column.format);
	}

	private getPercentValueFromDecimal(value: any): number {
		if (!Utils.exists(value) || Utils.isSpecialValue(value) || value <= 0)
			return 0;


		return value * 100;
	}
}
