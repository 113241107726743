<div class="d-flex justify-flex-end">
    <div class="global-search-holder">
        <mat-form-field [appearance]="'outline'" class="w-100" subscriptSizing="dynamic">
            <input
                #searchInput
                (input)="onChange($event.target.value)"
                [formControl]="myControl"
                [matAutocomplete]="auto"
                [placeholder]="searchPlaceholderText"
                aria-label="Number"
                matInput
                type="text"
            />
            <mat-progress-bar [hidden]="!showLoadBar" mode="indeterminate"></mat-progress-bar>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectItem($event)">
                <mat-option *ngFor="let option of searchResults" [value]="option" class="global-search-option">
                    <div class="d-flex flex-row">
                        <i class="{{ option.icon }} {{ getSelectedColorClass(option.searchResultType) }} provider-icon"></i>
                        <div class="flex-grow-1 provider-info">
                            <div class="provider-line1">
                                <span>{{ option.display }}</span>
                            </div>
                            <div class="provider-line2">
                                <span *ngIf="!option.isAco()">{{ option.npi || option.id }} | {{ option.city }}
                                    , {{ option.state | textTruncate: [22] }} {{ option.zipcode }}
                                    | {{ option.specialty }}</span>
                                <span *ngIf="option.isAco()">{{ option.state | textTruncate: [22] }}</span>
                            </div>
                        </div>
                        <div class="provider-type-label">
                            <span>{{ option.provider }}</span>
                        </div>
                    </div>
                </mat-option>
                <mat-option *ngIf="!searchResults?.length">
                    <div class="px-3">No records available.</div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div class="d-inline-block btn-group dropdown-container ms-3" ngbDropdown>
        <button (click)="openProviderType($event)" class="btn btn-block btn-outline-primary btn-dropdown" id="ProviderTypeButton"
                ngbDropdownToggle>
            {{ selectedProviderType.display }}
            <i class="fa-solid fa-caret-down"></i>
        </button>
        <div aria-labelledby="ProviderTypeButton" ngbDropdownMenu>
            <mat-radio-group [(ngModel)]="selectedProviderType">
                <mat-radio-button
                        (change)="handleFilterChanged(provider)"
                        *ngFor="let provider of options"
                        [class.text-secondary]="provider.id"
                        [value]="provider"
                        class="text-input dropdown-item"
                        color="primary"
                        ngbDropdownItem>
                    {{ provider.display }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>