import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GridActionService {
    gridPaginated: Observable<any>;
    private _gridPaginated = new Subject();
    
    constructor() {
        this.gridPaginated = this._gridPaginated.asObservable();
    }

    paginate(): void {
        this._gridPaginated.next(null);
    }
}
