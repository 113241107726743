import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { IFilterSlim } from '../shared/interfaces/filter-slim.interface';
import { ReportConfigJson } from '@appcore/interfaces/report-config-json.interface';
import { ReportDataResult } from '@appcore/interfaces/report-data-result';
import { QueryOptions } from '@appcore/models/query-options.model';
import { ConfigType } from '@appcore/enums/config-type.enum';

@Injectable({
	providedIn: 'root'
})
export class GridApi {

	private _controllerName = 'admin/Grid';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getConfig(reportName: string): Observable<ReportConfigJson> {
		return this.api.get(this._controllerName, `config/${reportName}`);
	}

	getData(request: Partial<GridDataRequest>): Observable<ReportDataResult> {
		return this.api.post(this._controllerName, `data/${request.reportName}`, request);
	}

	export(reportName: string, npi: string, gridFilters: DataStateChangeEvent, singleFilters: Array<IFilterSlim>): Observable<any[]> {
		const url = `admin/report-export/${reportName}`;
		const body = {
			npi,
			gridFilters,
			singleFilters
		};
		return this.api.post(url, null, body);
	}
}

export interface GridDataRequest {
	additionalMetrics: number[];
	gridFilters: QueryOptions;
	overrideTimeout?: number;
	queryType: string;
	reportName: string;
	skipAllCounts?: boolean;
	sqlFilters: any;
	type: ConfigType;
}
