<kendo-dialog *ngIf="opened" class="app-progress-bar">
	<div *ngIf="displayMessage" class="row px-4">
		<div class="col">
			{{ messageToDisplay }}
		</div>
	</div>
	<div *ngIf="displayMessage" class="row px-4">
		<div *ngIf="showProgressBar">
			<span>{{ percentComplete }}%</span>
			<mat-progress-bar class="mx-2" mode="{{progressType}}" value="{{percentComplete}}"></mat-progress-bar>
			<span>100%</span>
		</div>
	</div>
</kendo-dialog>
