import { Component } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

	constructor(private navigationService: NavigationService) {
	}

	toLogin() {
		this.navigationService.navigateToLogin('', true);
	}
}
