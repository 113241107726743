import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'trella-data-eula',
    templateUrl: './data-eula.component.html',
    styles: [
        `
  			ol li {
  				list-style: decimal;
  				padding-top: 10px;
  				margin-left: 20px;
  			}
  		`
    ]
})
export class DataEulaComponent {
    @Output() onAccept: EventEmitter<boolean> = new EventEmitter();
    @Output() onDecline: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    accept() {
        this.onAccept.emit();
    }

    decline() {
        this.onDecline.emit();
    }
}
