<div class="px-0 trella-filter-group-holder">
    <div class="pt-2 pb-1 w-100">
        <div class="fw-bold">
            Filter By
        </div>
    </div>

    <div class="d-flex flex-row">
        <trella-location
                (locationsChanged)="handleLocationChanged($event)"
                (myLocationSaved)="handleMyLocationSaved($event)"
                (myLocationToggled)="handleMyLocationToggled($event)"
                *ngIf="isIncluded(FILTER_TYPE.PROVIDER_LOCATION)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [allLocations]="filterRules.locations"
                [filterType]="FILTER_TYPE.PROVIDER_LOCATION"
                [header]="'Provider Locations'"
                [isMyLocationActivated]="defaultLocationTypes[FILTER_TYPE.PROVIDER_LOCATION]"
                [isMyLocationsLoading]="isMyLocationsLoading"
                [myLocations]="myLocations"
                [selectedLocations]="providerLocations"
                class="pe-2 pt-1 provider-location"
        ></trella-location>
        <trella-location
                (locationsChanged)="handleLocationChanged($event)"
                (myLocationSaved)="handleMyLocationSaved($event)"
                (myLocationToggled)="handleMyLocationToggled($event)"
                *ngIf="isIncluded(FILTER_TYPE.ACO_PROVIDER_LOCATION)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [allLocations]="filterRules.locations"
                [filterType]="FILTER_TYPE.ACO_PROVIDER_LOCATION"
                [header]="'Provider Locations'"
                [isMyLocationActivated]="defaultLocationTypes[FILTER_TYPE.ACO_PROVIDER_LOCATION]"
                [isMyLocationsLoading]="isMyLocationsLoading"
                [myLocations]="myLocations"
                [selectedLocations]="providerLocations"
                class="pe-2 pt-1 provider-location"
        ></trella-location>
        <trella-location
                (locationsChanged)="handleBeneficiaryLocationChanged($event)"
                (myLocationSaved)="handleMyLocationSaved($event)"
                (myLocationToggled)="handleMyLocationToggled($event)"
                *ngIf="isIncluded(FILTER_TYPE.BENEFICIARY_LOCATION)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [allLocations]="filterRules.locations"
                [filterType]="FILTER_TYPE.BENEFICIARY_LOCATION"
                [header]="'Beneficiary Locations'"
                [isMyLocationActivated]="defaultLocationTypes[FILTER_TYPE.BENEFICIARY_LOCATION]"
                [isMyLocationsLoading]="isMyLocationsLoading"
                [myLocations]="myLocations"
                [selectedLocations]="_beneficiaryLocations"
                [stateCountyOnly]="true"
                class="pe-2 pt-1 beneficiary-location"
        ></trella-location>
        <trella-location
                (locationsChanged)="handleBeneficiaryLocationChanged($event)"
                (myLocationSaved)="handleMyLocationSaved($event)"
                (myLocationToggled)="handleMyLocationToggled($event)"
                *ngIf="isIncluded(FILTER_TYPE.ACO_BENEFICIARY_LOCATION)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [allLocations]="filterRules.locations"
                [filterType]="FILTER_TYPE.ACO_BENEFICIARY_LOCATION"
                [header]="'Beneficiary Locations'"
                [isMyLocationActivated]="defaultLocationTypes[FILTER_TYPE.ACO_BENEFICIARY_LOCATION]"
                [isMyLocationsLoading]="isMyLocationsLoading"
                [myLocations]="myLocations"
                [selectedLocations]="_beneficiaryLocations"
                [stateCountyOnly]="true"
                class="pe-2 pt-1 beneficiary-location"
        ></trella-location>
        <trella-location
                (locationsChanged)="handleBeneficiaryLocationChanged($event)"
                (myLocationSaved)="handleMyLocationSaved($event)"
                (myLocationToggled)="handleMyLocationToggled($event)"
                *ngIf="isIncluded(FILTER_TYPE.PHYSICIAN_GROUP_BENEFICIARY_LOCATION)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [allLocations]="filterRules.locations"
                [filterType]="FILTER_TYPE.PHYSICIAN_GROUP_BENEFICIARY_LOCATION"
                [header]="'Beneficiary Locations'"
                [isMyLocationActivated]="defaultLocationTypes[FILTER_TYPE.PHYSICIAN_GROUP_BENEFICIARY_LOCATION]"
                [isMyLocationsLoading]="isMyLocationsLoading"
                [myLocations]="myLocations"
                [selectedLocations]="_beneficiaryLocations"
                [stateCountyOnly]="true"
                class="pe-2 pt-1 beneficiary-location"
        ></trella-location>
        <trella-multi-level-filter
                (selectionsChanged)="handleSpecialtyChanged($event)"
                *ngIf="isIncluded(FILTER_TYPE.SPECIALTY)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [allNodes]="specialties"
                [caption]="'Specialty'"
                [filterType]="FILTER_TYPE.SPECIALTY"
                [levels]="['Specialty', 'Subspecialty']"
                class="pe-2 pt-1 specialty-filter"
        ></trella-multi-level-filter>
        <trella-affiliation
                (affiliationsChanged)="handleAffiliationsChanged($event)"
                *ngIf="isIncluded(FILTER_TYPE.AFFILIATIONS)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [affiliationRules]="filterRules.affiliations"
                [currentAffiliations]="affiliations"
                class="pe-2 pt-1"
        ></trella-affiliation>
        <trella-simple-filter
                (selectionsChanged)="handleSelectionsChanged($event)"
                *ngIf="isIncluded(FILTER_TYPE.MEDICAL_GROUP)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [caption]="'Medical Group (Billing NPI)'"
                [filterType]="FILTER_TYPE.MEDICAL_GROUP"
                [options]="medicalGroups"
                class="pe-2 pt-1"
        ></trella-simple-filter>
        <trella-range
                (rangeChanged)="handleRangeChanged($event)"
                *ngIf="isIncluded(FILTER_TYPE.RANGE)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [rangeSelections]="rangeFilters"
                [rules]="filterRules.rangeFilters"
                class="pe-2 pt-1"
        ></trella-range>
        <trella-simple-filter
                (selectionsChanged)="handleSelectionsChanged($event)"
                *ngIf="isIncluded(FILTER_TYPE.NETWORK)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [caption]="'User Network'"
                [filterType]="FILTER_TYPE.NETWORK"
                [options]="networks"
                class="pe-2 pt-1"
        ></trella-simple-filter>
        <trella-multi-level-filter
                (selectionsChanged)="handleCmsAcoChanged($event)"
                *ngIf="isIncluded(FILTER_TYPE.ACOS)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [allNodes]="acos"
                [caption]="'MSSP ACO'"
                [filterType]="FILTER_TYPE.ACOS"
                [levels]="['Year', 'MSSP ACO']"
                [selectAllOption]="true"
                class="pe-2 pt-1 mssp-aco-filter"
        ></trella-multi-level-filter>
        <trella-multi-level-filter
                (selectionsChanged)="handleCmsAcoChanged($event)"
                *ngIf="isIncluded(FILTER_TYPE.PHYSICIAN_GROUP_MSSP_ACO)"
                [(definitionFactory)]="definitionFactory"
                [(titleFactory)]="titleFactory"
                [allNodes]="acos"
                [caption]="'MSSP ACO'"
                [filterType]="FILTER_TYPE.PHYSICIAN_GROUP_MSSP_ACO"
                [levels]="['Year', 'MSSP ACO']"
                [selectAllOption]="true"
                class="pe-2 pt-1 mssp-aco-filter"
        ></trella-multi-level-filter>
        <button (click)="reset()" class="btn btn-secondary btn-sm mt-1" type="button">
            Clear Filters
        </button>
    </div>
</div>
