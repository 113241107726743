import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureApi } from '../../api/feature.api';
import { Feature } from '@appcore/models/directory/feature.model';

@Injectable({
	providedIn: 'root'
})
export class FeatureService {
	constructor(private featureApi: FeatureApi) {
	}

	getActiveFeatures(): Observable<Feature[]> {
		return this.featureApi.getAll();
	}
}
