import { Injectable } from '@angular/core';
import { SPARK_TYPE } from '../enums/spark-type.enum';

@Injectable({
    providedIn: 'root'
})
export class EnvService {
    // The values that are defined here are the default values that can
    // be overridden by env.js

    public adminUrl = '';
    public apiUrl = '';
    public appUrl = '';
    public awsconfig = '';
    public cognitoOnly = false;
    public cognitoLoginUrl = '';
    public cognitoLogoutUrl = '';
    public crmUrl = '';
    public defaultClientId = '';
    public domain = '';
    public enableDebug = true;
    public isLocal = true;
    public isProduction = true;
    public insightsUrl = '';
    public KENDO_UI_LICENSE = '';
    public strategyUrl = '';
    public pendoPrefix = 'L';
    public redirectSignIn = '';
    public redirectSignOut = '';
    public sparkUrl = '';
    public sparkUrlLanding = '';
    public userPoolId = '';

    constructor() {
    }

    public cognitoUrl(id: string) {
        const clientId = id || this.defaultClientId;
        return `https://${this.domain}/login?client_id=${clientId}&response_type=code&scope=email+openid+profile&redirect_uri=${this.redirectSignIn}`;
    }

    public getSparkDeepLinkUrl(id: any, type: SPARK_TYPE) {
        return `${this.sparkUrl}/entity/lookup/type,${type}/npi,${id}`;
    }

    public resetPasswordUrl(id: string) {
        const clientId = id || this.defaultClientId;
        return `https://${this.domain}/forgotPassword?client_id=${clientId}&response_type=code&scope=email+openid+profile&redirect_uri=${this.redirectSignIn}`;
    }
}
