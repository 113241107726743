import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Role, RoleContract, UpdateRoleContract } from '@appcore/models/directory/role.model';

@Injectable({
	providedIn: 'root'
})
export class RoleApi {

	private _controllerName = 'Role';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getAll(companyIds: string[]): Observable<RoleContract[]> {
		return this.api.post(this._controllerName, 'AllRoles', companyIds);
	}

	get(roleId: string): Observable<RoleContract> {
		return this.api.get(this._controllerName, `${roleId}`);
	}

	create(role: Role): Observable<string> {
		return this.api.post(this._controllerName, null, role);
	}

	update(role: UpdateRoleContract): Observable<void> {
		return this.api.put(this._controllerName, null, role);
	}

}
