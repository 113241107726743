import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class EulaApi {

	private _controllerName = 'Eula';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	updateEula(choice: boolean) {
		return this.api.post(this._controllerName, null, {
			choice
		});
	}

	updateDataEula(choice: boolean) {
		return this.api.post(this._controllerName, 'data', {
			choice
		});
	}

}
