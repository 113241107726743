import { Pipe, PipeTransform } from '@angular/core';
import { NETWORK_ACCESS_LEVEL, NETWORK_ACCESS_LEVEL_NAME } from '../../../enums/network-access-level.enum';

@Pipe({name: 'networkAccessLevel'})
export class NetworkAccessLevelPipe implements PipeTransform {
    transform(value: NETWORK_ACCESS_LEVEL): string {
        switch (value) {
            case NETWORK_ACCESS_LEVEL.CAN_EDIT:
                return NETWORK_ACCESS_LEVEL_NAME.CAN_EDIT;
            case NETWORK_ACCESS_LEVEL.VIEW_ONLY:
                return NETWORK_ACCESS_LEVEL_NAME.VIEW_ONLY;
            default:
                return null;
        }
    }
}
