<div class="g-0 position-relative">
    <div (click)="toggleMenu()" class="app-menu-container">
        <div *ngIf="isInsights" class="app-menu__item app-menu__item--insights" data-selection="dropdown">
            <div class="app-menu__category">
                <img alt="" data-app="category" src="./assets/img/logo-insights.svg"/>
            </div>
            <div class="app-menu__subcategory">
                <a class="selected" data-app="subcategory" data-menuitem="subcategory">{{ currentPortal.label }}</a>
            </div>
        </div>

        <div *ngIf="isStrategy" class="app-menu__item app-menu__item--strategy" data-selection="dropdown">
            <div class="app-menu__category">
                <img alt="" data-app="category" src="./assets/img/logo-strategy.svg"/>
            </div>
        </div>
    </div>

    <ul *ngIf="menuOpen" class="app-menu">
        <li
                (click)="navigateToCRM()"
                [ngClass]="{ 'app-menu__item--not-subscribed order-5': !hasCRMAccess }"
                class="app-menu__item app-menu__item--crm"
                data-menuitem="marketscape-crm"
        >
            <div class="app-menu__category">
                <img alt="Logo: Marketscape CRM" src="./assets/img/logo-crm.svg"/>
                <div *ngIf="!hasCRMAccess" class="app-menu__item__subscribe-text">
                    Upgrade your package with our <span>Marketscape CRM</span> to streamline your sales efforts and
                    increase productivity.
                </div>
            </div>
        </li>

        <li
                [ngClass]="{ 'app-menu__item--not-subscribed order-6': !hasInsightsProductAccess }"
                class="app-menu__item app-menu__item--insights"
                data-menuitem="marketscape-insights"
        >
            <div class="app-menu__category">
                <img alt="Marketscape Insights" src="./assets/img/logo-insights.svg"/>
            </div>
            <div class="app-menu__subcategory">
                <a
                        (click)="navigateToInsightsPortal(product)"
                        *ngFor="let product of accessibleInsightsProducts"
                        [class.selected]="isCurrentPortal(product)"
                        data-menuitem="subcategory"
                        id="globe_nav_{{ getPortalType(product) }}"
                >{{ getPortalLabel(product) }}</a
                >
            </div>
        </li>

        <li
                (click)="navigateToStrategy()"
                *ngIf="!isStrategy"
                [ngClass]="{ 'app-menu__item--not-subscribed order-7': !hasStrategyAccess }"
                class="app-menu__item app-menu__item--strategy"
                data-menuitem="marketscape-strategy"
        >
            <div class="app-menu__category">
                <img alt="Logo: Marketscape Strategy" src="./assets/img/logo-strategy.svg"/>
                <div *ngIf="!hasStrategyAccess" class="app-menu__item__subscribe-text">
                    Upgrade your package with <span>Marketscape STRATEGY</span> to strengthen your network and evaluate
                    risk.
                </div>
            </div>
        </li>
    </ul>
</div>
