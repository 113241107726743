export class Filter {
    modelJson: string;
    filterType: string;
    paramName?: string;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}


