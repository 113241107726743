import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseFilterComponent } from '../base-filter.component';
import { FILTER_TYPE } from '../../../enums/filter-type.enum';
import { CheckedSelectionGroup } from '../../../interfaces/checked-selection-group.interface';
import { CheckedSelection } from '../../../interfaces/checked-selection.interface';
import { FilterGroupService } from '../../../services/filter-group.service';
import { ALL_OPTION } from '../../../constants/constants';

@Component({
    selector: 'trella-simple-filter',
    templateUrl: './simple-filter.component.html',
    styleUrls: ['./simple-filter.component.scss']
})
export class SimpleFilterComponent extends BaseFilterComponent {
    @Input() titleFactory: (filterType: FILTER_TYPE) => string;
    @Input() caption = '';
    @Input() filterType: FILTER_TYPE;
    @Input() listBoxHeight = '200px';
    @Input() note = null;
    @Input() singleSelect = false;
    @Input() definitionFactory: (filterType: FILTER_TYPE) => string;
    @Output() additionalSelectionsChanged: Observable<CheckedSelectionGroup>;
    @Output() selectionsChanged: Observable<CheckedSelectionGroup>;
    
    additionalFilterOptions: CheckedSelection[];
    filterOptions: CheckedSelection[];

    private _additionalSelectionsChanged: EventEmitter<CheckedSelectionGroup> = new EventEmitter<CheckedSelectionGroup>();
    private _selectionsChanged: EventEmitter<CheckedSelectionGroup> = new EventEmitter<CheckedSelectionGroup>();

    constructor(protected filterGroupService: FilterGroupService) {
        super(filterGroupService);
        this.additionalSelectionsChanged = this._additionalSelectionsChanged.asObservable();
        this.selectionsChanged  = this._selectionsChanged.asObservable();
    }

    get getDefinition(): string {
        return this.definitionFactory && this.definitionFactory(this.filterType) || '';
    }

    get getTitle(): string {
        return this.titleFactory && this.titleFactory(this.filterType) || this.caption;
    }

    get getCount(): number {
        return this.checkedFilterOptions.length;
    }

    get checkedFilterOptions(): CheckedSelection[] {
        return this.filterOptions.filter(o => o.checked);
    }

    get hasAdditionalFilterOptions(): number {
        return this.additionalFilterOptions?.length;
    }

    get hasNote(): boolean {
        return this.note !== null;
    }

    get selectedName(): string {
        return this.checkedFilterOptions[0].display;
    }

    @Input() set additionalOptions(selections: CheckedSelection[]) {
        this.additionalFilterOptions = selections?.map(x => ({...x}));
    }

    @Input() set options(selections: CheckedSelection[]) {
        this.filterOptions = selections.map(x => ({...x}));
    }

    handleAdditionalSelectionsChange(selection: CheckedSelection) {
        if (selection.id === ALL_OPTION) 
            this.handleAllAdditionalSelection(selection);
        
        this._additionalSelectionsChanged.emit({filterType: this.filterType, selections: this.additionalFilterOptions});
    }

    handleSelectionsChange(selection: CheckedSelection) {
        if (selection.id === ALL_OPTION && !this.singleSelect) 
            this.handleAllSelection(selection);
        
        this._selectionsChanged.emit({filterType: this.filterType, selections: this.filterOptions});
    }

    handleAllAdditionalSelection(selection: CheckedSelection) {
        this.additionalFilterOptions.forEach(o => o.checked = selection.checked);
    }

    handleAllSelection(selection: CheckedSelection) {
        this.filterOptions.forEach(o => o.checked = selection.checked);
    }
}
