import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { PercentileQuality } from '../../enums/percentile-quality.enum';
import { SpecialColors } from '../../constants/constants';

@Directive({
    selector: '[mosaic-percentile-evaluation]'
})
export class PercentileEvaluationDirective implements OnChanges {
    @Input('mosaic-percentile-evaluation') quality: PercentileQuality;
    constructor(private el: ElementRef, private renderer: Renderer2) { 
        
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.quality) 
            this.renderer.setStyle(this.el.nativeElement, 'color', this.getColor());
        
    }

    getColor() {
        switch (this.quality) {
            case PercentileQuality.Good:
                return SpecialColors.GoodPercentile;
            case PercentileQuality.Average:
                return SpecialColors.AveragePercentile;
            case PercentileQuality.Bad:
                return SpecialColors.BadPercentile;
            case PercentileQuality.Moot:
            default:
                return SpecialColors.MootPercentile;
        }
    }
}
