export const PERMISSIONS = {
    ADMIN_BULK_IMPORT_ASSIGNMENTS: 'admin.users.bulkassignmentimport',
    ADMIN_BULK_IMPORT_USERS: 'admin.users.bulkimport',
    ADMIN_CODESETS_SHOW: 'admin.codesets.show',
    ADMIN_CODESETS_CREATE: 'admin.codesets.create',
    ADMIN_COMPANIES_CANCOPYROLES: 'admin.companies.cancopyroles',
    ADMIN_CONVERSATION_STARTERS: 'admin.conversationstarters.allow',
    ADMIN_REPORTS: 'admin.reports',
    ADMIN_REPORTS_CODESETS: 'admin.reports.codesets',
    ADMIN_REPORTS_LOGIN: 'admin.reports.login',
    ADMIN_REPORTS_PAGE: 'admin.reports.pageview',
    ADMIN_REPORTS_USAGE: 'admin.reports.usage',
    ADMIN_SCHEDULED_REPORTS: 'admin.scheduledreports',
    ADMIN_SFTP_REPORTS_CREATE: 'admin.sftpreports.create',
    ADMIN_SFTP_REPORTS_SHOW: 'admin.sftpreports.show',
    ADMIN_TEMP_PASSWORD_CREATE: 'admin.temppassword.create',
    ALLOW_E_BUTTON: 'config.edit.allow',
    CHART_CART_CREATE: 'chartcart.create',
    CHART_CART_DELETE: 'chartcart.delete',
    CHART_CART_LIST: 'chartcart.list',
    CODESETS_EXPORT: 'codesets.export.allow',
    CONFIG_SAVE: 'config.save.allow',
    CRM_ALLOW: 'crm.allow',
    DATAKIT_CREATE: 'datakit.create',
    DATAKIT_CREATE_PMV: 'datakit.create.pmv',
    DATAKIT_DELETE: 'datakit.delete',
    DATAKIT_GET: 'datakit.get',
    DATAKIT_LIST: 'datakit.list',
    DATAKIT_SHARE_CUSTOM_OPTION: 'datakit.create.share',
    DATAKIT_SHARE_PUBLIC_OPTION: 'datakit.create.public',
    DATAKIT_UPDATE: 'datakit.update',
    DATA_API_ALL: 'dataapi.all',
    ENHANCED_EXPORT: 'enhanced.export.allow',
    FACILITY_DATA: 'facility.data.allow',
    FACILITY_DATA_EXPORT: 'facility.data.export.allow',
    FAVORITES_CREATE: 'favorites.create',
    FAVORITES_DELETE: 'favorites.delete',
    FAVORITES_LIST: 'favorites.list',
    FILTER_SHARE: 'filters.share',
    FILTER_SHARE_PUBLIC: 'filters.share.public',
    HHA_MARKETSHARE: 'hha.marketshare.allow',
    HHA_MARKETSHARE_EXPORT: 'hha.marketshare.export.allow',
    HOME_AGENCY_SHOW: 'home.agency.show',
    HOME_HEALTH_DATA: 'homehealth.data.allow',
    HOME_HEALTH_DATA_EXPORT: 'homehealth.data.export.allow',
    HOME_LISTS_SHOW: 'home.lists.show',
    HOSPICE_DATA: 'hospice.data.allow',
    HOSPICE_DATA_EXPORT: 'hospice.data.export.allow',
    HOSPICE_MARKETSHARE: 'hos.marketshare.allow',
    HOSPICE_MARKETSHARE_EXPORT: 'hos.marketshare.export.allow',
    MAINTENANCE_LOGIN: 'MaintenanceLogin',
    MARKETSCAPE_ALF_LTC: 'marketscape.alfltc.allow',
    MARKETSCAPE_FACILITY: 'marketscape.facility.allow',
    MARKETSCAPE_HOME_HEALTH: 'marketscape.homehealth.allow',
    MARKETSCAPE_HOSPICE: 'marketscape.hospice.allow',
    MARKETSCAPE_ORGANIZATION: 'marketscape.organization.allow',
    MARKETSCAPE_PHYSICIAN: 'marketscape.physician.allow',
    MARKETSCAPE_PHYSICIAN_GROUP: 'marketscape.physiciangroup.allow',
    MARKETSCAPE_SKILLED_NURSING: 'marketscape.skillednursing.allow',
    NOTIFICATIONS: 'notifications.allow',
    NPI_ASSIGNEE_ADD: 'npiassignee.add',
    NPI_ASSIGNEE_DELETE: 'npiassignee.delete',
    NPI_ASSIGNEE_LIST: 'npiassignee.list',
    NPI_GROUPS_COMPARISON_ADD: 'npigroups.comparison.npi.add',
    NPI_GROUPS_COMPARISON_CREATE: 'npigroups.comparison.create',
    NPI_GROUPS_COMPARISON_DELETE: 'npigroups.comparison.delete',
    NPI_GROUPS_COMPARISON_NPI_DELETE: 'npigroups.comparison.npi.delete',
    NPI_GROUPS_CREATE: 'npigroups.create',
    NPI_GROUPS_DELETE: 'npigroups.delete',
    NPI_GROUPS_LIST: 'npigroups.list',
    NPI_GROUPS_UPDATE: 'npigroups.update',
    ORGANIZATION_DATA: 'organization.data.allow',
    ORGANIZATION_DATA_EXPORT: 'organization.data.export.allow',
    PAGE_ACTION_GROUPS_SHOW: 'page.actiongroups.show',
    PAGE_ANALYZE_ALL_TABS_SHOW: 'page.analyze.alltabsexceptsalesspotlight.show',
    PAGE_ANALYZE_DESTINATIONS_SHOW: 'page.analyze.destinations.show',
    PAGE_ANALYZE_PRACTICE_DETAILS_SHOW: 'page.analyze.practicedetails.show',
    PAGE_ANALYZE_LEADS_LIMITED: 'page.analyze.leads.limited',
    PAGE_ANALYZE_ORDERSHARE_SHOW: 'page.analyze.ordershare.show',
    PAGE_ANALYZE_SALES_SPOTLIGHT_SHOW: 'page.analyze.salesspotlight.show',
    PAGE_ANALYZE_SHOW: 'page.analyze.show',
    PAGE_ASSESS_AGENCY_FACILITY_SHOW: 'page.assess.agenciesfac.show',
    PAGE_ASSESS_MARKET_SHARE_SHOW: 'page.assess.marketshare.show',
    PAGE_ASSESS_SHOW: 'page.assess.show',
    PAGE_CODESETS_SHOW: 'page.codesets.show',
    PAGE_COMPANIES_SHOW: 'page.customers.show',
    PAGE_COMPARISON_BENCHMARK: 'page.reports.comparisonbenchmark',
    PAGE_COUNTY_TRENDED: 'page.reports.countytrends',
    PAGE_DASHBOARD_SHOW: 'page.dashboard.show',
    PAGE_ENGAGE_SHOW: 'page.engage.show',
    PAGE_EXPLORE_HME_BILLING_ORGANIZATION_SHOW: 'page.explore.hme.billing.organization.show',
    PAGE_EXPLORE_HME_REFERRING_HOMEHEALTH_SHOW: 'page.explore.hme.referring.homehealth.show',
    PAGE_EXPLORE_HME_REFERRING_HOSPICE_SHOW: 'page.explore.hme.referring.hospice.show',
    PAGE_EXPLORE_HME_REFERRING_HOSPITAL_SHOW: 'page.explore.hme.referring.hospital.show',
    PAGE_EXPLORE_HME_REFERRING_LONGTERMCARE_SHOW: 'page.explore.hme.referring.longtermcare.show',
    PAGE_EXPLORE_HME_REFERRING_PHYSICIANS_SHOW: 'page.explore.hme.referring.physicians.show',
    PAGE_EXPLORE_HME_REFERRING_SKILLEDNURSING_SHOW: 'page.explore.hme.referring.skillednursing.show',
    PAGE_EXPLORE_SHOW: 'page.explore.show',
    PAGE_LEAD_GENERATOR_SHOW: 'page.leadgenerator.show',
    PAGE_MARKET_EVALUATION_SHOW: 'page.marketevaluation.show',
    PAGE_MARKET_SHARE_SHOW: 'page.reports.marketshare',
    PAGE_MEDICARE_ADVANTAGE_CONTRACTS_SHOW: 'page.reports.medicareadvantagecontracts.show',
    PAGE_MEDICARE_REIMBURSEMENT_SHOW: 'page.reports.medicarereimbursement',
    PAGE_MOSAIC_USER_NETWORKS_SHOW: 'page.mosaic.usernetworks.show',
    PAGE_NPI_GROUPS_SHOW: 'page.npigroups.show',
    PAGE_REPORTS_SHOW: 'page.reports.show',
    PAGE_ROLES_SHOW: 'page.roles.show',
    PAGE_USERS_SHOW: 'page.users.show',
    PHYSICIAN_DATA: 'physician.data.allow',
    PHYSICIAN_DATA_EXPORT: 'physician.data.export.allow',
    PHYSICIAN_GROUP_DATA: 'physiciangroup.data.allow',
    PHYSICIAN_GROUP_DATA_EXPORT: 'physiciangroup.data.export.allow',
    PROVIDER_EDIT: 'provider.edit',
    PROVIDER_EDIT_NOTES: 'provider.edit.notes',
    SALES_SPOTLIGHT: 'salesspotlight.allow',
    SFTP: 'sftp.allow',
    SKILLED_NURSING_DATA: 'skillednursing.data.allow',
    SKILLED_NURSING_DATA_EXPORT: 'skillednursing.data.export.allow',
    SKILLED_NURSING_MARKETSHARE: 'snf.marketshare.allow',
    SKILLED_NURSING_MARKETSHARE_EXPORT: 'snf.marketshare.export.allow',
    SPROUT_DATA: 'sprout.data.allow',
    SPROUT_DATA_EXPORT: 'sprout.data.export',
    TARGETS_CREATE: 'targets.create',
    TARGETS_DELETE: 'targets.delete',
    TARGETS_EDIT: 'targets.update',
    TARGETS_LIST: 'targets.list',
    TOTAL_COST_CARE: 'page.reports.totalcostcare',
    TRELLA_CUSTOMER_ADMIN: 'trella.customer.admin',
    TRELLA_SUPER_ADMIN: 'trella.super.admin'
};
