import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { EnvService } from './env.service';
import { InsightsNavigatorService } from './insights-navigator.service';
import { TokenService } from './token.service';
import { ROUTE_CONSTANTS } from '../constants/constants';
import { PORTAL_TYPE } from '../enums/portal-type.enum';

@Injectable({
    providedIn: 'root'
})
export class CrossAppNavigationService {
    constructor(private router: Router, private environmentService: EnvService, private insightsNavigator: InsightsNavigatorService) {
    }

    async navigateToAdmin() {
        const token = await TokenService.getCognitoToken();
        this.navigateToExternal(`${this.environmentService.adminUrl}/${ROUTE_CONSTANTS.sso.redirectRouteLabel}?${ROUTE_CONSTANTS.sso.tokenGetLabel}=${token}`);
    }

    async navigateToInsightsPortal(portal: PORTAL_TYPE) {
        this.navigateToExternal(`${this.environmentService.insightsUrl}/${ROUTE_CONSTANTS.sso.redirectRouteLabel}?${ROUTE_CONSTANTS.sso.portalGetLabel}=${portal}`);
    }

    async navigateToInsightsPortalFromInsights(portal: PORTAL_TYPE, currentUrlTree: UrlTree) {
        let url = `${this.environmentService.insightsUrl}/`;
        const extra = this.insightsNavigator.getNavigationUrl(portal, currentUrlTree);
        if (extra) 
            url += extra;
        
        await this.navigateToInternal([url]);
        window.location.reload();
    }

    navigateToExternalApp(url: string, token: string, method = 'POST') {
        const ssoFormGroup = [
            {
                key: `${ROUTE_CONSTANTS.sso.tokenGetLabel}`,
                value: token
            }
        ];
        this.navigateUsingSubmitForm(url, method, ssoFormGroup);
    }

    async navigateToSparkLanding() {
        const token = await TokenService.getCognitoToken();
        this.navigateToExternalApp(`${this.environmentService.sparkUrlLanding}`, token);
    }

    async navigateToStrategy() {
        this.navigateToExternal(`${this.environmentService.strategyUrl}`);
    }

    navigateToInternal(relativePath?: Array<string>, params?: any): Promise<boolean> {
        const extras = params ? {queryParams: params} as NavigationExtras : {};

        if (!relativePath) {
            this.router.navigateByUrl('', extras);
            return;
        }

        const url = this.router.createUrlTree([...relativePath], extras);
        return this.router.navigateByUrl(url);
    }

    navigateToExternal(url: string) {
        window.open(url);
    }

    navigateUsingSubmitForm(action, method, formGroup) {
        // Here we forward the user by creating/submitting a form with a payload
        // This avoids adding the payload as a URL parameter. (it is more secure)
        const form = document.createElement('form');
        form.style.display = 'none';
        form.method = method;
        form.action = action;
        let input;
        for (const item of formGroup) {
            input = document.createElement('input');
            input.name = item.key;
            input.id = item.key;
            input.value = item.value;
            form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
    }
}
