import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { EnumTypeDataContent } from '../../interfaces/enum-type-data-content.interface';
import { GridCellContentProcessor } from '../../constants/grid-cell.constant';

@Directive({
    selector: '[grid-cell-icon]'
})
export class GridCellIconDirective implements OnChanges {
    @Input('grid-cell-icon') content: EnumTypeDataContent;
    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.content) {
            const appearance = GridCellContentProcessor.process(this.content);
            const iconClass = appearance && appearance.iconClass;
            const swatch = appearance && appearance.colorSwatch;

            this.clearClasses();

            if (iconClass) 
                this.addClass(iconClass);
            

            if (swatch) 
                this.addClass(`color_swatch ${swatch}`);
            
        }
    }

    clearClasses() {
        const classesToRemove = [...this.el.nativeElement.classList] as string[];
        classesToRemove.forEach(c => this.renderer.removeClass(this.el.nativeElement, c));
    }

    addClass(text: string) {
        // Renderer2 can only add one class at a time.
        const classes = text.split(' ');
        classes.map(c => this.renderer.addClass(this.el.nativeElement, c));
    }
}
