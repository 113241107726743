import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AdminFilterGroupComponent } from './admin-filter-group/admin-filter-group.component';
import { HasPermissionDirective } from '../shared/directives/hasPermissions.directive';
import { ActionGroupService } from '../shared/services/action-group.service';
import { AssignAnywhereService } from '../shared/services/assign-anywhere.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { CompanyService } from '../shared/services/company.service';
import { FeatureService } from '../shared/services/feature.service';
import { ImportAssignmentsService } from '../shared/services/import-assignments.service';
import { MarketService } from '../shared/services/market.service';
import { NpigroupService } from '../shared/services/npigroup.service';
import { ProductService } from '../shared/services/product.service';
import { RoleService } from '../shared/services/role.service';
import { ScheduledReportsService } from '../shared/services/scheduledreports.service';
import { UploadUsersService } from '../shared/services/upload-users.service';
import { UserService } from '../shared/services/user.service';
import { AdminGridComponent } from './admin-grid/admin-grid.component';
import { AdminListBoxComponent } from './admin-list-box/admin-list-box.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { GridComponent } from './app-grid/app-grid.component';
import { AssignmentSelectionComponent } from './assignment-selection/assignment-selection.component';
import { CustomerFilterComponent } from './customer-filter/customer-filter.component';
import { ElementNotesComponent } from './element-notes/element-notes.component';
import { FilterListPipe } from './filter-list.pipe';
import { ListBoxComponent } from './list-box/list-box.component';
import { ModalProgressBarComponent } from './modal-progress-bar/modal-progress-bar.component';
import { MultiSelectListBoxComponent } from './multi-select-list-box/multi-select-list-box.component';
import { NpiSearchSelectComponent } from './npi-search-select/npi-search-select.component';
import { ReportDetailsComponent } from './report-details/report-details.component';
import { ComponentsModule } from '@appcore/components/components.module';


@NgModule({
	imports: [
		CommonModule, FormsModule, ReactiveFormsModule,
		MatAutocompleteModule, MatButtonModule, MatFormFieldModule, MatSelectModule, MatExpansionModule, MatChipsModule,
		MatCheckboxModule, MatSlideToggleModule, MatDatepickerModule, MatInputModule, MatNativeDateModule,
		MatTabsModule, MatRadioModule, MatProgressBarModule, TooltipModule, PopupModule, ChartsModule, DialogsModule,
		DateInputsModule, ScrollingModule, MatListModule, DropDownListModule, ComponentsModule, GridModule, ExcelModule
	],
	declarations: [
		FilterListPipe, NpiSearchSelectComponent, GridComponent, AdminGridComponent, MultiSelectListBoxComponent,
		ListBoxComponent, AlertDialogComponent, CustomerFilterComponent, AssignmentSelectionComponent,
		HasPermissionDirective, ReportDetailsComponent, AdminFilterGroupComponent, AlertDialogComponent,
		ModalProgressBarComponent, ElementNotesComponent, AdminListBoxComponent
	],
	providers: [AssignAnywhereService, AuthenticationService, ActionGroupService, FeatureService,
		ProductService, MarketService, RoleService, UploadUsersService, ImportAssignmentsService, UserService,
		CompanyService, NpigroupService, ScheduledReportsService],
	exports: [
		CommonModule, FormsModule, ReactiveFormsModule,
		MatAutocompleteModule, MatButtonModule, MatFormFieldModule, MatSelectModule, MatExpansionModule, MatChipsModule,
		MatCheckboxModule, MatSlideToggleModule, MatDatepickerModule, MatInputModule, MatNativeDateModule,
		MatTabsModule, MatRadioModule, MatProgressBarModule, TooltipModule, PopupModule, ChartsModule, DialogsModule,
		DateInputsModule, ScrollingModule, MatListModule, DropDownListModule,

		FilterListPipe, NpiSearchSelectComponent, GridComponent, AdminGridComponent, MultiSelectListBoxComponent,
		ListBoxComponent, AlertDialogComponent, CustomerFilterComponent, AssignmentSelectionComponent,
		HasPermissionDirective, AdminFilterGroupComponent, HasPermissionDirective, AlertDialogComponent,
		ModalProgressBarComponent, ElementNotesComponent, AdminListBoxComponent
	]
})
export class AdminComponentsModule {
}
