<div class="row g-0 align-items-center min-height">
	<div class="col d-flex flex-column">
		<span class="text-label">{{ sourceListTitle }}<span *ngIf="isRequired">*</span></span>
		<input [(ngModel)]="searchSource" [class.hidden]="!sourceSearchable" class="mt-1 mb-1 k-textbox searchFilter"
			   name="searchSource"
			   placeholder="Search" type="text">
		<div class="border rounded">
			<cdk-virtual-scroll-viewport itemSize="25">
				<div *cdkVirtualFor="let item of source | search:'display':searchSource"
					 [class.active]="selectedSourceItems.includes(item)">
					<div class="ps-1">
						<mat-list-item (click)="highlightSourceItem(item)"
									   class="pointer disable-text-selection">{{ item.display }}
						</mat-list-item>
					</div>
					<mat-divider></mat-divider>
				</div>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
	<div [ngClass]="{'move-arrows-down': sourceSearchable }" class="col-auto flex-column px-2">
		<div class="col mb-1">
			<button (click)="moveAllToSourceList()" class="btn-sm btn-light button-width">
				<i class="fas fa-chevron-double-left fa-xs pointer"></i>
			</button>
		</div>
		<div class="col mb-1">
			<button (click)="moveToSourceList()" class="btn-sm btn-light button-width">
				<i class="fas fa-chevron-left fa-xs pointer"></i>
			</button>
		</div>
		<div class="col mb-1">
			<button (click)="moveToDestinationList()" class="btn-sm btn-light button-width">
				<i class="fas fa-chevron-right fa-xs pointer"></i>
			</button>
		</div>
		<div class="col mb-1">
			<button (click)="moveAllToDestinationList()" class="btn-sm btn-light button-width">
				<i class="fas fa-chevron-double-right fa-xs pointer"></i>
			</button>
		</div>
	</div>
	<div class="col d-flex flex-column">
		<span class="text-label">{{ destinationListTitle }}</span>
		<input [(ngModel)]="searchDestination" [class.hidden]="!destinationSearchable"
			   class="mt-1 mb-1 k-textbox searchFilter"
			   name="searchDestination"
			   placeholder="Search" type="text">
		<div class="border rounded">
			<cdk-virtual-scroll-viewport itemSize="25">
				<div *cdkVirtualFor="let item of destination | search:'display':searchDestination"
					 [class.active]="selectedDestinationItems.includes(item)">
					<div class="ps-1">
						<mat-list-item (click)="highlightDestinationItem(item)"
									   class="pointer disable-text-selection">{{ item.display }}
						</mat-list-item>
					</div>
					<mat-divider></mat-divider>
				</div>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
</div>
<div class="row no gutters multi-select-margin">
	<div class="col d-flex flex-column">
		<span *ngIf="displaySelectedSourceItemDisplay"
			  class="text-data word-break help-text">{{ selectedSourceItemDisplay }}</span>
	</div>

	<div class="col-auto flex-column px-2">
		<div class="col"></div>
		<div class="col"></div>
	</div>

	<div class="col d-flex flex-column">
		<span *ngIf="displaySelectedDestinationItemDisplay"
			  class="text-data word-break help-text">{{ selectedDestinationItemDisplay }}</span>
	</div>
</div>
