import { Injectable } from '@angular/core';
import { ROUTE_CONSTANTS } from '../../shared/constants/route-constants.constant';
import { IRouteAttr } from '../../shared/interfaces/route-attr.interface';
import { NavigationService } from '../../shared/services/navigation.service';
import { PermissionsService } from '../../shared/services/permission.service';

@Injectable({
	providedIn: 'root',
})
export class NavbarService {
	sideNavLinks;

	private readonly lastPage = 'last_saved_page';

	constructor(
		private navigationService: NavigationService,
		private permissionService: PermissionsService,
	) {}

	public checkNavLinkPermission(route: IRouteAttr): boolean {
		if (!route.permission) 
			return false;
		

		return this.permissionService.hasPermissions(route.permission);
	}

	public checkRoutePermissions(permission: string): void {
		if (!this.sideNavLinks) 
			this.init();
		
		
		const hasAccess = this.permissionService.hasPermissions(permission);
		if (this.sideNavLinks.length && !hasAccess) {
			this.loadDefaultPage();
			return;
		}

		if (!this.sideNavLinks.length) {
			this.navigationService.navigateToUnauthorized();
			return;
		}
	}

	public init(): void {
		this.sideNavLinks = [
			this.buildNavlink(ROUTE_CONSTANTS.actionGroup),
			this.buildNavlink(ROUTE_CONSTANTS.codeSets),
			this.buildNavlink(ROUTE_CONSTANTS.companies),
			this.buildNavlink(ROUTE_CONSTANTS.npiGroup),
			this.buildNavlink(ROUTE_CONSTANTS.permissions),
			this.buildNavlink(ROUTE_CONSTANTS.reportsScheduled),
			this.buildNavlink(ROUTE_CONSTANTS.reportsSftp),
			this.buildNavlink(ROUTE_CONSTANTS.reportsUsage),
			this.buildNavlink(ROUTE_CONSTANTS.roles),
			this.buildNavlink(ROUTE_CONSTANTS.sprouts),
			this.buildNavlink(ROUTE_CONSTANTS.talkingPoints),
			this.buildNavlink(ROUTE_CONSTANTS.userNetworks),
			this.buildNavlink(ROUTE_CONSTANTS.users)
		];

		this.sideNavLinks = this.sideNavLinks.filter(link => link);
	}

	public loadLastPage(): void {
		if (!this.sideNavLinks) 
			this.init();
		

		// Navigating to the last saved page.
		const lastPageValue = localStorage.getItem(this.lastPage);
		const lastPageLink = this.sideNavLinks.find(link => link.route === lastPageValue);
		if (lastPageValue && lastPageLink) {
			this.navigateTo(lastPageLink);
			return;
		}

		// Navigating to the default page (Users).
		const defaultPageLink = this.sideNavLinks.find(link => link.route === `/${ROUTE_CONSTANTS.users.route}`);
		if (defaultPageLink) {
			this.navigateTo(defaultPageLink);
			return;
		}
		
		// Navigating to the first page in the array of links.
		if (this.sideNavLinks.length) {
			this.navigateTo(this.sideNavLinks[0]);
			return;
		}

		// Navigating to the unauthorized page if the array of links is empty.
		this.navigationService.navigateToUnauthorized();
	}

	public saveLastPage(link: IRouteAttr): void {
		localStorage.setItem(this.lastPage, link.route);
	}

	private buildNavlink(route: IRouteAttr, childRoute?: IRouteAttr): any {
		if (!route || (!route.bypassPermission && !this.checkNavLinkPermission(route))) 
			return;
		

		const navLinkRoute = !childRoute ? `/${route.route}` : `/${route.route}/${childRoute.route}`;
		return {
			title: route.label,
			icon: route.icon,
			route: navLinkRoute,
			permission: route.permission
		};
	}

	private loadDefaultPage(): void {
		let defaultPageLink = this.sideNavLinks.find(link => link.route === `/${ROUTE_CONSTANTS.users.route}`);
		if (defaultPageLink) {
			this.navigationService.navigateToApp(defaultPageLink.route);
			return;
		}

		defaultPageLink = this.sideNavLinks[0];
		this.navigationService.navigateToApp(defaultPageLink.route);
	}

	private navigateTo(sideNavLink: any): void {
		this.navigationService.navigateToApp(sideNavLink.route);
		this.checkRoutePermissions(sideNavLink.permission);
	}
}
