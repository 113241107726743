
export enum RISK_CATEGORY {
    VeryLow = 'Very Low',
    Low = 'Low',
    MediumLow = 'Medium - Low',
    Medium = 'Medium',
    MediumHigh = 'Medium - High',
    High = 'High',
    VeryHigh = 'Very High'
}
