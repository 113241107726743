import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Keyed, subscribeToKeyed } from '../models/keyed';

@Component({template: ''})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ComponentWithSubscription implements OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    protected subscribe<T>(observable: Observable<Keyed<T>>, key: string, observe: (t: T) => void): Subscription {
        return subscribeToKeyed(observable.pipe(takeUntil(this.ngUnsubscribe)), key, observe);
    }
}
