import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { EnumTypeDataContent } from '../../interfaces/enum-type-data-content.interface';
import { GridCellEnumType } from '../../enums/grid-cell-enum-type.enum';

@Directive({
    selector: '[smart-comparison]'
})
export class SmartComparisonDirective implements OnChanges {
    @Input('smart-comparison') content: EnumTypeDataContent;
    
    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.content) {
            this.clearClasses();
            this.addClass('text-data-xs');
            this.addClass('fw-bold');
            this.addClass(this.getIconClass());
            this.addClass(this.getColorClass());
        }
    }

    clearClasses() {
        const classesToRemove = [...this.el.nativeElement.classList] as string[];
        classesToRemove.forEach(c => this.renderer.removeClass(this.el.nativeElement, c));
    }

    addClass(text: string) {
        // Renderer2 can only add one class at a time.
        const classes = text.split(' ');
        classes.map(c => this.renderer.addClass(this.el.nativeElement, c));
    }

    private getIconClass(): string {
        const content = this.content;
        if (!content || !content.data || +content.data === 0) 
            return 'add-icon-equals';
        
        const isPositive = +content.data > 0;
        return isPositive ? 'add-icon-positive' : 'add-icon-negative';
    }

    private getColorClass(): string {
        const content = this.content;
        if (!content || !content.data || +content.data === 0) 
            return 'swatch_none';
        
        const isPositive = +content.data > 0;
        return isPositive ?
            content.enumType === GridCellEnumType.ComparisonHigherGood ? 'swatch_GoodPercentile' : 'swatch_BadPercentile' :
            content.enumType === GridCellEnumType.ComparisonLowerGood ? 'swatch_GoodPercentile' : 'swatch_BadPercentile';
    }
}
