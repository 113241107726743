import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    static async getCognitoToken() {
        return Auth.currentSession()
            .then(res => res.getIdToken().getJwtToken())
            .catch(err => {
                // the user not authorized, clear the cookie data
                console.log(err);
                return null;
            });
    }
}
