import { Injectable } from '@angular/core';
import { NpiGroupApi } from '../../api/npi-group.api';
import { ExistingNpiGroupInfo, NewNpiGroupInfo, NpiGroup } from '../models/npi-group';
import { apiNpiGroupToSelectListDisplay } from '../../api/api.utils';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class NpigroupService {
	constructor(private npiGroupApi: NpiGroupApi) {
	}

	getNpiGroupTypes() {
		return this.npiGroupApi.getNpiGroupTypes();
	}

	get(id: number): Observable<NpiGroup> {
		return this.npiGroupApi.get(id);
	}

	getAvailableNpiGroups(customerId: string) {
		return this.npiGroupApi.getAvailableNpiGroups(customerId).pipe(apiNpiGroupToSelectListDisplay());
	}

	verifyExisting(existingNpiGroupInfo: ExistingNpiGroupInfo) {
		return this.npiGroupApi.verifyExisting(existingNpiGroupInfo);
	}

	verifyNew(newNpiGroupInfo: NewNpiGroupInfo) {
		return this.npiGroupApi.verifyNew(newNpiGroupInfo);
	}

	finalizeImport(fileId: number) {
		return this.npiGroupApi.finalizeImport({fileId});
	}

	cleanupImport(fileId: number) {
		return this.npiGroupApi.cleanupImport({fileId});
	}
}
