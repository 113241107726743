export class ChartDataSeries {
    categories: string[];
    data: ChartDataElement[];
    isValidData: boolean;

    constructor(data: Partial<ChartDataSeries> = null) {
        if (data)
            Object.assign(this, data);

    }
}

export class ChartDataElement {
    data?: number | any | any[];
    rawData?: any;
    label?: string;
    xField?: any;
    xFieldRaw?: any;
    yField?: any;
    yFieldRaw?: any;
    radius?: any;
    radiusRaw?: any;
    color?: string;
    opacity?: string;
    facilityType?: any;
    rating?: any;
    text?: any;
    formatter?: any;
    npiname?: any;
    istopx?: boolean;
    notes?: any;
    blank?: any;
    empty?: any;
    decimalPoints?: number;

    // this field should be removed after venn diagrams
    // will preload data for each configuration at a time
    isValidData?: boolean;
    percentile?: string;
    riskCategory?: string;
}

export class InsightAdapterOptions {
    istopx: boolean;
    npiName: string;
    percentileText?: string;
    constructor(options?: Partial<InsightAdapterOptions>) {
        if (options) 
            Object.assign(this, options);
        
    }
}

export type AdapterOptionsType = InsightAdapterOptions;
