<trella-hover-tooltip *ngIf="!!getDefinition" [position]="'top'" [tooltipTemplate]="DefinitionTemplate"
                      [underline]="false">
    <div (click)="toggleFilter()"
         class="btn text-input d-flex flex-row align-items-center flex-nowrap btn-outline-primary">
		<span class="me-3">
			{{ getTitle }} <span *ngIf="selectedDate">({{ selectedDate.toLocaleDateString() }})</span>
		</span>
        <i [class.fa-chevron-down]="isOpen" [class.fa-chevron-right]="!isOpen" class="fas"></i>
    </div>
</trella-hover-tooltip>
<ng-template #DefinitionTemplate>
    <div class="p-1 text-center">
        {{ getDefinition }}
    </div>
</ng-template>
<div (click)="toggleFilter()" *ngIf="!getDefinition"
     class="btn text-input d-flex flex-row align-items-center flex-nowrap btn-outline-primary">
		<span class="me-3">
			{{ getTitle }} <span *ngIf="selectedDate">({{ selectedDate.toLocaleDateString() }})</span>
		</span>
    <i [class.fa-chevron-down]="isOpen" [class.fa-chevron-right]="!isOpen" class="fas"></i>
</div>

<div *ngIf="isOpen" class="simple-filter">
    <kendo-calendar (valueChange)="handleSelectionChange($event)" [value]="selectedDate"
                    type="classic"></kendo-calendar>
</div>
	