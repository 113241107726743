import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../shared/services/authentication.service';
import { SSOService } from '../shared/services/sso.service';
import { NavigationService } from '../shared/services/navigation.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthenticationService,
				private ssoService: SSOService,
				private activatedRoute: ActivatedRoute,
				private navigationService: NavigationService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.handle(request, next);
	}

	handle(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.authenticationService.getCognitoToken()).pipe(
			mergeMap(authToken => {
				const authRequest = request.clone({
					setHeaders: {
						Authorization: `Bearer ${authToken}`
					}
				});

				return next.handle(authRequest).pipe(
					catchError((error: HttpErrorResponse) => {
						if (error.status && error.status === 401) {
							const ssoSource = this.ssoService.determineSource(this.activatedRoute.snapshot);
							if (ssoSource) 
								this.ssoService.navigateToSSObySource(ssoSource);
							
							else 
								this.authenticationService.logout().then(() => this.navigationService.navigateToLogOut());
							

							return of(null);
						}

						return throwError(error);
					}));
			}));
	}
}

export const authInterceptorProviders = [
	{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
];
