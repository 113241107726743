<span
        [titleTemplate]="titleTemplate"
        [tooltipTemplate]="tooltipTemplate"
        filter=".hover-content"
        kendoTooltip
        position="{{ position }}"
        tooltipWidth="{{ width }}"
>
	<div [class.underline]="underline" class="hover-content">
    <ng-content></ng-content>
  </div>
</span>
