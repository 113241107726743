
export enum PORTAL_TYPE {
    ADMIN = 'admin',
    CAREPATH = 'carepath',
    DEFAULT = 'default',
    HHA = 'hha',
    HME = 'hme',
    HOS = 'hos',
    NONE = '',
    SNF = 'snf'
}
