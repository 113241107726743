import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionGroupApi } from '../../api/action-group.api.';
import {
	ActionGroup,
	ActionGroupContract,
	UpdateActionGroupContract
} from '@appcore/models/directory/action-group.model';

@Injectable({
	providedIn: 'root'
})
export class ActionGroupService {
	constructor(private actionGroupApi: ActionGroupApi) {
	}

	createActionGroup(actionGroup: ActionGroup): Observable<number> {
		return this.actionGroupApi.create(actionGroup);
	}

	updateActionGroup(actionGroup: UpdateActionGroupContract): Observable<void> {
		return this.actionGroupApi.update(actionGroup);
	}

	getActionGroup(actionGroupApi: number): Observable<ActionGroupContract> {
		if (!actionGroupApi)
			return;

		return this.actionGroupApi.get(actionGroupApi);
	}

	getAllActionGroups(): Observable<ActionGroupContract[]> {
		return this.actionGroupApi.getAll();
	}
}
