import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'trella-components',
    template: `
		<div class="row justify-content-md-center">
			<div class="col-centered">
				<p>
					This is just an example!
				</p>
			</div>
		</div>
	`,
    styles: ['p { color: red; }']
})
export class ComponentsComponent {
    constructor() {
    }
}
