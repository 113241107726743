import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from './shared/services/navigation.service';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';
import { NotificationsComponent } from '@appcore/components/notifications/notifications.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	@ViewChild(NotificationsComponent, {static: true}) notifications: NotificationsComponent;

	constructor(private navigationService: NavigationService, private userFeedbackService: UserFeedbackService) {
	}

	ngOnInit() {
		this.navigationService.initUrlStack();
		this.userFeedbackService.setGlobalNotificationComponent(this.notifications);
	}
}
