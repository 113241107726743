import { Component, OnInit } from '@angular/core';
import { ApplicationCacheService } from '../shared/services/application-cache.service';
import { AuthenticationService } from '../shared/services/authentication.service';
import { NavigationService } from '../shared/services/navigation.service';

@Component({
	selector: 'app-unauthorized-access',
	templateUrl: './unauthorized-access.component.html',
	styleUrls: ['./unauthorized-access.component.scss']
})
export class UnauthorizedAccessComponent implements OnInit {

	username: string;

	constructor(private navigationService: NavigationService, private applicationCacheService: ApplicationCacheService,
				private authenticationService: AuthenticationService) {
	}

	async ngOnInit() {
		const user = this.applicationCacheService.user;
		if (user) {
			this.username = user.email || user.name;
			return;
		}
		const token = await this.authenticationService.getCognitoToken();
		const decode = this.authenticationService.decodedToken(token);
		if (decode) {
			this.username = decode.email || decode.username || decode.name || 'Unknown Account';
			return;
		}

		await this.logout();
	}

	toPrevious() {
		this.navigationService.navigateToPrevious();
	}

	async logout() {
		await this.authenticationService.logout().then(() => this.navigationService.navigateToLogOut());
	}
}
