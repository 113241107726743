import { DestinationType } from '../enums/destination-type.enum';
import { GridCellEnumType } from '../enums/grid-cell-enum-type.enum';
import { CellContentProcessorTemplate } from '../interfaces/cell-content-processor-template.interface';
import { GridCellCheck } from '../enums/grid-cell-check.enum';
import { SpecialColors } from './constants';
import { EnumTypeDataContent } from '../interfaces/enum-type-data-content.interface';
import { GridColumn } from '../models/grid-column.model';
import { FormattedData } from '../interfaces/formatted-data.interface';


const unknown = 'swatch_unknown';

const isDestinationType = (value?: string) => {
    if (!value) 
        return false;
    

    const values = Object.values(DestinationType);
    return !!values.find(v => v.toString() === value);
};

const isExpectedEnumType = (enumType: string, expectedType: GridCellEnumType) => {
    const expectedText = expectedType.toString();
    return enumType === expectedText;
};

const ContentTemplates: CellContentProcessorTemplate[] = [
    {
        match: content => isExpectedEnumType(content.enumType, GridCellEnumType.Check),
        convert: content => ({
            colorSwatch: unknown,
            iconClass: content.data === GridCellCheck.On.toString() ? 'far fa-check' : null
        })
    },
    {
        match: content => isExpectedEnumType(content.enumType, GridCellEnumType.DestinationType),
        convert: content => ({
            colorSwatch: isDestinationType(content.data) ? `swatch_${content.data}` : unknown,
            iconClass: 'fas fa-square'
        })
    },
    {
        match: content => isExpectedEnumType(content.enumType, GridCellEnumType.UpArrow),
        convert: content => ({
            colorSwatch: SpecialColors.GoodPercentile,
            iconClass: 'fas fa-triangle'
        })
    },
    {
        match: content => isExpectedEnumType(content.enumType, GridCellEnumType.DownArrow),
        convert: content => ({
            colorSwatch: SpecialColors.BadPercentile,
            iconClass: 'fas fa-triangle fa-rotate-180'
        })
    }
];

export const GridCellContentProcessor = {
    process: (content: EnumTypeDataContent) => {
        const template = ContentTemplates.find(t => t.match(content));
        return template ? template.convert(content) : null;
    }
};

export const ComparisonEnumTypeTools = {
    isComparisonEnumType: (col: GridColumn) => {
        const acceptableTypes = [GridCellEnumType.ComparisonLowerGood, GridCellEnumType.ComparisonHigherGood];
        const enumType = col.enumType || col.metadata.enumType;
        return enumType && acceptableTypes.some(t => enumType === t.toString());
    },
    getEnumTypeData: (col: GridColumn, dataItem: Record<string, FormattedData>) => {
        const enumType = (col.enumType || col.metadata && col.metadata.enumType) as GridCellEnumType;
        const data = dataItem[col.enumType ? col.field : col.metadata.field].value;
        return {
            enumType,
            data
        };
    }
};