import { Injectable } from '@angular/core';
import { HttpOptions, HttpRequest } from './http-request';


@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(private http: HttpRequest) {
	}

	get(controller: string, route?: string, params?: any, useHttpOverride?: boolean) {
		const url = this.getUrl(controller, route);
		return this.http.get(url, params, useHttpOverride);
	}

	post(controller: string, route?: string, params?: any, options?: HttpOptions) {
		const url = this.getUrl(controller, route);
		return this.http.post(url, params, options);
	}

	patch(controller: string, route?: string, params?: any, options?: HttpOptions) {
		const url = this.getUrl(controller, route);
		return this.http.patch(url, params, options);
	}

	delete(controller: string, route?: string, options?: HttpOptions) {
		const url = this.getUrl(controller, route);
		return this.http.delete(url, options);
	}

	put(controller: string, route?: string, params?: any, options?: HttpOptions) {
		const url = this.getUrl(controller, route);
		return this.http.put(url, params, options);
	}

	getUrl(controller: string, route?: string) {
		return this.http.getUrl(controller, route);
	}

}
