import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseFilterComponent } from '../base-filter.component';
import { FILTER_TYPE } from '../../../enums/filter-type.enum';
import { FilterGroupService } from '../../../services/filter-group.service';
import { NO_SUB_SPECIALTY } from '../../../constants/constants';
import { Node } from '../../../models/tree-filter/tree-filter-node.model';

@Component({
    selector: 'trella-multi-level-filter',
    templateUrl: './multi-level-filter.component.html',
    styleUrls: ['./multi-level-filter.component.scss']
})
export class MultiLevelFilterComponent extends BaseFilterComponent {
    @Input() definitionFactory: (FILTER_TYPE) => string;
    @Input() titleFactory: (FILTER_TYPE) => string;
    @Input() selectAllOption = false;
    @Output() selectionsChanged: Observable<Node[]>;
    visibleLevelOneNodes: Node[] = [];
    visibleLevelTwoNodes: Node[] = [];
    searchQuery: string;
    filteredNodes: Node[] = [];
    private _allNodes: Node[] = [];
    private _focusedNode: Node;
    private _caption: string;
    private _levels: string[] = [];
    private _filterType: FILTER_TYPE;
    private _selectionsChanged: EventEmitter<Node[]> = new EventEmitter<Node[]>();


    constructor(protected filterGroupService: FilterGroupService, private ref: ChangeDetectorRef) {
        super(filterGroupService);
        this.selectionsChanged = this._selectionsChanged.asObservable();
    }

    get getDefinition(): string {
        return this.definitionFactory(this.filterType) ?? '';
    }

    get getCount(): number {
        // TODO: recursive
        let count = 0;
        for (const node of this._allNodes) {
            if (node.checked && !node.children.some(c => c.checked))
                count++;

            for (const child of node.children) {
                if (child.checked)
                    count++;

            }
        }
        return count;
    }

    get getTitle(): string {
        return this.titleFactory && this.titleFactory(this.filterType) || this.caption;
    }

    get visibleNodesByLevel(): Node[][] {
        const visibleLevelOneNodes = this.visibleLevelOneNodes.length ? this.visibleLevelOneNodes : this.sortNodes(this.allNodes);

        let visibleLevelTwoNodes = this.visibleLevelTwoNodes || [];
        if (visibleLevelOneNodes.includes(this._focusedNode))
            visibleLevelTwoNodes = this._focusedNode?.children || visibleLevelTwoNodes;
        else if (this._focusedNode && this._focusedNode.parent)
            visibleLevelTwoNodes = this._focusedNode.parent.children;


        this.visibleLevelTwoNodes = this.sortNodes(visibleLevelTwoNodes);

        return [visibleLevelOneNodes, visibleLevelTwoNodes];
    }

    get allNodes(): Node[] {
        return this._allNodes;
    }
    @Input() set allNodes(value: Node[]) {
        this._allNodes = value;
    }

    get caption(): string {
        return this._caption;
    }
    @Input() set caption(value: string) {
        this._caption = value;
    }

    get filterType(): FILTER_TYPE {
        return this._filterType;
    }

    @Input() set filterType(value: FILTER_TYPE) {
        this._filterType = value;
    }

    get levels(): string[] {
        return this._levels;
    }
    
    @Input() set levels(value: string[]) {
        this._levels = value;
    }

    sortNodes(nodes: Node[]): Node[] {
        return nodes.sort((a, b) => {
            if (a.checked && !b.checked) 
                return -1;
             else if (!a.checked && b.checked) 
                return 1;
             else if (a.display !== NO_SUB_SPECIALTY && b.display === NO_SUB_SPECIALTY) 
                return 1;
             else if (a.display === NO_SUB_SPECIALTY && b.display !== NO_SUB_SPECIALTY) 
                return -1;
             else 
                return a.display.localeCompare(b.display);
            
        });
    }

    onSearchKeyup(_: KeyboardEvent): void {
        if (this.searchQuery.length < 2) {
            this.filteredNodes = [];
            return;
        }

        let nodes = [];

        this.allNodes.forEach(n => {
            if (n.toString().toLowerCase().includes(this.searchQuery.toLowerCase())) 
                nodes.push(n);
            
            if (n.children) {
                const filtered = n.children.filter(child => child.toString().toLowerCase().includes(this.searchQuery.toLowerCase()));
                nodes = [...nodes, ...filtered];
            }
        });
        this.filteredNodes = nodes;
    }

    handleSearchResultSelection(node: Node) {
        this.filteredNodes = [];
        this._focusedNode = node;
        this.searchQuery = '';
        this.toggleNode(node);
    }

    handleNodeSelection(node: Node) {
        // Need to select all children, too
        this._focusedNode = node;
    }

    isSelectedNode(node: Node) {
        const areEqual = this._focusedNode?.value === node.value;
        const hasSelectedChildren = node.children && node.children.find(c => c.checked);
        // This protects from specialties/sub-specialties getting deselected every time we check/uncheck one
        if (areEqual) this.handleNodeSelection(node);
        return areEqual || hasSelectedChildren;
    }

    isFocusedNode(node: Node) {
        return this._focusedNode?.value === node.value || this._focusedNode?.parent && this._focusedNode?.parent.value === node.value;
    }

    toggleNode(node: Node) {
        const newCheckedState = !node.checked;
        node.checked = newCheckedState;
        if (newCheckedState && node.parent) 
            node.parent.checked = true;
        
        if (newCheckedState && node.parent && node.parent.children.every(n => !n.checked)) 
            node.parent.checked = false;
        
        this.toggleAllChildren(node);
        this.refreshAllNodes(node);
        this.emitSelections();
    }

    toggleAllChildren(node: Node) {
        const newCheckedState = node.checked;
        if (node.children) {
            node.children.forEach(c => {
                c.checked = newCheckedState;
                this.toggleAllChildren(c);
            });
        }
    }

    emitSelections() {
        this._selectionsChanged.emit(this.allNodes);
    }

    getHash(index: number, node: Node): string {
        return node.getHash();
    }

    getArrayHash(index: number, nodes: Node[]): string {
        return JSON.stringify(nodes.map(node => node.getHash()));
    }

    getParent(): Node {
        if (this._focusedNode?.parent === null) 
            return this._focusedNode;
        
        if (this._focusedNode) 
            return this._focusedNode.parent;
        

        return new Node();
    }

    displaySelectDeselectAll(parentNode: Node): string {
        return parentNode.checked ? 'Deselect All' : 'Select All';
    }

    withSelectAllOption(levelIndex: number): boolean {
        return levelIndex === 1
            && this.selectAllOption
            && this.visibleNodesByLevel.some(level => level.includes(this._focusedNode));
    }

    withFirstLevelCheckboxes(levelIndex: number): boolean {
        return levelIndex === 1
            || levelIndex === 0 && !this.selectAllOption;
    }

    areChildrenChecked(parentNode: Node): boolean {
        const anyChildChecked = parentNode.children.some(n => n.checked);

        if (anyChildChecked !== parentNode.checked) 
            this.toggleNode(parentNode);
        

        return anyChildChecked;
    }

    refreshAllNodes(node: Node) {
        const refreshedNodes = [];

        this.allNodes.forEach(n => {
            if (node.toString() === n.toString()) 
                refreshedNodes.push(node);
             else {
                n.children = this.refreshAllChildren(node, n);
                refreshedNodes.push(n);
            }
        });

        this._allNodes = refreshedNodes;
    }

    private refreshAllChildren(node: Node, oldNode: Node) {
        const refreshedChildren = [];

        oldNode.children.forEach(child => {
            if (node.toString() === child.toString()) 
                refreshedChildren.push(node);
             else 
                refreshedChildren.push(child);
            
        });

        return refreshedChildren;
    }
}
