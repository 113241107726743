import { Injectable } from '@angular/core';
import { UserApi } from 'src/app/api/user.api';
import { Observable } from 'rxjs';
import { AssigneeApi } from 'src/app/api/assignment.api';
import { EditableUser } from '../models/editable-user';
import { ProductName } from '@appcore/enums/product-name.enum';
import { Selection } from '@appcore/interfaces/selection.interface';
@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(private userApi: UserApi, private assigneeApi: AssigneeApi) {
	}

	getUsers(companyId: string) {
		return this.userApi.getUsers(companyId);
	}

	getCompanyProductUsers(companyId: string, productNames: ProductName[]) {
		return this.userApi.getCompanyProductUsers(companyId, productNames);
	}

	getEditableUser(userId: string): Observable<EditableUser> {
		if (!userId)
			return;

		return this.userApi.getEditableUser(userId);
	}

	getScheduledReportCompanyUsers(companyId: number) {
		return this.userApi.getScheduledReportCompanyUsers(companyId);
	}

	getScheduledReportUsers() {
		return this.userApi.getScheduledReportUsers();
	}

	getUserTypes(): Observable<Selection[]> {
		return this.userApi.getUserTypes();
	}

	create(user: EditableUser, sendWelcomeEmail: boolean) {
		return this.userApi.create(user, sendWelcomeEmail);
	}

	update(user: EditableUser) {
		return this.userApi.update(user);
	}

	createTemporaryPassword(userIds: string[], temporaryPassword: string, sendEmail: boolean) {
		if (!userIds?.length || !temporaryPassword)
			return;


		return this.userApi.requestUserPasswordResetToken(userIds, temporaryPassword, sendEmail).toPromise();
	}

	resendWelcomeEmail(userId: string): Observable<void> {
		return this.userApi.resendWelcomeEmail(userId);
	}

	resendWelcomeEmails(userIds: string[]): Observable<void> {
		return this.userApi.resendWelcomeEmails(userIds);
	}

	setUsersIsEnabled(userIds: string[], enable: boolean): Observable<void> {
		return this.userApi.setUsersIsEnabled(userIds, enable);
	}

	getUserAssignmentsCount(userId: string): Observable<number> {
		return this.assigneeApi.getUserAssignmentsCount(userId);
	}
}
