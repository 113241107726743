import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserApi } from 'src/app/api/user.api';
import { AuthApi } from '../../api/auth.api';
import { LoginResponse } from '../models/authentication';
import { ApplicationCacheService } from './application-cache.service';
import { Auth } from 'aws-amplify';
import { SSOService } from './sso.service';
import { CurrentUserResponse } from '../models/current-user-response';
import { AppUser } from '../models/app-user';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	jwtHelper = new JwtHelperService();

	constructor(private authApi: AuthApi,
				private userApi: UserApi,
				private appCache: ApplicationCacheService,
				private ssoService: SSOService) {
	}

	async getCognitoToken() {
		return Auth.currentSession()
			.then(res => {
				const jwt = res.getIdToken().getJwtToken();
				return jwt;
			})
			.catch(err => {
				console.log(err);
				return null;
			});
	}

	decodedToken(token: string) {
		return this.jwtHelper.decodeToken(token);
	}

	loginCognito(jwt: string) {
		return this.authApi.loginCognito(jwt);
	}

	login(username, password) {
		return this.authApi.login(username, password);
	}

	onLogin(authResponse: LoginResponse) {
		if (!authResponse || !authResponse.authenticated) 
			return;
		
		this.ssoService.convertUnconfirmedSSOSource();
	}

	async logout() {
		try {
			await this.authApi.logout().toPromise();
		} finally {
			await Auth.signOut();
		}
	}

	async getCurrentUser(): Promise<CurrentUserResponse> {

		try {
			const response = await this.userApi.getCurrent().toPromise();
			const user = new AppUser();
			Object.assign(user, response.user);
			this.appCache.setUser(user);
			this.appCache.setConfigOptions(response.configOptions);
			return response;
		} catch (error) {
			console.log(error);
		}
	}
}
