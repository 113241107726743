<div class="d-flex flex-column insight-wrapper my-3 mx-4">
    <div class="d-flex flex-row flex-shrink-1 justify-content-between">
        <div class="lead fw-normal insight-small-text">
            {{ insightOptions && insightOptions.displayName }}
        </div>
        <div>
            <trella-info-circle
                    *ngIf="insightOptions && insightOptions.tooltip"
                    [tooltipText]="insightOptions.tooltip"
                    class="zindex-foreground"
            ></trella-info-circle>
        </div>
    </div>
    <div *ngIf="data && data.isValidData"
         class="d-flex flex-column mt-2 justify-content-evenly align-items-center flex-grow-1">
        <div [title]="npiName" class="npi-name-heading text-capitalize line-clamp" placement="top">
            {{ npiName | lowercase }}
        </div>
        <div [ngSwitch]="npiType">
            <div *ngSwitchDefault class="py-2 fw-normal top-text">
                Top percentage recipient of inpatient and outpatient claims from
                <span class="text-capitalize">{{ sourceNpiName | lowercase }}</span> over the last year
            </div>
            <div *ngSwitchCase="'ACO'" class="py-2 fw-normal">
                ACO whose Group Participation Tax ID was most frequently used on the physician's professional claims in
                CY2020. Group participation in the ACO is based on Performance Year 2021
            </div>
        </div>
        <div [hidden]="npiType === 'ACO'" class="d-flex flex-row justify-content-end w-100">
            <div class="fw-bold lead low">
                {{ datapoint.data }}
            </div>
        </div>
    </div>
    <div *ngIf="data && !data.isValidData" class="d-flex align-items-center justify-content-center h-100">
        <div class="insight-blank-empty-text text-center fw-bold text-uppercase text-secondary-light">
            No Data Available
        </div>
    </div>
</div>
