import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentWithSubscription } from '../../components/component-with-subscription';
import { GridInfo } from '../../models/grid-info.model';
import { FilterSelection } from '../../models/filter-selection.model';
import { FormattedData } from '../../interfaces/formatted-data.interface';
import { GridService } from '../../services/grid.service';

@Component({
    selector: 'trella-multi-grid',
    templateUrl: './multi-grid.component.html',
    styleUrls: ['./multi-grid.component.scss']
})
export class MultiGridComponent extends ComponentWithSubscription implements OnInit {
    @Input() customerId = 0;
    @Input() dataChange: Observable<GridInfo>;
    @Input() disabled = false;
    @Input() filters: FilterSelection[];
    @Input() hasShowSelectedToggle = false;
    @Input() isLoading = false;
    @Input() key = '';
    // TODO: These can be options?
    @Input() showComparisonsButton = false;
    @Input() showFavoritesButton = false;
    @Input() showTargetsButton = false;
    @Input() showAssigneesButton = false;
    @Input() showUnAssigneeButton = false;
    @Input() showAddToClipboard = false;
    @Input() showSelected = false;
    @Input() rowLinkFactory: (key: Record<string, FormattedData>, info: GridInfo) => string;
    @Input() searchPlaceholderText = 'Search Name, NPI, Location or Specialty';
    @Input() userFavorites: unknown[] = [];
    @Input() userTargets: unknown[] = [];

    @Output() getStaff = new EventEmitter();
    @Output() selectOptions;

    selectedOption: any;
    selectedGridInfo: GridInfo;
    gridInfos: GridInfo[];

    constructor(private gridService: GridService) {
        super();
    }

    get showMagnifyingGlass(): boolean {
        return this.selectedGridInfo &&
            this.selectedGridInfo.gridAbilities.filterable &&
            this.selectedGridInfo.columns &&
            this.selectedGridInfo.columns.some(x => x.filterable);
    }

    @Input()
    set configs(configs: GridInfo[]) {
        this.gridInfos = configs;
        this.selectOptions = configs.map(config => {
            const index = configs.indexOf(config);
            return {
                display: config.title || config.reportName || index
            };
        });

        if (configs && configs.length)
            this.selectedOption = this.selectOptions[0];

    }

    ngOnInit() {
        this.dataChange.subscribe(gridInfo => {
            this.selectedGridInfo = gridInfo;
        });
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngAfterViewInit() {
        this.getStaff.emit();
    }

    public selectGrid() {
        const index = this.selectOptions.indexOf(this.selectedOption);
        this.selectedGridInfo = this.gridInfos[index];
        this.gridService.requestRefresh(this.key, this.selectedGridInfo);
    }

    filterHasValues() {
        return this.filters && this.filters.some(f => f.values.length > 1);
    }
}
