import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrellaChartComponent } from '../chart/chart.component';
import { ComponentWithSubscription } from '../../components/component-with-subscription';
import { Target } from '../../models/target.model';
import { FilterSelection } from '../../models/filter-selection.model';
import { AdapterOptionsType } from '../../models/chart-data-series.model';
import { ChartConfig } from '../../models/chart-config.model';
import { ChartService } from '../../services/chart.service';
import { ChartExportService } from '../../services/chart-export.service';
import { subscribeToKeyed } from '../../models/keyed';

@Component({
    selector: 'trella-multi-chart',
    templateUrl: './multi-chart.component.html',
    styleUrls: ['./multi-chart.component.scss']
})
export class MultiChartComponent extends ComponentWithSubscription implements OnInit {
    @ViewChild('chartExport') chartExport: TrellaChartComponent;
    @Input() target: Target;
    @Input() filters: FilterSelection[];
    @Input() key = '';
    @Input() isLoading = false;
    @Input() adapterOptions?: AdapterOptionsType;
    @Input() dataChange: Observable<ChartConfig>;
    @Input() activeLegendItems = [];
    @Input() useNativeImageExport = true;
    @Input() inDataKit = false;

    @Output() clickEvent = new EventEmitter();
    @Output() expandDialogEvent = new EventEmitter();
    @Output() activeLegendItemsChange = new EventEmitter();
    @Output() onRefreshData = new EventEmitter<ChartConfig>();
    @Output() addToClipboardEvent = new EventEmitter();
    @Output() exportChartImageEvent = new EventEmitter();
    @Output() removeDataKitComponent = new EventEmitter();
    @Output() selectOptions;
    /**
     * @deprecated Use {@link ChartService.reportEButtonClick} instead.
     */
    @Output() openDevInfoDialogEvent = new EventEmitter();

    selectedOption: any;
    selectedChartConfig: ChartConfig;

    allowEButton = false;
    chartConfigs: ChartConfig[];

    constructor(private cdr: ChangeDetectorRef, private chartService: ChartService, private chartExportService: ChartExportService) {
        super();
    }

    get showSpinner(): boolean {
        return this.isLoading;
    }

    get isSingleChart() {
        return this.chartConfigs && this.chartConfigs.length === 1;
    }

    @Input()
    set configs(configs: ChartConfig[]) {
        this.chartConfigs = configs;
        this.selectOptions = configs.map(config => {
            const index = configs.indexOf(config);
            return {
                display: config.title || config.reportName || index
            };
        });

        if (configs && configs.length)
            this.selectedOption = this.selectOptions[0];

    }
    ngOnInit() {
        this.dataChange.subscribe(chartConfig => {
            this.selectedChartConfig = chartConfig;
            this.cdr.detectChanges();
        });

        subscribeToKeyed(this.chartService.eButtonPermissionChecked.pipe(takeUntil(this.ngUnsubscribe)), this.key, isAllowed => {
            this.allowEButton = isAllowed;
        });
    }

    refreshData() {
        this.onRefreshData.emit(this.selectedChartConfig);
    }

    openExpandDialog() {
        this.expandDialogEvent.emit();
    }

    addToClipboard() {
        this.addToClipboardEvent.emit(this.selectedChartConfig);
    }

    exportChartImage() {
        if (this.useNativeImageExport) 
            this.chartExportService.exportChart(this.chartExport);
         else 
            this.exportChartImageEvent.emit(this);
        
    }

    removeFromDataKit() {
        this.removeDataKitComponent.emit(this);
    }

    openE() {
        this.openDevInfoDialogEvent.emit(this);
        if (!this.allowEButton) 
            return;
        
        this.chartService.reportEButtonClick(this.key);
    }

    public selectChart() {
        const index = this.selectOptions.indexOf(this.selectedOption);
        this.selectedChartConfig = this.chartConfigs[index];
        this.refreshData();
    }
}
