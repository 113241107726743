import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfirmationDialogConfig } from '../../interfaces/confirmation-dialog-config.interface';

@Component({
    selector: 'trella-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit, OnChanges {
    @Input() config: ConfirmationDialogConfig;
    @Output() okPressed? = new EventEmitter();
    @Output() cancelPressed? = new EventEmitter();

    opened = false;

    titleIcon: string;
    title: string;
    note: string;
    message: string;
    okButtonLabel: string;
    cancelButtonLabel: string;
    size: string;

    constructor() {
    }

    ngOnInit() {
        this.readConfig(this.config);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.config) 
            this.readConfig(changes.config.currentValue);
        
    }

    open() {
        this.opened = true;
    }

    close() {
        this.opened = false;
    }

    ok() {
        if (this.okPressed) 
            this.okPressed.emit();
        

        this.close();
    }

    cancel() {
        if (this.cancelPressed) 
            this.cancelPressed.emit();
        

        this.close();
    }

    private readConfig(config) {
        this.titleIcon = config.titleIcon;
        this.title = config.title || 'Confirmation';
        this.note = config.note;
        this.message = config.message;
        this.okButtonLabel = config.okButtonLabel || 'Ok';
        this.cancelButtonLabel = config.cancelButtonLabel || 'Cancel';
        this.size = config.size || null;
    }
}
