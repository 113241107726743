import { Component, ViewChild } from '@angular/core';
import { EulaApi } from '../api/eula.api';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { NavbarService } from '../main/navbar/navbar.service';
import { ApplicationCacheService } from '../shared/services/application-cache.service';
import {
	UserFeedbackService
} from '@appcore/services/user-feedback.service';

@Component({
	selector: 'app-data-eula',
	templateUrl: './data-eula.component.html'
})
export class DataEulaComponent {
	@ViewChild(AlertDialogComponent, {static: true}) alertDialog: AlertDialogComponent;

	constructor(
		private applicationCacheService: ApplicationCacheService,
		private eulaApi: EulaApi,
		private navBarService: NavbarService,
		private userFeedbackService: UserFeedbackService,
	) {}

	async acceptEula() {
		await this.updateEula(true);
		this.applicationCacheService.user.showDataEula = false;
		this.navBarService.loadLastPage();
	}

	async showConfirm() {
		this.alertDialog.open('Are you sure you want to decline?', 'You will not be able to export any data without accepting this agreement.', 'I Decline');
	}

	async onDecline() {
		const newPermissins = await this.updateEula(false);
		this.applicationCacheService.user.setPermissions(newPermissins);
		this.applicationCacheService.user.showDataEula = false;
		this.navBarService.loadLastPage();
	}

	async updateEula(choice: boolean): Promise<string[]> {
		this.userFeedbackService.startSpinner();
		try {
			return await this.eulaApi.updateDataEula(choice).toPromise();
		} catch (ex) {
			this.userFeedbackService.showUnexpectedError();
		} finally {
			this.userFeedbackService.stopSpinner();
		}
	}
}
