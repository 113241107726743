import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-modal-progress-bar',
	templateUrl: './modal-progress-bar.component.html',
	styleUrls: ['./modal-progress-bar.component.scss']
})

export class ModalProgressBarComponent {
	@Input() progressType = 'determinate';
	percentComplete = 0;
	messageToDisplay = '';
	displayMessage = false;
	showProgressBar = false;

	opened = false;

	open(displayMessage: boolean, initialMessage = '', showProgessBar = false) {
		this.messageToDisplay = initialMessage;
		this.displayMessage = displayMessage;
		this.showProgressBar = showProgessBar;
		this.opened = true;
	}

	setMessage(message: string) {
		if (!message) 
			return;
		

		this.messageToDisplay = message;
	}

	setPercent(percent: number) {
		if (!percent) 
			return;
		
		this.percentComplete = percent;
	}

	showHideProgressBar(barStatus: boolean) {
		this.showProgressBar = barStatus;
	}

	clear() {
		this.messageToDisplay = '';
		this.displayMessage = false;
		this.percentComplete = 0;
	}

	close() {
		this.clear();
		this.opened = false;
	}
}
