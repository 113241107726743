import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseChart } from '../base-chart';
import { geometry, GradientStop, Group, LinearGradient, Path, Rect, Text } from '@progress/kendo-drawing';
import { ChartComponent } from '@progress/kendo-angular-charts';
import { ChartDataType } from '../../../../enums/chart-data-type.enum';
import { ChartConfig } from '../../../../models/chart-config.model';
import { ChartDataElement, ChartDataSeries } from '../../../../models/chart-data-series.model';
import { RAW_VALUE } from '../../../../enums/raw-value.enum';
import { Utils } from '../../../../helpers/Utils';

const {Rect: RectGeometry, Point, Size} = geometry;

@Component({
    selector: 'trella-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent extends BaseChart implements OnInit {
    @ViewChild('kendoimage') kendoImage: ChartComponent;
    highlightedColumns: number[] = [];
    type: any; // TODO: Needed? created to enable compile
    red = '#D5839A';

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    getLineType(dataLabel: string) {
        if (dataLabel && (dataLabel.includes(this.countyAverageKey) || dataLabel.includes(this.stateAverageKey)))
            return 'dash';


        return 'solid';
    }

    readData(chartInfo: ChartConfig): ChartDataSeries {
        const result = [];
        const data = chartInfo.data;
        const seriesNameField = chartInfo.seriesNameFields;
        const valueFields = chartInfo.valueFields;

        const highlightedFields = chartInfo.columns.map(column => column.field);
        highlightedFields.forEach(field => {
            const index = valueFields.indexOf(field);
            this.highlightedColumns.push(index);
        });

        data.forEach(item => {
            const lineChartData = {
                data: [],
                rawData: [],
                label: item[seriesNameField].value
            };
            valueFields.forEach(field => {
                lineChartData.data.push(item[field].formattedValue);
                lineChartData.rawData.push(this.formatValue(item[field].value));
            });
            result.push(lineChartData);
        });

        const validData = this.validateData(result);
        return {
            data: result,
            categories: [],
            isValidData: validData
        };
    }

    formatValue(value) {
        switch (this.options.dataType) {
            case ChartDataType.Percent:
                if (value === RAW_VALUE.negativeOne || value === RAW_VALUE.negativeThree)
                    return null;
                 else if (value === RAW_VALUE.negativeTwo || value === RAW_VALUE.negativeFour)
                    return 0;
                 else
                    return value;

            case ChartDataType.Count:
            default:
                return this.getFormattedCountValue(value);
        }
    }

    getFormattedCountValue(value) {
        if (value === RAW_VALUE.negativeOne)
            return 5;
         else if (value === RAW_VALUE.negativeThree)
            return null;
         else if (value === RAW_VALUE.negativeTwo || value === RAW_VALUE.negativeFour)
            return 0;
         else
            return value;

    }

    highlightColumns(args: any): void {
        this.highlightedColumns.forEach(column => this.highlightColumn(column, args));
    }

    renderTarget(args: any): void {
        if (!this.target)
            return;


        const chart = args.sender;
        const valueAxis = chart.findAxisByName('valueAxis');
        const categoryAxis = chart.findAxisByName('categoryAxis');
        const valueSlot = valueAxis.slot(this.target.value);
        const range = categoryAxis.range();
        const categorySlot = categoryAxis.slot(range.min, range.max);
        const targetColor = Utils.isColor(this.target.color) ? this.target.color : this.red;
        const line = new Path({
            opacity: 1,
            stroke: {
                color: targetColor,
                width: 2,
                dashType: 'dot'
            }
        })
            .moveTo(valueSlot.origin)
            .lineTo(categorySlot.topRight().x, valueSlot.origin.y);
        const label = new Text(this.target.title || this.target.value, [0, 0]);
        const group = new Group();

        label.fill(targetColor);
        label.position([categorySlot.topLeft().x + 5, valueSlot.origin.y]);
        group.append(line, label);
        chart.surface.draw(group);
    }

    validateData = (chartData: ChartDataElement[]) => this.validateRange(chartData);

    postDataRead() {
        // no op
    }

    handleOptionsChange(config: ChartConfig) {
        // no op
    }

    private highlightColumn(columnNumber: number, args: any): void {
        const chart = args.sender;
        const valueAxis = chart.findAxisByName('valueAxis');
        const categoryAxis = chart.findAxisByName('categoryAxis');
        const data = chart.options.series[0].data;
        const lastDataPoint = data.length > 2 ? data[data.length - 2] : 0;
        const valueSlot = valueAxis.slot(lastDataPoint.y);
        const categorySlot = categoryAxis.slot(lastDataPoint.x);

        const gradient = new LinearGradient({
            name: 'HighlightedField',
            stops: [new GradientStop(0, '#EEF1F9', 0.5)]
        });

        const maxValue = valueAxis._axis.totalMax;
        const maxDataValueSlot = valueAxis.slot(maxValue);

        const rectX = categorySlot.origin.x + categorySlot.width() * columnNumber;
        const rectY = maxDataValueSlot.origin.y;

        const rectWidth = categorySlot.width();
        const rectHeight = valueSlot.origin.y - maxDataValueSlot.origin.y;

        const rect = new RectGeometry(new Point(rectX, rectY), new Size(rectWidth, rectHeight));

        const drawingRect = new Rect(rect, {
            stroke: {
                color: 'none',
                width: 2
            },
            fill: gradient
        });

        chart.surface.draw(drawingRect);
    }
}
