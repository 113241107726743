<div style="margin-bottom: 50px;">
	<div class="d-flex">
		<mat-form-field appearance="outline" class="w-100 mt-3" floatLabel="always">
			<mat-label><i class="fa fa-search pe-1"></i>NPI Search</mat-label>
			<input [formControl]="searchInput" [matAutocomplete]="auto" matInput type="text">
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="addNpi($event.option.value)"
							  [displayWith]="display">
				<mat-option *ngFor="let option of searchOptions" [value]="option">
					<i class="{{ icon(option.provider) }} px-2" height="25"></i>
					<strong>{{ option.display }}</strong>
					<br>
					<div class="search-list-indent">
						<span>{{ option.npi }} | {{ option.city }}, {{ option.market }} {{ option.zipcode }}</span>
					</div>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
		<trella-spinner [isFullDiv]="true" [show]="showSpinner" class="div-overlay-wrapper"
						style="width:100px; height: 100px;"></trella-spinner>
	</div>
	<div *ngIf="addError && addErrorText.length" class="error-text">{{ addErrorText }}</div>
</div>

<div>
	<span class="text-label">NPI List ({{ selectedNpis.length }}) <i
		(click)="removeAllNpi()" *ngIf="selectedNpis.length"
		class="fa fa-remove pt-1 ms-1 pointer text-secondary" title="Remove All"></i></span>
	<input (input)="updateFilterText($event.target.value)" class="w-100 mt-2 mb-2 p-10 border rounded result-filter"
		   matInput
		   type="text">
	<div [style.height]="height" class="border rounded">
		<cdk-virtual-scroll-viewport [style.height]="height" itemSize="22">
			<div class="item ps-1">
				<mat-list-item *cdkVirtualFor="let option of selectedNpisThatMeetSearchCriteria">
					<div class="flex-row group-npi">
						<div>
							<i class="{{ icon(option.provider) }} px-2 h-100 p-10 left-icon" height="25"></i>
						</div>
						<div class="flex1 flex-column h-100 npi-info-container">
							<div class="w-100 m1">
								<span class="npi-list-text">{{ optionDisplay(option) }}</span>
							</div>
							<div class="flex1 flex-row w-100 m1">
								<small>{{ option.npi }}</small>
								<small> | {{ option.city }}, {{ option.market }} {{ option.zipcode }}</small>
								<small *ngIf="showProviderPatientCount"> | Patient Count ({{ option.count || 'INS' }}
									)</small>
							</div>
						</div>
						<div>
							<i (click)="removeNpi(option)"
							   class="remove fa fa-remove pt-1 pe-1 pointer text-secondary flex1 right-icon"
							   title="Remove"></i>
						</div>
					</div>
					<mat-divider></mat-divider>
				</mat-list-item>
			</div>
		</cdk-virtual-scroll-viewport>
	</div>
</div>
