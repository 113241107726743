import { Injectable } from '@angular/core';
import { UserApi } from '../../api/user.api';

@Injectable({
	providedIn: 'root'
})

export class UploadUsersService {
	constructor(private userApi: UserApi) {
	}

	uploadUsers(base64string: string, fileName: string) {
		return this.userApi.uploadUsers(base64string, fileName);
	}
}
