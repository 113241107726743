import { isArray } from 'lodash';
import { ComparisonOptions, ConfigColumnModel, SparklineOptions } from './report-config';
import { parseNumber } from '@telerik/kendo-intl';
import { GridColumnType } from '../enums/grid-column-type.enum';
import { ISparklineGridColumnOptions } from '../interfaces/sparkline-grid-column-options.interface';
import { NumericDataTypes } from '../constants/numeric-data-types.constant';
import { GRID_FILTER_TYPE } from '../enums/grid-filter-type.enum';

export class GridColumn {
	field: string;
	title: string;
	definition: string;
	alignment: string;
	filterType: string;
	dataType: string;

	convertToLocalDate?: boolean;
	hidden = false;
	format: string;
	width: any;
	exportOnly?: boolean;
	includeInColumnChooser?: boolean;

	// Abilities
	clickable: boolean;
	sortable = true;
	filterable = true;
	wrapText = false;
	truncateLength: number;

	comparison?: ComparisonOptions;
	sparklineCol?: ISparklineGridColumnOptions;
	columnType: GridColumnType;
	columns?: GridColumn[] = [];

	highlight = false;

	constructor(config: ConfigColumnModel) {
		if (!config) 
			return;
		

		this.columnType = config.type;
		if (this.columnType === GridColumnType.preheader) 
			this.setPreHeaderOptions(config);
		
		this.setCommonOptions(config);
		this.setSparklineOptions(config.sparklineOptions);
	}

	private setPreHeaderOptions(config: ConfigColumnModel) {
		let total = 0;
		this.columns = config.columns.map(x => new GridColumn(x));
		this.columns.forEach(col => {
			total += parseNumber(col.width);
		});

		this.width = total;
	}

	private setCommonOptions(config: ConfigColumnModel) {
		this.field = config.field;
		this.title = config.title ?? '';
		this.definition = config.definition;
		this.width = config.width;
		this.comparison = config.comparisonOptions;
		this.convertToLocalDate = config.convertToLocalDate;
		this.hidden = config.hidden;
		this.wrapText = config.wrapText;
		this.truncateLength = config.truncateLength;
		this.alignment = config.alignment;
		this.includeInColumnChooser = config.includeInColumnChooser;
		this.exportOnly = config.exportOnly;
		this.highlight = config.highlight;

		if (config.sparklineOptions) {
			// Sparklines don't need a data type
			return;
		}

		this.format = config.flags;
		this.dataType = config.dataType;

		const isNumericType = this.dataType && NumericDataTypes.find(x => x === this.dataType);
		this.filterType = isNumericType ? GRID_FILTER_TYPE.number : GRID_FILTER_TYPE.text;
		this.filterable = config.filterable;
		this.clickable = config.clickable;
		if (this.filterType === GRID_FILTER_TYPE.number) {
			// Hide number filters for now
			this.filterable = false;
		}
	}

	private setSparklineOptions(options: SparklineOptions) {
		if (!options) 
			return;
		

		const fields = options.fields;
		if (isArray(fields)) {
			// Concatenate with a valid field character to get around console warnings
			this.field = fields.join('_');
		}

		this.sparklineCol = {
			subtitles: options.subtitle.map(subtitle => subtitle ?? ''),
			fields
		};

		this.filterType = null;
		this.filterable = false;
		this.clickable = false;
		this.sortable = false;
		this.dataType = null;
	}
}
