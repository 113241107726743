/* eslint-disable eqeqeq */
import dayjs from 'dayjs';
import plugin from 'dayjs/plugin/quarterOfYear';
import { cloneDeep, uniq, xor } from 'lodash';
import {
    FORMAT_FUNCTION_TARGET_TYPE,
    FORMAT_FUNCTIONS,
    ROUTE_CONSTANTS,
    SPECIAL_DATA_VALUES
} from '../constants/constants';
import { PROVIDER_TYPE } from '../enums/provider-type.enum';
import { RAW_VALUE } from '../enums/raw-value.enum';
import { DISPLAY_VALUE } from '../enums/display-value.enum';
import { FormattedData } from '../interfaces/formatted-data.interface';
import { TARGET_TYPE } from '../enums/target-type.enum';
import { Selection } from '../interfaces/selection.interface';


export class Utils {
    public static loadDate: string; // Gets set on CurrentUser call
    public static maDate: string; // Gets set on CurrentUser call

    static toggle = (array: any[], item: string) => xor(array, [item]);

    static exists(val: any): boolean {
        return val !== undefined && val !== null && val !== '';
    }

    static isSpecialValue(data) {
        return SPECIAL_DATA_VALUES.indexOf(data) > -1;
    }

    static deepClone(source: any) {
        return cloneDeep(source);
    }

    static stringContains(src: string, val: string) {
        return src.toLowerCase().indexOf(val.toLowerCase()) > -1;
    }

    static generateGuid() {
        const s = [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++)
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);

        s[14] = '4';
        // eslint-disable-next-line no-bitwise
        s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';

        const uuid = s.join('');
        return uuid;
    }

    static getSelectListObjectFromPrimitive(list: any[]): Selection[] {
        if (!list || !list.length)
            return [];


        const uniqueArray = uniq(list);
        return uniqueArray
            .map(x => ({
                id: x,
                display: x
            }))
            .sort((a, b) => {
                if (a.display < b.display)
                    return -1;

                if (a.display > b.display)
                    return 1;

                return 0;
            });
    }

    static getPercentValueFromDecimal(value: any): number {
        if (!Utils.exists(value) || Utils.isSpecialValue(value) || value <= 0)
            return 0;


        return value * 100;
    }

    static getRouteAttrByProviderType(type: PROVIDER_TYPE) {
        switch (type) {
            case PROVIDER_TYPE.physician:
                return ROUTE_CONSTANTS.physician;
            case PROVIDER_TYPE.physicianGroup:
                return ROUTE_CONSTANTS.physicianGroup;
            case PROVIDER_TYPE.facility:
                return ROUTE_CONSTANTS.facility;
            case PROVIDER_TYPE.hospice:
                return ROUTE_CONSTANTS.hospice;
            case PROVIDER_TYPE.homehealth:
                return ROUTE_CONSTANTS.homehealth;
            case PROVIDER_TYPE.skilledNursing:
                return ROUTE_CONSTANTS.skilledNursing;
            default:
                return null;
        }
    }

    static getDisplayedValue(value: any, format: string) {
        if (!format && value > 0 || !value && value !== 0 && value !== false)
            return value;


        // Need double equals here because sometimes data is string, sometimes it's a number
        if (value == RAW_VALUE.negativeOne)
            return DISPLAY_VALUE.lessThanEleven;
         else if (value == RAW_VALUE.negativeTwo || value == RAW_VALUE.negativeFour || value == RAW_VALUE.zero)
            return DISPLAY_VALUE.dash;
         else if (value == RAW_VALUE.negativeThree)
            return DISPLAY_VALUE.insufficient;
         else if (value == RAW_VALUE.negativeFive)
            return '';
         else if (format.indexOf('%') > -1)
            return `${(value * 100).toFixed(1)}%`;


        return value;
    }

    static convertToLocalDate(value: FormattedData): string {
        if (value && value.value)
            return Utils.toLocalDate(value.value);

        return '';
    }

    static convertToLocalDates(value: FormattedData): string {

        if (value && value.value) {
            const dates = [];
            const dateStrings = value.value.split(',');

            dateStrings.forEach(dateString => dates.push(Utils.toLocalDate(dateString.trim())));

            return dates.join('\n');
        }
        return '';
    }

    static getYearQuarterStringFromQm(qm: string) {
        if (!qm || qm.length < 2)
            return qm;


        const quarterM = parseInt(qm.match(/\d+/g)[0]); // number of quarters we want to go back

        dayjs.extend(plugin);
        const m = dayjs(this.loadDate);
        const newDate = m.subtract(quarterM, 'quarter'); // subtract the desired number of quarters

        return `${newDate.year()} Q${newDate.quarter()}`;
    }

    static getYearStringFromMaYm(ym: string) {
        return this.getYearString(ym, 5, this.maDate);
    }

    static getYearStringFromYm(ym: string) {
        return this.getYearString(ym, 2, this.loadDate);
    }

    static getParsedGridColumnHeader(title: string) {
        title = this._removeDoubleBrackets(title);
        if (!title)
            return '';


        const lowerTitle = title.toLowerCase();
        const maYearTitles = this.getMaYearTitles();
        const yearQuarterTitles = this.getYearQuarterTitles();
        const yearTitles = this.getYearTitles();

        if (yearQuarterTitles.includes(lowerTitle))
            return Utils.getYearQuarterStringFromQm(title);
         else if (yearTitles.includes(lowerTitle))
            return Utils.getYearStringFromYm(title);
         else if (maYearTitles.includes(lowerTitle))
            return Utils.getYearStringFromMaYm(title);
         else
            return title;

    }

    static replaceQuarterWithinString(title: string) {
        if (!title)
            return '';


        const quarterTagStart = title.indexOf('[[');
        const quarterTagEnd = title.indexOf(']]');

        // want to make sure these string exists
        if (quarterTagStart < 0 || quarterTagEnd < 0)
            return title;


        // want to make sure the starting brackets are positioned before the ending brackets
        if (quarterTagStart > quarterTagEnd)
            return title;


        const quarterString = title.substr(quarterTagStart, quarterTagEnd - quarterTagStart + 2);
        const quarter = Utils.getParsedGridColumnHeader(quarterString);

        // replace quarter tag with acutal quarter
        return title.replace(quarterString, quarter);
    }

    static doesFormatFunctionExist(functionName: string) {
        return FORMAT_FUNCTIONS.includes(functionName);
    }

    // TODO: This should be moved to backend!
    static getFormatFunction(formatFunction: string) {
        switch (formatFunction) {
            case FORMAT_FUNCTION_TARGET_TYPE:
                return this.targetTypeTransform;
        }
        return null;
    }

    static targetTypeTransform(val: TARGET_TYPE): string {
        switch (val) {
            case TARGET_TYPE.CA:
                return 'Competitor Affiliated';
            case TARGET_TYPE.UU:
                return 'Underutilizing';
            case TARGET_TYPE.UA:
                return 'Unaffiliated';
            case TARGET_TYPE.US:
                return 'User Selected';
        }
    }

    static encodeQueryParam(object: object) {
        const ret = [];

        for (const key of Object.keys(object)) {
            const value = object[key];
            const qKey = key.toLowerCase()[0];
            if (value instanceof Array) {
                if (value.length)
                    value.forEach(v => ret.push(qKey + '=' + v));

            } else if (value)
                ret.push(qKey + '=' + value);

        }
        return ret.join('&');
    }

    static convertToPascalCase(title: string) {
        if (!title)
            return '';


        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        title = title.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
        });

        return title;
    }

    static isColor(color = '') {
        if (!color.length)
            return false;

        const s = new Option().style;
        s.color = color;
        return s.color === color || new RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$').test(color);
    }

    private static toLocalDate(value: string): string {
        if (value) {
            const utcDate = new Date(`${value} UTC`);
            const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const dateTimeOptions = Intl.DateTimeFormat('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: localTimeZone,
                timeZoneName: 'short'
            });
            return dateTimeOptions.format(utcDate);
        }
        return '';
    }

    private static _removeDoubleBrackets(text: string): string {
        if (!text)
            return '';

        return text.replace(/^\[\[(.+)\]]$/, '$1');
    }

    private static getMaYearTitles(): Array<string> {
        const maYearTitles = new Array<string>();
        for (let i = 0; i < 9; i++) {
            maYearTitles.push(`ma_ym${i}`);
            maYearTitles.push(`ma_ym${i}b`);
        }

        return maYearTitles;
    }

    private static getYearQuarterTitles(): Array<string> {
        const yearQuarterTitles = new Array<string>();
        for (let i = 0; i < 20; i++) {
            yearQuarterTitles.push(`qm${i}`);
            yearQuarterTitles.push(`qm${i}b`);
        }

        return yearQuarterTitles;
    }

    private static getYearString(ym: string, length: number, date: string) {
        if (!ym || ym.length < length)
            return ym;


        const yearM = parseInt(ym.charAt(length)); // number of years we want to go back

        const m = dayjs(date);
        const newDate = m.subtract(yearM, 'year'); // subtract the desired number of years

        return `${newDate.year()}`;
    }

    private static getYearTitles(): Array<string> {
        const yearTitles = new Array<string>();
        for (let i = 0; i < 9; i++) {
            yearTitles.push(`ym${i}`);
            yearTitles.push(`ym${i}b`);
        }

        return yearTitles;
    }
}
