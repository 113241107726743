import { Component } from '@angular/core';
import { NavbarService } from '../main/navbar/navbar.service';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['page-not-found.component.scss']
})
export class PageNotFoundComponent {
	constructor(private navBarService: NavbarService) {}

	toHome() {
		this.navBarService.loadLastPage();
	}
}
