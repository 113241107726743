import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Observable } from 'rxjs';
import { Keyed } from '../models/keyed';
import { DataVisualizationBaseService } from './data-visualization.base.service';
import { GridInfo } from '../models/grid-info.model';
import { ProviderIds } from '../models/npi/provider-ids.model';
import { GridColumn } from '../models/grid-column.model';
import { GridCommandInfo } from '../interfaces/grid-command-info.interface';
import { FormattedResult } from '../interfaces/formatted-result.interface';
import { QueryOptions } from '../models/query-options.model';

@Injectable({
    providedIn: 'root'
})
export class GridService extends DataVisualizationBaseService {
    readonly addToNetworkRequested: Observable<Keyed<ProviderIds[]>>;
    readonly childGridInfoSet: Observable<Keyed<GridInfo>>;
    readonly childSubscriptionSet: Observable<Keyed<string>>;
    readonly clearAllSelected: Observable<Keyed<boolean>>;
    readonly columnsUpdated: Observable<Keyed<GridColumn[]>>;
    readonly commandIssued: Observable<Keyed<GridCommandInfo>>;
    readonly dataFetched: Observable<Keyed<GridDataResult>>;
    readonly detailsRequested: Observable<Keyed<string>>;
    readonly footerFetched: Observable<Keyed<FormattedResult>>;
    readonly itemToggled: Observable<Keyed<any>>;
    readonly loading: Observable<Keyed<boolean>>;
    readonly queryOptionsChanged: Observable<Keyed<QueryOptions>>;
    readonly refreshRequested: Observable<Keyed<GridInfo>>;
    readonly reportAdded: Observable<Keyed<GridInfo>>;
    readonly searchRequested: Observable<Keyed<string>>;
    readonly selectedRowsCleared: Observable<Keyed<void>>;
    readonly selectionCountRequested: Observable<Keyed<number>>;
    readonly selectionRequested: Observable<Keyed<string[]>>;
    readonly selectionsChanged: Observable<Keyed<string[]>>;
    readonly showSelectedToggled: Observable<Keyed<boolean>>;
    
    private _addToNetworkRequested: BehaviorSubject<Keyed<ProviderIds[]>> = new BehaviorSubject(null);
    private _childGridInfoSet: BehaviorSubject<Keyed<GridInfo>> = new BehaviorSubject(null);
    private _childSubscriptionSet: BehaviorSubject<Keyed<string>> = new BehaviorSubject(null);
    private _clearAllSelected: BehaviorSubject<Keyed<boolean>> = new BehaviorSubject(null);
    private _columnsUpdated: BehaviorSubject<Keyed<GridColumn[]>> = new BehaviorSubject<Keyed<GridColumn[]>>(null);
    private _commandIssued: BehaviorSubject<Keyed<GridCommandInfo>> = new BehaviorSubject(null);
    private _dataFetched: BehaviorSubject<Keyed<GridDataResult>> = new BehaviorSubject(null);
    private _footerFetched: BehaviorSubject<Keyed<FormattedResult>> = new BehaviorSubject(null);
    private _itemToggled: BehaviorSubject<Keyed<any>> = new BehaviorSubject(null);
    private _loading: BehaviorSubject<Keyed<boolean>> = new BehaviorSubject(null);
    private _queryOptionsChanged: BehaviorSubject<Keyed<QueryOptions>> = new BehaviorSubject(null);
    private _detailsRequested: BehaviorSubject<Keyed<string>> = new BehaviorSubject(null);
    private _refreshRequested: BehaviorSubject<Keyed<GridInfo>> = new BehaviorSubject(null);
    private _reportAdded: BehaviorSubject<Keyed<GridInfo>> = new BehaviorSubject(null);
    private _searchRequested: BehaviorSubject<Keyed<string>> = new BehaviorSubject(null);
    private _selectedRowsCleared: BehaviorSubject<Keyed<void>> = new BehaviorSubject(null);
    private _selectionRequested: BehaviorSubject<Keyed<string[]>> = new BehaviorSubject(null);
    private _selectionCountRequested: BehaviorSubject<Keyed<number>> = new BehaviorSubject(null);
    private _selectionsChanged: BehaviorSubject<Keyed<string[]>> = new BehaviorSubject(null);
    private _showSelectedToggled: BehaviorSubject<Keyed<boolean>> = new BehaviorSubject(null);
    
    constructor() {
        super();
        this.addToNetworkRequested = this._addToNetworkRequested.asObservable();
        this.childGridInfoSet = this._childGridInfoSet.asObservable();
        this.childSubscriptionSet = this._childSubscriptionSet.asObservable();
        this.clearAllSelected = this._clearAllSelected.asObservable();
        this.columnsUpdated = this._columnsUpdated.asObservable();
        this.commandIssued = this._commandIssued.asObservable();
        this.dataFetched = this._dataFetched.asObservable();
        this.detailsRequested = this._detailsRequested.asObservable();
        this.footerFetched = this._footerFetched.asObservable();
        this.itemToggled = this._itemToggled.asObservable();
        this.loading = this._loading.asObservable();
        this.queryOptionsChanged = this._queryOptionsChanged.asObservable();
        this.refreshRequested = this._refreshRequested.asObservable();
        this.reportAdded = this._reportAdded.asObservable();
        this.searchRequested = this._searchRequested.asObservable();
        this.selectedRowsCleared = this._selectedRowsCleared.asObservable();
        this.selectionCountRequested = this._selectionCountRequested.asObservable();
        this.selectionRequested = this._selectionRequested.asObservable();
        this.selectionsChanged = this._selectionsChanged.asObservable();
        this.showSelectedToggled = this._showSelectedToggled.asObservable();
    }

    addReport(key: string, report: GridInfo) {
        this._reportAdded.next({key, value: report});
    }

    addToNetwork(key: string, items: ProviderIds[]) {
        this._addToNetworkRequested.next({key, value: items});
    }

    clearAll(key: string, value: boolean) {
        this._clearAllSelected.next({key, value});
    }

    clearSelected(key: string) {
        this._selectedRowsCleared.next({key, value: null});
    }

    createChildSubcription(key: string, childKey: string) {
        this._childSubscriptionSet.next({key, value: childKey});
    }

    issueCommand(key: string, info: GridCommandInfo) {
        this._commandIssued.next({key, value: info});
    }

    reportQueryOptions(key: string, options: QueryOptions) {
        this._queryOptionsChanged.next({key, value: options});
    }

    requestDetails(key: string, dataItem: any) {
        this._detailsRequested.next({key, value: dataItem});
    }

    requestRefresh(key: string, info: GridInfo) {
        this._refreshRequested.next({key, value: info});
    }

    requestSelection(key: string, selections: string[]) {
        this._selectionRequested.next({key, value: selections});
    }

    requestSelectionCount(key: string, selectionCount: number) {
        this._selectionCountRequested.next({key, value: selectionCount});
    }

    search(key: string, searchText: string) {
        this._searchRequested.next({key, value: searchText});
    }

    select(key: string, selections: string[]) {
        this._selectionsChanged.next({key, value: selections});
    }

    setChildGridInfo(key: string, value: GridInfo) {
        this._childGridInfoSet.next({key, value});
    }

    setData(key: string, data: GridDataResult) {
        this._dataFetched.next({key, value: data});
    }

    setFooter(key: string, footer: FormattedResult) {
        this._footerFetched.next({key, value: footer});
    }

    setLoading(key: string, value: boolean) {
        this._loading.next({key, value});
    }

    showSelected(key: string, value: boolean) {
        this._showSelectedToggled.next({key, value});
    }

    toggle(key: string, value: any) {
        this._itemToggled.next({key, value});
    }

    updateColumns(key: string, value: GridColumn[]): void {
        this._columnsUpdated.next({key, value});
    }
}
