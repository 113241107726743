import {
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NavigationDirection } from '../../enums/navigation-direction.enum';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import {
    ColumnVisibilityChangeEvent,
    DataStateChangeEvent,
    GridComponent,
    GridDataResult,
    RowArgs,
    RowClassArgs,
    SelectionEvent
} from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { round } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { ComponentWithSubscription } from '../../components/component-with-subscription';
import { FormattedData } from '../../interfaces/formatted-data.interface';
import { GridInfo } from '../../models/grid-info.model';
import { FormattedResult } from '../../interfaces/formatted-result.interface';
import { QueryOptions } from '../../models/query-options.model';
import { ProviderIds } from '../../models/npi/provider-ids.model';
import { Command } from '../../models/command.model';
import { GRID_COMMAND } from '../../enums/grid-command.enum';
import { GridActionService } from '../../services/grid-action.service';
import { GridExportService } from '../../services/grid-export.service';
import { GridSortService } from '../../services/grid-sort.service';
import { GridService } from '../../services/grid.service';
import { ReplaceValueMappingService } from '../../services/replacement-value-mapping.service';
import { AGENCY_PROVIDER_TYPES } from '../../constants/constants';
import { GridAbilities } from '../../models/grid-abilities.model';
import { GridCommandInfo } from '../../interfaces/grid-command-info.interface';
import { GridColumn } from '../../models/grid-column.model';
import { GRID_ACTION_TYPE } from '../../enums/grid-action-type.enum';
import { Utils } from '../../helpers/Utils';
import { DISPLAY_VALUE } from '../../enums/display-value.enum';
import { DataSubType } from '../../enums/data-sub-type.enum';
import { GridDataType } from '../../enums/grid-data-type.enum';
import { ACTION_TYPE } from '../../enums/action-type.enum';
import { GridExport } from '../../models/grid/grid-export.model';
import { ComparisonEnumTypeTools } from '../../constants/grid-cell.constant';
import { EnumTypeDataContent } from '../../interfaces/enum-type-data-content.interface';
import { GridColumnType } from '../../enums/grid-column-type.enum';


const tableRow = node => node.tagName.toLowerCase() === 'tr';
const closest = (node, predicate) => {
    while (node && !predicate(node))
        node = node.parentNode;

    return node;
};
const ELLIPSIS_CSS = ['fal', 'fa-ellipsis-v', 'fa-lg'];
const DRAGDROPABOVE_CSS = 'dragDropAbove';
const DRAGDROPBELOW_CSS = 'dragDropBelow';

@Component({
    selector: 'trella-grid-base',
    templateUrl: './trella-grid-base.component.html',
    styleUrls: ['./trella-grid.component.scss']
})
export class TrellaGridBaseComponent extends ComponentWithSubscription implements OnChanges, OnInit {
    @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
    @ViewChild(GridComponent) grid: GridComponent;

    @Input() addActionText = 'Add';
    @Input() customerId = 0;
    @Input() disabled = false;
    @Input() allowAdd = false;
    @Input() parentKey?: string;
    @Input() parentDataItem?: any;
    @Input() hasShowSelectedToggle = false;
    @Input() key = '';
    @Input() reportName: string;
    @Input() rowLinkFactory: (key: Record<string, FormattedData>, info: GridInfo) => string;
    @Input() searchPlaceholderText = 'Search Name, NPI, Location or Specialty';
    // TODO: Move these inputs to GridOptions in the config
    @Input() showAssigneesButton = false;
    @Input() showComparisonsButton = false;
    @Input() showFavoritesButton = false;
    @Input() showSelected = false;
    @Input() showTargetsButton = false;
    @Input() showUnAssigneeButton = false;
    @Input() userFavorites: unknown[] = [];
    @Input() userTargets: unknown[] = [];

    NavigationDirection = NavigationDirection;
    allowGridStateChange = true;
    allowEButton = false;
    currentTarget: any;
    dataResponse: GridDataResult;
    footer: FormattedResult;
    queryOptions?: QueryOptions = new QueryOptions();
    rowIsSelectable = false;
    searchText: string;
    selectedRows: string[] = [];
    selectionsCount: number;
    timeout = null;
    selectedProviders: ProviderIds[] = [];

    dropIndex; // need to store the location of the dropTarget
    dragItem; // the item that we are dragging
    dragStartIndex; // the index of the dragItem

    favoriteHoverText = 'Click here to add selected providers to Favorites';
    targetHoverText = 'Click to add selected providers to Targets';
    comparisonHoverText = 'Click here to add selected providers to a Custom List';
    assigneeHoverText = 'Click here to add Assigned User';
    removeAssigneeHoverText = 'Click here to remove Assigned User';

    copyBtn = new Command({
        show: () => this.gridAbilities.copyCommand,
        execute: (rowIndex, dataItem, $event) => this.command({
            command: GRID_COMMAND.copy,
            rowIndex,
            dataItem,
            sourceEvent: $event
        })
    });

    viewBtn = new Command({
        show: () => this.gridAbilities.viewable,
        execute: (rowIndex, dataItem, $event) => this.command({
            command: GRID_COMMAND.view,
            rowIndex,
            dataItem,
            sourceEvent: $event
        })
    });

    editBtn = new Command({
        show: () => this.gridAbilities.editCommand,
        execute: (rowIndex, dataItem, $event) => this.command({
            command: GRID_COMMAND.edit,
            rowIndex,
            dataItem,
            sourceEvent: $event
        })
    });

    deleteBtn = new Command({
        show: () => this.gridAbilities.deleteRow,
        execute: (rowIndex, dataItem, $event) => this.command({
            command: GRID_COMMAND.delete,
            dataItem,
            rowIndex,
            sourceEvent: $event
        })
    });

    private _currentPage = 1;
    private _searchTextDebounce = new Subject<string>();
    private _gridInfo: GridInfo;

    constructor(
        public element: ElementRef,
        public renderer: Renderer2,
        public zone: NgZone,
        public gridActionService: GridActionService,
        public gridExportService: GridExportService,
        public gridSortService: GridSortService,
        public gridService: GridService,
        public replaceValueMappingService: ReplaceValueMappingService
    ) {
        super();
    }

    /**
     * This is the height of each row. Used for the virtual scroller to help calculate it's paging.
     */
    get rowHeight() {
        // current row height for grids using virtual scroller - will need to pass in as option if this changes. (Targets dashboard grid)
        return this.gridInfo && this.gridInfo.gridAbilities && this.gridInfo.gridAbilities.scrollable === 'virtual' && 37;
    }

    get enableCheckbox() {
        return this.gridAbilities.selectable && this.gridAbilities.selectable.enabled && !this.gridAbilities.hideSelectableCheckbox;
    }

    get canLock() {
        return this.gridInfo &&
            this.gridInfo.columns &&
            this.gridInfo.columns.filter(c => !c?.hidden && !c?.locked).length > 1 &&
            !this.hasDetailRows;
    }

    get createListParams() {
        return {
            selectedNpis: this.selectedRows,
            selectedNpiNames: this.gridInfo && this.dataResponse.data.filter(n => this.selectedRows.includes(n.npi)).map(n => n.npiName),
            providerType: this.gridInfo && this.gridInfo.npiType,
            queryOptions: this.gridInfo && this.queryOptions,
            reportName: this.gridInfo && this.gridInfo.reportName
        };
    }

    get showNpiGroupModal() {
        return this.gridInfo &&
            this.enableListModals &&
            AGENCY_PROVIDER_TYPES.includes(this.gridInfo.npiType) &&
            this.gridInfo.gridOptions.showComparisonsButton;
    }

    get showMasterSearch() {
        return this.gridInfo && this.gridInfo.showMasterSearch;
    }

    get enableListModals() {
        return this.gridAbilities && this.gridAbilities.selectable && this.gridAbilities.selectable.enabled;
    }

    get showAddFavoritesButton() {
        return this.enableListModals && this.gridInfo.gridOptions.showFavoritesButton;
    }

    get showAddTargetsButton() {
        return this.enableListModals && this.gridInfo.gridOptions.showTargetsButton;
    }

    get showClearSortButton() {
        return this.hasSortFields();
    }

    get anySelected() {
        return this.selectedRows && this.selectedRows.length;
    }

    get hasDetailRows() {
        return this.gridInfo && this.gridInfo.hasDetailRows;
    }

    get hasPostNotes() {
        return this.gridInfo && this.gridInfo.postNotes && this.gridInfo.postNotes.details;
    }

    get numberSelected() {
        const count = this.selectionsCount || this.selections && this.selections.length;
        return count && count > 0 ? `(${count})` : undefined;
    }

    get gridInfo() {
        return this._gridInfo;
    }

    @Input() set gridInfo(info: GridInfo) {
        this._gridInfo = info;
        if (info) {
            this.queryOptions = new QueryOptions();
            this.queryOptions.skip = info.skip;
            this.queryOptions.take = info.take;
            this.gridService.reportQueryOptions(this.key, this.queryOptions);
        }
    }

    set selections(selections: string[]) {
        this.selectedRows = selections;
        this.gridService.select(this.key, selections);
    }

    get gridAbilities() {
        return this.gridInfo && this.gridInfo.gridAbilities;
    }

    set gridAbilities(val) {
        if (this.gridInfo) {
            this.gridInfo.gridAbilities = val;
            this.rowIsSelectable =
                this.gridInfo.gridAbilities && this.gridInfo.gridAbilities.selectable && this.gridInfo.gridAbilities.selectable.enabled;
        }
    }

    ngOnInit() {
        this._searchTextDebounce.pipe(takeUntil(this.ngUnsubscribe), debounceTime(800)).subscribe(searchTextValue => {
            const normalizedSearchText = searchTextValue.normalize('NFKC');
            this.gridService.search(this.key, normalizedSearchText);
        });

        this.gridActionService.gridPaginated.pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000)).subscribe(_ => {
            this._currentPage = 1;
        });

        this.subscribe(this.gridSortService.sortRequested, this.key, event => {
            if (event?.sortDescriptors && this.gridInfo && this.queryOptions) {
                this.queryOptions.sort = event.sortDescriptors;
                this.gridService.reportQueryOptions(this.key, this.queryOptions);
                if (event.isRefreshRequested)
                    this.gridService.requestRefresh(this.key, this.gridInfo);

            }
        });

        this.subscribe(this.gridService.dataFetched, this.key, response => {
            if (response) {
                this.dataResponse = response;
                this.collapseAll();

                if (this.selectedRows && this.selectedRows.length) {
                    this.selectedRows.forEach(npiId => {
                        const _npi = this.dataResponse.data.find(row => row.npi && row.npi.value === npiId);
                        if (_npi)
                            this.selectedProviders.push(new ProviderIds(_npi.npiName.value, npiId));
                    });
                }
            }
        });

        this.subscribe(this.gridService.footerFetched, this.key, footer => {
            this.footer = footer;
        });

        this.subscribe(this.gridService.selectionRequested, this.key, selections => {
            this.selections = selections;
        });

        this.subscribe(this.gridService.selectionCountRequested, this.key, selectionsCount => {
            this.selectionsCount = selectionsCount;
        });

        this.subscribe(this.gridService.eButtonPermissionChecked, this.key, isAllowed => {
            this.allowEButton = isAllowed;
        });

        this.subscribe(this.gridService.selectedRowsCleared, this.key, _ => this.clearSelectedRows());

        if (this.parentKey)
            this.subscribe(this.gridService.selectedRowsCleared, this.parentKey, _ => this.clearSelectedRows());

    }

    ngOnChanges(changes: SimpleChanges) {
        // merge to use defaults
        // TODO: This fires too many times
        this.gridAbilities = Object.assign(new GridAbilities(), this.gridAbilities);
    }

    command(e: GridCommandInfo) {
        this.gridService.issueCommand(this.key, e);
    }

    columnChange(e: ColumnVisibilityChangeEvent) {
        this.gridInfo.mergeColumnChangeEvent(e);
        this.gridService.updateColumns(this.key, this.gridInfo.columns);
    }

    isFilterable() {
        return this.gridAbilities.filterable && this.hasFilterableColumn();
    }

    goBack() {
        this._currentPage = Math.max(1, this._currentPage - 1);
        this.updatePage();
    }

    goForward() {
        this._currentPage++;
        this.updatePage();
    }

    updatePage() {
        const oldSkip = this.queryOptions.skip;
        const newSkip = this.queryOptions.take * (this._currentPage - 1);

        if (oldSkip !== newSkip) {
            this.queryOptions.skip = newSkip;
            this.gridService.reportQueryOptions(this.key, this.queryOptions);
            this.gridService.requestRefresh(this.key, this.gridInfo);
        }
    }

    hasFilterableColumn() {
        return this.gridInfo.columns.some(x => x.filterable);
    }

    npiFieldNotEmpty(dataItem: unknown): boolean {
        const npi = dataItem && this.gridInfo.npiField && dataItem[this.gridInfo.npiField];
        return npi ? dataItem[this.gridInfo.npiField].value !== '' : true;
    }

    isFavoriteColumn(col: GridColumn) {
        return col && col.favoriteable;
    }

    isFavorite(data: unknown) {
        const npi = data && this.gridInfo.npiField && data[this.gridInfo.npiField];
        const currentFavorites = this.userFavorites as any[];
        return npi && currentFavorites && currentFavorites.find(i => i.npi === npi && i.providerType === this.gridInfo.npiType);
    }

    isHighlighted(column: GridColumn) {
        return column && column.highlight;
    }

    toggleFavorite(dataItem: unknown) {
        const npi = dataItem && this.gridInfo.npiField && dataItem[this.gridInfo.npiField];
        const providerType = this.gridInfo.npiType;
        this.gridService.toggle(this.key, [npi, providerType, GRID_ACTION_TYPE.FAVORITE]);
    }

    isTargetColumn(col: GridColumn) {
        return col && col.targetable;
    }

    isTarget(data: unknown) {
        const npi = data && this.gridInfo.npiField && data[this.gridInfo.npiField];
        const currentTargets = this.userTargets as any[];
        return npi && currentTargets && currentTargets.find(i => i.npi === npi && i.providerType === this.gridInfo.npiType);
    }

    toggleTarget(dataItem: unknown) {
        const npi = dataItem && this.gridInfo.npiField && dataItem[this.gridInfo.npiField];
        if (!npi)
            return;


        const npiType = this.gridInfo.npiType;
        this.gridService.toggle(this.key, [npi, npiType, GRID_ACTION_TYPE.TARGET]);
    }

    clearTextFilter() {
        this.searchText = '';
        this._searchTextDebounce.next(this.searchText);
    }

    triggerTextFilter() {
        this.searchText = this.searchText.normalize('NFKC');
        this._searchTextDebounce.next(this.searchText);
    }

    getColDefinition(kendoColumn: GridColumn) {
        return kendoColumn && kendoColumn.definition;
    }

    createCustomDefinition(column: GridColumn) {
        let customDefinition;
        if (column && column.definition) {
            customDefinition = this.replaceValueMappingService.runReplacement(column.definition);
            (column.replacements || []).forEach(x => {
                const replacementUrl = `<a class='definitionColor' target='_blank' href='http://${x.url}'>${x.title}</a>`;
                customDefinition = customDefinition.replace(new RegExp(`@${x.word}`, 'g'), replacementUrl);
            });
        }
        return customDefinition || column.title;
    }

    addFavoriteTargetEventListeners() {
        // TODO
    }

    getCustomColumnSubtitle(column: GridColumn): string[] {
        if (!column || !column.sparklineCol || !column.sparklineCol.subtitles)
            return [];


        return column.sparklineCol.subtitles;
    }

    columnNeedsBorder(col: GridColumn) {
        if (!col)
            return false;

        return !col.targetable;
    }

    public rowCallback = (context: RowClassArgs) => this.getRowCallBack(context);

    public getRowCallBack = (context: RowClassArgs) => ({
        dragging: context.dataItem.dragging,
        thRowSelectable: this.rowIsSelectable
    });

    public handleDragAndDrop() {
        if (!this.gridInfo.gridAbilities.isDragAndDrop)
            return;

        const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
        tableRows.forEach(item => {
            if ((item as any).draggable === true)
                return;

            this.renderer.setAttribute(item, 'draggable', 'true');
            // If we need to throttle these, we can easily use the lodash throttle function.
            item.addEventListener('dragenter', (event: DragEvent) => this.handleDragEnter(event));
            item.addEventListener('dragleave', (event: DragEvent) => this.handleDragLeave(event));
            item.addEventListener('dragstart', (event: DragEvent) => this.handleDragStart(event));
            item.addEventListener('dragend', (event: DragEvent) => this.handleDragEnd(event));
            item.addEventListener('mouseenter', (event: DragEvent) => this.handleMouseOver(event));
            item.addEventListener('mouseleave', (event: DragEvent) => this.handleMouseLeave(event));
        });
    }

    // Used in Html
    public clearSort() {
        if (this.queryOptions && this.queryOptions.sort) {
            this.queryOptions.sort = this.queryOptions.sort = [];

            this.gridService.reportQueryOptions(this.key, this.queryOptions);
            this.gridService.requestRefresh(this.key, this.gridInfo);
        }
    }

    dataStateChange(state: DataStateChangeEvent): void {
        if (this.allowGridStateChange) {
            this.queryOptions = state;
            this.gridService.reportQueryOptions(this.key, this.queryOptions);
            this.gridService.requestRefresh(this.key, this.gridInfo);
        }

        this.zone.onStable.pipe(take(1)).subscribe(() => this.handleDragAndDrop());
    }

    // TODO: all this formatting code will be ripped out in favor of a server side solution.
    getDisplayedValue(value: any | FormattedData, column: GridColumn) {
        // TODO: Can we return the full value in the value field and have the formattedValue field contain just the text?
        // Maybe a "map" transform
        if (!column)
            return;


        if (column.enumType)
            return;

        if ((value || {}).formattedValue || (value || {}).formattedValue === '') {
            if (column && column.convertToLocalDate) {
                if (value?.value.includes(',')) { // check if list of dates
                    return Utils.convertToLocalDates(value);
                }
                return Utils.convertToLocalDate(value);
            }

            return value.formattedValue;
        }

        if (column) {
            if (Utils.doesFormatFunctionExist(column.dataType)) {
                const transformFunc = Utils.getFormatFunction(column.dataType);
                return transformFunc(value.formattedValue);
            }
        }

        if (!Utils.exists(column.format) || !Utils.exists(value) || Utils.isSpecialValue(value))
            return value;


        if (value <= 0)
            return Utils.getDisplayedValue(value, column.format);


        if (column.format.indexOf('%') > -1) {
            // Turn into percent
            value = this.getPercentValueFromDecimal(value);

            if (!value) {
                // Value is special. Handle this generically
                return Utils.getDisplayedValue(value, column.format);
            }

            return value.toFixed(column.decimalPoints || 2) + '%';
        }

        if (column.format.indexOf('<') > -1 && value < 11 && value > 0) {
            // check for values <11
            return DISPLAY_VALUE.lessThanEleven;
        }

        if (column.format.includes('I'))
            value = round(value);


        if (column.decimalPoints)
            return parseFloat(value).toFixed(column.decimalPoints);


        if (column.format.indexOf('1') > -1) {
            // Decimal with 1 digit after dot
            return parseFloat(value).toFixed(1);
        }

        if (column.dataType === GridDataType.integer && column.dataSubType === DataSubType.currency) {
            const currencyFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: Number.isInteger(column.decimalPoints) ? column.decimalPoints : 2
            });

            return currencyFormatter.format(value);
        }

        return Utils.getDisplayedValue(value, column.format);
    }

    hasSortFields(): boolean {
        return !!(this.queryOptions && this.queryOptions.sort && this.queryOptions.sort.filter(sort => sort.dir).length);
    }

    getPercentValueFromDecimal(value: any): number {
        if (!Utils.exists(value) || Utils.isSpecialValue(value) || value <= 0)
            return 0;


        return value * 100;
    }

    getSparklineArray(dataItem: any, col: GridColumn) {
        if (!dataItem || !col || !col.sparklineCol || !col.sparklineCol.fields)
            return [];


        const sparklineFields = col.sparklineCol.fields;
        return sparklineFields.map(field => dataItem[field] < 0 ? 0 : dataItem[field]);
    }

    getRawSparklineArray(dataItem: any, col: GridColumn) {
        if (!dataItem || !col || !col.sparklineCol || !col.sparklineCol.fields)
            return [];


        const sparklineFields = col.sparklineCol.fields;
        return sparklineFields.map(field => dataItem[field]);
    }

    getProgressBarClass(dataItem: any, col: GridColumn): string {
        const neutralColor = 'bg-primary';

        if (!dataItem || !col || !col.comparisonBar)
            return neutralColor;


        const comparison = col.comparisonBar;
        if (dataItem[comparison.comparedFromColumn] <= 0)
            return neutralColor;


        const finalCompareFrom = Number((dataItem[comparison.comparedFromColumn] * 100).toFixed(1));
        const finalCompareTo = Number((dataItem[comparison.comparedToColumn] * 100).toFixed(1));

        const compareOperators: string[] = comparison.comparison.split(',');

        let lessThanCounter = 0;
        let greaterThanCounter = 0;
        let equalToCounter = 0;

        compareOperators.forEach(operator => {
            if (finalCompareFrom < finalCompareTo && operator === '<') {
                // compared from value is less than compared to value
                lessThanCounter++;
            } else if (finalCompareFrom > finalCompareTo && operator === '>') {
                // compared from value is greater than compared to value
                greaterThanCounter++;
            } else {
                // compared from value is equal to compared to value
                equalToCounter++;
            }
        });

        return lessThanCounter > 0 ? 'bg-success' : greaterThanCounter > 0 ? 'bg-danger' : neutralColor;
    }

    isDefaultCol(col: GridColumn) {
        return !col.clickable && !col.sparklineCol && !col.comparisonBar && !col.favoriteable && !col.targetable;
    }

    isClickableCol(col: GridColumn) {
        return col.clickable;
    }

    isComparisonCol(col: GridColumn) {
        return col.comparisonBar;
    }

    isSparklineCol(col: GridColumn) {
        return col.sparklineCol;
    }

    openCreateFavoriteListModal() {
        this.gridService.issueCommand(this.key, {
            command: GRID_COMMAND.favorite,
            dataItem: this.createListParams
        } as GridCommandInfo);
    }

    openCreateTargetListModal() {
        this.gridService.issueCommand(this.key, {
            command: GRID_COMMAND.target,
            dataItem: this.createListParams
        } as GridCommandInfo);
    }

    openCreateAssigneeListModal() {
        this.gridService.issueCommand(this.key, {
            command: GRID_COMMAND.assignee,
            dataItem: this.createListParams,
            sourceEvent: ACTION_TYPE.ADD
        } as GridCommandInfo);
    }

    openRemoveAssigneeModal() {
        this.gridService.issueCommand(this.key, {
            command: GRID_COMMAND.assignee,
            dataItem: this.createListParams,
            sourceEvent: ACTION_TYPE.REMOVE
        } as GridCommandInfo);
    }

    openAddToNpiGroupModal() {
        this.gridService.issueCommand(this.key, {
            command: GRID_COMMAND.npiGroup,
            dataItem: this.createListParams
        } as GridCommandInfo);
    }

    getExcelFileName(): string {
        return `${this.gridInfo.reportName || 'Trella Health Excel File'}.xlsx`;
    }

    getGridDataRowNpi(data): string {
        return data && data[this.gridInfo.keyField] && (data[this.gridInfo.keyField].value || data[this.gridInfo.keyField]);
    }

    getGridDataRowNpiName(data): string {
        return data &&
            (data.npiName && (data.npiName.value || data.npiName) ||
                data.npiname && (data.npiname.value || data.npiname) ||
                data.npiName2 && (data.npiName2.value || data.npiName2) ||
                data.billing_npi_name && (data.billing_npi_name.value || data.billing_npi_name));
    }

    selectKeyfield = (context: RowArgs): string =>
        this.gridInfo.keyField &&
        context.dataItem[this.gridInfo.keyField] &&
        (context.dataItem[this.gridInfo.keyField].value || context.dataItem[this.gridInfo.keyField]);

    openNetworkModal() {
        this.gridService.addToNetwork(this.key, this.selectedProviders);
    }

    selectionChange(event: SelectionEvent) {
        if (event.selectedRows.length) {
            event.selectedRows.forEach(row => {
                const npiId = this.getGridDataRowNpi(row.dataItem);
                const name = this.getGridDataRowNpiName(row.dataItem);
                this.selectedRows.push(npiId);
                this.selectedProviders.push(new ProviderIds(name, npiId));
            });
            this.gridService.select(this.key, this.selectedRows);
        } else {
            const deselectedNpis = event.deselectedRows.map(row => this.getGridDataRowNpi(row.dataItem));
            this.selections = this.selectedRows.filter(npi => !deselectedNpis.includes(npi));
            this.selectedProviders = this.selectedProviders.filter(provider => !deselectedNpis.includes(provider.npiId));
        }

        // In case if the last item was already selected and the user clicks on the selected item,
        // but kendo-grid doesn't include this information inside the event.
        const lastSelectedLevel = this.selectedRows[this.selectedRows.length - 1];
        const selectedIndex = Number(lastSelectedLevel) - 1;
        const gridData = (this.grid.data as GridDataResult).data;
        if (!event.selectedRows.some(row => row.index === selectedIndex) && gridData.some(i => i.lvl)) {
            const selectedItem = gridData.filter(i => i.lvl.value === lastSelectedLevel)[0];
            event.selectedRows.push({dataItem: selectedItem, index: selectedIndex});
        }

        const e: GridCommandInfo = {
            command: GRID_COMMAND.select,
            dataItem: this.selectedRows,
            rowIndex: null,
            sourceEvent: event
        };

        this.gridService.issueCommand(this.key, e);
    }

    sortChange(sort: SortDescriptor[]) {
        // this method, sortChange, gets called after dataStateChange. We have the pattern to manually set this flag to to prevent any data
        // or sort events from occurring when an ibutton is clicked.
        if (!this.allowGridStateChange) {
            this.allowGridStateChange = true;
            return;
        }
        const filteredSort = sort.filter(e => e.dir);
        this.queryOptions.sort = filteredSort;
        this.gridService.reportQueryOptions(this.key, this.queryOptions);
    }

    toggleShowSelected(event: MatSlideToggleChange) {
        this.gridService.showSelected(this.key, event.checked);
    }

    clearSelected() {
        this.gridService.clearSelected(this.key);
    }

    addReport() {
        this.gridService.addReport(this.key, this.gridInfo);
    }

    gridHasMultiColumnHeader(columns: GridColumn[]) {
        return columns.some(c => c.columnType === GridColumnType.preheader) ? 'bottom' : '';
    }

    public displayedData = async () => {
        const columns = (this.gridInfo?.columns ?? []).map(column => {
            if (column.field)
                column.field = this.formatKey(column.field);

            return column;
        });

        let bigExport: GridDataResult = null;
        if (this.gridInfo) {
            bigExport = await this.getExportData();
            if (bigExport && !bigExport.data?.length)
                return {};

        }

        const gridData = bigExport || this.dataResponse;
        const data = gridData?.data;

        const displayedResults = data.map(d => {
            const formattedData = {};
            for (const key in d) {
                if (key) {
                    const fieldKey = this.formatKey(key);
                    if (d.hasOwnProperty(key)) {
                        let column = columns.find(col => fieldKey === col.field);
                        // If we can't find the column, check the preheaders.
                        if (!column) {
                            const preheader = columns.find(p => p.columns.some(c => c.field === fieldKey));
                            if (preheader)
                                column = preheader.columns.find(c => fieldKey === c.field);

                        }
                        formattedData[fieldKey] = this.getDisplayedValue(d[key], column);
                    }
                }
            }
            return formattedData;
        });

        const result: ExcelExportData = {
            data: process(displayedResults, {}).data
        };

        return result;
    };

    async getExportData(): Promise<GridDataResult> {
        if (!this.gridInfo)
            return new Promise<GridDataResult>((resolve, reject) => null as GridDataResult);


        const result = await this.gridExportService.getDataToExport(this.gridInfo, this.parentDataItem);
        if (result && result.gridInfo.reportName === this.gridInfo.reportName)
            return result.result || GridExport.withNoData(this.gridInfo).result;


        return GridExport.withNoData(this.gridInfo).result;
    }

    openE() {
        if (!this.allowEButton)
            return;

        this.gridService.reportEButtonClick(this.key);
    }

    doesTextNeedTruncation(data: any, column: GridColumn): boolean {
        const toolTipText = this.getDisplayedValue(data, column);
        return toolTipText.length > column.maxCharacters;
    }

    buildLink(dataItem: Record<string, FormattedData>) {
        if (this.rowLinkFactory && this.gridInfo)
            return this.rowLinkFactory(dataItem, this.gridInfo);

        return '';
    }

    private collapseAll() {
        this.dataResponse.data.forEach((_, id) => {
            try {
                this.grid.collapseRow(id);
            } catch (err) {
                if (err instanceof TypeError) {
                    // do nothing
                } else
                    throw err;

            }
        });
    }

    isComparisonEnumType(col: GridColumn) {
        return ComparisonEnumTypeTools.isComparisonEnumType(col);
    }

    getEnumTypeData(col: GridColumn, dataItem: Record<string, FormattedData>): EnumTypeDataContent {
        return ComparisonEnumTypeTools.getEnumTypeData(col, dataItem);
    }

    // Removes - and () so that '-$10' or '($10)' become '$10'
    getComparisonEnumTypeDisplay(data: string) {
        return data.replace(/[-\(\)]/g, '');
    }

    private handleDragStart(event: DragEvent) {
        const {dataTransfer, target} = event;

        // Firefox won't drag without setting data
        dataTransfer.setData('application/json', '');

        const row: HTMLTableRowElement = target as HTMLTableRowElement;
        this.dropIndex = row.rowIndex;
        this.dragStartIndex = row.rowIndex;

        const dataItem = this.dataResponse.data[this.dropIndex];
        dataItem.dragging = true;
        this.dragItem = dataItem;
    }

    private handleDragEnter(event: DragEvent) {
        event.preventDefault();
        const target = closest(event.target, tableRow);
        this.dropIndex = target.rowIndex;

        target.classList.add(this.getApplicableDragDropCssClass());
    }

    private handleDragLeave(event: DragEvent) {
        event.preventDefault();
        const row = closest(event.target, tableRow);
        this.dropIndex = row.rowIndex;

        row.classList.remove(DRAGDROPABOVE_CSS, DRAGDROPBELOW_CSS);
    }

    private handleDragEnd(event: DragEvent) {
        event.preventDefault();
        const row = closest(event.target, tableRow);
        const data = this.dataResponse.data;
        const iconElement = this.getInnerIconElement(row);
        iconElement.classList.remove(...ELLIPSIS_CSS);

        let dropIndex = this.dropIndex;
        if (this.isStartIndexLessThanDropIndex(dropIndex))
            dropIndex++;


        let targetAtDropIndex = data[dropIndex];

        // handle something being dropped at end of list
        // need to incrememnt position, of prev element.
        let positionModifier = 0;
        if (!targetAtDropIndex) {
            targetAtDropIndex = data[dropIndex - 1];
            positionModifier = 1;
        }

        if (this.dragItem === targetAtDropIndex)
            return;


        data.splice(dropIndex, 0, {...this.dragItem, dragging: false}); // insert item
        if (data.find(item => item === this.dragItem))
            data.splice(data.indexOf(this.dragItem), 1); // remove item


        // get "real position" - it may have changed if we are moving things around in the grid
        // TODO: output dragEvent
        // const targetServiceTarget = this.targetsService.allTargets.find(target => target.targetId === targetAtDropIndex.targetId)
        // this.targetsService.move({
        // 	targetId: this.dragItem.targetId,
        // 	newPosition: targetServiceTarget.position + positionModifier
        // })
    }

    private handleMouseOver(event: DragEvent) {
        event.preventDefault();
        const row = this.getRowFromEvent(event);
        const iconEl = this.getInnerIconElement(row);

        if (!iconEl)
            return;


        iconEl.classList.add(...ELLIPSIS_CSS);
    }

    private handleMouseLeave(event: MouseEvent) {
        event.preventDefault();
        const row = this.getRowFromEvent(event);
        const iconEl = this.getInnerIconElement(row);

        if (!iconEl)
            return;


        iconEl.classList.remove(...ELLIPSIS_CSS);
    }

    private getRowFromEvent(event: MouseEvent | DragEvent): HTMLTableRowElement {
        const {target} = event;
        const row: HTMLTableRowElement = target as HTMLTableRowElement;
        return row;
    }

    private getInnerIconElement(row: HTMLTableRowElement): HTMLElement {
        return row.querySelector('i.ellipsis');
    }

    private isStartIndexLessThanDropIndex(dropIndex: number): boolean {
        return this.dragStartIndex < dropIndex;
    }

    private getApplicableDragDropCssClass(): string {
        if (!this.isStartIndexLessThanDropIndex(this.dropIndex))
            return DRAGDROPABOVE_CSS;
        else
            return DRAGDROPBELOW_CSS;

    }

    private clearSelectedRows() {
        if (!this.gridInfo)
            return;


        this.selections = [];
        this.gridService.clearAll(this.key, true);
    }


    private formatKey = (key: string) => key.replace(/[\]\s)}[{(]/g, '');
}
