import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'trella-savable-wrapper',
    templateUrl: './savable-wrapper.component.html',
    styleUrls: ['./savable-wrapper.component.scss']
})
export class SavableWrapperComponent {
    @Input() canSave = false;
    @Input() header: string;
    @Output() cancelClicked: EventEmitter<void> = new EventEmitter();
    @Output() saveClicked: EventEmitter<void> = new EventEmitter();


    cancel() {
        this.cancelClicked.emit();
    }

    save() {
        this.saveClicked.emit();
    }
}