import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ImportAssignments, ImportAssignmentsError, ImportAssignmentsResults } from '../models/import-assignments';
import { AssigneeApi } from '../../api/assignment.api';
import { ImportValidationEnum } from '../enums/import-validate.enum';

@Injectable({
	providedIn: 'root'
})
export class ImportAssignmentsService {
	companyId: string;
	target: string;
	importAssignmentFileChange$: Observable<any>;
	fileValidationMonitorChange$: Observable<any>;
	fileErrorInfoRequest$: Observable<any>;
	fileErrorInfoChange$: Observable<ImportAssignmentsError>;
	cleanUpMessageChange$: Observable<any>;
	finalizeAssignmentImportChange$: Observable<any>;
	isFinalizeDoneChange$: Observable<any>;
	finalAssignmentResultsRequestChange$: Observable<any>;
	resultsInfoChange$: Observable<any>;
	insertCompleteCheckChange$: Observable<boolean>;
	wasAssignmentFinalizedBeforeChange$: Observable<any>;

	private fileValidationsErrors: ImportAssignmentsError;
	private importAssignmentFile = new Subject<any>(); // holder for imported file
	private fileValidationMonitor = new Subject<any>(); // holder for validation response from backend
	private requestFileErrorTrigger = new Subject<any>(); // trigger for the import-users-dialog to send error information
	private fileErrorInfo = new Subject<ImportAssignmentsError>(); // holder for import errors
	private cleanUpMessage = new Subject<any>(); // holder for any clean up messages
	private finalizeImport = new Subject<any>(); // trigger for import-users-dialog to finalize the import
	private isFinalizeDone = new Subject<any>(); // let finalize component know that the process has finished
	private finalResultsRequest = new Subject<any>(); // trigger for import-user-dialog to send the results information;
	private resultsInfo = new Subject<any>(); // holder for import results
	private insertCompleteCheck = new Subject<boolean>();
	private wasFinalizedBefore = new Subject<any>();

	constructor(private assignmentApi: AssigneeApi) {
		this.importAssignmentFileChange$ = this.importAssignmentFile.asObservable();
		this.fileValidationMonitorChange$ = this.fileValidationMonitor.asObservable();
		this.fileErrorInfoRequest$ = this.requestFileErrorTrigger.asObservable();
		this.fileErrorInfoChange$ = this.fileErrorInfo.asObservable();
		this.cleanUpMessageChange$ = this.cleanUpMessage.asObservable();
		this.finalizeAssignmentImportChange$ = this.finalizeImport.asObservable();
		this.isFinalizeDoneChange$ = this.isFinalizeDone.asObservable();
		this.finalAssignmentResultsRequestChange$ = this.finalResultsRequest.asObservable();
		this.resultsInfoChange$ = this.resultsInfo.asObservable();
		this.insertCompleteCheckChange$ = this.insertCompleteCheck.asObservable();
		this.wasAssignmentFinalizedBeforeChange$ = this.wasFinalizedBefore.asObservable();
	}

	setAssignmentTargets(id: string) {
		const marketscape = 'marketscape';
		const crm = 'crm';
		this.target = id === marketscape ? marketscape : id === crm ? crm : null;
	}

	sendImportAssignmentFile(file: File) {
		this.importAssignmentFile.next(file);
	}

	requestFileErrors() {
		this.requestFileErrorTrigger.next(null);
	}

	fileValidationComplete(validationResponse: ImportValidationEnum) {
		this.fileValidationMonitor.next(validationResponse);
	}

	setFileErrors(errors: ImportAssignmentsError) {
		this.fileErrorInfo.next(errors);
	}

	triggerFinalizeComplete() {
		this.isFinalizeDone.next(null);
	}

	insertCheckResponse(result: boolean) {
		this.insertCompleteCheck.next(result);
	}

	setImportResults(results: ImportAssignmentsResults) {
		this.resultsInfo.next(results);
	}

	sendCleanUpMessage(message: string) { 
		this.cleanUpMessage.next(message);
	}

	triggerResultsRequest() {
		this.finalResultsRequest.next(null);
	}

	startFinalizeImport() {
		this.finalizeImport.next(null);
	}

	checkOnFinalize() {
		this.wasFinalizedBefore.next(null);
	}

	validateAssignmentImport(importUserObj: ImportAssignments) {
		return this.assignmentApi.validateAssignmentImport(importUserObj);
	}

	finalizeAssignmentImport(fileId: number, invalidRows: number[], customerId: string, target: string) {
		return this.assignmentApi.finalizeAssignmentImport(fileId, invalidRows, customerId, target);
	}

	cleanUpImport(fileId: number) {
		return this.assignmentApi.cleanUpAssignmentImport(fileId);
	}

	setFileValidationErrors(errors: ImportAssignmentsError) {
		this.fileValidationsErrors = errors;
	}

	getFileValidationErrors() {
		return this.fileValidationsErrors;
	}
}
