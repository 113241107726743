export enum ProductName {
    Crm = 'Crm',
    Hha = 'Hha',
    Hme = 'Hme',
    Hos = 'Hos',
    LeadGenerator = 'LeadGenerator',
    Snf = 'Snf',
    Strategy = 'Strategy',
    TrellaDataApi = 'TrellaDataApi'
}
