<div *ngIf="options" class="row border g-0 div-overlay-wrapper lookup-card bg-white">
    <div class="col">
        <div *ngIf="options.displayTitleBar"
             class="row g-0 p-3 justify-content-between align-items-center border-bottom">
            <div class="col-auto insight-text-heading w-100">
                Demographic Card
                <i
                        (click)="removeFromDataKit()"
                        *ngIf="options.removeDataKit"
                        class="far fa-trash me-3 pointer float-end"
                        title="Remove Demographic Card"
                ></i>
            </div>
        </div>
        <div class="row g-0 border text-secondary dc-height-control">
            <div class="col-12 col-md-4 border-0 border-md-right p-3">
                <div class="row g-0 align-items-center">
                    <div *ngIf="icon" class="col-auto me-4">
                        <i class="{{ icon }} {{ color }} fa-2x"></i>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col text-label-md">
                                <span>{{ displayName }}</span>
                                <span> {{ agencyAndAlias }}</span>
                                <span *ngIf="showMedicalDirector" class="col text-label">(Medical Director)</span>
                                <span
                                        *ngIf="isMultiNPIFacility"
                                        class="ms-3"
                                        title="This is the billing NPI for more than one facility or location"
                                ><i class="fas fa-users-class fa-lg"></i
                                ></span>
                                <ng-container *ngIf="showStarRating">
                                    <br/>
                                    <span class="star-rating-container">
										<trella-stars
                                                [max]="options.maxNumberofStars"
                                                [noRating]="noRating"
                                                [ratingNotApplicable]="info?.rating === -2"
                                                [rating]="info?.rating || 0"
                                                class="d-inline"
                                                title="{{ customStarRatingHover }}"
                                        ></trella-stars>
									</span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col button-holder text-data text-primary {{ color }}">
                                <span class="fw-bold">NPI</span>
                                <a
                                        *ngIf="options.displayLinkToNPPES"
                                        class="ms-1"
                                        href="https://npiregistry.cms.hhs.gov/provider-view/{{ info.npi }}"
                                        id="providerNPI"
                                        target="_blank"
                                >{{ info.npi }}</a
                                >
                                <span *ngIf="!options.displayLinkToNPPES" class="ms-1">{{ info.npi }}</span>
                                <i
                                        (click)="toggleTarget()"
                                        *ngIf="options.displayTarget"
                                        [class.disable_icon]="disableActionItems"
                                        [class.pointer]="options.editTarget && !disableActionItems"
                                        [class.target-active]="isTarget"
                                        aria-hidden="true"
                                        class="ms-3 target-icon-default fa-lg"
                                        title="Target"
                                ></i>
                                <i
                                        (click)="toggleFavorite()"
                                        *ngIf="options.displayFavorite"
                                        [class.disable_icon]="disableActionItems"
                                        [class.favorite-active]="isFavorite"
                                        [class.pointer]="options.editFavorite && !disableActionItems"
                                        aria-hidden="true"
                                        class="ms-1 favorite-icon-default fa-lg"
                                        title="Favorite"
                                ></i>
                                <i
                                        (click)="toggleNpiGroup()"
                                        *ngIf="options.displayNpiGroup"
                                        [class.disable_icon]="disableActionItems"
                                        [class.npi-group-active]="isInNpiGroup"
                                        [class.pointer]="options.editNpiGroup && !disableActionItems"
                                        aria-hidden="true"
                                        class="ms-1 npi-group-icon-default fa-lg"
                                        title="Custom List"
                                ></i>

                                <a (click)="goToCRM($event)" *ngIf="options.displayCRMLink && !disableActionItems"
                                   class="ms-1 cmr-icon-default" href="">
                                    <img [src]="options.crmSvg" aria-hidden="true" title="View in CRM"/>
                                    <span>View in CRM</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md border-0 border-md-right p-3">
                <div class="row">
                    <div class="col text-label">
                        Location
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col text-data">
                        {{ city }}, {{ state }}
                        <br/>
                        {{ county }}
                    </div>
                </div>
            </div>
            <div class="col-6 col-md border-0 border-md-right p-3">
                <div class="row">
                    <div class="col-auto">
                        <div class="row">
                            <div class="col text-label">
                                Specialty
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col text-data">
                                {{ specialty }} <span *ngIf="options.displaySubSpecialty">{{ subSpecialty }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="options.displayAssignees && !disableActionItems"
                 class="col-12 col-md border-0 border-md-right p-3">
                <div class="row">
                    <div class="col text-label">
                        Assigned User(s) <span (click)="addUser()" class="pointer"><i aria-hidden="true"
                                                                                      class="fas fa-plus"></i></span>
                    </div>
                </div>

                <div *ngIf="permissions.assigneeAdd && !disableActionItems" class="row">
                    <div class="col">
                        <div (click)="addUser()" class="row pointer">
                            <div *ngIf="!assignees?.length" class="col-7">None</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!disableActionItems" class="row">
                    <div class="col">
                        <div class="col" class="assignments">
                            <div *ngFor="let assignee of assignees">
                                <span class="text-data">{{ assignee.name }} - {{ assignee.lob | uppercase }}</span> <i
                                    (click)="removeUser(assignee.id, assignee.lob, assignee.email)"
                                    class="fa fa-remove ms-2 pointer"
                                    style="color:red"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="(options.displayDetails || options.displayPresentationButton) && !disableActionItems"
                 class="col-12 border-top py-3 col-md-auto border-md-0 py-md-0 d-flex no-print">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <i (click)="addToCartChart()"
                                   *ngIf="options.addToCartChart"
                                   class="me-3 far fa-clipboard text-primary pointer float-end"
                                   title="Add Demographic To Your Clipboard">
                                </i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col align-items-center">
                                <button (click)="createPresentation()"
                                        *ngIf="options.displayPresentationButton"
                                        class="presentation-button"
                                        title="{{ customDataKitHover }}"
                                        type="button">
                                    PRESENTATION
                                </button>

                                <i (click)="toggleDetails()"
                                   *ngIf="options.displayDetails"
                                   [class.fa-angle-down]="!open"
                                   [class.fa-angle-up]="open"
                                   class="far fa-2x px-3 pointer">
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="open && options.displayDetails && !disableActionItems"
             class="row g-0 border border-top-0 text-data text-secondary">
            <div class="col p-3">
                <div class="row">
                    <div class="col text-label">
                        Contact
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-auto">
                        <i class="fas fa-envelope"></i>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input
                                [(ngModel)]="info.email"
                                [readonly]="!options.editInfo"
                                class="form-control"
                                placeholder="Email"
                                type="text"
                        />
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.email" class="text-black">{{ info.email }}</span>
                        <span *ngIf="!info.email" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-auto">
                        <i class="fas fa-phone"></i>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input
                                [(ngModel)]="info.phone"
                                [readonly]="!options.editInfo"
                                class="form-control"
                                placeholder="Phone"
                                type="text"
                        />
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.phone" class="text-black">{{ info.phone }}</span>
                        <span *ngIf="!info.phone" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-auto">
                        <i class="fas fa-fax"></i>
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input [(ngModel)]="info.fax" [readonly]="!options.editInfo" class="form-control" placeholder="Fax"
                               type="text"/>
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.fax" class="text-black">{{ info.fax }}</span>
                        <span *ngIf="!info.fax" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-auto">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="col text-black">
                        <div class="row">
                            <div *ngIf="editMode" class="col">
                                <input
                                        [(ngModel)]="info.address1"
                                        [readonly]="!options.editInfo"
                                        class="form-control"
                                        placeholder="Address 1"
                                        type="text"
                                />
                            </div>
                            <div *ngIf="!editMode" class="col">
                                {{ info.address1 }}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div *ngIf="editMode" class="col">
                                <input
                                        [(ngModel)]="info.address2"
                                        [readonly]="!options.editInfo"
                                        class="form-control"
                                        placeholder="Address 2"
                                        type="text"
                                />
                            </div>
                            <div *ngIf="!editMode" class="col">
                                {{ info.address2 }}
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div *ngIf="editMode" class="col">
                                <div class="row">
                                    <div class="col-auto">
                                        <input
                                                [(ngModel)]="info.city"
                                                [readonly]="!options.editInfo"
                                                class="form-control"
                                                placeholder="city"
                                                type="text"
                                        />
                                    </div>
                                    <div class="col-auto">
                                        <input
                                                [(ngModel)]="info.state"
                                                [readonly]="!options.editInfo"
                                                class="form-control"
                                                placeholder="state"
                                                style="width: 80px;"
                                                type="text"
                                        />
                                    </div>
                                    <div class="col-auto">
                                        <input
                                                [(ngModel)]="info.zip"
                                                [readonly]="!options.editInfo"
                                                class="form-control"
                                                placeholder="zip"
                                                type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!editMode" class="col">{{ info.city }}, {{ info.state }} {{ info.zip }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col p-3 text-data">
                <div class="row">
                    <div class="col text-label">
                        Other
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-auto other-label-width">
                        Alias
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input
                                [(ngModel)]="info.alias"
                                [readonly]="!options.editInfo"
                                class="form-control"
                                placeholder="Alias"
                                type="text"
                        />
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.alias" class="text-black">{{ info.alias }}</span>
                        <span *ngIf="!info.alias" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-auto other-label-width">
                        Subspecialty
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input
                                [(ngModel)]="info.subSpecialty"
                                [readonly]="!options.editInfo"
                                class="form-control"
                                placeholder="Subspecialty"
                                type="text"
                        />
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.subSpecialty" class="text-black">{{ info.subSpecialty }}</span>
                        <span *ngIf="!info.subSpecialty" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-auto other-label-width">
                        Specialty Alias
                    </div>
                    <div *ngIf="editMode" class="col">
                        <input
                                [(ngModel)]="info.specialtyAlias"
                                [readonly]="!options.editInfo"
                                class="form-control"
                                placeholder="Specialty Alias"
                                type="text"
                        />
                    </div>
                    <div *ngIf="!editMode" class="col">
                        <span *ngIf="info.specialtyAlias" class="text-black">{{ info.specialtyAlias }}</span>
                        <span *ngIf="!info.specialtyAlias" class="font-italic text-secondary-light">Empty</span>
                    </div>
                </div>

                <div *ngIf="editMode" class="d-flex flex-row justify-content-evenly mt-5">
                    <button (click)="save()" [disabled]="!canSave" class="btn btn-success w-100 mx-2" type="button">
                        Save
                    </button>
                    <button (click)="cancel()" class="btn btn-secondary w-100 mx-2" type="button">Cancel</button>
                </div>
            </div>

            <div *ngIf="options.editInfo || options.editInternalNote" class="col-auto p-3 text-data">
                <div (click)="toggleEditMode()" class="d-flex flex-row pointer px-3">
                    <i [class.text-primary]="editMode" [class.text-secondary-light]="!editMode"
                       class="fas fa-pencil me-1"></i>
                    <span class="text-underline fw-bold text-uppercase">Edit</span>
                </div>
            </div>

            <div class="col p-3 bg-secondary-light text-black">
                <div class="row text-label align-items-center justify-content-between">
                    <div class="col-auto">
                        Internal Note
                    </div>
                </div>
                <div class="row my-3 text-data">
                    <div class="col">
						<textarea
                                [(ngModel)]="info.note"
                                [readonly]="!(editMode && options.editInternalNote)"
                                class="form-control"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="open && options.displayStaff" class="row g-0 border border-top-0 text-data text-secondary">
            <div class="col p-3">
                <span *ngIf="staffConfig && staffConfig.title" class="fw-bold ms-4 mb-2">{{ staffConfig.title }}</span>

                <trella-multi-grid
                        (addNewItem)="addKeyStaff($event)"
                        (getStaff)="refreshStaff()"
                        (toggleItem)="toggleItem($event)"
                        *ngIf="staffConfig"
                        [configs]="[staffConfig]"
                        [dataChange]="staffdataChange"
                        [isLoading]="dataUpdate"
                        [key]="key"
                >
                </trella-multi-grid>
            </div>
        </div>
    </div>
</div>
