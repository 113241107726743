<div class="row align-items-center">
    <div class="col-auto mx-auto d-flex flex-row align-items-center">
        <highcharts-chart
            #chart
            #highchartimage
            *ngIf="isValidData()"
            [Highcharts]="highCharts"
            [callbackFunction]="chartCallback"
            [options]="chartOptions"
        ></highcharts-chart>
    </div>
</div>
