<kendo-dialog (close)="close()" *ngIf="opened">
    <kendo-dialog-titlebar>
        <div *ngIf="title" class="d-flex flex-row align-items-center text-white">
            <i *ngIf="titleIcon" class="{{titleIcon}} mx-3"></i>
            <div class="text-heading"> {{ title }}</div>
        </div>
    </kendo-dialog-titlebar>

    <div class="row g-0 p-4 g-0">
        <div class="col">
            <div *ngIf="message" class="row">
                <div class="col">
                    <div [innerHTML]="message" class="text-body"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row g-0 pt-3">
        <div class="col"></div>
        <div class="col-auto">
            <button (click)="cancel()" class="btn btn-light" type="button">{{ cancelButtonLabel }}</button>
        </div>
        <div class="col-auto ms-2">
            <button (click)="ok()" class="btn btn-success" type="button">{{ okButtonLabel }}</button>
        </div>
    </div>

</kendo-dialog>
