export enum TargetingStrategyName {
    UnaffiliatedFacilityHomeHealthStrategy = 'UnaffiliatedFacilityHomeHealthStrategy',
    UnaffiliatedFacilityHospiceStrategy = 'UnaffiliatedFacilityHospiceStrategy',
    UnaffiliatedPhysicianHomeHealthStrategy = 'UnaffiliatedPhysicianHomeHealthStrategy',
    UnaffiliatedPhysicianHospiceStrategy = 'UnaffiliatedPhysicianHospiceStrategy'
}

export enum TargetingStrategyDisplay {
    UnaffiliatedFacilityHomeHealthStrategy = 'Unaffiliated Facility Home Health Strategy',
    UnaffiliatedFacilityHospiceStrategy = 'Unaffiliated Facility Hospice Strategy',
    UnaffiliatedPhysicianHomeHealthStrategy = 'Unaffiliated Physician Home Health Strategy',
    UnaffiliatedPhysicianHospiceStrategy = 'Unaffiliated Physician Hospice Strategy'
}

export const getTargetingStrategyName = (targetingStrategy: TargetingStrategyDisplay): TargetingStrategyName => {
    switch (targetingStrategy) {
        case TargetingStrategyDisplay.UnaffiliatedFacilityHomeHealthStrategy:
            return TargetingStrategyName.UnaffiliatedFacilityHomeHealthStrategy;
        case TargetingStrategyDisplay.UnaffiliatedFacilityHospiceStrategy:
            return TargetingStrategyName.UnaffiliatedFacilityHospiceStrategy;
        case TargetingStrategyDisplay.UnaffiliatedPhysicianHomeHealthStrategy:
            return TargetingStrategyName.UnaffiliatedPhysicianHomeHealthStrategy;
        case TargetingStrategyDisplay.UnaffiliatedPhysicianHospiceStrategy:
            return TargetingStrategyName.UnaffiliatedPhysicianHospiceStrategy;
    }
};
