import { Pipe, PipeTransform } from '@angular/core';
import { TargetingStrategyDisplay, TargetingStrategyName } from '../../../enums/targeting-strategy-name.enum';

@Pipe({name: 'targetingStrategy'})
export class TargetingStrategyPipe implements PipeTransform {
    transform(value: TargetingStrategyName): string {
        switch (value) {
            case TargetingStrategyName.UnaffiliatedFacilityHomeHealthStrategy:
                return TargetingStrategyDisplay.UnaffiliatedFacilityHomeHealthStrategy;
            case TargetingStrategyName.UnaffiliatedFacilityHospiceStrategy:
                return TargetingStrategyDisplay.UnaffiliatedFacilityHospiceStrategy;
            case TargetingStrategyName.UnaffiliatedPhysicianHomeHealthStrategy:
                return TargetingStrategyDisplay.UnaffiliatedPhysicianHomeHealthStrategy;
            case TargetingStrategyName.UnaffiliatedPhysicianHospiceStrategy:
                return TargetingStrategyDisplay.UnaffiliatedPhysicianHospiceStrategy;
            default:
                return null;
        }
    }
}
