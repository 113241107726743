import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Market } from '@appcore/models/directory/market.model';

@Injectable({
	providedIn: 'root'
})
export class MarketApi {

	private _controllerName = 'Market';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getMarkets(): Observable<Market[]> {
		return this.api.get(this._controllerName);
	}
}
