import { UserContract } from '@appcore/models/directory/user/user-contract.model';
import { ProductName } from '@appcore/enums/product-name.enum';


export class AppUser extends UserContract {
	name: string;
	purposeId: number;
	customerId: number;
	failedLoginAttemptCount: number;
	lastPasswordChange: Date;
	marketscapeId: number;
	userAccess: Map<string, string[]>;
	permissions: string[];
	userIsAdmin: boolean;
	showEula: boolean;
	showDataEula: boolean;
	token: string;

	setPermissions(userPermissions: string[]) {
		this.permissions = userPermissions;
	}

	getProductNames(): ProductName[] {
		return Array.from(new Set(this.products.map(p => p.productName)));
	}
}
