import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseChart } from '../base-chart';
import * as Highcharts from 'highcharts';
import { ChartDataType } from '../../../../enums/chart-data-type.enum';
import { ChartConfig } from '../../../../models/chart-config.model';
import { ChartDataElement, ChartDataSeries } from '../../../../models/chart-data-series.model';
import { FormattedData } from '../../../../interfaces/formatted-data.interface';
import { RAW_VALUE } from '../../../../enums/raw-value.enum';
import { ChartUtils } from '../../../../models/chart-utils.model';

@Component({
    selector: 'trella-column-chart-with-averages',
    templateUrl: './column-chart-with-averages.component.html',
    styleUrls: ['./column-chart-with-averages.component.scss']
})
export class ColumnChartWithAveragesComponent extends BaseChart implements OnInit {
    @ViewChild('chart', {static: true}) public chartElement: ElementRef;
    @ViewChild('highchartimage') highChartImage: Highcharts.Chart;

    dataSetCount = 3; // this number is for the main data (bars), state & county data (shapes)

    shapeRadius = 6;
    dataBarColor: string[] = [this.seriesColors[0]];
    averageChartType = 'scatter';

    countyMarkerShape = 'triangle';
    countyMarkerColor = '#FF8200';

    stateMarkerShape = 'circle';
    stateMarkerColor = '#43B02A';

    Highcharts = Highcharts; // required
    chartOptions: any;
    filterList: Selection[] = [];
    selectedData: Selection;
    filteredData = [];
    font;
    filter;
    dataType;

    private chart;
    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.font = {
            fontSize: this.fullScreen ? '.9rem' : '.68rem',
            // eslint-disable-next-line quotes
            fontFamily: 'Roboto Condensed, \'sans-serif\'',
            fontWeight: this.fullScreen ? 'bold' : ''
        };
    }

    readData(chartInfo: ChartConfig): ChartDataSeries {
        super.readData(chartInfo);
        const barChartData: ChartDataElement[] = [];
        const data = chartInfo.data;
        const categoriesField = chartInfo.categoriesField;
        this.dataType = chartInfo.dataType;
        this.filter = chartInfo.key;
        const valueFields = chartInfo.groups.map(x => ({
            key: x.field,
            label: x.label
        }));

        valueFields.forEach(field =>
            barChartData.push({
                data: data.map(d => d[field.key].formattedValue || d[field.key]),
                rawData: data.map(d => this.formatValue(d[field.key])),
                label: field.label
            } as ChartDataElement));

        const categories = data.map(d => d[categoriesField] && (d[categoriesField].value || d[categoriesField]));
        const validData = this.validateData(barChartData);

        return {
            data: barChartData,
            categories,
            isValidData: validData
        };
    }

    formatValue(value: FormattedData) {
        switch (this.options.dataType) {
            case ChartDataType.Percent:
                return +value.value <= 0 ? 0 : +value.value;
            default:
                return this.getFormattedCountValue(value);
        }
    }

    getFormattedCountValue(value: FormattedData): number {
        const valueToRead = value.value || value;
        if (valueToRead === RAW_VALUE.negativeOne)
            return 5;

        if (+valueToRead <= 0)
            return 0;


        if (!valueToRead) {
            // if null
            return 0;
        }

        return +valueToRead;
    }

    validateData(data: ChartDataElement[]): boolean {
        return data && data.length && !this.chartOptions;
    }

    showFilter() {
        return this.data.data && this.data.data.length && this.data.data.length / this.dataSetCount > 1;
    }

    selectionChange(filter: any) {
        this.filter = filter;
        this.updateChart();
    }

    getFilteredData() {
        this.filteredData = [];
        this.filteredData.push(this.data.data.find(d => d.label.toLowerCase() === this.filter.toLowerCase()));
        this.filteredData.push(
            this.data.data.find(d => d.label.toLowerCase() === (this.filter + ' ' + this.countyAverageKey).toLowerCase())
        );
        this.filteredData.push(
            this.data.data.find(d => d.label.toLowerCase() === (this.filter + ' ' + this.stateAverageKey).toLowerCase())
        );
    }

    initializeChartOptions() {
        this.chartOptions = {
            chart: {},
            credits: {
                enabled: false // remove highchars watermark
            },
            boost: {
                enabled: true
            },
            title: {
                text: ''
            },
            export: {
                enabled: false
            },
            legend: {
                align: 'center',
                verticalAlign: 'top',
                y: 15,
                symbolRadius: 0,
                itemStyle: this.font
            },
            yAxis: {
                min: 0,
                labels: {
                    style: this.font
                },
                tooltipValueFormat: this.dataType,
                title: {
                    text: this.yAxisTitle && this.yAxisTitle.text,
                    style: this.font
                }
            },
            tooltip: {
                formatter(tooltip) {
                    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                    function getTooltipValue(value: any, symbol: string) {
                        return value.y ? ChartUtils.getChartDisplayedValue(value.y, symbol) : value.label;
                    }

                    const index = this.point.index;
                    const format =
                        this.series &&
                        this.series.yAxis &&
                        this.series.yAxis.userOptions &&
                        this.series.yAxis.userOptions.tooltipValueFormat;

                    const header = `<span style='font-size: 12px'>${this.key}</span><br/>`;

                    const series = tooltip.chart.axes[1].series;
                    const mainData = series[0].options.data;
                    const countyData = series[1].options.data;
                    const stateData = series[2].options.data;

                    const rawDataValue = mainData[index].y;
                    const dataTypeSymbol = ChartUtils.getFormattedChartTooltipType(rawDataValue, format);

                    const displayValue = getTooltipValue(mainData[index], dataTypeSymbol);
                    const countyValue = getTooltipValue(countyData[index], dataTypeSymbol);
                    const stateValue = getTooltipValue(stateData[index], dataTypeSymbol);

                    const displayData = `<b>${displayValue}</b><br/>`;
                    const displayCounty = `County: <b>${countyValue}</b><br/>`;
                    const displayState = `State: <b>${stateValue}</b><br/>`;

                    return header + displayData + displayCounty + displayState;
                }
            },
            plotOptions: {
                column: {
                    grouping: false,
                    softThreshold: true
                },
                line: {
                    marker: {
                        enabled: false
                    }
                }
            },
            colors: this.dataBarColor,
            xAxis: {
                title: {
                    text: this.xAxisTitle && this.xAxisTitle.text,
                    style: this.font
                },
                type: 'category',
                categories: this.data.categories,
                labels: {
                    style: this.font
                }
            },
            series: [
                {
                    type: 'column',
                    name: this.filter,
                    data: this.getSeries(0)
                },
                {
                    name: this.countyAverageKey,
                    type: this.averageChartType,
                    data: this.getSeries(1),
                    marker: {
                        symbol: this.countyMarkerShape,
                        radius: this.shapeRadius,
                        fillColor: this.countyMarkerColor
                    }
                },
                {
                    name: this.stateAverageKey,
                    type: this.averageChartType,
                    data: this.getSeries(2),
                    marker: {
                        symbol: this.stateMarkerShape,
                        radius: this.shapeRadius,
                        fillColor: this.stateMarkerColor
                    }
                }
            ]
        };
    }

    updateChart() {
        this.chartOptions.chart.renderTo = this.chartElement['el'] && this.chartElement['el'].nativeElement;
        this.chart = Highcharts.chart(this.chartOptions);

        if (this.chart) {
            this.chart.series[0].update({
                data: [...this.filteredData[0].rawData],
                name: this.filter
            });

            this.chart.series[1].setData([...this.filteredData[1].data]);
            this.chart.series[2].setData([...this.filteredData[2].data]);

            this.chart.redraw(true);
        }
    }

    postDataRead() {
        this.getFilteredData();
        this.initializeChartOptions();
    }

    getSeries(index: number) {
        return [
            {y: this.filteredData[index].rawData[0], label: this.filteredData[index].data[0]},
            {y: this.filteredData[index].rawData[1], label: this.filteredData[index].data[1]},
            {y: this.filteredData[index].rawData[2], label: this.filteredData[index].data[2]}
        ];
    }

    handleOptionsChange(config: ChartConfig) {
        // no op
    }
}
