import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { Observable } from 'rxjs';
import { SelectionsWithRangeSelection } from '../../../models/selections-with-range-selection.model';
import { RangeFilterRules } from '../../../models/ranges/range-filter-rules.model';
import { FILTER_TYPE } from '../../../enums/filter-type.enum';
import { getRangeFilterNameByType } from '../../../constants/get-range-filter-name-by-type.constant';
import { RangeFilterType } from '../../../enums/range-filter-type.enum';
import { FilterGroupService } from '../../../services/filter-group.service';
import { SelectionsWithRange } from '../../../interfaces/selections-with-range.interface';

@Component({
    selector: 'trella-affiliation',
    templateUrl: './affiliation.component.html',
    styleUrls: ['./affiliation.component.scss']
})
export class AffiliationComponent extends BaseFilterComponent {
    @Input() definitionFactory: (FILTER_TYPE) => string;
    @Input() titleFactory: (FILTER_TYPE) => string;
    @Output() affiliationsChanged: Observable<SelectionsWithRangeSelection>;

    rangeRules: RangeFilterRules;
    selectedAffiliations: SelectionsWithRangeSelection;

    filterType: FILTER_TYPE = FILTER_TYPE.AFFILIATIONS;
    rangeFilterName = getRangeFilterNameByType(RangeFilterType.LEVEL_OF_AFFILIATION);

    private _affiliationsChanged: EventEmitter<SelectionsWithRangeSelection> = new EventEmitter<SelectionsWithRangeSelection>();

    constructor(protected filterGroupService: FilterGroupService) {
        super(filterGroupService);
        this.affiliationsChanged = this._affiliationsChanged.asObservable();
    }

    get getDefinition(): string {
        return this.definitionFactory(this.filterType) ?? '';
    }

    get getTitle(): string {
        return this.titleFactory && this.titleFactory(this.filterType) || 'Facility Affiliation';
    }

    get getCount(): number {
        return this.selectedAffiliations?.items?.filter(i => i.checked).length || 0;
    }

    @Input() set affiliationRules(affiliations: SelectionsWithRange) {
        if (affiliations)
            this.rangeRules = new RangeFilterRules(affiliations.range);

    }

    @Input() set currentAffiliations(affiliations: SelectionsWithRangeSelection) {
        this.selectedAffiliations = {
            items: affiliations.items,
            range: {...affiliations.range}
        };
    }

    handleRangeChange() {
        const range = this.selectedAffiliations.range;
        this._affiliationsChanged.emit({
            ...this.selectedAffiliations,
            range
        });
    }

    handleSelectedAffiliationsChange() {
        this._affiliationsChanged.emit(this.selectedAffiliations);
    }

    getRangeMin() {
        return this.selectedAffiliations?.range.minSelected || 0;
    }

    getRangeMax() {
        return this.selectedAffiliations?.range.maxSelected || 100;
    }
}
