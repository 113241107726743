import { Injectable } from '@angular/core';
import $ from 'jquery';
import { NotificationsComponent } from '../components/notifications/notifications.component';

@Injectable({
    providedIn: 'root'
})
export class UserFeedbackService {
    public globalNotification: NotificationsComponent;

    setGlobalNotificationComponent(component: NotificationsComponent) {
        this.globalNotification = component;
    }

    startSpinner() {
        $('trella-spinner').show();
    }

    stopSpinner() {
        $('trella-spinner').hide();
    }

    showSaveSucess(object: string) {
        this.globalNotification.showSaveSucess(object);
    }

    showCreateSucess(object: string) {
        this.globalNotification.showCreateSucess(object);
    }

    showDeleteSucess(object: string) {
        this.globalNotification.showDeleteSucess(object);
    }

    showSaveError(object: string) {
        this.globalNotification.showSaveError(object);
    }

    showUnexpectedError() {
        this.globalNotification.showUnexpectedError();
    }

    showSuccess(message) {
        this.globalNotification.showSuccess(message);
    }

    showWarning(message) {
        this.globalNotification.showWarning(message);
    }

    showError(message) {
        this.globalNotification.showError(message);
    }

    resetNotifications() {
        this.globalNotification.reset();
    }
}
