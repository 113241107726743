import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';

export class QueryOptions implements DataStateChangeEvent {
    filter?: CompositeFilterDescriptor;
    group?: Array<GroupDescriptor> = [];
    npi?: string;
    overrideSkip?: boolean;
    simpleSearch?: boolean;
    skip: number;
    sort?: Array<SortDescriptor> = [];
    take: number;

    constructor() {
        this.skip = 0;
        this.take = 25;
    }
}
