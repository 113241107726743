<trella-spinner></trella-spinner>

<div class="container-fluid">
	<div class="row py-3 justify-content-center">
		<div class="col-auto">
			<img class="img-fluid" src="../../assets/img/logo.jpg"/>
		</div>
	</div>
	<trella-eula (onUpdateEula)="updateEula($event)"></trella-eula>
</div>
