import '../helpers/extensions/extensions';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartTooltipComponent } from './chart/chart-tooltip/chart-tooltip.component';
import { BarChartComponent } from './chart/chart-types/bar-chart/bar-chart.component';
import {
    ColumnChartWithAveragesComponent
} from './chart/chart-types/column-chart-with-averages/column-chart-with-averages.component';
import { ColumnChartComponent } from './chart/chart-types/column-chart/column-chart.component';
import { InsightComponent } from './chart/chart-types/insight/insight.component';
import { LineChartComponent } from './chart/chart-types/line-chart/line-chart.component';
import { PieChartComponent } from './chart/chart-types/pie-chart/pie-chart.component';

import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AcoDemographicCardComponent } from './aco-demographic-card/aco-demographic-card.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { TopInsightComponent } from './chart/chart-types/insight-top/top-insight.component';
import { SunburstChartComponent } from './chart/chart-types/sunburst-chart/sunburst-chart.component';
import { TopPlacesInsightComponent } from './chart/chart-types/top-places-insight/top-places-insight.component';
import { TrellaChartComponent } from './chart/chart.component';
import { ComparisonsDialogComponent } from './comparisons-dialog/comparisons-dialog.component';
import { ComponentsComponent } from './components.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DataEulaComponent } from './data-eula/data-eula.component';
import { DemographicCardComponent } from './demographic-card/demographic-card.component';
import { EulaComponent } from './eula/eula.component';
import { FilterSelectionDropdownComponent } from './filter-selection-dropdown/filter-selection-dropdown.component';
import { AffiliationComponent } from './filters/affiliation/affiliation.component';
import { DateFilterComponent } from './filters/date/date-filter.component';
import { FilterGroupComponent } from './filters/filter-group.component';
import { LocationComponent } from './filters/location/location.component';
import { MultiLevelFilterComponent } from './filters/multi-level/multi-level-filter.component';
import { RangeComponent } from './filters/range/range.component';
import { SearchResultContainerDirective } from './filters/searchResultContainer.directive';
import { SimpleFilterComponent } from './filters/simple/simple-filter.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { PostNotesComponent } from './grid/post-notes/post-notes.component';
import { RowDetailComponent } from './grid/row-detail-grid/row-detail-grid.component';
import { TrellaGridComponent } from './grid/trella-grid.component';
import { HoverTooltipComponent } from './hover-tooltip/hover-tooltip.component';
import { InfoCircleComponent } from './info-circle/info-circle.component';
import { ListBoxComponent } from './list-box/list-box.component';
import { MultiChartComponent } from './multi-chart/multi-chart.component';
import { MultiGridComponent } from './multi-grid/multi-grid.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { RiskAdjustedToggleComponent } from './risk-adjusted-toggle/risk-adjusted-toggle.component';
import { SearchComponent } from './search/search.component';
import { SparklineAreaComponent } from './sparkline-area/sparkline-area.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StarsComponent } from './stars/stars.component';
import { TabsComponent } from './tabs/tabs.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { TrellaNestedGridComponent } from './grid/nested-grid/trella-nested-grid.component';
import { TrellaGridBaseComponent } from './grid/trella-grid-base.component';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { DualListBoxComponent } from './dual-list-box/dual-list-box.component';
import { SavableWrapperComponent } from './savable-wrapper/savable-wrapper.component';
import { RadioListBoxComponent } from './radio-list-box/radio-list-box.component';
import { AbstractValueAccessor } from '../components/AbstractValueAccessor';
import { AlertDialogDirective } from '../helpers/directives/alert-dialog.directive';
import { ComponentWithSubscription } from '../components/component-with-subscription';
import { GridCellIconDirective } from '../helpers/directives/grid-cell-icon.directive';
import { NavigationButtonDirective } from '../helpers/directives/navigation-button.directive';
import { PercentileEvaluationDirective } from '../helpers/directives/percentile-evaluation.directive';
import { SmartComparisonDirective } from '../helpers/directives/smart-comparison.directive';
import { PipeModule } from '../helpers/pipes/pipes.module';
import { ClickOutsideDirective } from '../helpers/directives/click-outside.directive';

@NgModule({
    declarations: [
        AbstractValueAccessor,
        AcoDemographicCardComponent,
        AffiliationComponent,
        AlertDialogComponent,
        AlertDialogDirective,
        ButtonDropdownComponent,
        DateFilterComponent,
        SimpleFilterComponent,
        RangeComponent,
        BarChartComponent,
        ChartTooltipComponent,
        ClickOutsideDirective,
        ColumnChartComponent,
        ColumnChartWithAveragesComponent,
        ComparisonsDialogComponent,
        ComponentWithSubscription,
        ComponentsComponent,
        ConfirmationDialogComponent,
        DateFilterComponent,
        DataEulaComponent,
        DemographicCardComponent,
        DualListBoxComponent,
        EulaComponent,
        FilterGroupComponent,
        FilterSelectionDropdownComponent,
        GlobalSearchComponent,
        GridCellIconDirective,
        HoverTooltipComponent,
        InfoCircleComponent,
        InsightComponent,
        LineChartComponent,
        ListBoxComponent,
        LocationComponent,
        MultiChartComponent,
        MultiGridComponent,
        NavigationButtonDirective,
        NotificationsComponent,
        SimpleFilterComponent,
        RangeComponent,
        PercentileEvaluationDirective,
        PieChartComponent,
        PostNotesComponent,
        RiskAdjustedToggleComponent,
        RowDetailComponent,
        SavableWrapperComponent,
        SearchComponent,
        SearchResultContainerDirective,
        SparklineAreaComponent,
        MultiLevelFilterComponent,
        RadioListBoxComponent,
        SmartComparisonDirective,
        SpinnerComponent,
        StarsComponent,
        SunburstChartComponent,
        TabsComponent,
        TopInsightComponent,
        TopMenuComponent,
        TopPlacesInsightComponent,
        TrellaChartComponent,
        TrellaGridComponent,
        TrellaNestedGridComponent,
        TrellaGridBaseComponent,
        UserSettingsComponent
    ],
    imports: [
        ChartsModule,
        CommonModule,
        DateInputsModule,
        DialogsModule,
        DropDownsModule,
        ExcelModule, // Not exported - use App-Grid instead
        FormsModule,
        GridModule,
        HighchartsChartModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDividerModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatSliderModule,
        PipeModule,
        ReactiveFormsModule,
        RouterModule,
        ScrollingModule,
        TooltipModule,
        NgbDropdownModule
    ],
    exports: [
        AcoDemographicCardComponent,
        AffiliationComponent,
        AlertDialogComponent,
        ButtonDropdownComponent,
        DateFilterComponent,
        SimpleFilterComponent,
        RangeComponent,
        TrellaChartComponent,
        ClickOutsideDirective,
        ComparisonsDialogComponent,
        ComponentsComponent,
        ConfirmationDialogComponent,
        DataEulaComponent,
        DemographicCardComponent,
        DualListBoxComponent,
        EulaComponent,
        FilterGroupComponent,
        FilterSelectionDropdownComponent,
        GlobalSearchComponent,
        GridCellIconDirective,
        HighchartsChartModule,
        HoverTooltipComponent,
        InfoCircleComponent,
        ListBoxComponent,
        LocationComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatDividerModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatSliderModule,
        MultiChartComponent,
        MultiGridComponent,
        NavigationButtonDirective,
        NotificationsComponent,
        PercentileEvaluationDirective,
        PipeModule,
        RadioListBoxComponent,
        RiskAdjustedToggleComponent,
        RouterModule,
        ScrollingModule,
        SparklineAreaComponent,
        MultiLevelFilterComponent,
        SavableWrapperComponent,
        SmartComparisonDirective,
        SpinnerComponent,
        SearchComponent,
        StarsComponent,
        TabsComponent,
        TopMenuComponent,
        TopPlacesInsightComponent,
        TrellaGridComponent,
        TrellaNestedGridComponent,
        TrellaGridBaseComponent,
        UserSettingsComponent
    ]
})
export class ComponentsModule {
    public static forRoot() {
        return {
            ngModule: ComponentsModule,
            providers: []
        };
    }
}
