import { NgModule } from '@angular/core';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { NetworkAccessLevelPipe } from './pipes/network-access-level.pipe';
import { PurposePipe } from './pipes/purpose.pipe';
import { TargetingStrategyPipe } from './pipes/targeting-strategy.pipe';
import { TextTruncatePipe } from './pipes/text-truncate.pipe';
import { UserTypePipe } from './pipes/user-type.pipe';

@NgModule({
    imports: [],
    declarations: [FilterListPipe, NetworkAccessLevelPipe, PurposePipe, TargetingStrategyPipe, TextTruncatePipe, UserTypePipe],
    exports: [FilterListPipe, NetworkAccessLevelPipe, PurposePipe, TargetingStrategyPipe, TextTruncatePipe, UserTypePipe]
})
export class PipeModule {
    static forRoot() {
        return {
            ngModule: PipeModule,
            providers: []
        };
    }
}
