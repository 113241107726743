import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentUserResponse } from '../shared/models/current-user-response';
import { EditableUser } from '../shared/models/editable-user';
import { LegacyUserListResponse } from '../shared/models/legacy-user-list-response';
import { ReportUser } from '../shared/models/scheduled-report';
import { ResetPasswordResponse } from '../shared/models/user';
import { ApiService } from './api.service';
import { ImportResult } from '../shared/models/import-result';
import { ProductName } from '@appcore/enums/product-name.enum';
import { UserContract } from '@appcore/models/directory/user/user-contract.model';
import { Selection } from '@appcore/interfaces/selection.interface';

@Injectable({
	providedIn: 'root'
})
export class UserApi {

	private _controllerName = 'User';

	constructor(private api: ApiService, protected http: HttpClient) {
	}

	getCurrent(): Observable<CurrentUserResponse> {
		return this.api.get(this._controllerName, 'Current');
	}

	getCompanyProductUsers(companyId: string, productNames: ProductName[]): Observable<UserContract[]> {
		return this.api.post(this._controllerName, `getcompanyproductusers/${companyId}/`, productNames);
	}

	get(userId: string): Observable<UserContract> {
		return this.api.get(this._controllerName, `${userId}`);
	}

	getEditableUser(userId: string): Observable<EditableUser> {
		return this.api.get(this._controllerName, `${userId}/editable`);
	}

	getUserTypes(): Observable<Selection[]> {
		return this.api.get(this._controllerName, `types`);
	}

	getScheduledReportCompanyUsers(companyId: number): Observable<ReportUser[]> {
		return this.api.get(this._controllerName, `scheduledreports/users/company/${companyId}`);
	}

	getScheduledReportUsers(): Observable<ReportUser[]> {
		return this.api.get(this._controllerName, `scheduledreports/users`);
	}

	getAllUsers(): Observable<LegacyUserListResponse[]> {
		return this.api.get(this._controllerName, 'getAllUsers');
	}

	getUsers(companyId: string): Observable<UserContract[]> {
		return this.api.get(this._controllerName, `company/${companyId}`);
	}

	getUsersPerissions(id: string): Observable<any[]> {
		return this.api.get(this._controllerName, `userPermissions/${id}`);
	}

	create(user: EditableUser, sendWelcomeEmail: boolean) {
		return this.api.post(this._controllerName, `create/${sendWelcomeEmail}`, user);
	}

	update(user: EditableUser) {
		return this.api.post(this._controllerName, `update`, user);
	}

	requestUserPasswordResetToken(ids: string[], password: string, sendEmail: boolean): Observable<ResetPasswordResponse> {
		return this.api.post(this._controllerName, 'setuserpasswords', {
			ids,
			password,
			sendEmail
		});
	}

	resendWelcomeEmail(userId: string): Observable<void> {
		return this.api.patch(this._controllerName, `resendwelcomeemail/${userId}`);
	}

	resendWelcomeEmails(userIds: string[]): Observable<void> {
		return this.api.post(this._controllerName, `resendwelcomeemails`, { userIds });
	}

	setUsersIsEnabled(ids: string[], enable: boolean): Observable<void> {
		return this.api.post(this._controllerName, `setUsersIsEnabled`, { ids, enable });
	}

	uploadUsers(base64string: string, fileName: string): Observable<ImportResult> {
		return this.api.post(this._controllerName, 'upload',
			{
				file: base64string,
				fileName
			}
		);
	}
}
