import { Injectable, OnInit } from '@angular/core';
import { FilterGroupService } from '../../services/filter-group.service';
import { FILTER_TYPE } from '../../enums/filter-type.enum';

@Injectable()
export abstract class BaseFilterComponent {

    isOpen: boolean;
    isDisabled = false;
    abstract filterType: FILTER_TYPE;

    constructor(protected filterGroupService: FilterGroupService) {
        this.filterGroupService.filtersClosed.subscribe(() => this.isOpen = false);
        this.filterGroupService.filterDisabled.subscribe((filterType: FILTER_TYPE) => this.disableFilter(filterType));
        this.filterGroupService.filterEnabled.subscribe((filterType: FILTER_TYPE) => this.enableFilter(filterType));
        this.filterGroupService.filtersToggled.subscribe((filterType: FILTER_TYPE) => this.closeFilter(filterType));
    }


    abstract get getCount(): number;

    toggleFilter() {
        if (!this.isOpen && !this.isDisabled) 
            this.filterGroupService.toggleFilters(this.filterType);
        

        this.isOpen = !this.isOpen && !this.isDisabled;
    }

    closeFilter(filterType: FILTER_TYPE) {
        if (filterType !== this.filterType) 
            this.isOpen = false;
        
    }

    disableFilter(filterType: FILTER_TYPE) {
        if (filterType === this.filterType) {
            this.isDisabled = true;
            this.isOpen = false;
        }
    }

    enableFilter(filterType: FILTER_TYPE) {
        if (filterType === this.filterType) 
            this.isDisabled = false;
        
    }
}
