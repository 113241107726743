<div class="d-flex flex-column insight-wrapper my-3 mx-4">
    <div *ngIf="isValidData()">
        <div class="d-flex flex-row flex-shrink-1 justify-content-between">
            <div class="lead fw-normal insight-small-text">
                {{ options && options.displayName }}
            </div>
            <div>
                <trella-info-circle
                        *ngIf="options && options.tooltip"
                        [tooltipText]="options.tooltip"
                        class="zindex-foreground"
                ></trella-info-circle>
            </div>
        </div>
        <div class="d-flex flex-row mt-2 justify-content-center align-items-center flex-grow-1">
            <div *ngFor="let datapoint of datapoints; first as isFirst; count as count" [ngClass]="{ 'border-start': !isFirst }"
                 class="px-4">
                <div
                        [ngClass]="{ 'insight-large-text': count === 1, 'insight-medium-text': count > 1 }"
                        class="text-center fw-light"
                >
                    {{ datapoint.data }}
                </div>
                <div *ngIf="datapoint.riskCategory" class="text-center insight-small-text">
                    {{ datapoint.riskCategory }}
                </div>
                <div *ngIf="datapoint.facilityType" class="text-center insight-small-text">
                    {{ datapoint.facilityType }}
                </div>
                <div *ngIf="datapoint.percentile && datapoint.data !== 'ins'" class="text-break insight-small-text">
                    This is in the <span [mosaic-percentile-evaluation]="quality">{{ datapoint.percentile }}
                    percentile</span> of physicians
                    in the selected network.
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isValidData()">
        <div class="container">
            <div class="insight-content-wrapper row">
                <div class="col my-auto">
                    <div class="insight-blank-empty-text text-center fw-bold text-uppercase text-secondary-light">
                        No Data Available
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
