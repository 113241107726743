import { Component, ElementRef, NgZone, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { TrellaGridBaseComponent } from './trella-grid-base.component';
import { GridActionService } from '../../services/grid-action.service';
import { GridExportService } from '../../services/grid-export.service';
import { GridSortService } from '../../services/grid-sort.service';
import { GridService } from '../../services/grid.service';
import { ReplaceValueMappingService } from '../../services/replacement-value-mapping.service';

@Component({
    selector: 'trella-grid',
    templateUrl: './trella-grid.component.html',
    styleUrls: ['./trella-grid.component.scss']
})
export class TrellaGridComponent extends TrellaGridBaseComponent implements OnChanges, OnInit {
    constructor(public element: ElementRef,
                public renderer: Renderer2,
                public zone: NgZone,
                public gridActionService: GridActionService,
                public gridExportService: GridExportService,
                public gridSortService: GridSortService,
                public gridService: GridService,
                public replaceValueMappingService: ReplaceValueMappingService) {
        super(element, renderer, zone, gridActionService, gridExportService, gridSortService, gridService, replaceValueMappingService);
    }
}
