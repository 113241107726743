import { Injectable } from '@angular/core';
import { ScheduledReportsApi } from '../../api/scheduledreports.api';
import { ScheduledReport } from '../models/scheduled-report';

@Injectable({
	providedIn: 'root'
})
export class ScheduledReportsService {
	constructor(private scheduledReportApi: ScheduledReportsApi) {
	}

	getScheduledReport(scheduledReportId: number) {
		return this.scheduledReportApi.get(scheduledReportId);
	}

	async editScheduledReport(scheduledReport: ScheduledReport) {
		if (!scheduledReport) 
			return;
		
		return this.scheduledReportApi.update(scheduledReport).toPromise();
	}

	async saveScheduledReports(scheduledReports: ScheduledReport[]) {
		if (!scheduledReports?.length) 
			return;
		
		return this.scheduledReportApi.create(scheduledReports).toPromise();
	}
}
