import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { Observable } from 'rxjs';
import { CheckedSelectionGroup } from '../../../interfaces/checked-selection-group.interface';
import { FILTER_TYPE } from '../../../enums/filter-type.enum';
import { FilterGroupService } from '../../../services/filter-group.service';
import { CheckedSelection } from '../../../interfaces/checked-selection.interface';

@Component({
    selector: 'trella-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent extends BaseFilterComponent {
    @Input() caption = '';
    @Input() definitionFactory: (FILTER_TYPE) => string;
    @Input() filterType: FILTER_TYPE;
    @Input() selectedDate: Date;
    @Input() titleFactory: (FILTER_TYPE) => string;
    @Output() selectionsChanged: Observable<CheckedSelectionGroup>;

    private _selectionsChanged: EventEmitter<CheckedSelectionGroup> = new EventEmitter<CheckedSelectionGroup>();

    constructor(protected filterGroupService: FilterGroupService) {
        super(filterGroupService);
        this.selectionsChanged = this._selectionsChanged.asObservable();
    }

    get getDefinition(): string {
        return this.definitionFactory && this.definitionFactory(this.filterType) || '';
    }

    get getTitle(): string {
        return this.titleFactory && this.titleFactory(this.filterType) || this.caption;
    }

    get checkedOptions(): CheckedSelection[] {
        return [];
    }

    get getCount(): number {
        return 0;
    }

    handleSelectionChange(selection: Date) {
        const selectionGroup = {
            filterType: this.filterType,
            selections: [({id: selection.toLocaleDateString(), display: selection.toLocaleDateString()})]
        };
        this._selectionsChanged.next(selectionGroup);
    }
}
