<div [class.small-switch]="isSmallSwitch" class="form-check form-switch">
    <input
            (change)="onRiskAdjustToggleChange.emit(isRiskAdjusted)"
            [(ngModel)]="isRiskAdjusted"
            class="form-check-input risk-adjust-toggle pointer pb-1"
            id="{{ riskAdjustSwitchId }}"
            role="switch"
            type="checkbox"
    />
    <label class="form-check-label pointer" for="{{ riskAdjustSwitchId }}">Risk Adjusted</label>
</div>
